<template>
    <v-container fluid class="py-0" :class="miniVariant == true ? 'pl-9' : ''" style="height:  100%">
        <v-row>
            <LoadingMe
            :isLoading="showLoadingTxn"
            style="padding: 0"
            :fullPage="false"
            :myLoading="true" />
            <v-offline hidden @detected-condition="amIOnline"></v-offline>
            <!-- Key in -->
            <v-dialog
                v-model="dialogKey"
                fullscreen
                hide-overlay
                persistent
                transition="dialog-bottom-transition"
            >
                <v-card style="background-color: #f8f8f9;">
                    <v-container>
                        <v-card style="width: 420px;margin: 0 auto;" outlined dense class="px-3 no_border mt-4" color="white">
                            <div class="modal_header">
                                <v-card-title>{{ $t("session_key") }}</v-card-title>
                            </div>
                            <v-card-text class="modal_text_content">
                                <v-row>
                                    <div id="pin" class="m-auto my-4">
                                        <v-otp-input
                                            length="8"
                                            v-model="sessionKey"
                                            plain
                                            type="password"
                                        ></v-otp-input>
                                        <h2 v-if="incorrect">{{$t("wrong_pin")}}</h2>
                                        <div class="numbers">
                                            <div @click="kpinClick(1)" class="number">1</div>
                                            <div @click="kpinClick(2)" class="number">2</div>
                                            <div @click="kpinClick(3)" class="number">3</div>
                                            <div @click="kpinClick(4)" class="number">4</div>
                                            <div @click="kpinClick(5)" class="number">5</div>
                                            <div @click="kpinClick(6)" class="number">6</div>
                                            <div @click="kpinClick(7)" class="number">7</div>
                                            <div @click="kpinClick(8)" class="number">8</div>
                                            <div @click="kpinClick(9)" class="number">9</div>
                                            <div @click="kpinClick('clear')" class="number" style="">C</div>
                                            <div @click="kpinClick(0)" class="number">0</div>
                                            <div @click="searchKPin" class="number" style="background-color: rgb(154 27 46);color: #ffff;">S</div>
                                        </div>
                                    </div>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-container>
                </v-card>
            </v-dialog>
            <!-- Check out-->
            <!-- Cash -->
            <v-dialog
                v-model="dialogCash"
                fullscreen
                hide-overlay
                persistent
                transition="dialog-bottom-transition"
            >
                <v-card style="background-color: #f8f8f9;">
                    <v-container>
                        <v-card outlined dense class="px-3 no_border mt-4" color="white">
                            <div class="modal_header">
                                <v-card-title>{{ $t("cash_receipt") }}</v-card-title>
                                <v-icon
                                    @click="dialogCash = false"
                                    style="cursor: pointer; font-size: 40px;"
                                    color="grey"
                                    class="float-right mt-n1">close
                                </v-icon>
                            </div>
                            <v-card-text class="modal_text_content">
                                <v-row>
                                    <v-col sm="6" cols="6" class="pr-0 py-0 border_right_lightgrey">
                                        <v-row>
                                            <v-col sm="6" cols="6" class=" function_content pa-3 pb-0">
                                                <v-simple-table>
                                                    <template v-slot:default>
                                                        <tbody>
                                                            <tr style="background-color: red;color: #fff;">
                                                                <td class="text-left pr-0 text-bold ">
                                                                    {{ $t('amount_to_pay')}}
                                                                </td>
                                                                <td class="text-center">:</td>
                                                                <td class="text-right text-bold">
                                                                    {{numberFormat(t.total)}} {{baseRate.code}}
                                                                </td>
                                                            </tr>
                                                            <tr v-if="isSecondRate">
                                                                <td class="text-left pr-0">{{ secondRate.code }}</td>
                                                                <td class="text-center">:</td>
                                                                <td class="text-right">
                                                                    {{numberFormat(t.total * parseFloat(secondRate.rate))}} {{secondRate.code}}
                                                                </td>
                                                            </tr>
                                                            <tr v-if="isSecondRate">
                                                                <td class="text-left pr-0">{{
                                                                    $t('exchange_rate')
                                                                }}
                                                                </td>
                                                                <td class="text-center">:</td>
                                                                <td class="text-right">
                                                                    {{numberFormat(parseFloat(secondRate.rate))}} {{secondRate.code}}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="text-left pr-0">{{
                                                                    $t('receipt_amount')
                                                                }}
                                                                </td>
                                                                <td class="text-center">:</td>
                                                                <td class="text-right">
                                                                    {{numberFormat(t.amtReceipt)}} {{baseRate.code}}
                                                                </td>
                                                            </tr>
                                                            <tr color="secondary">
                                                                <td color="secondary" class="text-left pr-0">
                                                                    {{$t('exchange')}} ({{baseRate.code}})
                                                                </td>
                                                                <td class="text-center">:</td>
                                                                <td color="secondary" class="text-right">
                                                                    {{ numberFormat(exchange.base) }}
                                                                </td>
                                                            </tr>
                                                            <tr v-if="isSecondRate" color="secondary">
                                                                <td color="secondary" class="text-left pr-0">
                                                                    {{$t('exchange')}} ({{secondRate.code}})
                                                                </td>
                                                                <td class="text-center">:</td>
                                                                <td color="secondary" class="text-right">
                                                                    {{ numberFormat(exchange.secondary) }}
                                                                </td>
                                                            </tr>
                                                            <tr color="secondary">
                                                                <td color="secondary" class="text-left pr-0">
                                                                    {{$t('receipt')}} (USD)
                                                                </td>
                                                                <td class="text-center">:</td>
                                                                <td color="secondary" class="text-right">
                                                                    {{ numberFormat(usdPaidAmt) }}
                                                                </td>
                                                            </tr>
                                                            <tr color="secondary">
                                                                <td color="secondary" class="text-left pr-0">
                                                                    {{$t('receipt')}} (KHR)
                                                                </td>
                                                                <td class="text-center">:</td>
                                                                <td color="secondary" class="text-right">
                                                                    {{ numberFormat(khrPaidAmt) }}
                                                                </td>
                                                            </tr>
                                                            <!-- <tr color="secondary">
                                                                <td color="secondary" class="text-left pr-0">
                                                                    {{$t('receipt')}} (Bank)
                                                                </td>
                                                                <td class="text-center">:</td>
                                                                <td color="secondary" class="text-right">
                                                                    {{ numberFormat(bankPaidAmt) }}
                                                                </td>
                                                            </tr> -->
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                                <v-btn @click="resetReceipt()" style="width: 100%;border-radius: 0!important;" color="secondary" class="text-bold rounded-0 receipt-btn ml-0">
                                                    {{ $t('reset') }}
                                                </v-btn>
                                            </v-col>
                                            <v-col sm="6" cols="6" class="pb-0">
                                                <div class="text-center">
                                                    <v-row>
                                                        <v-col sm="12" cols="12" class="py-0">
                                                            <h3>{{numberFormat(amtReceipt)}}</h3>
                                                        </v-col>
                                                        <v-col sm="12" cols="12" class="pt-0">
                                                            <v-btn @click="changeClick(1)" color="secondary" class="text-bold rounded-0 receipt-btn ml-0">
                                                                1
                                                            </v-btn>
                                                                
                                                            <v-btn @click="changeClick(2)"  color="secondary" class="text-bold rounded-0 receipt-btn">
                                                                2
                                                            </v-btn>

                                                            <v-btn @click="changeClick(3)" color="secondary" class="text-bold rounded-0 receipt-btn mr-0">
                                                                3
                                                            </v-btn>
                                                            <br>
                                                            <v-btn @click="changeClick(4)" color="secondary" class="text-bold rounded-0 receipt-btn ml-0">
                                                                4
                                                            </v-btn>
                                                                
                                                            <v-btn @click="changeClick(5)" color="secondary" class="text-bold rounded-0 receipt-btn">
                                                                5
                                                            </v-btn>

                                                            <v-btn @click="changeClick(6)" color="secondary" class="text-bold rounded-0 receipt-btn mr-0">
                                                                6
                                                            </v-btn>
                                                            <br>
                                                            <v-btn @click="changeClick(7)" color="secondary" class="text-bold rounded-0 receipt-btn ml-0">
                                                                7
                                                            </v-btn>
                                                                
                                                            <v-btn @click="changeClick(8)" color="secondary" class="text-bold rounded-0 receipt-btn">
                                                                8
                                                            </v-btn>

                                                            <v-btn @click="changeClick(9)" color="secondary" class="text-bold rounded-0 receipt-btn mr-0">
                                                                9
                                                            </v-btn>
                                                            <br>
                                                                
                                                            <v-btn @click="changeClick(0)" color="secondary" class="text-bold rounded-0 receipt-btn ml-0">
                                                                0
                                                            </v-btn>

                                                            <v-btn @click="changeClick('.')" color="secondary" class="text-bold rounded-0 receipt-btn">
                                                                .
                                                            </v-btn>

                                                            <v-btn @click="changeClick('Clear')" color="secondary" class="text-bold rounded-0 receipt-btn mr-0">
                                                                <v-icon>fas fa-backspace</v-icon>
                                                                c
                                                            </v-btn>
                                                        </v-col>
                                                        <v-col>
                                                            <v-btn @click="khrPaid(0)" color="secondary" class="text-bold rounded-0 receipt-btn ml-0">
                                                                KHR
                                                            </v-btn>
                                                                
                                                            <v-btn @click="usdPaid(0)"  color="secondary" class="text-bold rounded-0 receipt-btn">
                                                                USD
                                                            </v-btn></v-col>
                                                    </v-row>
                                                </div>
                                                
                                            </v-col>
                                            <v-col sm="12" cols="4" class="pl-0 pb-0">
                                                <v-tabs>
                                                    <v-tab v-if="hasUSD">
                                                        <span class="text-capitalize text-left">
                                                        {{ $t("usd") }}
                                                        </span>
                                                    </v-tab>
                                                    <v-tab v-if="hasKHR">
                                                        <span  class="text-capitalize text-left">
                                                        {{ $t("khr") }}
                                                        </span>
                                                    </v-tab>
                                                    <!-- <v-tab>
                                                        <span  class="text-capitalize text-left">
                                                        {{ $t("bank_wallets") }}
                                                        </span>
                                                    </v-tab> -->
                                                    <v-tab-item v-if="hasUSD">
                                                        <v-col sm="12" cols="12" class="grayBg">
                                                            <v-card color="white" class="pa-3 no_border text-center" elevation="0">
                                                                    <v-btn @click="changeClick('1$')" color="third" class="text-white text-bold rounded-0 receipt-btn">
                                                                        1$
                                                                    </v-btn>
                                                                    <v-btn @click="changeClick('2$')" color="third" class="text-white text-bold rounded-0 receipt-btn">
                                                                        2$
                                                                    </v-btn>
                                                                    <v-btn @click="changeClick('5$')" color="third" class="text-white text-bold rounded-0 receipt-btn">
                                                                        5$
                                                                    </v-btn>
                                                                        
                                                                    <v-btn @click="changeClick('10$')" color="third" class="text-white text-bold rounded-0 receipt-btn">
                                                                        10$
                                                                    </v-btn>

                                                                    <v-btn @click="changeClick('15$')" color="third" class="text-white text-bold rounded-0 receipt-btn">
                                                                        15$
                                                                    </v-btn>
                                                                    <v-btn @click="changeClick('20$')" color="third" class="text-white text-bold rounded-0 receipt-btn">
                                                                        20$
                                                                    </v-btn>
                                                                        
                                                                    <v-btn @click="changeClick('25$')" color="third" class="text-white text-bold rounded-0 receipt-btn">
                                                                        25$
                                                                    </v-btn>

                                                                    <v-btn @click="changeClick('30$')" color="third" class="text-white text-bold rounded-0 receipt-btn">
                                                                        30$
                                                                    </v-btn>

                                                                    <!-- <v-btn @click="changeClick('35$')" color="third" class="text-white text-bold rounded-0 receipt-btn">
                                                                        35$
                                                                    </v-btn> -->
                                                                    <v-btn @click="changeClick('40$')" color="third" class="text-white text-bold rounded-0 receipt-btn">
                                                                        40$
                                                                    </v-btn>

                                                                    <v-btn @click="changeClick('45$')" color="third" class="text-white text-bold rounded-0 receipt-btn">
                                                                        45$
                                                                    </v-btn>
                                                                    <v-btn @click="changeClick('50$')" color="third" class="text-white text-bold rounded-0 receipt-btn">
                                                                        50$
                                                                    </v-btn>
                                                                    <!-- <v-btn @click="changeClick('55$')" color="third" class="text-white text-bold rounded-0 receipt-btn">
                                                                        55$
                                                                    </v-btn> -->

                                                                    <v-btn @click="changeClick('60$')" color="third" class="text-white text-bold rounded-0 receipt-btn">
                                                                        60$
                                                                    </v-btn>
                                                                    <v-btn @click="changeClick('70$')" color="third" class="text-white text-bold rounded-0 receipt-btn">
                                                                        70$
                                                                    </v-btn>
                                                                    <v-btn @click="changeClick('80$')" color="third" class="text-white text-bold rounded-0 receipt-btn">
                                                                        80$
                                                                    </v-btn>

                                                                    <v-btn @click="changeClick('90$')" color="third" class="text-white text-bold rounded-0 receipt-btn">
                                                                        90$
                                                                    </v-btn>
                                                                    <v-btn @click="changeClick('100$')" color="third" class="text-white text-bold rounded-0 receipt-btn">
                                                                        100$
                                                                    </v-btn>
                                                            </v-card>
                                                        </v-col>
                                                    </v-tab-item>
                                                    <v-tab-item v-if="hasKHR">
                                                        <v-col sm="12" cols="12" class="grayBg">
                                                            <v-card color="white" class="pa-3 no_border text-center" elevation="0">
                                                                    <v-btn  @click="changeClick('100r')" color="primary" class="text-white text-bold rounded-0 receipt-btn">
                                                                        100
                                                                    </v-btn>
                                                                    <v-btn  @click="changeClick('500r')" color="primary" class="text-white text-bold rounded-0 receipt-btn">
                                                                        500
                                                                    </v-btn>
                                                                    <v-btn  @click="changeClick('1000r')" color="primary" class="text-white text-bold rounded-0 receipt-btn">
                                                                        1,000
                                                                    </v-btn>
                                                                    <v-btn  @click="changeClick('5000r')" color="primary" class="text-white text-bold rounded-0 receipt-btn">
                                                                        5,000
                                                                    </v-btn>
                                                                    <v-btn @click="changeClick('10000r')" color="primary" class="text-white text-bold rounded-0 receipt-btn">
                                                                        10,000
                                                                    </v-btn>
                                                                    <v-btn @click="changeClick('20000r')" color="primary" class="text-white text-bold rounded-0 receipt-btn">
                                                                        20,000
                                                                    </v-btn>
                                                                    <v-btn @click="changeClick('50000r')" color="primary" class="text-white text-bold rounded-0 receipt-btn">
                                                                        50,000
                                                                    </v-btn>
                                                                    <v-btn @click="changeClick('100000r')" color="primary" class="text-white text-bold rounded-0 receipt-btn">
                                                                        100,000
                                                                    </v-btn>
                                                            </v-card>
                                                        </v-col>
                                                    </v-tab-item>
                                                    <!-- <v-tab-item>
                                                        <v-col sm="12" cols="12" class="grayBg">
                                                            <v-card-text class="py-0" style="background-color: #f8f8f9;">
                                                                <v-row>
                                                                    <v-col v-for="b in bankWallets" v-bind:key="b.uid" class="pa-1 text-center" sm="4" cols="12">
                                                                        <v-card
                                                                            outlined
                                                                            dense
                                                                            class="pa-6  raduis_10 pop no_border pop"
                                                                            @click="bankPaid(b)"
                                                                            min-height="150"
                                                                            >
                                                                            <div style="display: flex;justify-content: space-between; margin-top: 0px;">
                                                                                <h1
                                                                                class="font_12 primary--text"
                                                                                style="text-transform: uppercase;">
                                                                                    {{b.bank.name}}
                                                                                </h1>
                                                                            </div>
                                                                            <div style="display: flex;justify-content: space-between; margin-top: 10px;">
                                                                                <h1
                                                                                class="font_10 black--text"
                                                                                style="text-transform: uppercase;">
                                                                                    {{ $t("account_name") }}
                                                                                </h1>
                                                                                <h3
                                                                                class="font_10 primary--text"
                                                                                style="text-transform: uppercase;">
                                                                                    {{b.accountName}}
                                                                                </h3>
                                                                            </div>
                                                                            <div style="display: flex;justify-content: space-between; margin-top: 10px;">
                                                                                <h1
                                                                                class="font_10 black--text"
                                                                                style="text-transform: uppercase;">
                                                                                    {{ $t("account_number") }}
                                                                                </h1>
                                                                                <h3
                                                                                class="font_10 primary--text"
                                                                                style="text-transform: uppercase;">
                                                                                    {{b.accountNumber}}
                                                                                </h3>
                                                                            </div>
                                                                        </v-card>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-card-text>
                                                        </v-col>
                                                    </v-tab-item> -->
                                                </v-tabs>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col sm="6" cols="6" class="py-0" v-if="showCardInv">
                                        <v-row>
                                            <v-btn style="width: 100%;padding: 30px!important;" color="primary" class="float-right white--text text-capitalize"
                                                @click="saveTxn">
                                                {{ $t('pay') }}
                                            </v-btn>
                                        </v-row>
                                        <PrintReceipt :printObj="printObj" />
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-container>
                </v-card>
            </v-dialog>
            <!--Card-->
            <v-dialog
                v-model="dialogCard"
                fullscreen
                persistent
                hide-overlay
                transition="dialog-bottom-transition"
            >
                <template v-slot:activator="{ on, attrs }">
                </template>
                <v-card style="background-color: #f8f8f9;">
                    <v-container>
                        <v-card outlined dense class="px-3 no_border mt-4" color="white">
                            <div class="modal_header">
                                <v-card-title>{{ $t("card") }}</v-card-title>
                                <v-icon
                                    @click="dialogCard = false"
                                    style="cursor: pointer; font-size: 40px;"
                                    color="grey"
                                    class="float-right mt-n1">close
                                </v-icon>
                            </div>
                            <v-card-text class="modal_text_content">
                                <v-row>    
                                    <v-col sm="6" cols="6" class="pl-0 py-0 border_right_lightgrey">
                                        <v-col sm="12" cols="12" class=" function_content pa-3 pb-0">
                                            <v-row>
                                                <v-col sm="4" cols="4" class="pt-0">
                                                    <v-card
                                                        class="mx-auto"
                                                        max-width="465"
                                                        outlined
                                                        @click="cardPayment('masterCard')"
                                                    >
                                                        <v-list-item three-line>
                                                            <v-list-item-content class="pk-3">
                                                                <v-row>
                                                                    <v-col sm="12" cols="12" class="">
                                                                        <img
                                                                            class="img-1"
                                                                            src="@/assets/images/bank/mastercard.png"
                                                                            width="100%"
                                                                        />
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-card>
                                                </v-col>
                                                <v-col sm="4" cols="4" class="pt-0">
                                                    <v-card
                                                        class="mx-auto"
                                                        max-width="465"
                                                        outlined
                                                        @click="cardPayment('visaCard')"
                                                    >
                                                        <v-list-item three-line>
                                                            <v-list-item-content class="pk-3">
                                                                <v-row>
                                                                    <v-col sm="12" cols="12" class="">
                                                                        <img
                                                                            class="img-1"
                                                                            src="@/assets/images/bank/visa.png"
                                                                            width="100%"
                                                                        />
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-card>
                                                </v-col>
                                                <v-col sm="4" cols="4" class="pt-0">
                                                    <v-card
                                                        class="mx-auto"
                                                        max-width="465"
                                                        outlined
                                                        @click="cardPayment('unionPay')"
                                                    >
                                                        <v-list-item three-line>
                                                            <v-list-item-content class="pk-3">
                                                                <v-row>
                                                                    <v-col sm="12" cols="12" class="">
                                                                        <img
                                                                            class="img-1"
                                                                            src="@/assets/images/bank/union-pay.png"
                                                                            width="100%"
                                                                        />
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-card>
                                                </v-col>
                                                <v-col sm="4" cols="4" class="pt-0">
                                                    <v-card
                                                        class="mx-auto"
                                                        max-width="465"
                                                        outlined
                                                        @click="cardPayment('dinersClub')"
                                                    >
                                                        <v-list-item three-line>
                                                            <v-list-item-content class="pk-3">
                                                                <v-row>
                                                                    <v-col sm="12" cols="12" class="">
                                                                        <img
                                                                            class="img-1"
                                                                            src="@/assets/images/bank/diners-club.png"
                                                                            width="100%"
                                                                        />
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-col>
                                    <v-col sm="6" cols="6" class="py-0" v-if="showCardInv">
                                        <PrintReceipt :printObj="printObj" />
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-container>
                </v-card>
            </v-dialog>
            <!-- cardPayment -->
            <template>
                <v-row>
                    <v-dialog
                        v-model="dialogCardPayment"
                        persistent
                        max-width="350px"
                        >
                        <v-card>
                            <div class="modal_header">
                                <v-card-title>{{ $t("card_payment") }}</v-card-title>
                                <v-icon
                                    @click="dialogCardPayment = false"
                                    style="cursor: pointer; font-size: 30px;"
                                    color="grey"
                                    class="float-right mt-n1">close
                                </v-icon>
                            </div>
                            <v-col md="12" col="12" class="function_content pa-3">
                                <v-row>
                                    <v-col  sm="12" cols="12" class="pb-0">
                                        <v-select
                                        :items="banks"
                                        :label="$t('select_banks')"
                                        outlined
                                        v-model="cardPay.name"
                                        append-icon="mdi-account"
                                        item-value="uuid"
                                        item-text="name"
                                        required
                                        ></v-select>
                                    </v-col>
                                    <v-col  sm="6" cols="6" class="pt-1 pr-0">
                                        <label class="label">{{ $t("expire_date") }}</label>
                                        <app-datepicker
                                            :initialDate="cardPay.expireDate"
                                            @emitDate="cardPay.expireDate = $event"/>
                                    </v-col>
                                    <v-col  sm="6" cols="6" class="pt-1">
                                        <label class="label">{{ $t("last_4_digit") }}</label>
                                        <v-text-field
                                            required
                                            outlined
                                            v-model="cardPay.digit"
                                            type="number"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                    
                            </v-col>
                            <v-card-actions>
                                <div class="function_footer">
                                    <v-btn color="secondary" class="float-right white--text text-capitalize"
                                        @click="enterCardPay">
                                        {{ $t('enter') }}
                                    </v-btn>
                                </div>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
            </template>
            <!-- Bank -->
            <v-dialog
                v-model="dialogBank"
                fullscreen
                persistent
                hide-overlay
                transition="dialog-bottom-transition"
            >
                <template v-slot:activator="{ on, attrs }">
                </template>
                <v-card style="background-color: #f8f8f9;">
                    <v-container>
                        <v-card outlined dense class="px-3 no_border mt-4" color="white">
                            <div class="modal_header">
                                <v-card-title>{{ $t("bank") }}</v-card-title>
                                <v-icon
                                    @click="dialogBank = false"
                                    style="cursor: pointer; font-size: 40px;"
                                    color="grey"
                                    class="float-right mt-n1">close
                                </v-icon>
                            </div>
                            <v-card-text class="modal_text_content">
                                <v-row>    
                                    <v-col sm="6" cols="6" class="px-0 py-0 border_right_lightgrey">
                                        <v-col sm="12" cols="12" class=" function_content pa-3 pb-0">
                                            <v-row v-for="b in bankWallets" v-bind:key="b.uid" >
                                                <v-col sm="3" cols="3">
                                                    <v-btn
                                                        color="primary black--text"
                                                        @click="selectBank(b)">
                                                        {{b.bank.name}}
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-col>
                                    <v-col sm="6" cols="6" class="py-0" v-if="showCardInv">
                                        <PrintReceipt :printObj="printObj" />
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-container>
                </v-card>
            </v-dialog>
            <!-- bank-info -->
            <template>
                <v-row>
                    <v-dialog
                        v-model="infoBank"
                        persistent
                        max-width="400px"
                        >
                        <v-card>
                            <div class="modal_header">
                                <v-card-title>{{ $t("bank_info") }}</v-card-title>
                                <v-icon
                                    @click="infoBank = false"
                                    style="cursor: pointer; font-size: 30px;"
                                    color="grey"
                                    class="float-right mt-n1">close
                                </v-icon>
                            </div>
                            <v-col md="12" col="12" class="function_content pa-3">
                                <v-row>
                                    <v-col cols="12"  md="12" class="pt-0">
                                        <v-row>
                                            <v-col md="12" col="12" class="function_content pa-3">
                                                <template>
                                                    <v-simple-table>
                                                        <template>
                                                            <tbody>
                                                                <tr>
                                                                    <td class="text-left tdbig">
                                                                        <span class="niradei_medium font_14 grey--text">
                                                                            {{ $t("bank_name") }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="text-left tdbig">:</td>
                                                                    <td class="text-right">
                                                                        <span class="niradei_heavy font_18 dark_grey">
                                                                            {{activePayBank.bankName}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="text-left tdbig">
                                                                        <span class="niradei_medium font_14 grey--text">
                                                                            {{ $t("account_name") }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="text-left tdbig">:</td>
                                                                    <td class="text-right">
                                                                        <span class="niradei_heavy font_18 dark_grey">
                                                                            {{activePayBank.accountName}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="text-left tdbig">
                                                                        <span class="niradei_medium font_14 grey--text">
                                                                        {{ $t("account_num") }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="text-left tdbig">:</td>
                                                                    <td class="text-right">
                                                                        <span class="niradei_heavy font_18 dark_grey">
                                                                            {{activePayBank.accountNumber}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="text-left tdbig">
                                                                        <span class="niradei_medium font_14 grey--text">
                                                                        {{ $t("reference_num") }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="text-left tdbig">:</td>
                                                                    <td class="text-right px-0 pt-2">
                                                                        <v-text-field
                                                                            required
                                                                            outlined
                                                                            v-model="activePayBank.refNum"
                                                                            type="number"
                                                                            ></v-text-field>
                                                                    </td>
                                                                </tr>
                                                                
                                                            </tbody>
                                                        </template>
                                                    </v-simple-table>
                                                </template>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-card-actions>
                                <div class="function_footer text-right">
                                    <v-btn color="secondary" class="mr-2 white--text text-capitalize"
                                        @click="infoBank = false">
                                        {{ $t('skip') }}
                                    </v-btn>

                                    <v-btn color="primary" class="float-right white--text text-capitalize"
                                        @click="enterBank">
                                        {{ $t('enter') }}
                                    </v-btn>
                                </div>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
            </template>
            <!--KHQR-->
            <v-dialog
                v-model="dialogQrcode"
                fullscreen
                persistent
                hide-overlay
                transition="dialog-bottom-transition"
            >
                <template v-slot:activator="{ on, attrs }">
                </template>
                <v-card style="background-color: #f8f8f9;">
                    <v-container>
                        <v-card outlined dense class="px-3 no_border mt-4" color="white">
                            <div class="modal_header">
                                <v-card-title>{{ $t("khqr") }}</v-card-title>
                                <v-icon
                                    @click="dialogQrcode = false"
                                    style="cursor: pointer; font-size: 40px;"
                                    color="grey"
                                    class="float-right mt-n1">close
                                </v-icon>
                            </div>
                            <v-card-text class="modal_text_content">
                                <v-row>    
                                    <v-col sm="6" cols="6" class="px-0 py-0 border_right_lightgrey d-flex">
                                        <v-row>
                                            
                                            <v-col sm="12" cols="12" class="pt-0 d-flex" style="position: relative;">
                                                
                                                <v-card
                                                    class="ma-auto text-center"
                                                    max-width="400"
                                                    id="printKHQR"
                                                    outlined
                                                >
                                                    <v-list-item three-line>
                                                        <v-list-item-content class="pk-3">
                                                            <v-row>
                                                                <h2 class="mt-4" style="width: 100%;">scan QR code to pay</h2>
                                                                <v-col sm="12" cols="12" class="">
                                                                    <img
                                                                        class="img-1"
                                                                        :src="khqrUrl"
                                                                        width="100%"
                                                                    />
                                                                </v-col>
                                                                <h5 style="width: 100%;font-size: 16px;padding: 10px 0; padding-top: 0;">{{ $t('total') }} {{ numberFormat(t.total) }}</h5>
                                                                <h5 style="width: 100%;font-size: 16px;padding: 10px 0; padding-top: 0;">{{ $t('store') }} {{ activeStore.name }}</h5>
                                                            </v-row>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                    
                                                </v-card>
                                                <v-btn style="position: absolute;bottom: 5%;right: 46%;" color="secondary" class="float-right white--text text-capitalize"
                                                    @click="printHTMLQR">
                                                    {{ $t('print') }}
                                                </v-btn>
                                            </v-col>
                                        </v-row>  
                                    </v-col>
                                    <v-col sm="6" cols="6" class="py-0" v-if="showCardInv">
                                        <PrintReceipt :printObj="printObj" />
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-divider/>
                            <v-card-actions class="py-0">
                                <div class="function_footer pt-0">
                                    <v-row>
                                        <v-col cols="7" class="border_right_lightgrey">
                                            <v-btn outlined color="#494846" class="float-left text-capitalize"
                                            @click="dialogQrcode = false">{{ $t('cancel') }}
                                            </v-btn>
                                            <!-- <v-btn color="primary" class="float-right white--text text-capitalize"
                                                @click="dialogQrcode = false">
                                                {{ $t('pay') }}
                                            </v-btn> -->
                                        </v-col>
                                        <v-col cols="5">
                                            <!-- <v-btn color="secondary" class="float-right white--text text-capitalize"
                                                @click="dialogQrcode = false">
                                                {{ $t('print') }}
                                            </v-btn> -->
                                        </v-col>
                                    </v-row>
                                </div>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-container>
                </v-card>
            </v-dialog>
            <!-- Print Invoice -->
            <v-dialog
                v-model="dialogPrint"
                fullscreen
                hide-overlay
                persistent
                transition="dialog-bottom-transition"
            >
                <v-card style="background-color: #f8f8f9;">
                    <v-container>
                        <v-card outlined dense class="px-3 no_border mt-4" color="white">
                            <div class="modal_header">
                                <v-card-title>{{ $t("print_bill") }}</v-card-title>
                                <v-icon
                                    @click="closePrintBill"
                                    style="cursor: pointer; font-size: 40px;"
                                    color="grey"
                                    class="float-right mt-n1">close
                                </v-icon>
                            </div>
                            <v-card-text class="modal_text_content">
                                <v-row>
                                    <v-col sm="12" cols="12" class="py-0" v-if="showCardInv">
                                        <PrintReceipt :printObj="printObj" />
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-divider/>
                            <v-card-actions class="py-0">
                                <div class="function_footer pt-0">
                                    <v-row>
                                        <v-col cols="7" class="border_right_lightgrey">
                                        </v-col>
                                        <v-col cols="5">
                                            <v-btn color="secondary" class="float-right white--text text-capitalize"
                                                @click="printHtml">
                                                {{ $t('print') }}
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-container>
                </v-card>
            </v-dialog>
            <!-- Serving Dialog-->
            <v-dialog
                v-model="dialogServingLine"
                fullscreen
                hide-overlay
                persistent
                transition="dialog-bottom-transition"
            >
                <template v-slot:activator="{ on, attrs }">
                </template>
                <v-card>
                    <v-toolbar
                        dark
                        color="secondary"
                        >
                        <v-btn
                            icon
                            dark
                            @click="dialogServingLine = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ $t("serving") }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn
                            dark
                            text
                            @click="dialogServingLine = false"
                            >
                            {{ $t('skip') }}
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-col md="12" col="12" class="function_content pa-3" style="height: 93vh; overflow: hidden; overflow-y: scroll">
                        <v-card class="mx-auto my-auto" max-width="1000" style="background: none;box-shadow: none;">
                                <v-row>
                                    <v-col sm="12" cols="12" class="py-0">
                                        <v-row>
                                            <v-col v-for="s in servingLines" v-bind:key="s.pk" sm="3" cols="3" class="">
                                                <div class="d-flex flex-column" style="height: 100%;border: 1px solid #ccc;border-radius: 5px;">
                                                    <v-simple-table fixed-header style="height: 100%;"  class="flex-1" >
                                                        <template v-slot:default>
                                                            <v-card
                                                                class="mx-auto"
                                                                max-width="400"
                                                            >
                                                                <v-list-item two-line>
                                                                    <v-list-item-content>
                                                                        <v-list-item-subtitle style="background: #ccc;padding: 5px;text-align: center;font-weight: bold;border-radius: 5px;">{{s.saleUnit}}</v-list-item-subtitle>
                                                                        
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                                <v-card-text style="padding-top: 0;padding-bottom: 0;">
                                                                    <div>
                                                                        {{s.hour}}
                                                                    </div>
                                                                </v-card-text>
                                                                <v-card-text style="padding-top: 0;padding-bottom: 0; text-align: center;">
                                                                <v-row align="center">
                                                                    <v-col
                                                                    class="text-h4"
                                                                    style="padding-top: 0;padding-bottom: 0;"
                                                                    cols="12"
                                                                    >
                                                                    {{numberFormat(s.total)}} {{baseRate.symbol}}
                                                                    </v-col>
                                                                </v-row>
                                                                </v-card-text>
                                                                <v-divider></v-divider>
                                                                <v-card-text>
                                                                    <v-chip-group
                                                                        active-class="deep-purple accent-4 white--text"
                                                                        column
                                                                    >
                                                                        <v-btn
                                                                            color="primary"
                                                                            style="width: 100%;font-size: 12px;"
                                                                            prepend-icon="mdi-cloud-upload"
                                                                            @click="serveCheckOut(s)"
                                                                        >
                                                                            {{ $t('check_out') }}
                                                                        </v-btn>
                                                                        <v-btn
                                                                            v-if="s.status != 2"
                                                                            color="secendary"
                                                                            style="width: 100%; font-size: 12px; margin-top: 5px;"
                                                                            prepend-icon="mdi-cloud-upload"
                                                                            @click="changeSaleUnit(s)"
                                                                        >
                                                                            {{ $t('change_sale_unit') }}
                                                                        </v-btn>
                                                                        <v-btn
                                                                            color="secendary"
                                                                            v-if="s.status != 2"
                                                                            style="width: 100%; font-size: 12px; margin-top: 5px;"
                                                                            prepend-icon="mdi-cloud-upload"
                                                                            @click="editServing(s)"
                                                                        >
                                                                            {{ $t('edit') }}
                                                                        </v-btn>
                                                                        <v-btn
                                                                            color="primary"
                                                                            v-if="s.status != 2 && g.allowSPrintCheck"
                                                                            style="width: 100%; font-size: 12px; margin-top: 5px;background-color:darkblue!important"
                                                                            prepend-icon="mdi-cloud-upload"
                                                                            @click="endServing(s)"
                                                                        >
                                                                            {{ $t('end_serving') }}
                                                                        </v-btn>
                                                                        <v-btn
                                                                            color="secendary"
                                                                            v-if="s.status == 2"
                                                                            style="width: 100%; font-size: 12px; margin-top: 5px;"
                                                                            prepend-icon="mdi-cloud-upload"
                                                                            @click="printServing(s)"
                                                                        >
                                                                            {{ $t('print_bill') }}
                                                                        </v-btn>
                                                                        <v-btn
                                                                            color="secendary"
                                                                            v-if="s.status != 2 && g.allowSPrint"
                                                                            style="width: 100%; font-size: 12px; margin-top: 5px;"
                                                                            prepend-icon="mdi-cloud-upload"
                                                                            @click="printServing(s)"
                                                                        >
                                                                            {{ $t('print_bill') }}
                                                                        </v-btn>
                                                                        <!-- <v-chip @click="serveCheckOut(s)">
                                                                            {{ $t('check_out') }}
                                                                        </v-chip> -->
                                                                        <!-- <v-chip>
                                                                            {{ $t('print_bill') }}
                                                                        </v-chip>
                                                                        <v-chip>
                                                                            {{ $t('split_item') }}
                                                                        </v-chip>
                                                                        <v-chip>
                                                                            {{ $t('merge_item') }}
                                                                        </v-chip> -->
                                                                    </v-chip-group>
                                                                </v-card-text>
                                                                
                                                            </v-card>
                                                        </template>
                                                    </v-simple-table>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                        </v-card>
                    </v-col>
                </v-card>
            </v-dialog>
            <!-- Pre Order-->
            <v-dialog
                v-model="dialogPreOrder"
                fullscreen
                hide-overlay
                persistent
                transition="dialog-bottom-transition"
            >
                <template v-slot:activator="{ on, attrs }">
                    
                </template>
                <v-card>
                    <v-toolbar
                        dark
                        color="secondary"
                        >
                        <v-btn
                            icon
                            dark
                            @click="dialogPreOrder = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ $t("pre_order") }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn
                            dark
                            text
                            @click="dialogPreOrder = false"
                            >
                            {{ $t('skip') }}
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-col md="12" col="12" class="function_content pa-3" style="height: 93vh;overflow: hidden; overflow-y: scroll;">
                        <v-card class="mx-auto my-auto" max-width="1200" style="background: none;box-shadow: none;">
                            <v-row>
                                <v-col sm="12" cols="12" class="py-0">
                                    <v-row   >
                                        <PreOrder :preorders="preorders" />
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-card>
            </v-dialog>
            <!-- Invoice -->
            <v-dialog
                v-model="dialogInvoice"
                fullscreen
                hide-overlay
                persistent
                transition="dialog-bottom-transition"
            >
                <v-card>
                    <v-toolbar
                        dark
                        color="secondary"
                        >
                        <v-btn
                            icon
                            dark
                             @click="dialogInvoice = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ $t("invoice") }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn
                            dark
                            text
                             @click="dialogInvoice = false"
                            >
                            {{ $t('skip') }}
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-col md="12" col="12" class="function_content pa-3" style="height: 93vh;overflow: hidden; overflow-y: scroll;">
                        <v-card class="mx-auto my-auto" max-width="1200" style="background: none;box-shadow: none;">
                                <v-row>
                                    <v-col sm="12" cols="12" class="py-0">
                                        <v-row   >
                                            <!-- <Invoice :invoices="invoices" /> -->
                                            <v-row>
                                                <v-text-field
                                                v-model="invSearchText"
                                                outlined
                                                :placeholder="$t('name_or_phone')"
                                                clearable
                                                />
                                                <v-btn color="primary" icon @click="searchInv" class="btn_search ml-2" style="width: 5%;">
                                                    <v-icon style="font-size: 32px;color: #fff;">search</v-icon>
                                                </v-btn>
                                            </v-row>
                                            <v-row >
                                                <kendo-datasource
                                                    ref="gridInvoice"
                                                    :type="'JSON'"
                                                    :data="invoiceLines"
                                                    :server-paging="false"/>
                                                <kendo-grid
                                                    id="gridInvoice" class="grid-function"
                                                    :data-source-ref="'gridInvoice'"
                                                    :editable="false"
                                                    :groupable="false"
                                                    :column-menu="true"
                                                    :noRecords="true"
                                                    :sortable="true"
                                                    :excel-filterable="true"
                                                    :excel-all-pages="true"
                                                    :scrollable-virtual="true">
                                                    <kendo-grid-column
                                                        :title="$t('no.')"
                                                        :width="40"
                                                        :template="invRowNumber"
                                                        :column-menu="false"
                                                        :headerAttributes="{style: 'background-color: #EDF1F5;',class: 'text-products px-1' }"
                                                        :attributes="{ style: 'text-align: products'}"
                                                    />
                                                    <kendo-grid-column
                                                        :field="'customer'"
                                                        :title="$t('name')"
                                                        :width="150"
                                                        :template="'#= customer.name#'"
                                                        :headerAttributes="{ style: 'background-color: #EDF1F5, color: green !important' }"/>
                                                    <kendo-grid-column
                                                        :field="'phoneNumber'"
                                                        :title="$t('phone')"
                                                        :width="150"
                                                        :headerAttributes="{ style: 'background-color: #EDF1F5, color: green !important' }"/>
                                                    <kendo-grid-column
                                                        :field="'txnNumber'"
                                                        :title="$t('number')"
                                                        :width="150"
                                                        :attributes="{style: 'text-align: right; ', class:'tb_name_td'}"
                                                        :headerAttributes="{ style: 'background-color: #EDF1F5' }"/>
                                                    <kendo-grid-column
                                                        :field="'issuedDate'"
                                                        :title="$t('issued_date')"
                                                        :width="150"
                                                        :template="'#=kendo.toString(new Date(issuedDate), `dd-MM-yyyy`)#'"
                                                        :attributes="{style: 'text-align: right; ', class:'tb_name_td'}"
                                                        :headerAttributes="{ style: 'background-color: #EDF1F5' }"/>
                                                    <kendo-grid-column
                                                        :field="'total'"
                                                        :title="$t('amount')"
                                                        :width="150"
                                                        :headerAttributes="{ style: 'background-color: #EDF1F5, color: green !important' }"/>
                                                    <kendo-grid-column
                                                        :command="{
                                                            text: cashReceiptText,
                                                            click: cashReceipt,
                                                            className: 'btn-plus isEditable',
                                                        }"
                                                        :title="$t('action')"
                                                        :width="150"
                                                        :headerAttributes="{
                                                        style: 'text-align: left; background-color: #EDF1F5',
                                                        }"
                                                    />
                                                </kendo-grid>
                                            </v-row>
                                        </v-row>
                                    </v-col>
                                </v-row>
                        </v-card>
                    </v-col>
                </v-card>
            </v-dialog>
            <!-- Order Txn-->
            <v-dialog
                v-model="dialogOrderTxn"
                fullscreen
                persistent
                hide-overlay
                transition="dialog-bottom-transition"
            >
                <template v-slot:activator="{ on, attrs }">
                    
                </template>
                <v-card style="background-color: #f8f8f9;">
                    <v-container>
                        <v-card outlined dense class="px-3 no_border mt-4" color="white">
                            <div class="modal_header">
                                <v-card-title>{{ $t("orders") }}</v-card-title>
                                <v-icon
                                    @click="dialogOrderTxn = false"
                                    style="cursor: pointer; font-size: 40px;"
                                    color="grey"
                                    class="float-right mt-n1">close
                                </v-icon>
                            </div>
                            <v-card-text class="modal_text_content">
                                <v-row>
                                    <v-col sm="12" cols="12" class="py-0">
                                        <v-row   >
                                            <!-- <Invoice :invoices="invoices" /> -->
                                            <v-row>
                                                <v-text-field
                                                    v-model="orderSearchText"
                                                    outlined
                                                    :placeholder="$t('name_or_phone')"
                                                    clearable
                                                />
                                                <v-btn icon @click="searchInv">
                                                    <v-icon>search</v-icon>
                                                </v-btn>
                                            </v-row>
                                            <v-row >
                                                <kendo-datasource
                                                    ref="gridOrderTxn"
                                                    :type="'JSON'"
                                                    :data="orderLines"
                                                    :server-paging="false"/>
                                                <kendo-grid
                                                    id="gridOrderTxn" class="grid-function"
                                                    :data-source-ref="'gridOrderTxn'"
                                                    :editable="false"
                                                    :groupable="false"
                                                    :column-menu="true"
                                                    :noRecords="true"
                                                    :sortable="true"
                                                    :excel-filterable="true"
                                                    :excel-all-pages="true"
                                                    :scrollable-virtual="true">
                                                    <kendo-grid-column
                                                        :title="$t('no.')"
                                                        :width="40"
                                                        :template="ordRowNumber"
                                                        :column-menu="false"
                                                        :headerAttributes="{style: 'background-color: #EDF1F5;',class: 'text-products px-1' }"
                                                        :attributes="{ style: 'text-align: products'}"
                                                    />
                                                    <kendo-grid-column
                                                        :field="'customer'"
                                                        :title="$t('name')"
                                                        :width="150"
                                                        :template="'#= customer.name#'"
                                                        :headerAttributes="{ style: 'background-color: #EDF1F5, color: green !important' }"/>
                                                    <kendo-grid-column
                                                        :field="'phoneNumber'"
                                                        :title="$t('phone')"
                                                        :width="150"
                                                        :headerAttributes="{ style: 'background-color: #EDF1F5, color: green !important' }"/>
                                                    <kendo-grid-column
                                                        :field="'txnNumber'"
                                                        :title="$t('number')"
                                                        :width="150"
                                                        :attributes="{style: 'text-align: right; ', class:'tb_name_td'}"
                                                        :headerAttributes="{ style: 'background-color: #EDF1F5' }"/>
                                                    <kendo-grid-column
                                                        :field="'issuedDate'"
                                                        :title="$t('issued_date')"
                                                        :width="150"
                                                        :template="'#=kendo.toString(new Date(issuedDate), `dd-MM-yyyy`)#'"
                                                        :attributes="{style: 'text-align: right; ', class:'tb_name_td'}"
                                                        :headerAttributes="{ style: 'background-color: #EDF1F5' }"/>
                                                    <kendo-grid-column
                                                        :field="'total'"
                                                        :title="$t('amount')"
                                                        :width="150"
                                                        :headerAttributes="{ style: 'background-color: #EDF1F5, color: green !important' }"/>
                                                    <kendo-grid-column
                                                        :command="{
                                                            text: cashReceiptText,
                                                            click: cashReceipt,
                                                            className: 'btn-plus isEditable',
                                                        }"
                                                        :title="$t('action')"
                                                        :width="150"
                                                        :headerAttributes="{
                                                        style: 'text-align: left; background-color: #EDF1F5',
                                                        }"
                                                    />
                                                </kendo-grid>
                                            </v-row>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-divider/>
                        </v-card>
                    </v-container>
                </v-card>
            </v-dialog>
            <!-- Check out Dialog -->
            <v-dialog
                v-model="dialogPayment"
                fullscreen
                persistent
                hide-overlay
                transition="dialog-bottom-transition"
            >
                <template v-slot:activator="{ on, attrs }">
                </template>
                <v-card >
                    <v-toolbar
                        dark
                        color="secondary"
                        >
                        <v-btn
                            icon
                            dark
                            @click="closePayment"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ $t("cash_receipt") }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <!-- <v-toolbar-items>
                            <v-btn
                            dark
                            text
                            @click="closePayment"
                            >
                            {{ $t('skip') }}
                            </v-btn>
                        </v-toolbar-items> -->
                    </v-toolbar>
                    <v-col md="12" col="12" class="function_content pa-3" style="">
                        <v-card class="mx-auto my-auto" max-width="1000" style="box-shadow: none;">
                            <v-row>
                                <v-col sm="12" cols="12" class="pr-0 py-0 border_right_lightgrey">
                                    <v-row class="mx-1" style="height: 100%;min-height: 200px;">
                                        <!-- cash -->
                                        <v-col sm="2" cols="2" class="pa-0" >
                                            <template>
                                                <div justify="center" style="height: 200px;">
                                                    <v-btn 
                                                        color="primary"
                                                        dark
                                                        @click="payByCash"
                                                        height="50%"
                                                        class="rounded-0 pay-btn" 
                                                        style="height:100% !important;">
                                                        <div style="display: inline-grid;">
                                                            <v-icon
                                                                style="font-size: 40px;"
                                                                color="primary"
                                                                class="b-cash">
                                                            </v-icon>
                                                            <span class="font_13 text-bold mt-2">{{ $t("cash") }}</span>  
                                                        </div>
                                                    </v-btn>
                                                </div>
                                            </template>
                                        </v-col>
                                        <!-- card -->
                                        <v-col v-if="allowPCard" sm="2" cols="2" class="pa-0" >
                                            <template>
                                                <div justify="center" style="height: 200px;">
                                                    <v-btn 
                                                        color="primary"
                                                        dark
                                                        @click="payByCard"
                                                        height="50%"
                                                        class="rounded-0 pay-btn" 
                                                        style="height:100% !important;">
                                                        <div style="display: inline-grid;">
                                                            <v-icon
                                                                style="font-size: 40px;"
                                                                color="primary"
                                                                class="b-loyalty">
                                                            </v-icon>
                                                            <span class="font_13 text-bold mt-2">{{ $t("card") }}</span>  
                                                        </div>
                                                    </v-btn>
                                                </div>
                                            </template>
                                        </v-col>
                                        <!-- bank -->
                                        <v-col v-if="allowPBank" sm="2" cols="2" class="pa-0" >
                                            <template>
                                                <div justify="center" style="height: 200px;">
                                                    <v-btn 
                                                        color="primary"
                                                        dark
                                                        @click="payByBank"
                                                        height="50%"
                                                        class="rounded-0 pay-btn" 
                                                        style="height:100% !important;">

                                                        <div style="display: inline-grid;">
                                                            <v-icon
                                                                style="font-size: 40px;"
                                                                color="primary"
                                                                class="b-banking">
                                                            </v-icon>
                                                            <span class="font_13 text-bold mt-2">{{ $t("bank") }}</span>  
                                                        </div>
                                                    </v-btn>
                                                </div>
                                            </template>
                                        </v-col>
                                        <!-- qrcode -->
                                        <v-col v-if="allowPKHQR" sm="2" cols="2" class="pa-0" >
                                            <template>
                                                <div justify="center" style="height: 200px;">
                                                    <v-btn 
                                                        color="primary"
                                                        dark
                                                        @click="payByKHQR"
                                                        height="50%"
                                                        class="rounded-0 pay-btn" 
                                                        style="height:100% !important;">
                                                        <div style="display: inline-grid;">
                                                            <v-icon
                                                                style="font-size: 40px;"
                                                                color="primary"
                                                                class="b-payment">
                                                            </v-icon>
                                                            <span class="font_13 text-bold mt-2">{{ $t('khqr') }}</span>  
                                                        </div>
                                                    </v-btn>
                                                </div>
                                            </template>
                                        </v-col>
                                        <!-- point card -->
                                        <v-col v-if="allowPPoint" sm="2" cols="2" disabled class="pa-0" >
                                            <template>
                                                <div justify="center" style="height: 200px;">
                                                    <v-btn 
                                                        color="primary"
                                                        dark
                                                        @click="payPointCard"
                                                        height="50%"
                                                        class="rounded-0 pay-btn" 
                                                        style="height:100% !important;">

                                                        <div style="display: inline-grid;">
                                                            <v-icon
                                                                style="font-size: 40px;"
                                                                color="primary"
                                                                class="b-banking">
                                                            </v-icon>
                                                            <span class="font_13 text-bold mt-2">{{ $t("point_card") }}</span>  
                                                        </div>
                                                    </v-btn>
                                                </div>
                                            </template>
                                        </v-col>
                                        <!-- store credit -->
                                        <v-col v-if="allowPStoreCredit" sm="2" cols="2" class="pa-0" >
                                            <template>
                                                <div justify="center" style="height: 200px;">
                                                    <v-btn 
                                                        color="primary"
                                                        dark
                                                        height="50%"
                                                        @click="payFleetCard"
                                                        class="rounded-0 pay-btn" 
                                                        style="height:100% !important;">
                                                        <div style="display: inline-grid;">
                                                            <v-icon
                                                                style="font-size: 40px;"
                                                                color="primary"
                                                                class="b-payment">
                                                            </v-icon>
                                                            <span class="font_13 text-bold mt-2">{{ $t("fleet_card") }}</span>  
                                                        </div>
                                                    </v-btn>
                                                </div>
                                            </template>
                                        </v-col>
                                        <!-- fleet card -->
                                        <v-col v-if="allowPFleet" sm="2" cols="2" class="pa-0" >
                                            <template>
                                                <div justify="center" style="height: 200px;">
                                                    <v-btn 
                                                        color="primary"
                                                        dark
                                                        height="50%"
                                                        @click="payFleetCard"
                                                        class="rounded-0 pay-btn" 
                                                        style="height:100% !important;">
                                                        <div style="display: inline-grid;">
                                                            <v-icon
                                                                style="font-size: 40px;"
                                                                color="primary"
                                                                class="b-payment">
                                                            </v-icon>
                                                            <span class="font_13 text-bold mt-2">{{ $t("fleet_card") }}</span>  
                                                        </div>
                                                    </v-btn>
                                                </div>
                                            </template>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <!-- <v-col sm="10" cols="10" class="py-0">
                                    <PrintReceipt :printObj="printObj" />
                                </v-col> -->
                            </v-row>
                        </v-card>
                    </v-col>
                </v-card>
            </v-dialog>
            <v-dialog
                v-model="dialogPOnly"
                fullscreen
                persistent
                hide-overlay
                transition="dialog-bottom-transition"
            >
                <template v-slot:activator="{ on, attrs }">
                </template>
                <v-card >
                    <v-toolbar
                        dark
                        color="secondary"
                        >
                        <v-btn
                            icon
                            dark
                            @click="closePayOnly"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ $t("cash_receipt") }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-col md="12" col="12" class="function_content pa-3" style="">
                        <v-card class="mx-auto my-auto" max-width="1000" style="box-shadow: none;">
                            <v-row>
                                <v-col sm="12" cols="12" class="pr-0 py-0 border_right_lightgrey">
                                    <v-row class="mx-1" style="height: 100%;">
                                        <!-- cash -->
                                        <v-col sm="6" cols="12" class="pa-0" style="min-height: 120px;" >
                                            <template>
                                                <div justify="center" style="height: 100%;">
                                                    <v-btn 
                                                        color="primary"
                                                        dark
                                                        @click="payByCash"
                                                        height="50%"
                                                        class="rounded-0 pay-btn" 
                                                        style="height:100% !important;">
                                                        <div style="display: inline-grid;">
                                                            <v-icon
                                                                style="font-size: 40px;"
                                                                color="primary"
                                                                class="b-cash">
                                                            </v-icon>
                                                            <span class="font_13 text-bold mt-2">{{ $t("cash") }}</span>  
                                                        </div>
                                                    </v-btn>
                                                </div>
                                            </template>
                                        </v-col>
                                        <!-- card -->
                                        <v-col sm="6" cols="12" class="pa-0" style="min-height: 120px;" >
                                            <template>
                                                <div justify="center" style="height: 100%;">
                                                    <v-btn 
                                                        color="primary"
                                                        dark
                                                        @click="payByCard"
                                                        height="50%"
                                                        class="rounded-0 pay-btn" 
                                                        style="height:100% !important;">
                                                        <div style="display: inline-grid;">
                                                            <v-icon
                                                                style="font-size: 40px;"
                                                                color="primary"
                                                                class="b-loyalty">
                                                            </v-icon>
                                                            <span class="font_13 text-bold mt-2">{{ $t("card") }}</span>  
                                                        </div>
                                                    </v-btn>
                                                </div>
                                            </template>
                                        </v-col>
                                        <!-- bank -->
                                        <v-col sm="6" cols="12" class="pa-0" style="min-height: 120px;" >
                                            <template>
                                                <div justify="center" style="height: 100%;">
                                                    <v-btn 
                                                        color="primary"
                                                        dark
                                                        @click="payByBank"
                                                        height="50%"
                                                        class="rounded-0 pay-btn" 
                                                        style="height:100% !important;">

                                                        <div style="display: inline-grid;">
                                                            <v-icon
                                                                style="font-size: 40px;"
                                                                color="primary"
                                                                class="b-banking">
                                                            </v-icon>
                                                            <span class="font_13 text-bold mt-2">{{ $t("bank") }}</span>  
                                                        </div>
                                                    </v-btn>
                                                </div>
                                            </template>
                                        </v-col>
                                        <!-- qrcode -->
                                        <v-col sm="6" cols="12" class="pa-0" style="min-height: 120px;" >
                                            <template>
                                                <div justify="center" style="height: 100%;">
                                                    <v-btn 
                                                        color="primary"
                                                        dark
                                                        @click="payByKHQR"
                                                        height="50%"
                                                        class="rounded-0 pay-btn" 
                                                        style="height:100% !important;">
                                                        <div style="display: inline-grid;">
                                                            <v-icon
                                                                style="font-size: 40px;"
                                                                color="primary"
                                                                class="b-payment">
                                                            </v-icon>
                                                            <span class="font_13 text-bold mt-2">{{ $t("khqr") }}</span>  
                                                        </div>
                                                    </v-btn>
                                                </div>
                                            </template>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-card>
            </v-dialog>
            <!-- Print Session-->
            <v-dialog
                v-model="dialogPrintSession"
                fullscreen
                persistent
                hide-overlay
                transition="dialog-bottom-transition"
            >
                <template v-slot:activator="{ on, attrs }">
                </template>
                <v-card>
                    <v-toolbar
                        dark
                        color="secondary"
                        >
                        <v-btn
                            icon
                            dark
                            @click="closePrintSession"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ $t("session") }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-col md="12" col="12" class="function_content pa-3" style="height: 93vh;overflow: hidden; overflow-y: scroll;">
                        <v-card class="mx-auto my-auto" max-width="1000" style="background: none;box-shadow: none;">
                            <v-row>
                                <v-col sm="12" cols="12" class="pr-0 py-0 border_right_lightgrey">
                                    <v-row class="mx-1" style="height: 100%;">
                                        <PrintSession :session="session"/>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-card>
            </v-dialog>
            <!-- Print Shift-->
            <v-dialog
                v-model="dialogPrintShift"
                fullscreen
                persistent
                hide-overlay
                transition="dialog-bottom-transition"
            >
                <template v-slot:activator="{ on, attrs }">
                </template>
                <v-card>
                    <v-toolbar
                        dark
                        color="secondary"
                        >
                        <v-btn
                            icon
                            dark
                            @click="dialogPrintShift = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ $t("shift") }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-col md="12" col="12" class="function_content pa-3" style="height: 93vh;overflow: hidden; overflow-y: scroll;">
                        <v-card class="mx-auto my-auto" max-width="1000" style="background: none;box-shadow: none;">
                            <v-row>
                                <v-col sm="12" cols="12" class="pr-0 py-0 border_right_lightgrey">
                                    <v-row class="mx-1" style="height: 100%;">
                                        <PrintShift :shiftSes="shiftSes"/>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-card>
            </v-dialog>
            <!--Loyalty-->
            <v-col md="6" sm="3" col="3" class="pa-1">
                <template>
                    <v-dialog
                        v-model="dialogLoyalSearch"
                        fullscreen
                        persistent
                        hide-overlay
                        transition="dialog-bottom-transition"
                        >
                        <v-card>
                            <v-toolbar
                                dark
                                color="secondary"
                                >
                                <v-btn
                                    icon
                                    dark
                                    v-if="pointCardPay"
                                    @click="dialogLoyalSearch = false"
                                >
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-toolbar-title>{{ $t("loyalty_search") }}</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-toolbar-items>
                                    <v-btn
                                    dark
                                    text
                                    v-if="!pointCardPay"
                                    @click="skipLoyalty"
                                    >
                                    {{ $t('skip') }}
                                    </v-btn>
                                </v-toolbar-items>
                            </v-toolbar>
                            <v-col md="12" col="12" class="function_content pa-3" style="height: 93vh;">
                                <v-card class="mx-auto my-auto" max-width="1000" style="background: none;box-shadow: none;">
                                    <v-row>
                                        <v-col sm="4" cols="4" class="">
                                            <v-card
                                                class="mx-auto"
                                                max-width="465"
                                                outlined
                                                @click="lsearchBy('card')"
                                            >
                                                <v-list-item three-line>
                                                    <v-list-item-content class="pk-3">
                                                        <v-row>
                                                            <v-col sm="12" cols="12" class="text-center">
                                                                <h2  class="font_22">{{$t('search_by')}}</h2>
                                                                <h2  class="font_22">{{$t('card')}}</h2>
                                                            </v-col>
                                                        </v-row>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-card>
                                        </v-col>
                                        <v-col sm="4" cols="4" class="">
                                            <v-card
                                                class="mx-auto"
                                                max-width="465"
                                                outlined
                                                @click="lsearchBy('phone')"
                                            >
                                                <v-list-item three-line>
                                                    <v-list-item-content class="pk-3">
                                                        <v-row>
                                                            <v-col sm="12" cols="12" class="text-center">
                                                                <h2 class="primary--text font_22">{{$t('search_by')}}</h2>
                                                                <h2 class="primary--text font_22">{{$t('phone')}}</h2>
                                                            </v-col>
                                                        </v-row>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-card>
                                        </v-col>
                                        <v-col sm="4" cols="4" class="">
                                            <v-card
                                                class="mx-auto"
                                                max-width="465"
                                                outlined
                                                @click="lsearchBy('nfc')"
                                            >
                                                <v-list-item three-line>
                                                    <v-list-item-content class="pk-3">
                                                        <v-row>
                                                            <v-col sm="12" cols="12" class="text-center">
                                                                <h2 class="font_22">{{$t('search_by')}}</h2>
                                                                <h2 class="font_22">{{$t('nfc_chip')}}</h2>
                                                            </v-col>
                                                        </v-row>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                            <!-- <v-card-actions>
                                <div class="function_footer">
                                    <v-btn color="secondary" class="float-right white--text text-capitalize"
                                        v-if="!pointCardPay"
                                        @click="skipLoyalty">
                                        {{ $t('skip') }}
                                    </v-btn>
                                </div>
                                <v-spacer></v-spacer>
                            </v-card-actions> -->
                        </v-card>
                    </v-dialog>
                </template>
            </v-col>
            <template>
                <v-row>
                    <v-dialog
                        v-model="dialogRewardInfo"
                        persistent
                        max-width="400px"
                        >
                        <v-card>
                            <div class="modal_header">
                                <v-card-title>{{ $t("point_earn_info") }}</v-card-title>
                                <v-icon
                                    @click="dialogRewardInfo = false"
                                    style="cursor: pointer; font-size: 30px;"
                                    color="grey"
                                    class="float-right mt-n1">close
                                </v-icon>
                            </div>
                            <v-col md="12" col="12" class="function_content pa-3">
                                <v-row>
                                    <v-col cols="12"  md="12" class="pt-0">
                                        <v-row>
                                            <v-col md="12" col="12" class="function_content pa-3">
                                                <template>
                                                    <v-simple-table>
                                                        <template>
                                                            <tbody>
                                                                <tr>
                                                                    <td class="text-left tdbig">
                                                                        <span class="niradei_medium font_14 grey--text">
                                                                            {{ $t("card_owner_name") }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="text-left tdbig">:</td>
                                                                    <td class="text-right">
                                                                        <span class="niradei_heavy font_18 dark_grey">
                                                                            {{t.pointEarn.name}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="text-left tdbig">
                                                                        <span class="niradei_medium font_14 grey--text">
                                                                            {{ $t("card_number") }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="text-left tdbig">:</td>
                                                                    <td class="text-right">
                                                                        <span class="niradei_heavy font_18 dark_grey">
                                                                            {{t.pointEarn.cardNum}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="text-left tdbig">
                                                                        <span class="niradei_medium font_14 grey--text">
                                                                        {{ $t("phone_number") }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="text-left tdbig">:</td>
                                                                    <td class="text-right">
                                                                        <span class="niradei_heavy font_18 dark_grey">
                                                                            {{t.pointEarn.phoneNum}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="text-left tdbig">
                                                                        <span class="niradei_medium font_14 grey--text">
                                                                        {{ $t("loyalty_program") }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="text-left tdbig">:</td>
                                                                    <td class="text-right">
                                                                        <span class="niradei_heavy font_18 dark_grey">
                                                                            {{t.pointEarn.loyaltyName}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="text-left tdbig">
                                                                        <span class="niradei_medium font_14 grey--text">
                                                                        {{ $t("point_balance") }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="text-left tdbig">:</td>
                                                                    <td class="text-right">
                                                                        <span class="niradei_heavy font_18 dark_grey">
                                                                            {{t.pointEarn.totalPoint}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="text-left tdbig">
                                                                        <span class="niradei_medium font_14 grey--text">
                                                                        {{ $t("point_earn") }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="text-left tdbig">:</td>
                                                                    <td class="text-right">
                                                                        <span class="niradei_heavy font_18 dark_grey">
                                                                            {{t.pointEarn.pointEarn}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                
                                                            </tbody>
                                                        </template>
                                                    </v-simple-table>
                                                </template>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-card-actions>
                                <div class="function_footer">
                                    <v-btn color="primary" class="float-right white--text text-capitalize"
                                        @click="saveReward">
                                        {{ $t('enter') }}
                                    </v-btn>
                                </div>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
            </template>
            <!-- loyalty search -->
            <template>
                <v-row>
                    <v-dialog
                        v-model="dialogLoyalty"
                        fullscreen
                        persistent
                        hide-overlay
                        transition="dialog-bottom-transition"
                        >
                        <v-card>
                            <v-toolbar
                                dark
                                color="secondary"
                                >
                                <v-btn
                                    icon
                                    dark
                                    @click="dialogLoyalty = false"
                                >
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-toolbar-title>{{ $t("loyalty") }}</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-toolbar-items>
                                    <v-btn
                                    dark
                                    text
                                    @click="dialogLoyalty = false"
                                    >
                                    {{ $t('skip') }}
                                    </v-btn>
                                </v-toolbar-items>
                            </v-toolbar>
                            <v-col md="12" col="12" class="function_content pa-3" style="height: 93vh;overflow: hidden; overflow-y: scroll;">
                                <v-card class="mx-auto my-auto" max-width="1000" style="background: none;box-shadow: none;">
                                    <v-row>
                                        <v-col sm="9" cols="9" class="pb-0">
                                            <v-text-field
                                            :label="lsearchName == 'card' ? $t('card_number') : lsearchName == 'phone' ? $t('phone_number') : $t('nfc_chip')"
                                            required
                                            outlined
                                            v-model="lcardNumber"
                                            append-icon="mdi-credit-card-settings-outline"
                                            :type="lsearchName == 'nfc' ? 'password': 'text'"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col sm="3" cols="3" class="pb-0" >
                                            <v-btn color="primary" class="mr-2 white--text text-capitalize"
                                                @click="searchLoyaltyCard">
                                                {{ $t('search') }}
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-card>
                    </v-dialog>
                </v-row>
            </template>
            <template>
                <v-row>
                    <v-dialog
                        v-model="dialogRProgram"
                        persistent
                        max-width="600px"
                        >
                        <v-card>
                            <div class="modal_header">
                                <v-card-title>{{ $t("reward_program") }}</v-card-title>
                                <v-icon
                                    @click="dialogRProgram = false"
                                    style="cursor: pointer; font-size: 30px;"
                                    color="grey"
                                    class="float-right mt-n1">close
                                </v-icon>
                            </div>
                            <v-col md="12" col="12" class="function_content pa-3">
                                <v-row >
                                    <v-col sm="4" cols="12" class="" v-for="d in activeRPrograms" v-bind:key="d.pk">
                                        <v-card
                                            class="mx-auto"
                                            max-width="465"
                                            outlined
                                            :color="activeReward.pk == d.pk ? 'primary': 'white' "
                                            :class="activeReward.pk == d.pk ? 'white--text': 'black--text' "
                                            @click="selectReward(d)"
                                        >
                                            <v-list-item three-line>
                                                <v-list-item-content class="pk-3">
                                                    <v-row>
                                                        <v-col sm="12" cols="12" class="text-center">
                                                            <h2 class="font_22">{{d.name}}</h2>
                                                        </v-col>
                                                    </v-row>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-card-actions>
                                <div class="function_footer text-right">
                                    <v-btn color="secondary" class="mr-2 white--text text-capitalize"
                                        @click="dialogRProgram = false">
                                        {{ $t('skip') }}
                                    </v-btn>
                                    <v-btn color="primary" class="float-right white--text text-capitalize"
                                        @click="enterReward()">
                                        {{ $t('enter') }}
                                    </v-btn>
                                </div>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
            </template>
            <!-- phone number-->
            <template>
                <v-row>
                    <v-dialog
                        v-model="dailogPhone"
                        persistent
                        max-width="600px"
                        >
                        <v-card>
                            <div class="modal_header">
                                <v-card-title>{{ $t("phone_number") }}</v-card-title>
                                <v-icon
                                    v-if="isCreateCard"
                                    @click="dailogPhone = false"
                                    style="cursor: pointer; font-size: 30px;"
                                    color="grey"
                                    class="float-right mt-n1">close
                                </v-icon>
                            </div>
                            <v-col md="12" col="12" class="function_content pa-3">
                                <v-row>
                                    <v-col sm="12" cols="12" class="pb-0">
                                        <v-text-field
                                        :label="$t('phone_number')"
                                        required
                                        outlined
                                        v-model="phoneNumber"
                                        append-icon="mdi-credit-card-settings-outline"
                                        type="text"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-card-actions>
                                <div class="function_footer text-right">
                                    <!-- <v-btn color="secondary" class="mr-2 white--text text-capitalize"
                                        @click="dialogRProgram = false">
                                        {{ $t('skip') }}
                                    </v-btn> -->
                                    <v-btn color="primary" class="float-right white--text text-capitalize"
                                        @click="enterPhone()">
                                        {{ $t('enter') }}
                                    </v-btn>
                                </div>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
            </template>
            <!-- register card-->
            <template>
                <v-row>
                    <v-dialog
                        v-model="dailogRCard"
                        persistent
                        max-width="600px"
                        >
                        <v-card>
                            <div class="modal_header">
                                <v-card-title>{{ $t("register_card") }}</v-card-title>
                                <v-icon
                                    @click="dailogRCard = false"
                                    style="cursor: pointer; font-size: 30px;"
                                    color="grey"
                                    class="float-right mt-n1">close
                                </v-icon>
                            </div>
                            <v-col md="12" col="12" class="function_content pa-3">
                                <v-row>
                                    <v-col sm="6" cols="12" class="pb-0">
                                        <v-text-field
                                        :label="$t('card_number')"
                                        required
                                        outlined
                                        :disabled="this.createCardFunc == 'number' ? false:true"
                                        v-model="registerCard.cardNumber"
                                        append-icon="mdi-credit-card-settings-outline"
                                        type="text"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col sm="6" cols="12" class="pb-0">
                                        <v-select
                                            class="mt-1"
                                            :label="$t('group')"
                                            item-text="name"
                                            v-model="registerCard.group"
                                            :items="cardGroups"
                                            :placeholder="$t('select')"
                                            return-object
                                            outlined>
                                        </v-select>
                                    </v-col>
                                    <v-col sm="6" cols="12" class="pb-0">
                                        <v-text-field
                                        :label="$t('name')"
                                        required
                                        outlined
                                        v-model="registerCard.activateName"
                                        append-icon="mdi-credit-card-settings-outline"
                                        type="text"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col sm="6" cols="12" class="pb-0">
                                        <v-text-field
                                        :label="$t('phone_number')"
                                        required
                                        outlined
                                        @change="rCardPhoneChange"
                                        v-model="registerCard.activatePhone"
                                        append-icon="mdi-credit-card-settings-outline"
                                        type="text"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col sm="6" cols="12" class="pb-0">
                                        <v-text-field
                                        :label="$t('email')"
                                        required
                                        outlined
                                        v-model="registerCard.activateEmail"
                                        append-icon="mdi-credit-card-settings-outline"
                                        type="text"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col sm="6" cols="12" class="pb-0">
                                        <v-text-field
                                        :label="$t('nfc_chip')"
                                        required
                                        outlined
                                        v-model="registerCard.nfcNumber"
                                        append-icon="mdi-credit-card-settings-outline"
                                        type="password"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col sm="6" cols="12" class="pb-0">
                                        <v-select
                                            class="mt-1"
                                            :label="$t('gender')"
                                            item-text="name"
                                            item-value="id"
                                            v-model="registerCard.activateGender"
                                            :items="genders"
                                            :placeholder="$t('select')"
                                            retrun-object
                                            outlined>
                                        </v-select>
                                    </v-col>
                                    <v-col sm="6" cols="12" class="pb-0">
                                        <label>{{$t('dob')}}</label>
                                        <app-datepicker
                                            :initialDate="registerCard.activateDob"
                                            @emitDate="registerCard.activateDob = $event"/>
                                    </v-col>
                                    <v-col sm="6" cols="12" class="pb-0">
                                        <v-select
                                            class="mt-1"
                                            :label="$t('nationality')"
                                            item-text="name"
                                            item-value="code"
                                            v-model="registerCard.activateNationality"
                                            :items="nationalities"
                                            :placeholder="$t('select')"
                                            outlined>
                                        </v-select>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-card-actions>
                                <div class="function_footer text-right">
                                    <v-btn color="primary" class="float-right white--text text-capitalize"
                                        @click="saveRCard">
                                        {{ $t('enter') }}
                                    </v-btn>
                                </div>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
            </template>
            <!-- Print card -->
            <v-dialog
                v-model="dailogPrintCard"
                fullscreen
                persistent
                hide-overlay
                transition="dialog-bottom-transition"
            >
                <template v-slot:activator="{ on, attrs }">
                    
                </template>
                <v-card style="background-color: #f8f8f9;">
                    <v-container>
                        <v-card outlined dense class="px-3 no_border mt-4" color="white">
                            <div class="modal_header">
                                <v-card-title>{{ $t("register_card") }}</v-card-title>
                                <v-icon
                                    @click="dailogPrintCard = false"
                                    style="cursor: pointer; font-size: 40px;"
                                    color="grey"
                                    class="float-right mt-n1">close
                                </v-icon>
                            </div>
                            <v-card-text class="modal_text_content">
                                <v-row>
                                    <v-col sm="6" cols="6" class="py-0" style="margin: 0 auto;">
                                        <PrintCard :printObj="printObj" />
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-divider/>
                            <v-card-actions class="py-0">
                                <div class="function_footer pt-0">
                                    <v-row>
                                        <v-col cols="7" class="border_right_lightgrey">
                                        </v-col>
                                        <v-col cols="5">
                                            <v-btn color="secondary" class="float-right white--text text-capitalize"
                                                @click="printHtmlB">
                                                {{ $t('print') }}
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-container>
                </v-card>
            </v-dialog>
            <!-- Amount Key Pad-->
            <template>
                <v-dialog
                    v-model="showAmountKeyPad"
                    fullscreen
                    persistent
                    hide-overlay
                    transition="dialog-bottom-transition"
                    >
                    <template v-slot:activator="{ on, attrs }">
                    </template>
                    <v-card>
                        <v-toolbar
                            dark
                            color="secondary"
                            >
                            <v-btn
                                icon
                                dark
                                @click="showAmountKeyPad = false"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-toolbar-title>{{ activebankpay.bank.name }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                            
                        </v-toolbar>
                        <v-col md="12" col="12" class="function_content pa-3" style="height: 90vh;overflow: hidden;overflow-y: scroll;">
                            <v-card class="mx-auto my-auto" max-width="1000" style="background: none;box-shadow: none;">
                                <div class="text-center">
                                    <v-row>
                                        <v-col sm="12" cols="12" class="py-0">
                                            <h3>{{numberFormat(amtReceipt)}}</h3>
                                        </v-col>
                                        <v-col sm="12" cols="12" class="pt-0">
                                            <v-btn @click="changeClick(1)" color="secondary" class="text-bold rounded-0 receipt-btn ml-0">
                                                1
                                            </v-btn>
                                                
                                            <v-btn @click="changeClick(2)"  color="secondary" class="text-bold rounded-0 receipt-btn">
                                                2
                                            </v-btn>

                                            <v-btn @click="changeClick(3)" color="secondary" class="text-bold rounded-0 receipt-btn mr-0">
                                                3
                                            </v-btn>
                                            <br>
                                            <v-btn @click="changeClick(4)" color="secondary" class="text-bold rounded-0 receipt-btn ml-0">
                                                4
                                            </v-btn>
                                                
                                            <v-btn @click="changeClick(5)" color="secondary" class="text-bold rounded-0 receipt-btn">
                                                5
                                            </v-btn>

                                            <v-btn @click="changeClick(6)" color="secondary" class="text-bold rounded-0 receipt-btn mr-0">
                                                6
                                            </v-btn>
                                            <br>
                                            <v-btn @click="changeClick(7)" color="secondary" class="text-bold rounded-0 receipt-btn ml-0">
                                                7
                                            </v-btn>
                                                
                                            <v-btn @click="changeClick(8)" color="secondary" class="text-bold rounded-0 receipt-btn">
                                                8
                                            </v-btn>

                                            <v-btn @click="changeClick(9)" color="secondary" class="text-bold rounded-0 receipt-btn mr-0">
                                                9
                                            </v-btn>
                                            <br>
                                                
                                            <v-btn @click="changeClick(0)" color="secondary" class="text-bold rounded-0 receipt-btn ml-0">
                                                0
                                            </v-btn>

                                            <v-btn @click="changeClick('.')" color="secondary" class="text-bold rounded-0 receipt-btn">
                                                .
                                            </v-btn>

                                            <v-btn @click="changeClick('Clear')" color="secondary" class="text-bold rounded-0 receipt-btn mr-0">
                                                <v-icon>fas fa-backspace</v-icon>
                                                c
                                            </v-btn>
                                        </v-col>
                                        <v-col>
                                            <v-btn @click="khrPaid(0)" color="secondary" class="text-bold rounded-0 receipt-btn ml-0">
                                                KHR
                                            </v-btn>
                                                
                                            <v-btn @click="usdPaid(0)"  color="secondary" class="text-bold rounded-0 receipt-btn">
                                                USD
                                            </v-btn></v-col>
                                    </v-row>
                                </div>
                            </v-card>
                        </v-col>
                    </v-card>
                </v-dialog>
            </template> 
            <!-- QTY-->
            <template>
                <v-row>
                    <v-dialog
                        v-model="dialogQTY"
                        persistent
                        max-width="600px"
                        >
                        <v-card>
                            <div class="modal_header">
                                <v-card-title>{{ $t("qty_card") }}</v-card-title>
                                <v-icon
                                    @click="dialogQTY = false"
                                    style="cursor: pointer; font-size: 30px;"
                                    color="grey"
                                    class="float-right mt-n1">close
                                </v-icon>
                            </div>
                            <v-col md="12" col="12" class="function_content pa-3">
                                <v-row>
                                    <v-col sm="12" cols="12" class="pb-0">
                                        <v-text-field
                                        :label="$t('qty')"
                                        required
                                        outlined
                                        v-model="qtyAmount"
                                        append-icon="mdi-credit-card-settings-outline"
                                        type="text"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-card-actions>
                                <div class="function_footer text-right">
                                    <!-- <v-btn color="secondary" class="mr-2 white--text text-capitalize"
                                        @click="dialogRProgram = false">
                                        {{ $t('skip') }}
                                    </v-btn> -->
                                    <v-btn color="primary" class="float-right white--text text-capitalize"
                                        @click="enterQTY()">
                                        {{ $t('enter') }}
                                    </v-btn>
                                </div>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
            </template>
            <!-- promotion -->
            <v-col md="6" sm="3" col="3" class="pa-1">
                <template>
                    <v-dialog
                        v-model="dialogPromotion"
                        fullscreen
                        persistent
                        hide-overlay
                        transition="dialog-bottom-transition"
                        >
                        <template v-slot:activator="{ on, attrs }">
                        </template>
                        <v-card>
                            <v-toolbar
                                dark
                                color="secondary"
                                >
                                <v-btn
                                    icon
                                    dark
                                     @click="cancelPromotion"
                                >
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-toolbar-title>{{ $t("promotion") }}</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-toolbar-items>
                                    <v-btn
                                    dark
                                    text
                                    @click="cancelPromotion"
                                    >
                                    {{ $t('skip') }}
                                    </v-btn>
                                </v-toolbar-items>
                            </v-toolbar>
                            <v-col md="12" col="12" class="function_content pa-3" style="height: 93vh;overflow: hidden; overflow-y: scroll;">
                                <v-card class="mx-auto my-auto" max-width="1000" style="background: none;box-shadow: none;">
                                    <v-row>
                                        <v-col sm="3" cols="6" class="">
                                            <v-select class="mt-1 rounded-0 "
                                                    clearable
                                                    outlined
                                                    :label="$t('code')"
                                            />
                                        </v-col>
                                        <v-col sm="9" cols="6" class="">
                                            <v-text-field
                                                class="mt-1 rounded-0 font_18"
                                                outlined
                                                :label="$t('search')"
                                                append-icon="b-search"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col v-for="cam in promotions" v-bind:key="cam.id"   sm="4" cols="4" class="">
                                            <v-card
                                                class="mx-auto pa-3"
                                                max-width="500"
                                                outlined
                                                @click="applyPromotion(cam)"
                                            >
                                                <template>
                                                    <v-simple-table>
                                                        <template>
                                                            <tbody>
                                                                <tr>
                                                                    
                                                                    <td colspan="3" class="text-left  px-1" style="background: #eb0238;">
                                                                        <span class="niradei_heavy font_34 white--text ml-2">
                                                                            {{cam.name}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="text-left tdbig  px-2">
                                                                        <span class="niradei_medium font_12 grey--text">
                                                                            {{ $t("code") }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="text-left tdbig px-0 ">:</td>
                                                                    <td class="text-right  px-2">
                                                                        <span class="niradei_heavy font_12 dark_grey">
                                                                            {{cam.code}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="text-left tdbig  px-2">
                                                                        <span class="niradei_medium font_14 grey--text">
                                                                        {{ $t("expiry_date") }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="text-left tdbig px-0">:</td>
                                                                    <td class="text-right  px-2">
                                                                        <span class="niradei_heavy font_14 primary--text">
                                                                            {{cam.isEndDate ? cam.endDate : ''}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </template>
                                                    </v-simple-table>
                                                </template>
                                            </v-card>
                                        </v-col>
                                        
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-card>
                    </v-dialog>
                </template> 
            </v-col>
            <!-- partner -->
            <template>
                <v-row>
                    <v-dialog
                        v-model="dialogPartner"
                        persistent
                        max-width="600px"
                        >
                        <v-card>
                            <div class="modal_header">
                                <v-card-title>{{ $t("partner") }}</v-card-title>
                                <v-icon
                                    @click="dialogPartner = false"
                                    style="cursor: pointer; font-size: 30px;"
                                    color="grey"
                                    class="float-right mt-n1">close
                                </v-icon>
                            </div>
                            <v-col md="12" col="12" class="function_content pa-3">
                                <v-row>
                                    <div v-for="p in partners" v-bind:key="p.pk" class="col-4 pa-0">
                                        <v-col sm="12" cols="12" class="">
                                            <v-card
                                                class="mx-auto"
                                                max-width="465"
                                                outlined
                                                @click="selectPartner(p)"
                                            >
                                                <v-list-item three-line>
                                                    <v-list-item-content class="pk-3">
                                                        <v-row>
                                                            <v-col sm="12" cols="12" class="">
                                                                <img
                                                                    class="img-1"
                                                                    :src="p.image != '' ? 'https://s3-ap-southeast-1.amazonaws.com/images.banhji/' + p.image : ''"
                                                                    width="100%"
                                                                />
                                                                <h4 style="text-align: center;">{{p.name}}</h4>
                                                            </v-col>
                                                        </v-row>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-card>
                                        </v-col>
                                    </div>
                                </v-row>
                            </v-col>
                            <!-- <v-card-actions>
                                <div class="function_footer">
                                        <v-btn color="secondary" class="float-right white--text text-capitalize"
                                        @click="skipPartner">
                                        {{ $t('skip') }}
                                    </v-btn>
                                </div>
                                <v-spacer></v-spacer>
                            </v-card-actions> -->
                        </v-card>
                    </v-dialog>
                </v-row>
            </template> 
            <!-- partner ref number -->
            <template>
                <v-row>
                    <v-dialog
                        v-model="dialogPartnerRef"
                        persistent
                        max-width="400px"
                        >
                        <v-card>
                            <div class="modal_header">
                                <v-card-title>{{ $t("reference_number") }}</v-card-title>
                                <v-icon
                                    @click="dialogPartnerRef = false"
                                    style="cursor: pointer; font-size: 30px;"
                                    color="grey"
                                    class="float-right mt-n1">close
                                </v-icon>
                            </div>
                            <v-col md="12" col="12" class="function_content pa-3">
                                <v-row>
                                    <v-col sm="12" cols="12" style="background-color: antiquewhite;">
                                        <h2 class="font_14">{{$t('partner')}}</h2>
                                        <h2 class="font_18 primary--text">{{activePartner.name}}</h2>

                                    </v-col>
                                    <v-col md="12" cols="12" class="">
                                        <v-text-field
                                        :label="$t('number')"
                                        required
                                        v-model="partnerRefNum"
                                        outlined
                                        append-icon="mdi-credit-card-settings-outline"
                                        type="text"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-card-actions>
                                <div class="function_footer text-right">
                                    <!-- <v-btn color="secondary" class="white--text text-capitalize mr-2"
                                        @click="skipPartnerRef">
                                        {{ $t('skip') }}
                                    </v-btn> -->
                                    <v-btn color="primary" class="float-right white--text text-capitalize"
                                        @click="enterPartnerRef">
                                        {{ $t('enter') }}
                                    </v-btn>
                                </div>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
            </template>
            <!-- Sale Unit -->
            <template>
                <v-dialog
                    v-model="dialogSaleUnit"
                    fullscreen
                    persistent
                    hide-overlay
                    transition="dialog-bottom-transition"
                    >
                    <template v-slot:activator="{ on, attrs }">
                    </template>
                    <v-card>
                        <v-toolbar
                            dark
                            color="secondary"
                            >
                            <v-btn
                                icon
                                dark
                                v-if="showCancelODF"
                                @click="closeODF"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-btn
                                icon
                                dark
                                v-if="showBackODF"
                                @click="backODF"
                            >
                                <v-icon>mdi-arrow-left</v-icon>
                            </v-btn>
                            <v-toolbar-title>{{ $t("sale_unit") }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                                <v-btn
                                    dark
                                    text
                                    v-if="!g.rsaleunit"
                                    @click="skipSaleUnit"
                                >
                                    {{ $t('skip') }}
                                </v-btn>
                                <v-btn
                                    dark
                                    text
                                    @click="enterSaleUnit()">
                                
                                    {{ $t('enter') }}
                                </v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <v-col v-if="showSaleUnitCate" md="12" col="12" class="function_content pa-3" style="height: 90vh;overflow: hidden; overflow-y: scroll;">
                            <v-card class="mx-auto my-auto" max-width="1000" style="background: none;box-shadow: none;">
                                <v-row class="d-flex justify-center" >
                                    <v-col sm="4" cols="6" class="" v-for="d in saleUnitCates" v-bind:key="d.id">
                                        <v-card
                                            class="mx-auto"
                                            max-width="500"
                                            outlined
                                            @click="selectCateSaleUnit(d)"
                                        >
                                            <v-list-item three-line>
                                                <v-list-item-content class="pk-3">
                                                    <v-row>
                                                        <v-col sm="12" cols="12" class="text-center">
                                                            <h2 class="font_22">{{d.name}}</h2>
                                                        </v-col>
                                                    </v-row>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                        <v-col v-else md="12" col="12" class="function_content pa-3" style="height: 90vh;overflow: hidden; overflow-y: scroll;">
                            <v-card class="mx-auto my-auto" max-width="1000" style="background: none;box-shadow: none;">
                                <v-row class="d-flex justify-center">
                                    <v-col sm="3" cols="6" class="" v-for="d in saleUnits" v-bind:key="d.id">
                                        <v-card
                                            class="mx-auto"
                                            :color="saleUnit.id == d.id ? 'primary': 'white' "
                                            :class="saleUnit.id == d.id ? 'white--text': 'black--text' "
                                            max-width="500"
                                            outlined
                                            @click="selectSaleUnit(d)"
                                        >
                                            <v-list-item three-line>
                                                <v-list-item-content class="pk-3">
                                                    <v-row>
                                                        <v-col sm="12" cols="12" class="text-center">
                                                            <h2 class="font_22">{{d.name}}</h2>
                                                        </v-col>
                                                    </v-row>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                        <!-- <v-card-actions v-show="!showSaleUnitCate">
                            <div class="function_footer text-right">
                                <v-btn color="secondary" class="float-left mr-2 white--text text-capitalize"
                                    @click="backSaleUnit()">
                                    {{ $t('back') }}
                                </v-btn>
                                <v-btn color="secondary" class="mr-2 white--text text-capitalize"
                                    @click="skipSaleUnit()">
                                    {{ $t('skip') }}
                                </v-btn>
                                <v-btn color="primary" class="float-right white--text text-capitalize"
                                    @click="enterSaleUnit()">
                                    {{ $t('enter') }}
                                </v-btn>
                            </div>
                            <v-spacer></v-spacer>
                        </v-card-actions> -->
                    </v-card>
                </v-dialog>
            </template> 
            <!-- Buy Loyalty Card -->
            <template>
                <v-dialog
                    v-model="dialogLcardBy"
                    fullscreen
                    hide-overlay
                    persistent
                    transition="dialog-bottom-transition"
                    >
                    <template v-slot:activator="{ on, attrs }">
                    </template>
                    <v-card>
                        <v-toolbar
                            dark
                            color="secondary"
                            >
                            <v-btn
                                icon
                                dark
                                @click="dialogLcardBy = false"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-toolbar-title>{{ $t("create_card_by") }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-col md="12" col="12" class="function_content pa-3" style="height: 90vh;overflow: hidden;overflow-y: scroll;">
                            <v-card class="mx-auto my-auto" max-width="1000" style="background: none;box-shadow: none;">
                                <v-row class="d-flex justify-center" >
                                    <v-col sm="4" cols="4" class="">
                                        <v-card
                                            class="mx-auto"
                                            max-width="500"
                                            @click="createCardBy('phone')"
                                            outlined
                                        >
                                            <v-list-item three-line>
                                                <v-list-item-content class="pk-3">
                                                    <v-row>
                                                        <v-col sm="12" cols="12" class="text-center">
                                                            <h2 class="font_22">{{$t('phone_number')}}</h2>
                                                        </v-col>
                                                    </v-row>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-card>
                                    </v-col>
                                    <v-col sm="4" cols="4" class="">
                                        <v-card
                                            class="mx-auto"
                                            max-width="500"
                                            @click="createCardBy('randomnumber')"
                                            outlined
                                        >
                                            <v-list-item three-line>
                                                <v-list-item-content class="pk-3">
                                                    <v-row>
                                                        <v-col sm="12" cols="12" class="text-center">
                                                            <h2 class="font_22">{{$t('random_number')}}</h2>
                                                        </v-col>
                                                    </v-row>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-card>
                                    </v-col>
                                    <v-col sm="4" cols="4" class="">
                                        <v-card
                                            class="mx-auto"
                                            max-width="500"
                                            @click="createCardBy('number')"
                                            outlined
                                        >
                                            <v-list-item three-line>
                                                <v-list-item-content class="pk-3">
                                                    <v-row>
                                                        <v-col sm="12" cols="12" class="text-center">
                                                            <h2 class="font_22">{{$t('physical_card')}}</h2>
                                                        </v-col>
                                                    </v-row>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-card>
                </v-dialog>
            </template> 
            <!--top up-->
            <template>
                <v-row>
                    <v-dialog
                        v-model="showTopUpForm"
                        persistent
                        max-width="400px"
                        >
                        <v-card>
                            <div class="modal_header">
                                <v-card-title>{{ $t("top_up") }}</v-card-title>
                                <v-icon
                                    @click="showTopUpForm = false"
                                    style="cursor: pointer; font-size: 30px;"
                                    color="grey"
                                    class="float-right mt-n1">close
                                </v-icon>
                            </div>
                            <v-col md="12" col="12" class="function_content pa-3">
                                <v-row>
                                    <v-col cols="12"  md="12" class="pt-0">
                                        <v-row>
                                            <v-col md="12" col="12" class="function_content pa-3">
                                                <template>
                                                    <v-simple-table>
                                                        <template>
                                                            <tbody>
                                                                <tr>
                                                                    <td class="text-left tdbig">
                                                                        <span class="niradei_medium font_14 grey--text">
                                                                            {{ $t("card_owner_name") }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="text-left tdbig">:</td>
                                                                    <td class="text-right">
                                                                        <span class="niradei_heavy font_18 dark_grey">
                                                                            {{topUpCard.name}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="text-left tdbig">
                                                                        <span class="niradei_medium font_14 grey--text">
                                                                            {{ $t("card_number") }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="text-left tdbig">:</td>
                                                                    <td class="text-right">
                                                                        <span class="niradei_heavy font_18 dark_grey">
                                                                            {{topUpCard.cardNum}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="text-left tdbig">
                                                                        <span class="niradei_medium font_14 grey--text">
                                                                        {{ $t("phone_number") }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="text-left tdbig">:</td>
                                                                    <td class="text-right">
                                                                        <span class="niradei_heavy font_18 dark_grey">
                                                                            {{topUpCard.phoneNum}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="text-left tdbig">
                                                                        <span class="niradei_medium font_14 grey--text">
                                                                        {{ $t("point_balance") }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="text-left tdbig">:</td>
                                                                    <td class="text-right">
                                                                        <span class="niradei_heavy font_18 dark_grey">
                                                                            {{topUpCard.totalPoint}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="text-left tdbig">
                                                                        <span class="niradei_medium font_14 grey--text">
                                                                        {{ $t("top_up_rate") }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="text-left tdbig">:</td>
                                                                    <td class="text-right">
                                                                        <span class="niradei_heavy font_18 dark_grey">
                                                                            {{pointTopUpRate.topUpRate}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="text-left tdbig">
                                                                        <span class="niradei_medium font_14 grey--text">
                                                                        {{ $t("top_up_amount") }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="text-left tdbig">:</td>
                                                                    <td class="text-right">
                                                                        <v-text-field
                                                                            class="mt-1"
                                                                            v-model="topUpAmount"
                                                                            type="number"
                                                                            outlined
                                                                            clearable
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </template>
                                                    </v-simple-table>
                                                </template>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-card-actions>
                                <div class="function_footer">
                                    <v-btn color="primary" class="float-right white--text text-capitalize"
                                        @click="saveTopUp">
                                        {{ $t('enter') }}
                                    </v-btn>
                                </div>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
            </template>
            <template>
                <v-dialog
                    v-model="dialogSelectTopup"
                    fullscreen
                    hide-overlay
                    persistent
                    transition="dialog-bottom-transition"
                    >
                    <v-card>
                        <v-toolbar
                            dark
                            color="secondary"
                            >
                            <v-btn
                                icon
                                dark
                                @click="closeTopUp"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-toolbar-title>{{ $t("top_up") }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-col md="12" col="12" class="function_content pa-3" style="height: 93vh;overflow: hidden; overflow-y: scroll;">
                            <v-card class="mx-auto my-auto" max-width="1000" style="background: none;box-shadow: none;">
                                <v-row>
                                    <v-col sm="4" cols="4" class="">
                                        <v-card
                                            class="mx-auto"
                                            max-width="465"
                                            outlined
                                            @click="selectTopUp('counter')"
                                        >
                                            <v-list-item three-line>
                                                <v-list-item-content class="pk-3">
                                                    <v-row>
                                                        <v-col sm="12" cols="12" class="text-center">
                                                            <h2  class="font_22">{{$t('top_up')}}</h2>
                                                            <h2  class="font_22">{{$t('counter')}}</h2>
                                                        </v-col>
                                                    </v-row>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-card>
                                    </v-col>
                                    <v-col sm="4" cols="4" class="">
                                        <v-card
                                            class="mx-auto"
                                            max-width="465"
                                            outlined
                                            @click="selectTopUp('giftcard')"
                                        >
                                            <v-list-item three-line>
                                                <v-list-item-content class="pk-3">
                                                    <v-row>
                                                        <v-col sm="12" cols="12" class="text-center">
                                                            <h2 class="primary--text font_22">{{$t('top_up')}}</h2>
                                                            <h2 class="primary--text font_22">{{$t('gift_card')}}</h2>
                                                        </v-col>
                                                    </v-row>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-card>
                </v-dialog>
            </template>
            <!-- Search Card-->
             <template>
                <v-row>
                    <v-dialog
                        v-model="dialogSCard"
                        persistent
                        max-width="400px"
                        >
                        <v-card>
                            <div class="modal_header">
                                <v-card-title>{{ $t("loyalty_card") }}</v-card-title>
                                <v-icon
                                    @click="closeSearchCard"
                                    style="cursor: pointer; font-size: 30px;"
                                    color="grey"
                                    class="float-right mt-n1">close
                                </v-icon>
                            </div>
                            <v-col md="12" col="12" class="function_content pa-3">
                                <v-row>
                                    <v-col cols="12"  md="12" class="pt-0">
                                        <v-row>
                                            <v-col md="12" col="12" class="function_content pa-3">
                                                <template>
                                                    <v-simple-table>
                                                        <template>
                                                            <tbody>
                                                                <tr>
                                                                    <td class="text-left tdbig">
                                                                        <span class="niradei_medium font_14 grey--text">
                                                                            {{ $t("card_owner_name") }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="text-left tdbig">:</td>
                                                                    <td class="text-right">
                                                                        <span class="niradei_heavy font_18 dark_grey">
                                                                            {{searchCard.name}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="text-left tdbig">
                                                                        <span class="niradei_medium font_14 grey--text">
                                                                            {{ $t("card_number") }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="text-left tdbig">:</td>
                                                                    <td class="text-right">
                                                                        <span class="niradei_heavy font_18 dark_grey">
                                                                            {{searchCard.cardNum}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="text-left tdbig">
                                                                        <span class="niradei_medium font_14 grey--text">
                                                                            {{ $t("serial") }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="text-left tdbig">:</td>
                                                                    <td class="text-right">
                                                                        <span class="niradei_heavy font_18 dark_grey">
                                                                            {{searchCard.serial}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="text-left tdbig">
                                                                        <span class="niradei_medium font_14 grey--text">
                                                                        {{ $t("phone_number") }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="text-left tdbig">:</td>
                                                                    <td class="text-right">
                                                                        <span class="niradei_heavy font_18 dark_grey">
                                                                            {{searchCard.phoneNum}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="text-left tdbig">
                                                                        <span class="niradei_medium font_14 grey--text">
                                                                        {{ $t("point_balance") }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="text-left tdbig">:</td>
                                                                    <td class="text-right">
                                                                        <span class="niradei_heavy font_18 dark_grey">
                                                                            {{searchCard.totalPoint}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="text-left tdbig">
                                                                        <span class="niradei_medium font_14 grey--text">
                                                                        {{ $t("status") }}
                                                                        </span>
                                                                    </td>
                                                                    <td class="text-left tdbig">:</td>
                                                                    <td class="text-right">
                                                                        <span class="niradei_heavy font_18 dark_grey">
                                                                            {{searchCard.status}}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </template>
                                                    </v-simple-table>
                                                </template>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-card>
                    </v-dialog>
                </v-row>
            </template>
            <!-- Change Sale unit-->
            <template>
                <v-row>
                    <v-dialog
                        v-model="dialogChangeSaleunit"
                        persistent
                        max-width="600px"
                        >
                        <v-card>
                            <div class="modal_header">
                                <v-card-title>{{ $t("change_sale_unit") }}</v-card-title>
                                <v-icon
                                    @click="dialogChangeSaleunit = false"
                                    style="cursor: pointer; font-size: 30px;"
                                    color="grey"
                                    class="float-right mt-n1">close
                                </v-icon>
                            </div>
                            <v-col md="12" col="12" class="function_content pa-3">
                                <v-row>
                                    <v-col sm="12" cols="12" class="pb-0">
                                        <v-select
                                            class="mt-1"
                                            :label="$t('sale_unit')"
                                            v-model="cSaleUnit"
                                            :items="saleUnitList"
                                            item-value="id"
                                            item-text="name"
                                            return-object
                                            outlined
                                        />
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-card-actions>
                                <div class="function_footer text-right">
                                    <!-- <v-btn color="secondary" class="mr-2 white--text text-capitalize"
                                        @click="dialogRProgram = false">
                                        {{ $t('skip') }}
                                    </v-btn> -->
                                    <v-btn color="primary" class="float-right white--text text-capitalize"
                                        @click="enterCSaleUnit">
                                        {{ $t('enter') }}
                                    </v-btn>
                                </div>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
            </template>
            <!--verify-->
            <template>
                <v-row>
                    <v-dialog
                        v-model="dialogCode"
                        persistent
                        style="z-index: 9999"
                        max-width="350px"
                        >
                        <v-card>
                            <div class="modal_header">
                                <v-card-title>{{ $t("code") }}</v-card-title>
                                <v-icon
                                    @click="dialogCode = false"
                                    style="cursor: pointer; font-size: 30px;"
                                    color="grey"
                                    class="float-right mt-n1">close
                                </v-icon>
                            </div>
                            <v-col md="12" col="12" class="function_content pa-3">
                                <v-row>
                                    <v-col sm="12" cols="12" class="pb-0">
                                        <v-text-field
                                        :label="$t('code')"
                                        required
                                        outlined
                                        v-model="verifyCode"
                                        inputmode='none'
                                        append-icon="mdi-credit-card-settings-outline"
                                        type="text"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col sm="12" cols="12" class="pb-0">
                                        <div class="numbers">
                                            <div @click="vClick(1)" class="number">1</div>
                                            <div @click="vClick(2)" class="number">2</div>
                                            <div @click="vClick(3)" class="number">3</div>
                                            <div @click="vClick(4)" class="number">4</div>
                                            <div @click="vClick(5)" class="number">5</div>
                                            <div @click="vClick(6)" class="number">6</div>
                                            <div @click="vClick(7)" class="number">7</div>
                                            <div @click="vClick(8)" class="number">8</div>
                                            <div @click="vClick(9)" class="number">9</div>
                                            <div @click="verifyCode = ''" class="number" >{{$t("ac")}}</div>
                                            <div @click="vClick(0)" class="number">0</div>
                                            <div @click="enterVerify" class="number" style="background-color: rgb(154 27 46);color: #ffff;">{{$t("go")}}</div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <!-- <v-card-actions>
                                <div class="function_footer text-right">
                                    <v-btn color="primary" class="float-right white--text text-capitalize"
                                        @click="enterVerify()">
                                        {{ $t('enter') }}
                                    </v-btn>
                                </div>
                                <v-spacer></v-spacer>
                            </v-card-actions> -->
                        </v-card>
                    </v-dialog>
                </v-row>
            </template>
            <!-- Gifcard -->
            <template>
                <v-row>
                    <v-dialog
                        v-model="dialogSearchGCard"
                        fullscreen
                        persistent
                        hide-overlay
                        transition="dialog-bottom-transition"
                        >
                        <v-card>
                            <v-toolbar
                                dark
                                color="secondary"
                                >
                                <v-btn
                                    icon
                                    dark
                                    @click="showGCardSForm"
                                >
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-toolbar-title>{{ $t("gift_card_search") }}</v-toolbar-title>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                            <v-col md="12" col="12" class="function_content pa-3" style="height: 93vh;overflow: hidden; overflow-y: scroll;">
                                <v-card class="mx-auto my-auto" max-width="1000" style="background: none;box-shadow: none;">
                                    <v-row>
                                        <v-col sm="9" cols="9" class="pb-0">
                                            <v-text-field
                                            :label="$t('card_number')"
                                            required
                                            outlined
                                            v-model="gcnumber"
                                            append-icon="mdi-credit-card-settings-outline"
                                            type="text"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col sm="3" cols="3" class="pb-0" >
                                            <v-btn color="primary" class="mr-2 white--text text-capitalize"
                                                @click="searchGCard">
                                                {{ $t('search') }}
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-card>
                    </v-dialog>
                </v-row>
            </template>
            <template>
                <v-row>
                    <v-dialog
                        v-model="dialogSGCard"
                        fullscreen
                        hide-overlay
                        persistent
                        transition="dialog-bottom-transition"
                        >
                        <v-card>
                            <v-toolbar
                                dark
                                color="secondary"
                                >
                                <v-btn
                                    icon
                                    dark
                                    @click="dialogSGCard = false"
                                >
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-toolbar-title>{{ $t("gift_card_search") }}</v-toolbar-title>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                            <v-col md="12" col="12" class="function_content pa-3" style="height: 93vh;overflow: hidden; overflow-y: scroll;">
                                <v-card class="mx-auto my-auto" max-width="1000" style="background: none;box-shadow: none;">
                                    <v-row>
                                        <v-col sm="5" cols="5" class="pb-0">
                                            <v-text-field
                                            :label="$t('card_number')"
                                            required
                                            outlined
                                            v-model="giftCard.number"
                                            append-icon="mdi-credit-card-settings-outline"
                                            type="text"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col sm="5" cols="5" class="pb-0">
                                            <v-text-field
                                            :label="$t('serial')"
                                            required
                                            outlined
                                            v-model="giftCard.serial"
                                            append-icon="mdi-credit-card-settings-outline"
                                            type="password"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col sm="2" cols="2" class="pb-0" >
                                            <v-btn color="primary" class="mr-2 white--text text-capitalize"
                                                @click="searchTGCard">
                                                {{ $t('search') }}
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-card>
                    </v-dialog>
                </v-row>
            </template>
            <!-- Point Pay Option -->
            <template>
                <v-dialog
                    v-model="dialogPPOption"
                    fullscreen
                    hide-overlay
                    persistent
                    transition="dialog-bottom-transition"
                    >
                    <v-card>
                        <v-toolbar
                            dark
                            color="secondary"
                            >
                            <v-btn
                                icon
                                dark
                                @click="dialogPPOption = false"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-toolbar-title>{{ $t("point_pay") }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-col md="12" col="12" class="function_content pa-3" style="height: 93vh;overflow: hidden; overflow-y: scroll;">
                            <v-card class="mx-auto my-auto" max-width="1000" style="background: none;box-shadow: none;">
                                <v-row>
                                    <v-col sm="4" cols="4" class="">
                                        <v-card
                                            class="mx-auto"
                                            max-width="465"
                                            outlined
                                            @click="pointPayOpt('qr')"
                                        >
                                            <v-list-item three-line>
                                                <v-list-item-content class="pk-3">
                                                    <v-row>
                                                        <v-col sm="12" cols="12" class="text-center">
                                                            <h2  class="font_22">{{$t('qr_code')}}</h2>
                                                        </v-col>
                                                    </v-row>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-card>
                                    </v-col>
                                    <v-col sm="4" cols="4" class="">
                                        <v-card
                                            class="mx-auto"
                                            max-width="465"
                                            outlined
                                            @click="pointPayOpt('code')"
                                        >
                                            <v-list-item three-line>
                                                <v-list-item-content class="pk-3">
                                                    <v-row>
                                                        <v-col sm="12" cols="12" class="text-center">
                                                            <h2 class="primary--text font_22">{{$t('verify_code')}}</h2>
                                                        </v-col>
                                                    </v-row>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-card>
                </v-dialog>
            </template>
            <!--Point QR-->
            <v-dialog
                v-model="dailogPrintPQR"
                fullscreen
                hide-overlay
                persistent
                transition="dialog-bottom-transition"
            >
                <template v-slot:activator="{ on, attrs }">
                    
                </template>
                <v-card style="background-color: #f8f8f9;">
                    <v-container>
                        <v-card outlined dense class="px-3 no_border mt-4" color="white">
                            <div class="modal_header">
                                <v-card-title>{{ $t("register_card") }}</v-card-title>
                                <v-icon
                                    @click="dailogPrintPQR = false"
                                    style="cursor: pointer; font-size: 40px;"
                                    color="grey"
                                    class="float-right mt-n1">close
                                </v-icon>
                            </div>
                            <v-card-text class="modal_text_content">
                                <v-row>
                                    <v-col sm="6" cols="6" class="py-0" style="margin: 0 auto;">
                                        <PrintPointQR :qrObj="qrObj" />
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-divider/>
                            <v-card-actions class="py-0">
                                <div class="function_footer pt-0">
                                    <v-row>
                                        <v-col cols="7" class="border_right_lightgrey">
                                            <v-btn color="primary" class="float-left white--text text-capitalize"
                                                @click="checkPointPay('qr')">
                                                {{ $t('check') }}
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="5">
                                            <v-btn color="secondary" class="float-right white--text text-capitalize"
                                                @click="printHtmlA">
                                                {{ $t('print') }}
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-container>
                </v-card>
            </v-dialog>
            <!--Reprot-->
            <v-dialog
                v-model="dialogReport"
                fullscreen
                hide-overlay
                persistent
                transition="dialog-bottom-transition"
            >
                <v-card>
                    <v-toolbar dark color="secondary">
                        <v-btn icon dark  @click="dialogReport = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ $t("report") }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <downloadexcel
                            icon
                            color="black"
                            class="float-right "
                            :data="reportDatas"
                            :fields="exfield"
                            style="background-color: rgba(0,0,0,1)!important;padding: 10px; border-radius: 5px;"
                            type="xls"
                            :name="'saleSummary'+new Date().toISOString().substr(0, 10)+'.xls'">
                            {{$t('export_excel')}}
                        </downloadexcel>
                    </v-toolbar>
                    <v-col md="12" col="12" class="function_content pa-3" style="height: 93vh;overflow: hidden; overflow-y: scroll;">
                        <v-card class="mx-auto my-auto" max-width="1200" style="background: none;box-shadow: none;">
                            <v-row>
                                <v-col sm="12" cols="12" class="py-0" style="overflow: scroll">
                                    <v-row >
                                        <v-col sm="12" cols="12">
                                            <v-row>
                                                <v-col sm="12" cols="12" style="display: flex;justify-content: space-between;">
                                                    <div style="border: 1px solid #ccc; border-radius: 5px;text-align: center;width: 32%;padding: 10px 0;">
                                                        <div style="width: 100%; font-size: 18px">
                                                            {{ $t('sub_total') }}
                                                        </div>
                                                        <div style="width: 100%; font-size: 30px; line-height: 40px;">
                                                            {{ numberSetting(repSubTotal) }}
                                                        </div>
                                                    </div>
                                                    <div style="border: 1px solid #ccc; border-radius: 5px;text-align: center;width: 32%;padding: 10px 0;">
                                                        <div style="width: 100%; font-size: 18px">
                                                            {{ $t('discount') }}
                                                        </div>
                                                        <div style="width: 100%; font-size: 30px; line-height: 40px;">
                                                            {{ numberSetting(repDiscount) }}
                                                        </div>
                                                    </div>
                                                    <div class="primary" style="border: 1px solid #ccc; border-radius: 5px;text-align: center;width: 32%;padding: 10px 0;">
                                                        <div style="width: 100%; font-size: 18px;color: #fff">
                                                            {{ $t('total') }}
                                                        </div>
                                                        <div style="width: 100%; font-size: 30px; line-height: 40px;color: #fff">
                                                            {{ numberSetting(repTotal) }}
                                                        </div>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                            <gridFullScreen>
                                                <template>
                                                    <kendo-datasource
                                                        ref="saleSummary"
                                                        :group="reportGroup"
                                                        :data="sSumary" />
                                                    <kendo-grid
                                                        id="saleSummary" class="grid-function"
                                                        :data-source-ref="'saleSummary'"
                                                        :editable="false"
                                                        :groupable="true"
                                                        :column-menu="true"
                                                        :noRecords="true"
                                                        :sortable="true"
                                                        :excel-filterable="false"
                                                        :excel-all-pages="false"
                                                        v-on:databound="dataBound"
                                                        :scrollable-virtual="true">
                                                        <kendo-grid-column
                                                            :field="'shift'"
                                                            :title="$t('shift')"
                                                            :width="100"
                                                            :headerAttributes="{ style: 'background-color: #EDF1F5, color: green !important' }"/>
                                                        <kendo-grid-column
                                                            :command="{
                                                                text: rePrint,
                                                                click: rePrintBill,
                                                                className: 'btn-plus isEditable',
                                                            }"
                                                            :title="$t('action')"
                                                            :width="150"
                                                            :headerAttributes="{
                                                            style: 'text-align: left; background-color: #EDF1F5',
                                                            }"
                                                        />
                                                        <kendo-grid-column
                                                            :field="'txnNumber'"
                                                            :title="$t('number')"
                                                            :width="180"
                                                            :attributes="{style: 'text-align: right; ', class:'tb_name_td'}"
                                                            :headerAttributes="{ style: 'background-color: #EDF1F5' }"/>
                                                        <kendo-grid-column
                                                            :field="'issuedDate'"
                                                            :title="$t('issued_date')"
                                                            :width="200"
                                                            :template="'#= kendo.toString(new Date(issuedDate), `yyyy-MMM-dd hh:mm tt`)#'"
                                                            :attributes="{style: 'text-align: right; ', class:'tb_name_td'}"
                                                            :headerAttributes="{ style: 'background-color: #EDF1F5' }"/>
                                                        <kendo-grid-column
                                                            :field="'cashier'"
                                                            :title="$t('cashier')"
                                                            :width="200"
                                                            :hidden="true"
                                                            :template="cashierTmpl"
                                                            :attributes="{style: 'text-align: right; ', class:'tb_name_td'}"
                                                            :headerAttributes="{ style: 'background-color: #EDF1F5' }"/>
                                                        <kendo-grid-column
                                                            :field="'subTotal'"
                                                            :title="$t('sub_total')"
                                                            :width="150"
                                                            :template="'#= subTotal.toFixed(2) #'"
                                                            :attributes="{style: 'text-align: right; ', class:'tb_name_td'}"
                                                            :headerAttributes="{ style: 'background-color: #EDF1F5' }"/>
                                                        <kendo-grid-column
                                                            :field="'discount'"
                                                            :title="$t('discount')"
                                                            :width="150"
                                                            :template="'#= discount.toFixed(2) #'"
                                                            :attributes="{style: 'text-align: right; ', class:'tb_name_td'}"
                                                            :headerAttributes="{ style: 'background-color: #EDF1F5' }"/>
                                                        <kendo-grid-column
                                                            :field="'comission'"
                                                            :title="$t('sale_comission')"
                                                            :template="'#= comission.toFixed(2) #'"
                                                            :width="150"
                                                            :hidden="true"
                                                            :attributes="{style: 'text-align: right; ', class:'tb_name_td'}"
                                                            :headerAttributes="{ style: 'background-color: #EDF1F5' }"/>
                                                        <kendo-grid-column
                                                            :field="'tax'"
                                                            :title="$t('vat')"
                                                            :template="'#= tax.toFixed(2) #'"
                                                            :width="150"
                                                            :attributes="{style: 'text-align: right; ', class:'tb_name_td'}"
                                                            :groupFooterAttributes="{style: 'text-align: right'}"
                                                            :group-footer-template="$t('total')"
                                                            :headerAttributes="{ style: 'background-color: #EDF1F5' }"/>
                                                        <kendo-grid-column
                                                            :field="'total'"
                                                            :template="'#= total.toFixed(2) #'"
                                                            :group-footer-template="'#=  kendo.toString(sum, `n2`)#'"
                                                            :groupFooterAttributes="{style: 'text-align: right'}"
                                                            :title="$t('total_amount')"
                                                            :width="150"
                                                            :attributes="{style: 'text-align: right; ', class:'tb_name_td'}"
                                                            :headerAttributes="{ style: 'background-color: #EDF1F5' }"/>
                                                        <kendo-grid-column
                                                            :command="{
                                                                text: voidText,
                                                                click: voidInv,
                                                                className: 'k-state-disabled isStatus'
                                                            }"
                                                            :title="$t('void')"
                                                            :width="150"
                                                            :hidden="true"
                                                            :headerAttributes="{
                                                            style: 'text-align: left; background-color: #EDF1F5',
                                                            }"
                                                        />
                                                    </kendo-grid>
                                                </template>
                                            </gridFullScreen>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-card>
            </v-dialog>
            <!--noprice-->
            <!-- <v-dialog
                v-model="showNoPrice"
                fullscreen
                hide-overlay
                persistent
                transition="dialog-bottom-transition"
            >
                <v-card>
                    <v-toolbar dark color="secondary">
                        <v-btn icon dark  @click="showNoPrice = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ $t("report") }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <downloadexcel
                            icon
                            color="black"
                            class="bg-none float-right "
                            :data="itemNoPrices"
                            :fields="exfield"
                            type="xls"
                            :name="$t('itemNoPrice')+'.xls'">
                            {{$t('export_excel')}}
                        </downloadexcel>
                    </v-toolbar>
                    <v-col md="12" col="12" class="function_content pa-3" style="height: 93vh;overflow: hidden; overflow-y: scroll;">
                        
                        <v-card class="mx-auto my-auto" max-width="1200" style="background: none;box-shadow: none;">
                            <v-row>
                                <v-col sm="12" cols="12" class="py-0" style="overflow: scroll">
                                    <v-row >
                                        <v-col sm="12" cols="12">
                                            <gridFullScreen>
                                                <template>
                                                    <kendo-datasource
                                                        ref="itemNoPrices"
                                                        :data="itemNoPrices" />
                                                    <kendo-grid
                                                        id="itemNoPrices" class="grid-function"
                                                        :data-source-ref="'itemNoPrices'"
                                                        :editable="false"
                                                        :groupable="true"
                                                        :column-menu="true"
                                                        :noRecords="true"
                                                        :sortable="true"
                                                        :excel-file-name="$t('itemNoPrices')+'.xlsx'"
                                                        :excel-filterable="true"
                                                        :excel-all-pages="true"
                                                        :toolbar="['excel']"
                                                        :scrollable-virtual="true">
                                                        <kendo-grid-column
                                                            :field="'name'"
                                                            :title="$t('name')"
                                                            :width="100"
                                                            :headerAttributes="{ style: 'background-color: #EDF1F5, color: green !important' }"/>
                                                        <kendo-grid-column
                                                            :field="'category'"
                                                            :title="$t('category')"
                                                            :width="100"
                                                            :headerAttributes="{ style: 'background-color: #EDF1F5, color: green !important' }"/>
                                                        <kendo-grid-column
                                                            :field="'group'"
                                                            :title="$t('group')"
                                                            :width="100"
                                                            :headerAttributes="{ style: 'background-color: #EDF1F5, color: green !important' }"/>
                                                        <kendo-grid-column
                                                            :field="'subGroup'"
                                                            :title="$t('sub_group')"
                                                            :width="100"
                                                            :headerAttributes="{ style: 'background-color: #EDF1F5, color: green !important' }"/>
                                                        <kendo-grid-column
                                                            :field="'uom'"
                                                            :title="$t('uom')"
                                                            :width="100"
                                                            :headerAttributes="{ style: 'background-color: #EDF1F5, color: green !important' }"/>
                                                        <kendo-grid-column
                                                            :field="'sku'"
                                                            :title="$t('sku')"
                                                            :width="180"
                                                            :attributes="{style: 'text-align: right; ', class:'tb_name_td'}"
                                                            :headerAttributes="{ style: 'background-color: #EDF1F5' }"/>
                                                        <kendo-grid-column
                                                            :field="'barcode'"
                                                            :title="$t('barcode')"
                                                            :width="200"
                                                            :attributes="{style: 'text-align: right; ', class:'tb_name_td'}"
                                                            :headerAttributes="{ style: 'background-color: #EDF1F5' }"/>
                                                        <kendo-grid-column
                                                            :field="'price'"
                                                            :title="$t('price')"
                                                            :width="200"
                                                            :attributes="{style: 'text-align: right; ', class:'tb_name_td'}"
                                                            :headerAttributes="{ style: 'background-color: #EDF1F5' }"/>
                                                    </kendo-grid>
                                                </template>
                                            </gridFullScreen>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-card>
            </v-dialog> -->
            <!--Park Sale-->
            <v-dialog
                v-model="dialogPSale"
                fullscreen
                hide-overlay
                persistent
                transition="dialog-bottom-transition"
            >
                <v-card>
                    <v-toolbar dark color="secondary" >
                        <v-btn icon dark @click="dialogPSale = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ $t("park_sale") }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn dark text @click="dialogPSale = false">
                                {{ $t('skip') }}
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-col md="12" col="12" class="function_content pa-3" style="height: 93vh;overflow: hidden; overflow-y: scroll;">
                        <v-card class="mx-auto my-auto" max-width="1200" style="background: none;box-shadow: none;">
                            <v-row>
                                <v-col sm="12" cols="12" class="py-0">
                                    <v-row >
                                        <kendo-datasource
                                            ref="gridParkSale"
                                            :type="'JSON'"
                                            :data="parkSales"
                                            :server-paging="false"/>
                                        <kendo-grid
                                            id="gridParkSale" class="grid-function"
                                            :data-source-ref="'gridParkSale'"
                                            :editable="false"
                                            :groupable="false"
                                            :column-menu="true"
                                            :noRecords="true"
                                            :sortable="true"
                                            :excel-filterable="true"
                                            :excel-all-pages="true"
                                            :scrollable-virtual="true">
                                            <kendo-grid-column
                                                :title="$t('no.')"
                                                :width="40"
                                                :template="psRowNumber"
                                                :column-menu="false"
                                                :headerAttributes="{style: 'background-color: #EDF1F5;',class: 'text-products px-1' }"
                                                :attributes="{ style: 'text-align: products'}"
                                            />
                                            <kendo-grid-column
                                                :field="'issuedDate'"
                                                :title="$t('issued_date')"
                                                :width="150"
                                                :template="'#=kendo.toString(new Date(issuedDate), `dd-MM-yyyy hh:mm tt`)#'"
                                                :attributes="{style: 'text-align: right; ', class:'tb_name_td'}"
                                                :headerAttributes="{ style: 'background-color: #EDF1F5' }"/>
                                            <kendo-grid-column
                                                :field="'totalItem'"
                                                :title="$t('total_item')"
                                                :width="150"
                                                :headerAttributes="{ style: 'background-color: #EDF1F5, color: green !important' }"/>
                                            <kendo-grid-column
                                                :field="'total'"
                                                :title="$t('amount')"
                                                :width="150"
                                                :headerAttributes="{ style: 'background-color: #EDF1F5, color: green !important' }"/>
                                            <kendo-grid-column
                                                :command="{
                                                    text: useText,
                                                    click: useParkSale,
                                                    className: 'btn-plus isEditable',
                                                }"
                                                :title="$t('action')"
                                                :width="150"
                                                :headerAttributes="{
                                                style: 'text-align: left; background-color: #EDF1F5',
                                                }"
                                            />
                                        </kendo-grid>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-card>
            </v-dialog>
            <!-- Start Session-->
            <v-dialog style="z-index: 9999" v-model="showStartSession" max-width="350px">
                <v-card>
                  <div class="modal_header">
                    <v-card-title>{{ $t("session") }}</v-card-title>
                    <v-icon @click="showStartSession = false">close</v-icon>
                  </div>

                  <v-card-text class="modal_text_content">
                    <v-row>
                      <v-col sm="12" cols="12" class="pb-0">

                        <label>{{ $t("number") }}</label>
                        <v-text-field
                          class="mt-1"
                          disabled
                          v-model="addsession.number"
                          outlined
                          clearable
                        />
                      </v-col>
                      <v-col sm="12" cols="12" class="py-0">
                        <label class="label">{{ $t("start_date") }}</label>
                        <app-datepicker
                          :initialDate="addsession.startDate"
                          @emitDate="addsession.startDate = $event"/>
                      </v-col>
                      <v-col sm="12" cols="12" class="pb-0">
                        <div class="d-flex">
                          <label style="padding: 10px;background: #eee;line-height: 21px;height: 40px;margin-top: 4px;border-radius: 3px;">USD</label>
                          <v-text-field
                            class="mt-1"
                            outlined
                            v-model="addsession.amtUSD"
                            clearable
                          />
                          <label style="padding: 10px;background: #eee;line-height: 21px;height: 40px;margin-top: 4px;border-radius: 3px;margin-left: 5px;">KHR</label>
                          <v-text-field
                            class="mt-1"
                            outlined
                            v-model="addsession.amtKHR"
                            clearable
                          />
                      </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions class="modal_footer">
                    <v-row>
                      <v-col sm="6" cols="6" class="py-0 text-left">
                        <v-btn
                          color="black"
                          outlined
                          class=" text-capitalize  black--text float-left"
                          @click="showStartSession = false"
                          >{{ $t("cancel") }}
                        </v-btn>
                      </v-col>
                      <v-col sm="6" cols="6" class="py-0 text-right">
                        <v-btn
                          color="primary"
                          class="px-3  white--text text-capitalize"
                            @click="openSession"
                        >
                          {{ $t("save") }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- Pin -->
            <div sm="3" cols="12" class="ml-8">
                <template>
                    <v-dialog
                        v-model="pinActivate"
                        open-delay="0"
                        persistent
                        fullscreen
                        hide-overlay
                        transition="dialog-bottom-transition"
                        >
                        <template v-slot:activator="{ on, attrs }">
                        </template>
                        <v-card>
                            <v-toolbar
                                dark
                                color="secondary"
                                >
                                <v-btn
                                    v-if="!isLockPin"
                                    icon
                                    dark
                                    @click="skipFlowPin"
                                >
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-btn
                                    icon
                                    dark
                                    v-if="showCancelODF"
                                    @click="closeODF"
                                >
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-btn
                                    icon
                                    dark
                                    v-if="showBackODF"
                                    @click="backODF"
                                >
                                    <v-icon>mdi-arrow-left</v-icon>
                                </v-btn>
                                <v-toolbar-title>{{ $t("user_pin") }}</v-toolbar-title>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                            <v-col md="12" col="12" class="function_content pa-3 d-flex justify-center" style="height: 90vh;overflow: hidden;overflow-y: scroll;">
                                <v-card class="mx-auto my-auto" max-width="350px" style="background: none;box-shadow: none;">
                                    <v-row max-width="350px">
                                        <v-form ref="form" v-model="valid" lazy-validation>
                                            <template>
                                                <!-- <v-container> -->
                                                <div id="pin" class="m-auto my-4">
                                                    <!-- <v-text-field
                                                        class="mt-4"
                                                        v-model="pin"
                                                        placeholder=""
                                                        :rules="[v => !!v || $t('pin_is_required') ]"
                                                    /> -->
                                                    <v-otp-input
                                                        length="4"
                                                        v-model="pin"
                                                        plain
                                                        :rules="[v => !!v || 'PIN is required']"
                                                        type="password"
                                                    ></v-otp-input>
                                                    <h2 v-if="incorrect">{{$t("wrong_pin")}}</h2>
                                                    <div class="numbers">
                                                        <div @click="pinClick(1)" class="number">1</div>
                                                        <div @click="pinClick(2)" class="number">2</div>
                                                        <div @click="pinClick(3)" class="number">3</div>
                                                        <div @click="pinClick(4)" class="number">4</div>
                                                        <div @click="pinClick(5)" class="number">5</div>
                                                        <div @click="pinClick(6)" class="number">6</div>
                                                        <div @click="pinClick(7)" class="number">7</div>
                                                        <div @click="pinClick(8)" class="number">8</div>
                                                        <div @click="pinClick(9)" class="number">9</div>
                                                        <div @click="pinClick('clear')" class="number" style="">{{$t("ac")}}</div>
                                                        <div @click="pinClick(0)" class="number">0</div>
                                                        <div @click="searchPin" class="number" style="background-color: rgb(154 27 46);color: #ffff;">{{$t("go")}}</div>
                                                    </div>
                                                </div>
                                                <!-- </v-container> -->
                                            </template>
                                        </v-form>
                                    </v-row>
                                </v-card>
                            </v-col>
                            
                        </v-card>
                    </v-dialog>
                </template>
            </div>
            <!--body-->
            <v-col sm="12" cols="12" class="py-0">
                <v-card color="#f8f8f9" class="pa-0 no_border" elevation="0">
                    <LoadingMe
                        :isLoading="loadPullData"
                        :fullPage="false"
                        :myLoading="true" />
                    <LoadingMe
                        :isLoading="showLoading"
                        :fullPage="false"
                        :myLoading="true" />
                    <LoadingMe
                        :isLoading="showBindItmLocal"
                        :fullPage="false"
                        :myLoading="true" />
                    <v-row>
                        <!-- Sidebar -->
                        <div
                            class="sidebar hidden-sm-and-down"
                            v-on:mouseover="onMouseOver"
                            v-on:mouseleave="onMouseLeave"
                            >
                            <v-navigation-drawer
                                :class="{ miniWith: minWidth }"
                                v-model="drawer"
                                :mini-variant="miniVariant"
                                :expand-on-hover="handOver"
                                :mini-variant-width="40"
                                :app="isApp"
                                :fixed="isAbsolute"
                                color="#F8F8F9"
                                :width="260"
                            >
                                <div class="d-flex flex-column" style="height: 98vh;background-color: rgb(248 248 249);">
                                    <div sm="12" cols="12" class=" flex-1">
                                        <v-card flat color="" style="background-color: rgb(248 248 249);">
                                            <span class="hidden-sm-and-down">
                                                <a class="main_logo  d-flex pl-6 py-3" style="background-color: rgb(248 248 249);">
                                                <div class="mb-0">
                                                    <img
                                                        src="@/assets/images/bench_by_banhji.png"
                                                        width="85%"
                                                        height="auto"
                                                        alt="BanhJi Logo"
                                                    />
                                                </div>
                                                </a>
                                            </span>
                                            <!-- <span
                                                v-bind:class="{
                                                    angle_action_small: miniVariant,
                                                    angle_action: !miniVariant,
                                                }">
                                                <v-icon
                                                    size="16"
                                                    class="arr_icon"
                                                    @click="hideSidebar"
                                                    v-if="!miniVariant"
                                                    >mdi-chevron-left-circle</v-icon
                                                >
                                                <v-icon
                                                    size="16"
                                                    class="arr_icon"
                                                    @click="hideSidebar"
                                                    v-if="miniVariant"
                                                    >mdi-chevron-right-circle</v-icon
                                                >
                                            </span> -->
                                            
                                            <div v-if="isAccountLogo" class="" style="background-color: rgb(248 248 249);">
                                                
                                                <div v-if="g.allowSelectCustomer" class="v-list-item v-list-item-left  d-block mr-0 pr-0">
                                                    
                                                    <v-col cols="12" class="py-0">
                                                        <v-row>
                                                            <v-text-field
                                                            class="pl-2"
                                                            outlined
                                                            :placeholder="$t('search')"
                                                            clearable
                                                            style="width: 50px"/>
                                                            <v-btn class=" rounded-0 ml-1" color="primary" style="min-width: 25px">
                                                                <i  class=" b-search" />
                                                            </v-btn>
                                                        </v-row>
                                                    </v-col>
                                                
                                                </div>
                                                
                                                <div class="v-list-item v-list-item-left  d-block mr-0">
                                                    <p class="pl-2 dark_grey" style="float: left;margin-top: 10px;">{{$t('shift_number')}}</p>
                                                    <h2 style="background: #ccc;padding: 5px 13px!important; border-radius: 5px; font-size: 20px; text-align: center; float: right;" class="pl-2">
                                                        {{ shiftNum }}
                                                    </h2>
                                                </div>
                                                <!-- <div class="v-list-item v-list-item-left  d-block mr-0">
                                                    <span class="pl-2 dark_grey">{{$t('points')}}</span><br>
                                                    <h2 class="pl-2 border-b">{{customerPoint}}</h2>
                                                </div> -->
                                                <!-- <div class="v-list-item v-list-item-left  d-block mr-0">
                                                    <span class="pl-2 dark_grey">{{$t('loyalty_program')}}</span><br>
                                                    <h2 class="pl-2 border-b">10%</h2>
                                                </div>
                                                <div class="v-list-item v-list-item-left  d-block mr-0">
                                                    <span class="pl-2 dark_grey">{{$t('loyalty_num')}}</span><br>
                                                    <h2 class="pl-2 border-b">123456789</h2>
                                                </div> -->
                                                <!-- <div v-if="g.cashierName" class="v-list-item v-list-item-left  d-block mr-0">
                                                    <span class="pl-2 dark_grey">{{$t('cashier_name')}}</span><br>
                                                    <h2 class="pl-2 border-b">{{activePin.name}}</h2>
                                                </div> -->
                                                <!-- <v-divider /> -->

                                                <!-- <div class="v-list-item v-list-item-left  d-block mr-0 pt-2" style="">
                                                    <span class="pl-2 dark_grey">{{$t('partner')}}</span><br>
                                                    <h2 class="pl-2 border-b  primary--text">{{activePartner.name}}</h2>
                                                </div> -->
                                                <!-- <div v-if="g.saleUnitItem" class="v-list-item v-list-item-left  d-block mr-0 pt-2" style="">
                                                    <span class="pl-2 dark_grey">{{$t('sale_unit')}}</span><br>
                                                    <h2 class="pl-2 border-b  primary--text">{{t.saleUnit.name}}</h2>
                                                </div>
                                                <div v-if="g.orderNumber" class="v-list-item  v-list-item-left d-block mr-0" style="">
                                                    <span class="pl-2 dark_grey">{{$t('order_number')}}</span><br>
                                                    <h2 class="pl-2 border-b  primary--text">{{orderNumber}}</h2>
                                                </div>
                                                <div v-if="g.rordertype" class="v-list-item  v-list-item-left d-block mr-0" style="">
                                                    <span class="pl-2 dark_grey">{{$t('order_type')}}</span><br>
                                                    <h2 class="pl-2 border-b  primary--text">{{activeOrderType.name}}</h2>
                                                </div>
                                                <div v-if="g.timeIn" class="v-list-item  v-list-item-left d-block mr-0" style="">
                                                    <span class="pl-2 dark_grey">{{$t('time_in')}}</span><br>
                                                    <h2 class="pl-2 border-b  primary--text" style="font-size: 14px">{{new Date(t.timeIn).toLocaleString('km-KH')}}</h2>
                                                </div> -->
                                                <!-- <div class="v-list-item  v-list-item-left d-block mr-0" style="">
                                                    <span class="pl-2 dark_grey">{{$t('price_level')}}</span><br>
                                                    <v-select
                                                        class="mt-1"
                                                        v-model="g.defaultPriceLevel"
                                                        :items="priceLevels"
                                                        :disabled="!g.allowSelectPriceLevel"
                                                        item-value="id"
                                                        item-text="name"
                                                        @change="priceChange"
                                                        placeholder="Price Level"
                                                        tage="Default Price Level"
                                                        outlined
                                                    />
                                                </div> -->
                                                <!-- <div class="v-list-item d-block ma-3 mb-0 mr-0 v-divider"></div> -->
                                                <!-- <div v-if="g.guestCount" class="v-list-item v-list-item-left  d-block mr-0 pt-2">
                                                    <v-row>
                                                        <v-col md="6" cols="12" class="pr-0">
                                                            <small class="pl-2 dark_grey">{{$t('local')}}</small><br>
                                                            <small class="pl-2">M :</small>
                                                            <small class="pl-2">{{guestCount.localMen}}</small><br>
                                                            <small class="pl-2">F :</small>
                                                            <small class="pl-2">{{guestCount.localWomen}}</small>
                                                        </v-col>
                                                        <v-col md="6" cols="12" class="px-0">
                                                            <small class="pl-2 dark_grey">{{$t('foreigner')}}</small><br>
                                                            <small class="pl-2">M :</small>
                                                            <small class="pl-2">{{guestCount.forMen}}</small><br>
                                                            <small class="pl-2">F :</small>
                                                            <small class="pl-2">{{guestCount.forWomen}}</small>
                                                        </v-col>
                                                    </v-row>
                                                </div> -->
                                                <!-- <div class="v-list-item v-list-item-left  d-block mr-0 pt-2">
                                                    <v-row>
                                                        <v-col md="6" cols="12" class="pr-0">
                                                            <small class="pl-2 dark_grey">{{$t('operator')}}</small><br>
                                                            <small class="pl-2">{{operator}}</small>
                                                        </v-col>
                                                        <v-col md="6" cols="12" class="px-0">
                                                            <small class="pl-2 dark_grey">{{$t('start_of_day')}}</small><br>
                                                            <small class="pl-2">{{sessionStart}}</small>
                                                        </v-col>
                                                    </v-row>
                                                </div> -->
                                                <v-col sm="12" cols="12" class="py-0"> 
                                                    <v-row>
                                                        <v-col cols="12" style="margin: 0;padding: 0;" v-if="!isEditServing">
                                                            <!-- payments -->
                                                            <v-col v-if="!isProcessOrder" md="12" sm="12" cols="12" class="pa-1 pb-0 px-0">
                                                                <v-row class="mx-0">
                                                                    <!-- start order -->
                                                                    <v-col sm="12" cols="12" class="pa-0" >
                                                                        <template>
                                                                            <div justify="center" style="height: 150px;">
                                                                                <v-btn 
                                                                                    color="primary"
                                                                                    dark
                                                                                    @click="startOrder"
                                                                                    height="50%"
                                                                                    class="rounded-0 pay-btn" 
                                                                                    style="height:100% !important;">
                                                                                    <div style="display: inline-grid;">
                                                                                        <v-icon
                                                                                            style="font-size: 40px;"
                                                                                            color="primary"
                                                                                            class="b-order_type">
                                                                                        </v-icon>
                                                                                        <span class="font_20 text-bold mt-2">{{ $t("order") }}</span>  
                                                                                    </div>
                                                                                </v-btn>
                                                                            </div>
                                                                        </template>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-col>
                                                            <v-col v-else md="12" sm="12" cols="12" class="pa-1 px-0">
                                                                <v-row class="mx-0" v-if="g.paymentLayout == 1">
                                                                    <!-- check out -->
                                                                    <v-col sm="12" cols="12" class="pa-0" >
                                                                        <template>
                                                                            <div justify="center" style="height: 125px;">
                                                                                <v-btn 
                                                                                    color="primary"
                                                                                    dark
                                                                                    @click="checkOut"
                                                                                    height="50%"
                                                                                    class="rounded-0 pay-btn" 
                                                                                    style="height:100% !important;">
                                                                                    <div style="display: inline-grid;">
                                                                                        <v-icon
                                                                                            style="font-size: 40px;"
                                                                                            color="primary"
                                                                                            class="b-cash">
                                                                                        </v-icon>
                                                                                        <span class="font_13 text-bold mt-2">{{ $t("check_out") }}</span>  
                                                                                    </div>
                                                                                </v-btn>
                                                                            </div>
                                                                        </template>
                                                                    </v-col>
                                                                    <!-- serving -->
                                                                    <v-col sm="6" cols="6" class="pa-0" >
                                                                        <template>
                                                                            <div justify="center" style="height: 123px;">
                                                                                <v-btn 
                                                                                    color="primary"
                                                                                    dark
                                                                                    @click="saveServing"
                                                                                    height="50%"
                                                                                    class="rounded-0 pay-btn" 
                                                                                    style="height:100% !important;">
                                                                                    <div style="display: inline-grid;">
                                                                                        <v-icon
                                                                                            style="font-size: 40px;"
                                                                                            color="primary"
                                                                                            class="b-loyalty">
                                                                                        </v-icon>
                                                                                        <span class="font_13 text-bold mt-2">{{ $t("serving") }}</span>  
                                                                                    </div>
                                                                                </v-btn>
                                                                            </div>
                                                                        </template>
                                                                    </v-col>
                                                                    <!-- partner -->
                                                                    <v-col sm="6" cols="6" class="pa-0" >
                                                                        <template>
                                                                            <div justify="center" style="height: 100%;">
                                                                                <v-btn 
                                                                                    color="primary"
                                                                                    dark
                                                                                    @click="payByPartner"
                                                                                    height="50%"
                                                                                    class="rounded-0 pay-btn" 
                                                                                    style="height:100% !important;">
                                                                                    <div style="display: inline-grid;">
                                                                                        <v-icon
                                                                                            style="font-size: 40px;"
                                                                                            color="primary"
                                                                                            class="b-payment">
                                                                                        </v-icon>
                                                                                        <span class="font_13 text-bold mt-2">{{ $t("partner") }}</span>  
                                                                                    </div>
                                                                                </v-btn>
                                                                            </div>
                                                                        </template>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row class="mx-0" v-else-if="g.paymentLayout == 2">
                                                                    <!-- serving -->
                                                                    <v-col sm="12" cols="12" class="pa-0" >
                                                                        <template>
                                                                            <div justify="center" style="height: 125px;">
                                                                                <v-btn 
                                                                                    color="primary"
                                                                                    dark
                                                                                    @click="saveServing"
                                                                                    height="50%"
                                                                                    class="rounded-0 pay-btn" 
                                                                                    style="height:100% !important;">
                                                                                    <div style="display: inline-grid;">
                                                                                        <v-icon
                                                                                            style="font-size: 40px;"
                                                                                            color="primary"
                                                                                            class="b-loyalty">
                                                                                        </v-icon>
                                                                                        <span class="font_13 text-bold mt-2">{{ $t("serving") }}</span>  
                                                                                    </div>
                                                                                </v-btn>
                                                                            </div>
                                                                        </template>
                                                                    </v-col>
                                                                    <!-- check out -->
                                                                    <v-col sm="12" cols="12" class="pa-0" >
                                                                        <template>
                                                                            <div justify="center" style="height: 125px;">
                                                                                <v-btn 
                                                                                    color="primary"
                                                                                    dark
                                                                                    @click="checkOut"
                                                                                    height="50%"
                                                                                    class="rounded-0 pay-btn" 
                                                                                    style="height:100% !important;">
                                                                                    <div style="display: inline-grid;">
                                                                                        <v-icon
                                                                                            style="font-size: 40px;"
                                                                                            color="primary"
                                                                                            class="b-cash">
                                                                                        </v-icon>
                                                                                        <span class="font_13 text-bold mt-2">{{ $t("check_out") }}</span>  
                                                                                    </div>
                                                                                </v-btn>
                                                                            </div>
                                                                        </template>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row class="mx-0" v-else-if="g.paymentLayout == 3">
                                                                    <!-- check out -->
                                                                    <v-col sm="12" cols="12" class="pa-0" >
                                                                        <template>
                                                                            <div justify="center" style="height: 125px;">
                                                                                <v-btn 
                                                                                    color="primary"
                                                                                    dark
                                                                                    @click="checkOut"
                                                                                    height="50%"
                                                                                    class="rounded-0 pay-btn" 
                                                                                    style="height:100% !important;">
                                                                                    <div style="display: inline-grid;">
                                                                                        <v-icon
                                                                                            style="font-size: 40px;"
                                                                                            color="primary"
                                                                                            class="b-cash">
                                                                                        </v-icon>
                                                                                        <span class="font_13 text-bold mt-2">{{ $t("check_out") }}</span>  
                                                                                    </div>
                                                                                </v-btn>
                                                                            </div>
                                                                        </template>
                                                                    </v-col>
                                                                    <!-- partner -->
                                                                    <v-col sm="12" cols="12" class="pa-0" >
                                                                        <template>
                                                                            <div justify="center" style="height: 125px;">
                                                                                <v-btn 
                                                                                    color="primary"
                                                                                    dark
                                                                                    @click="payByPartner"
                                                                                    height="50%"
                                                                                    class="rounded-0 pay-btn" 
                                                                                    style="height:100% !important;">
                                                                                    <div style="display: inline-grid;">
                                                                                        <v-icon
                                                                                            style="font-size: 40px;"
                                                                                            color="primary"
                                                                                            class="b-payment">
                                                                                        </v-icon>
                                                                                        <span class="font_13 text-bold mt-2">{{ $t("partner") }}</span>  
                                                                                    </div>
                                                                                </v-btn>
                                                                            </div>
                                                                        </template>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row class="mx-0" v-else-if="g.paymentLayout == 4">
                                                                    <v-col sm="12" cols="12" class="pa-0" >
                                                                        <template>
                                                                            <div justify="center" style="height: 250px;">
                                                                                <v-btn 
                                                                                    color="primary"
                                                                                    dark
                                                                                    @click="checkOut"
                                                                                    height="50%"
                                                                                    class="rounded-0 pay-btn" 
                                                                                    style="height:100% !important;">
                                                                                    <div style="display: inline-grid;">
                                                                                        <v-icon
                                                                                            style="font-size: 60px;"
                                                                                            color="primary"
                                                                                            class="b-cash">
                                                                                        </v-icon>
                                                                                        <span class="font_20 text-bold mt-2">{{ $t("check_out") }}</span>  
                                                                                    </div>
                                                                                </v-btn>
                                                                            </div>
                                                                        </template>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row class="mx-0" v-else-if="g.paymentLayout == 5">
                                                                    <v-col sm="12" cols="12" class="pa-0" >
                                                                        <template>
                                                                            <div justify="center" style="height: 250px;">
                                                                                <v-btn 
                                                                                    color="primary"
                                                                                    dark
                                                                                    @click="saveServing"
                                                                                    height="50%"
                                                                                    class="rounded-0 pay-btn" 
                                                                                    style="height:100% !important;">
                                                                                    <div style="display: inline-grid;">
                                                                                        <v-icon
                                                                                            style="font-size: 60px;"
                                                                                            color="primary"
                                                                                            class="b-loyalty">
                                                                                        </v-icon>
                                                                                        <span class="font_20 text-bold mt-2">{{ $t("serving") }}</span>  
                                                                                    </div>
                                                                                </v-btn>
                                                                            </div>
                                                                        </template>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-col>
                                                        </v-col>
                                                        <v-col md="6" sm="6" cols="12" style="margin: 0;padding: 0;" v-else>
                                                            <v-col cols="12" class="pa-1 px-0">
                                                                <v-row class="mx-0">
                                                                    <!-- save edit serving -->
                                                                    <v-col sm="12" cols="12" class="pa-0" >
                                                                        <template>
                                                                            <div justify="center" style="height: 250px;">
                                                                                <v-btn 
                                                                                    color="primary"
                                                                                    dark
                                                                                    @click="saveEditServing"
                                                                                    height="50%"
                                                                                    class="rounded-0 pay-btn" 
                                                                                    style="height:100% !important;">
                                                                                    <div style="display: inline-grid;">
                                                                                        <v-icon
                                                                                            style="font-size: 60px;"
                                                                                            color="primary"
                                                                                            class="b-order_type">
                                                                                        </v-icon>
                                                                                        <span class="font_20 text-bold mt-2">{{ $t("save") }}</span>  
                                                                                    </div>
                                                                                </v-btn>
                                                                            </div>
                                                                        </template>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-col>
                                                        </v-col>
                                                        <v-col cols="12" class="pa-0">
                                                            <v-row class="" style="width: 100%;margin: 0;">
                                                                <v-col v-if="activeKeyPad" md="12" sm="12" cols="12" class="pa-1 px-0 text-letf">
                                                                    <v-row class="ml-1 mr-0">
                                                                        <v-col md="6" sm="6" col="6" class="pa-0">
                                                                            <v-btn @click="cancelKeyFunction" color="primary" class="rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;">
                                                                                <span class="text-bold letter_spacing">{{$t('cancel')}}</span>
                                                                            </v-btn>
                                                                        </v-col>
                                                                        <v-col @click="enterKeyPad"  md="6" sm="6" col="6" class="pa-0">
                                                                            <v-btn  class="rounded-0 btn-funtion1" color="secondary"  style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;">
                                                                                <span class="text-bold letter_spacing">{{$t('confirm')}}</span>
                                                                            </v-btn>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-col>
                                                                <v-col v-else md="12" sm="12" cols="12" class="pa-1 px-0 pt-0 text-left">
                                                                    <v-row class="ml-1 mr-0">
                                                                        <v-col @click="setKeyFunction('qty')" md="4" sm="4" col="4" class="pa-0">
                                                                            <v-btn  class="rounded-0 btn-funtion1" style="height: 65px !important;border-radius: 0px !important; border: 2px solid #f8f8f9;border-right-color: azure !important;background-color: #eee9ea;">
                                                                                <span class="text-bold letter_spacing" style="font-size: 12px !important;" >{{$t('qty')}}</span>
                                                                            </v-btn>
                                                                        </v-col>
                                                                        <v-col @click="setKeyFunction('price')" md="4" sm="4" col="4" class="pa-0">
                                                                            <v-btn class="rounded-0 btn-funtion1" style="height: 65px!important; border-radius: 0px!important;
                                                                            border: 2px solid #f8f8f9;border-right-color: azure !important;background-color: #eee9ea;">
                                                                                <span class="text-bold letter_spacing" style="font-size: 12px !important;">{{$t('price')}}</span>
                                                                            </v-btn>
                                                                        </v-col>
                                                                        <v-col @click="editUOM" md="4" sm="4" col="4" class="pa-0">
                                                                            <v-btn class="rounded-0 btn-funtion1" style="height: 65px !important;border-radius: 0px !important;
                                                                            border: 2px solid #f8f8f9;border-right-color: azure !important;background-color: #eee9ea;">
                                                                                <span class="text-bold letter_spacing" style="font-size: 12px !important;">{{$t('uom')}}</span>
                                                                            </v-btn>
                                                                        </v-col>
                                                                        <v-col @click="editEmp" md="4" sm="4" col="4" class="pa-0">
                                                                            <v-btn class="rounded-0 btn-funtion1" style="height: 65px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;background-color: #eee9ea;">
                                                                                <span class="text-bold letter_spacing" style="font-size: 12px !important;">{{$t('employee')}}</span>
                                                                            </v-btn>
                                                                        </v-col>
                                                                        <v-col md="4" sm="4" col="4" class="pa-0">
                                                                            <v-btn @click="editModi" class="rounded-0 btn-funtion1" style="height: 65px !important;border-radius: 0px !important;
                                                                            border: 2px solid #f8f8f9;border-right-color: azure !important;background-color: #eee9ea;">
                                                                                <span class="text-bold letter_spacing" style="font-size: 12px !important;">{{$t('modifier')}}</span>
                                                                            </v-btn>
                                                                        </v-col>
                                                                        <v-col @click="setKeyFunction('dis')" md="4" sm="4" col="4" class="pa-0">
                                                                            <v-btn class="rounded-0 btn-funtion1" style="height: 65px !important;border-radius: 0px !important;
                                                                            border: 2px solid #f8f8f9;border-right-color: azure !important;background-color: #eee9ea;">
                                                                                <span class="text-bold letter_spacing" style="font-size: 12px !important;">{{$t('discount')}}</span>
                                                                            </v-btn>
                                                                        </v-col>
                                                                        <v-col md="4" sm="4" col="4" class="pa-0">
                                                                            <v-btn @click="removeRow()" class="rounded-0 btn-funtion1" style="height: 65px !important;border-radius: 0px !important;
                                                                            border: 2px solid #f8f8f9;border-right-color: azure !important;background-color: #eee9ea;">
                                                                                <span class="text-bold letter_spacing" style="font-size: 12px !important;">{{$t('delete')}}</span>
                                                                            </v-btn>
                                                                        </v-col>
                                                                        <v-col md="4" sm="4" col="4" class="pa-0">
                                                                            <v-btn @click="voidTxn" class="rounded-0 btn-funtion1" style="height: 65px !important;border-radius: 0px !important;
                                                                            border: 2px solid #f8f8f9;border-right-color: azure !important;background-color: #eee9ea;">
                                                                                <span class="text-bold letter_spacing" style="font-size: 12px !important;">{{$t('void')}}</span>
                                                                            </v-btn>
                                                                        </v-col>
                                                                        <v-col @click="resetOrder" md="4" sm="4" col="4" class="pa-0">
                                                                            <v-btn class="rounded-0 btn-funtion1 primary" style="height: 65px !important;border-radius: 0px !important;
                                                                            border: 2px solid #f8f8f9;border-right-color: azure !important;background-color: #eee9ea;">
                                                                                <span class="text-bold letter_spacing" style="font-size: 12px !important;">{{$t('clear')}}</span>
                                                                            </v-btn>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-col>
                                                                <v-col v-if="activeKeyPad" md="12" sm="12" cols="12" class="pa-1 px-0 text-left">
                                                                    <v-row class="ml-1" style="width: 99%; margin: 0;"> 
                                                                        <v-col md="3" sm="3" col="3" class="pa-0">
                                                                            <v-btn  @click="keyPad(1)" class="rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;background-color: #eee9ea;">
                                                                                <span class="text-bold letter_spacing">1</span>
                                                                            </v-btn>
                                                                        </v-col>    
                                                                        <v-col md="3" sm="3" col="3" class="pa-0">
                                                                            <v-btn  @click="keyPad(2)" class="rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;background-color: #eee9ea;">
                                                                                <span class="text-bold letter_spacing">2</span>
                                                                            </v-btn>
                                                                        </v-col>
                                                                        <v-col md="3" sm="3" col="3" class="pa-0">
                                                                            <v-btn  @click="keyPad(3)" class="rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;background-color: #eee9ea;">
                                                                                <span class="text-bold letter_spacing">3</span>
                                                                            </v-btn>
                                                                        </v-col>
                                                                        <v-col md="3" sm="3" col="3" class="pa-0">
                                                                            <v-btn  @click="keyPad(4)" class="rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;background-color: #eee9ea;">
                                                                                <span class="text-bold letter_spacing">4</span>
                                                                            </v-btn>
                                                                        </v-col>
                                                                        <v-col md="3" sm="3" col="3" class="pa-0">
                                                                            <v-btn  @click="keyPad(5)" class="rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;background-color: #eee9ea;">
                                                                                <span class="text-bold letter_spacing">5</span>
                                                                            </v-btn>
                                                                        </v-col>
                                                                        <v-col md="3" sm="3" col="3" class="pa-0">
                                                                            <v-btn  @click="keyPad(6)" class="rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;background-color: #eee9ea;">
                                                                                <span class="text-bold letter_spacing">6</span>
                                                                            </v-btn>
                                                                        </v-col>                                                      
                                                                        <v-col md="3" sm="3" col="3" class="pa-0">
                                                                            <v-btn  @click="keyPad(7)" class="rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;background-color: #eee9ea;">
                                                                                <span class="text-bold letter_spacing">7</span>
                                                                            </v-btn>
                                                                        </v-col>
                                                                        <v-col md="3" sm="3" col="3" class="pa-0">
                                                                            <v-btn  @click="keyPad(8)" class="rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;background-color: #eee9ea;">
                                                                                <span class="text-bold letter_spacing">8</span>
                                                                            </v-btn>
                                                                        </v-col>
                                                                        <v-col md="3" sm="3" col="3" class="pa-0">
                                                                            <v-btn  @click="keyPad(9)" class="rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;background-color: #eee9ea;">
                                                                                <span class="text-bold letter_spacing">9</span>
                                                                            </v-btn>
                                                                        </v-col>
                                                                        <v-col md="3" sm="3" col="3" class="pa-0">
                                                                            <v-btn  @click="keyPad(0)" class="rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;background-color: #eee9ea;">
                                                                                <span class="text-bold letter_spacing">0</span>
                                                                            </v-btn>
                                                                        </v-col>
                                                                        <v-col md="3" sm="3" col="3" class="pa-0">
                                                                            <v-btn @click="keyPad('c')" class="rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;background-color: #eee9ea;">
                                                                                <span class="text-bold letter_spacing">c</span>
                                                                            </v-btn>
                                                                        </v-col>
                                                                        <v-col md="3" sm="3" col="3" class="pa-0">
                                                                            <v-btn @click="keyPad('.')" class="rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;background-color: #eee9ea;">
                                                                                <span class="text-bold letter_spacing">.</span>
                                                                            </v-btn>
                                                                        </v-col>
                                                                    </v-row>
                                                                    
                                                                    
                                                                </v-col>
                                                                <!-- <v-col v-else md="12" sm="12" cols="12" class="pa-1 pl-0 float-right text-right">
                                                                    <v-row class="mx-1"> 
                                                                        <v-col md="3" sm="3" col="3" class="pa-0">
                                                                            <v-btn class="rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;background-color: #eee9ea;">
                                                                                <span class="text-bold letter_spacing">1</span>
                                                                            </v-btn>
                                                                        </v-col>    
                                                                        <v-col md="3" sm="3" col="3" class="pa-0">
                                                                            <v-btn class="rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;background-color: #eee9ea;">
                                                                                <span class="text-bold letter_spacing">2</span>
                                                                            </v-btn>
                                                                        </v-col>
                                                                        <v-col md="3" sm="3" col="3" class="pa-0">
                                                                            <v-btn class="rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;background-color: #eee9ea;">
                                                                                <span class="text-bold letter_spacing">3</span>
                                                                            </v-btn>
                                                                        </v-col>
                                                                        <v-col md="3" sm="3" col="3" class="pa-0">
                                                                            <v-btn class="rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;background-color: #eee9ea;">
                                                                                <span class="text-bold letter_spacing">4</span>
                                                                            </v-btn>
                                                                        </v-col>
                                                                        <v-col md="3" sm="3" col="3" class="pa-0">
                                                                            <v-btn class="rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;background-color: #eee9ea;">
                                                                                <span class="text-bold letter_spacing">5</span>
                                                                            </v-btn>
                                                                        </v-col>
                                                                        <v-col md="3" sm="3" col="3" class="pa-0">
                                                                            <v-btn class="rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;background-color: #eee9ea;">
                                                                                <span class="text-bold letter_spacing">6</span>
                                                                            </v-btn>
                                                                        </v-col>                                                      
                                                                        <v-col md="3" sm="3" col="3" class="pa-0">
                                                                            <v-btn class="rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;background-color: #eee9ea;">
                                                                                <span class="text-bold letter_spacing">7</span>
                                                                            </v-btn>
                                                                        </v-col>
                                                                        <v-col md="3" sm="3" col="3" class="pa-0">
                                                                            <v-btn class="rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;background-color: #eee9ea;">
                                                                                <span class="text-bold letter_spacing">8</span>
                                                                            </v-btn>
                                                                        </v-col>
                                                                        <v-col md="3" sm="3" col="3" class="pa-0">
                                                                            <v-btn class="rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;background-color: #eee9ea;">
                                                                                <span class="text-bold letter_spacing">9</span>
                                                                            </v-btn>
                                                                        </v-col>
                                                                        <v-col md="3" sm="3" col="3" class="pa-0">
                                                                            <v-btn class="rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;background-color: #eee9ea;">
                                                                                <span class="text-bold letter_spacing">10</span>
                                                                            </v-btn>
                                                                        </v-col>
                                                                        <v-col md="3" sm="3" col="3" class="pa-0">
                                                                            <v-btn class="rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;background-color: #eee9ea;">
                                                                                <span class="text-bold letter_spacing">c</span>
                                                                            </v-btn>
                                                                        </v-col>
                                                                        <v-col md="3" sm="3" col="3" class="pa-0">
                                                                            <v-btn class="rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;background-color: #eee9ea;">
                                                                                <span class="text-bold letter_spacing">0</span>
                                                                            </v-btn>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-col> -->
                                                            </v-row>
                                                        </v-col>
                                                    </v-row> 
                                                </v-col>
                                                <div class="v-list-item v-list-item-left d-block mr-0 pt-2 ">
                                                    <v-row>
                                                        <v-col style="padding-left: 0;text-align: center;" @click="switchLocale('kh')" md="6" cols="12" class="pr-0 primary">
                                                            <small style="font-size: 16px; color: #fff; padding-left: 0!important;" class="pl-2 dark_grey">ខ្មែរ</small>
                                                        </v-col>
                                                        <v-col style="text-align: center;border-top: 1px solid #ccc;border-bottom: 1px solid #ccc;" @click="switchLocale('en')" md="6" cols="12" class="px-0">
                                                            <small style="font-size: 16px; padding-left: 0!important;" class="pl-2 dark_grey">English</small>
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                                <div>
                                                    <v-btn @click="logOut()" color=third class="white--text rounded-0 btn-funtion" style="padding: 20px !important;border-radius: 0!important;">
                                                        <span class="text-bold letter_spacing">{{$t('log_out')}}</span>
                                                    </v-btn>
                                                </div>
                                            </div>
                                        </v-card>
                                    </div>
                                    <div v-if="isAccountLogo" sm="12" cols="12" class="">
                                        <v-card flat color="" style="background-color: rgb(248 248 249);">
                                            <div
                                                class="">
                                                <img
                                                class="mt-1 v-list-item v-list-item-left "
                                                src="@/assets/images/made_in_cambodia.png"
                                                width="100%"
                                                height="auto"
                                                alt=""
                                                />
                                                <v-divider />
                                                <div class=" v-list-item py-1">
                                                    <p class="mb-0 font_12 mt-3 pl-2">
                                                        © {{ year }} {{ $t("banhji_name_desc") }}
                                                    </p>
                                                </div>
                                            </div>
                                        </v-card>
                                    </div>
                                </div>
                            </v-navigation-drawer>
                        </div>
                        <!-- function on left -->
                        <v-col md="6" sm="12" cols="12" class="py-0 " style="position: relative;background: #fff;">
                            <div class="" style="height: 95vh;background-color: #ffff;display: flex; flex-direction: column;">
                                <!-- sale transation -->
                                <div sm="12" cols="12" style="flex-grow: 1; overflow-y: scroll;overflow-x: hidden;">
                                    <v-card color="white" class="px-2 no_border" elevation="0">
                                        <v-row>
                                            <v-col sm="12" cols="12" class="pt-3 pb-0">
                                                 <v-app-bar-nav-icon style="margin-top: -10px;background: #eee;margin-right: 10px;" variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
                                                 <h2 class="mb-1 font_20">{{ $t('customer_order') }}</h2>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col sm="12" cols="12" class="py-0" style="overflow-y: scroll;">
                                                <template>
                                                    <v-simple-table class="attachment_table">
                                                        <template v-slot:default>
                                                            <thead>
                                                                <tr>
                                                                    <!-- <th style="width: 50px"/> -->
                                                                    <th class="text-uppercase">{{ $t('description') }}</th>
                                                                    <th class="text-uppercase">{{ $t('uom') }}</th>
                                                                    <th class="text-uppercase">{{ $t('qty') }}</th>
                                                                    <th class="text-uppercase">{{ $t('price') }}</th>
                                                                    <th class="text-uppercase">{{ $t('discount') }}</th>
                                                                    <th class="text-uppercase">{{ $t('amount') }}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr 
                                                                    v-for="d in lineDS" 
                                                                    v-bind:key="d.id"  
                                                                    :style="activeLine.id == d.id ? 'background: #eee': '' "
                                                                    @click="selectLine(d)">
                                                                    <!-- <td>
                                                                        <v-btn style="min-width: 0;" class="btn_edit_setting" @click="removeRow(d)">
                                                                            <v-icon
                                                                                dark
                                                                                right
                                                                                style="margin-right:0;margin-left:0;"
                                                                                color="white"
                                                                            >
                                                                                mdi-delete
                                                                            </v-icon>
                                                                        </v-btn>
                                                                    </td> -->
                                                                    <td>{{ d.description }}</td>
                                                                    <td>{{ d.uom.toUOM.name }}</td>
                                                                    <td>{{ numberFormat(d.qty) }}</td>
                                                                    <td>{{ numberFormat(d.price) }}</td>
                                                                    <td>{{ numberFormat(d.discount) }}</td>
                                                                    <td>{{ numberFormat(d.amount) }}</td>
                                                                </tr>
                                                            </tbody>
                                                        </template>
                                                    </v-simple-table>
                                                </template>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </div>
                                <div sm="12" cols="12" class="pt-0" style="background: #fff;">
                                    <v-row class="px-4">
                                        <v-col sm="12" cols="12" class="py-0" style="border-top: 1px solid lightblue;">
                                            <v-row>
                                                <v-col md="3" sm="3" col="3" class="pb-0">
                                                    <small class="dark_grey">{{$t('sub_total')}}</small>
                                                    <small class="float-right primary--text">{{numberFormat(t.subTotal)}}</small>
                                                </v-col>
                                                <v-col md="3" sm="3" col="3" class="pb-0">
                                                    <small class="dark_grey">{{$t('discount')}}</small>
                                                    <small class="float-right primary--text">{{numberFormat(t.discount)}}</small>
                                                </v-col>
                                                <v-col md="3" sm="3" col="3" class="pb-0">
                                                    <small class="dark_grey">{{$t('modifier_amount')}}</small>
                                                    <small class="float-right primary--text">{{numberFormat(t.modiAmt)}}</small>
                                                </v-col>
                                                <v-col md="3" sm="3" col="3" class="pb-0">
                                                    <small class="dark_grey">{{$t('total_tax')}}</small>
                                                    <small class="float-right primary--text">{{numberFormat(t.tax)}}</small>
                                                </v-col>
                                            </v-row>
                                            <v-row class="mt-0">
                                                
                                                <v-col md="4" sm="4" col="4" class="pa-2 px-0 py-0">
                                                    <div class="ma-1 mr-0 pl-2">
                                                        <h2 class="font_20 mb-0">{{$t('total')}}</h2>
                                                    </div>
                                                </v-col>
                                                <v-col md="8" sm="8" col="8" class="pa-2 px-0 py-0">
                                                    <div class="ma-1 ml-0 pr-2" style="text-align: right;">
                                                        <h2 class="primary--text font_20 mb-0">{{numberFormat(t.total)}}</h2>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        
                                       
                                    </v-row>
                                </div>
                            </div>         
                        </v-col>
                        <!-- right sidebar -->
                        <v-col md="6" cols="12" class="pa-0 " style="height: 100%">
                            <template style="height: 100%">
                                <v-card style="box-shadow: none;height: 100%">
                                    <v-tabs vertical style="flex-direction: row-reverse;height: 100%;position: relative;" class="vertical-tab">
                                        <v-tab id="tabProduct">
                                            <div style="display: grid;">
                                                <i style="font-size:40px" class="b-product" />
                                                <span class="mt-2 font_16">{{$t('products')}}</span>
                                            </div>
                                        </v-tab>
                                        <v-tab v-if="g.saleUnitItem">
                                            <div style="display: grid;">
                                                <i style="font-size:40px" class="b-budget" />
                                                <span class="mt-2 font_16">{{$t('sale_unit')}}</span>
                                            </div>
                                        </v-tab>
                                        <v-tab>
                                            <div style="display: grid;">
                                                <i style="font-size:40px" class="b-compliance" />
                                                <span class="mt-2 font_16">{{$t('list')}}</span>
                                            </div>
                                        </v-tab>
                                        <v-tab>
                                            <div style="display: grid;">
                                                <i style="font-size:36px" class="b-modul" />
                                                <span class="mt-2 font_16">{{$t('functions')}}</span>
                                            </div>
                                        </v-tab>
                                        <v-tab>
                                            <div @click="searchReport({})" style="display: grid;">
                                                <i style="font-size:36px" class="b-report" />
                                                <span class="mt-2 font_16">{{$t('reports')}}</span>
                                            </div>
                                        </v-tab>
                                        <v-tab class="primary">
                                            <div @click="refreshSys" style="display: grid;">
                                                <i style="font-size:36px;color: #000!important;" class="b-refresh" />
                                                <span class="mt-2 font_16" style="color: #fff">{{$t('restart')}}</span>
                                            </div>
                                        </v-tab>
                                        <!-- product -->
                                        <v-tab-item style="height: 100%">
                                            <v-card flat style="height: 100%">
                                                <v-card-text class="py-0" style="background-color: #f8f8f9;height: 100%;">
                                                    <v-row style="height: 100%; position: relative;">
                                                        <!-- items list -->
                                                        <v-col md="12" sm="12" cols="12" class="pt-0" style="overflow-y: auto;">
                                                            <v-row>
                                                                <v-col sm="12" cols="12" class="pa-0">
                                                                    <v-card flat height="93vh" class="card-item px-0" style=""> 
                                                                        <v-card-text class="pa-0">
                                                                            <v-row class="" style=" margin: auto;">
                                                                                <v-col sm="4" cols="4" class="py-0 px-1">
                                                                                    <v-select class="mt-1 rounded-0 "
                                                                                        v-model="searchCodeBy"
                                                                                        item-value="id"
                                                                                        disabled
                                                                                        item-text="name"
                                                                                        :items="searchCodeBys"
                                                                                        outlined
                                                                                    />
                                                                                </v-col>
                                                                                <v-col sm="8" cols="8"  class="py-0 px-1">
                                                                                    <input type="text"
                                                                                        :plasceholder="$t('scan_code')"
                                                                                        v-model.lazy="barcodetext"
                                                                                        inputmode='none'
                                                                                        id="barcodetext" 
                                                                                        style="width:100%; height: auto;padding: 8px; margin-top: 4px;border: 1px solid #ccc; border-radius: 3px;" 
                                                                                    />
                                                                                    <!-- <v-text-field
                                                                                        class="mt-1 rounded-0 "
                                                                                        outlined
                                                                                        id="searchCodeField"
                                                                                        inputmode='none'
                                                                                        v-model="searchCode"
                                                                                        :label="$t('scan_code')"
                                                                                        clearable
                                                                                    ></v-text-field> -->
                                                                                </v-col>
                                                                                <LoadingMe
                                                                                    :isLoading="loadingItmBlock"
                                                                                    :fullPage="false"
                                                                                    :myLoading="true" />
                                                                                <!-- item -->
                                                                                <div v-show="showItem" style="width: 100%;display: contents; text-align: center;">
                                                                                    <v-col cols="12" class="py-0 px-1">
                                                                                        <v-row>
                                                                                            <v-col v-if="g.favorite" cols="3" class="pa-0 py-0">
                                                                                                <v-btn @click="itemFavorite" color=third class="white--text rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;">
                                                                                                    <!-- <i  class=" b-reward_s" style=""/> -->
                                                                                                    <span class="text-bold letter_spacing">{{$t('favorite')}}</span>
                                                                                                </v-btn>
                                                                                            </v-col>
                                                                                            <v-col cols="3" class="pa-0 py-0">
                                                                                                <v-btn  @click="goCategory" color=third class="white--text rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;">
                                                                                                    <!-- <i  class=" b-reward_s" style=""/> -->
                                                                                                    <span class="text-bold letter_spacing">{{$t('categories')}}</span>
                                                                                                </v-btn>
                                                                                            </v-col>
                                                                                            <v-col v-if="g.allowLoyalty" cols="3" class="pa-0 py-0">
                                                                                                <v-btn @click="showCard" color=third class="white--text rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;">
                                                                                                    <!-- <i  class=" b-reward_s" style=""/> -->
                                                                                                    <span class="text-bold letter_spacing">{{$t('card')}}</span>
                                                                                                </v-btn>
                                                                                            </v-col>
                                                                                            <!-- <v-col cols="3" class="pa-0 py-0">
                                                                                                <v-btn  @click="goGroup" color=third class="white--text rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;">
                                                                                                        <i  class=" b-reward_s" style=""/>
                                                                                                    <span class="text-bold letter_spacing">{{$t('group')}}</span>
                                                                                                </v-btn>
                                                                                            </v-col> -->
                                                                                            <v-col v-if="g.allowCatalog" cols="3" class="pa-0 py-0" >
                                                                                                <v-btn  @click="goSubGroup" color=third class="white--text rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;">
                                                                                                    <!-- <i  class=" b-reward_s" style=""/> -->
                                                                                                    <span class="text-bold letter_spacing">{{$t('catalogs')}}</span>
                                                                                                </v-btn>
                                                                                            </v-col>
                                                                                            <v-col cols="3" class="pa-0 pb-2">
                                                                                                <v-btn  @click="backItmBlock" color=third class="white--text rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important; border-right-color: azure !important; background: #eb0238 !important">
                                                                                                    <!-- <i  class=" b-reward_s" style=""/> -->
                                                                                                    <span class="text-bold letter_spacing">{{$t('back')}}</span>
                                                                                                </v-btn>
                                                                                            </v-col>
                                                                                        </v-row>
                                                                                    </v-col>
                                                                                    <v-col style="position: relative;" md="4" sm="4" xs="6" cols="12" class="pt-0 px-1" v-for="item in items" :key="item.id">
                                                                                        <v-card
                                                                                            class="pb-0"
                                                                                            elevation="0"
                                                                                            max-width="200"
                                                                                            :style="item.borderColor"
                                                                                            @click="addItem(item)"
                                                                                        >
                                                                                            <template slot="progress">
                                                                                                <v-progress-linear
                                                                                                    color="deep-purple"
                                                                                                    height="10"
                                                                                                    indeterminate
                                                                                                ></v-progress-linear>
                                                                                            </template>
                                                                                            <v-img
                                                                                                class="mt-2"
                                                                                                height="140"
                                                                                                aspect-ratio="1.7"
                                                                                                cover
                                                                                                style="width: 100%;margin-top: 0!important;border-radius: 0;"
                                                                                                :src="item.img"
                                                                                            ></v-img>
                                                                                            <p class="pa-2 px-1 name-items mb-0" style="height: auto;background: #fff; color: #000;line-height: 1.15;position: absolute;top: 0;left: 0;width: 100%">
                                                                                                {{ item.saleDescription }}
                                                                                                <span style="width: 100%; float: left; background: #eee; padding: 5px 0;" v-if="item.countStock">{{$t('stock_on_hand')}}:{{ item.stock }}</span>
                                                                                            </p>
                                                                                            <v-card-text class="py-0 text-white" :style="'background-color: #898c8f;text-align: center;border-radius: 0;' + item.titleColor">
                                                                                                <h2 class="text-white mb-0" style="font-size:18px;"> {{ numberFormat(item.price) }} </h2>
                                                                                            </v-card-text>
                                                                                        </v-card>
                                                                                    </v-col>
                                                                                </div>
                                                                                <!-- category -->
                                                                                <v-row v-show="showCatePage" style="width: 100%;display: contents; text-align: center;">
                                                                                    <v-col cols="12" class="py-0 px-1">
                                                                                        <v-row>
                                                                                            <v-col v-if="g.favorite" cols="3" class="pa-0 py-0">
                                                                                                <v-btn @click="itemFavorite" color=third class="white--text rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;">
                                                                                                    <!-- <i  class=" b-reward_s" style=""/> -->
                                                                                                    <span class="text-bold letter_spacing">{{$t('favorite')}}</span>
                                                                                                </v-btn>
                                                                                            </v-col>
                                                                                            <v-col cols="3" class="pa-0 py-0">
                                                                                                <v-btn  @click="goCategory" color=third class="white--text rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;">
                                                                                                    <!-- <i  class=" b-reward_s" style=""/> -->
                                                                                                    <span class="text-bold letter_spacing">{{$t('categories')}}</span>
                                                                                                </v-btn>
                                                                                            </v-col>
                                                                                            <v-col v-if="g.allowLoyalty" cols="3" class="pa-0 py-0">
                                                                                                <v-btn @click="showCard" color=third class="white--text rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;">
                                                                                                    <!-- <i  class=" b-reward_s" style=""/> -->
                                                                                                    <span class="text-bold letter_spacing">{{$t('card')}}</span>
                                                                                                </v-btn>
                                                                                            </v-col>
                                                                                            <!-- <v-col cols="3" class="pa-0 py-0">
                                                                                                <v-btn  @click="goGroup" color=third class="white--text rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;">
                                                                                                        <i  class=" b-reward_s" style=""/>
                                                                                                    <span class="text-bold letter_spacing">{{$t('group')}}</span>
                                                                                                </v-btn>
                                                                                            </v-col> -->
                                                                                            <v-col v-if="g.allowCatalog" cols="3" class="pa-0 py-0" >
                                                                                                <v-btn  @click="goSubGroup" color=third class="white--text rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;">
                                                                                                    <!-- <i  class=" b-reward_s" style=""/> -->
                                                                                                    <span class="text-bold letter_spacing">{{$t('catalogs')}}</span>
                                                                                                </v-btn>
                                                                                            </v-col>
                                                                                            <v-col cols="3" class="pa-0 pb-2">
                                                                                                <v-btn  @click="goCategory" color=third class="white--text rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important; border-right-color: azure !important; background: #eb0238 !important">
                                                                                                    <!-- <i  class=" b-reward_s" style=""/> -->
                                                                                                    <span class="text-bold letter_spacing">{{$t('back')}}</span>
                                                                                                </v-btn>
                                                                                            </v-col>
                                                                                        </v-row>
                                                                                    </v-col>
                                                                                    <v-col md="4" sm="4" xs="6" cols="12" class="pa-1" v-for="item in categories" :key="item.id">
                                                                                        <v-card
                                                                                            class="pb-0"
                                                                                            elevation="0"
                                                                                            max-width="200"
                                                                                            :style="item.borderColor"
                                                                                            @click="searchItemByCate(item.id)"
                                                                                        >
                                                                                            <template slot="progress">
                                                                                                <v-progress-linear
                                                                                                    color="deep-purple"
                                                                                                    height="10"
                                                                                                    indeterminate
                                                                                                ></v-progress-linear>
                                                                                            </template>
                                                                                            <v-img
                                                                                                class="mt-2"
                                                                                                height="140"
                                                                                                aspect-ratio="1.7"
                                                                                                contain
                                                                                                :src="item.img"
                                                                                            ></v-img>
                                                                                            <p class="pa-2 name-items mb-0" style="height: 50px;">{{ item.name }}</p>
                                                                                            <v-divider class="mx-4"></v-divider>
                                                                                            <v-card-text class="py-0 text-white" style="background-color: #898c8f;text-align: center;">
                                                                                                <!--h2 class="text-white mb-0" style="font-size:18px;"> {{ item.price }} {{ item.uom[0].priceLevel.currency.symbol}}</h2-->
                                                                                            </v-card-text>
                                                                                        </v-card>
                                                                                    </v-col>
                                                                                </v-row>
                                                                                <!-- group -->
                                                                                <v-row v-show="showGroupPage" style="width: 100%;display: contents; text-align: center;">
                                                                                    <v-col cols="12" class="py-0 px-1">
                                                                                        <v-row>
                                                                                            <v-col v-if="g.favorite" cols="3" class="pa-0 py-0">
                                                                                                <v-btn @click="itemFavorite" color=third class="white--text rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;">
                                                                                                    <!-- <i  class=" b-reward_s" style=""/> -->
                                                                                                    <span class="text-bold letter_spacing">{{$t('favorite')}}</span>
                                                                                                </v-btn>
                                                                                            </v-col>
                                                                                            <v-col cols="3" class="pa-0 py-0">
                                                                                                <v-btn  @click="goCategory" color=third class="white--text rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;">
                                                                                                    <!-- <i  class=" b-reward_s" style=""/> -->
                                                                                                    <span class="text-bold letter_spacing">{{$t('categories')}}</span>
                                                                                                </v-btn>
                                                                                            </v-col>
                                                                                            <v-col v-if="g.allowLoyalty" cols="3" class="pa-0 py-0">
                                                                                                <v-btn @click="showCard" color=third class="white--text rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;">
                                                                                                    <!-- <i  class=" b-reward_s" style=""/> -->
                                                                                                    <span class="text-bold letter_spacing">{{$t('card')}}</span>
                                                                                                </v-btn>
                                                                                            </v-col>
                                                                                            <!-- <v-col cols="3" class="pa-0 py-0">
                                                                                                <v-btn  @click="goGroup" color=third class="white--text rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;">
                                                                                                        <i  class=" b-reward_s" style=""/>
                                                                                                    <span class="text-bold letter_spacing">{{$t('group')}}</span>
                                                                                                </v-btn>
                                                                                            </v-col> -->
                                                                                            <v-col v-if="g.allowCatalog" cols="3" class="pa-0 py-0" >
                                                                                                <v-btn  @click="goSubGroup" color=third class="white--text rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;">
                                                                                                    <!-- <i  class=" b-reward_s" style=""/> -->
                                                                                                    <span class="text-bold letter_spacing">{{$t('catalogs')}}</span>
                                                                                                </v-btn>
                                                                                            </v-col>
                                                                                            <v-col cols="3" class="pa-0 pb-2">
                                                                                                <v-btn  @click="goCategory" color=third class="white--text rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important; border-right-color: azure !important; background: #eb0238 !important">
                                                                                                    <!-- <i  class=" b-reward_s" style=""/> -->
                                                                                                    <span class="text-bold letter_spacing">{{$t('back')}}</span>
                                                                                                </v-btn>
                                                                                            </v-col>
                                                                                        </v-row>
                                                                                    </v-col>
                                                                                    <v-col md="4" sm="4" xs="6" cols="12" class="pa-1" v-for="item in cateGroup" :key="item.id">
                                                                                        <v-card
                                                                                            class="pb-0"
                                                                                            elevation="0"
                                                                                            max-width="200"
                                                                                            :style="item.borderColor"
                                                                                            @click="searchItemByGroup(item.id)"
                                                                                        >
                                                                                            <template slot="progress">
                                                                                                <v-progress-linear
                                                                                                    color="deep-purple"
                                                                                                    height="10"
                                                                                                    indeterminate
                                                                                                ></v-progress-linear>
                                                                                            </template>
                                                                                            <v-img
                                                                                                class="mt-2"
                                                                                                height="140"
                                                                                                aspect-ratio="1.7"
                                                                                                contain
                                                                                                :src="item.img"
                                                                                            ></v-img>
                                                                                            <p class="pa-2 name-items mb-0" style="height: 50px;">{{ item.name }}</p>
                                                                                            <v-divider class="mx-4"></v-divider>
                                                                                            <v-card-text class="py-0 text-white" style="background-color: #898c8f;text-align: center;">
                                                                                                <!--h2 class="text-white mb-0" style="font-size:18px;"> {{ item.price }} {{ item.uom[0].priceLevel.currency.symbol}}</h2-->
                                                                                            </v-card-text>
                                                                                        </v-card>
                                                                                    </v-col>
                                                                                </v-row>
                                                                                <!-- sub group -->
                                                                                <v-row v-show="showSupGroupPage" style="width: 100%;display: contents; text-align: center;">
                                                                                    <v-col cols="12" class="py-0 px-1">
                                                                                        <v-row>
                                                                                            <v-col v-if="g.favorite" cols="3" class="pa-0 py-0">
                                                                                                <v-btn @click="itemFavorite" color=third class="white--text rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;">
                                                                                                    <!-- <i  class=" b-reward_s" style=""/> -->
                                                                                                    <span class="text-bold letter_spacing">{{$t('favorite')}}</span>
                                                                                                </v-btn>
                                                                                            </v-col>
                                                                                            <v-col cols="3" class="pa-0 py-0">
                                                                                                <v-btn  @click="goCategory" color=third class="white--text rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;">
                                                                                                    <!-- <i  class=" b-reward_s" style=""/> -->
                                                                                                    <span class="text-bold letter_spacing">{{$t('categories')}}</span>
                                                                                                </v-btn>
                                                                                            </v-col>
                                                                                            <v-col v-if="g.allowLoyalty" cols="3" class="pa-0 py-0">
                                                                                                <v-btn @click="showCard" color=third class="white--text rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;">
                                                                                                    <!-- <i  class=" b-reward_s" style=""/> -->
                                                                                                    <span class="text-bold letter_spacing">{{$t('card')}}</span>
                                                                                                </v-btn>
                                                                                            </v-col>
                                                                                            <!-- <v-col cols="3" class="pa-0 py-0">
                                                                                                <v-btn  @click="goGroup" color=third class="white--text rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;">
                                                                                                        <i  class=" b-reward_s" style=""/>
                                                                                                    <span class="text-bold letter_spacing">{{$t('group')}}</span>
                                                                                                </v-btn>
                                                                                            </v-col> -->
                                                                                            <v-col v-if="g.allowCatalog" cols="3" class="pa-0 py-0" >
                                                                                                <v-btn  @click="goSubGroup" color=third class="white--text rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important;border: 2px solid #f8f8f9;border-right-color: azure !important;">
                                                                                                    <!-- <i  class=" b-reward_s" style=""/> -->
                                                                                                    <span class="text-bold letter_spacing">{{$t('catalogs')}}</span>
                                                                                                </v-btn>
                                                                                            </v-col>
                                                                                            <v-col cols="3" class="pa-0 pb-2">
                                                                                                <v-btn  @click="goCategory" color=third class="white--text rounded-0 btn-funtion1" style="height: 60px !important;border-radius: 0px !important; border-right-color: azure !important; background: #eb0238 !important">
                                                                                                    <!-- <i  class=" b-reward_s" style=""/> -->
                                                                                                    <span class="text-bold letter_spacing">{{$t('back')}}</span>
                                                                                                </v-btn>
                                                                                            </v-col>
                                                                                        </v-row>
                                                                                    </v-col>
                                                                                    <v-col md="4" sm="4" xs="6" cols="12" class="pa-1" v-for="item in subGroup" :key="item.id">
                                                                                        <v-card
                                                                                            class="pb-0"
                                                                                            elevation="0"
                                                                                            max-width="200"
                                                                                            :style="item.borderColor"
                                                                                            @click="searchItemBySubGroup(item.id)"
                                                                                        >
                                                                                            <template slot="progress">
                                                                                                <v-progress-linear
                                                                                                    color="deep-purple"
                                                                                                    height="10"
                                                                                                    indeterminate
                                                                                                ></v-progress-linear>
                                                                                            </template>
                                                                                            <v-img
                                                                                                class="mt-2"
                                                                                                height="140"
                                                                                                aspect-ratio="1.7"
                                                                                                contain
                                                                                                :src="item.img"
                                                                                            ></v-img>
                                                                                            <p class="pa-2 name-items mb-0" style="height: 50px;">{{ item.name }}</p>
                                                                                            <v-divider class="mx-4"></v-divider>
                                                                                            <v-card-text class="py-0 text-white" style="background-color: #898c8f;text-align: center;">
                                                                                                <!--h2 class="text-white mb-0" style="font-size:18px;"> {{ item.price }} {{ item.uom[0].priceLevel.currency.symbol}}</h2-->
                                                                                            </v-card-text>
                                                                                        </v-card>
                                                                                    </v-col>
                                                                                </v-row>
                                                                                <!-- card -->
                                                                                <v-row v-show="sCardTap" style="width: 100%;display: contents; text-align: center;">
                                                                                    <v-col md="4" @click="showLCardSearch" sm="4" xs="6" cols="12" class="pa-1">
                                                                                        <v-card
                                                                                            class="pb-0"
                                                                                            elevation="0"
                                                                                            max-width="200"
                                                                                            style="border: 3px solid rgb(230, 160, 68)"
                                                                                        >
                                                                                            <template slot="progress">
                                                                                                <v-progress-linear
                                                                                                    color="deep-purple"
                                                                                                    height="10"
                                                                                                    indeterminate
                                                                                                ></v-progress-linear>
                                                                                            </template>
                                                                                            <v-img
                                                                                                class="mt-2"
                                                                                                height="140"
                                                                                                aspect-ratio="1.7"
                                                                                                contain
                                                                                                src="https://s3.ap-southeast-1.amazonaws.com/images.banhji/reward.png"
                                                                                            ></v-img>
                                                                                            <p class="pa-2 name-items mb-0" style="height: 50px;">{{$t('loyalty_card')}}</p>
                                                                                            <v-divider class="mx-4"></v-divider>
                                                                                            <v-card-text class="py-0 text-white" style="background-color: #898c8f;text-align: center;">
                                                                                                <!--h2 class="text-white mb-0" style="font-size:18px;"> {{ item.price }} {{ item.uom[0].priceLevel.currency.symbol}}</h2-->
                                                                                            </v-card-text>
                                                                                        </v-card>
                                                                                    </v-col>
                                                                                    <v-col v-if="g.allowGCard" md="4" sm="4" xs="6" cols="12" class="pa-1">
                                                                                        <v-card
                                                                                            class="pb-0"
                                                                                            elevation="0"
                                                                                            max-width="200"
                                                                                            @click="dialogSearchGCard = true; gcnumber = ''"
                                                                                            style="border: 3px solid green"
                                                                                        >
                                                                                            <template slot="progress">
                                                                                                <v-progress-linear
                                                                                                    color="deep-purple"
                                                                                                    height="10"
                                                                                                    indeterminate
                                                                                                ></v-progress-linear>
                                                                                            </template>
                                                                                            <v-img
                                                                                                class="mt-2"
                                                                                                height="140"
                                                                                                aspect-ratio="1.7"
                                                                                                contain
                                                                                                src="https://s3.ap-southeast-1.amazonaws.com/images.banhji/gift-card.png"
                                                                                            ></v-img>
                                                                                            <p class="pa-2 name-items mb-0" style="height: 50px;">{{$t('gift_card')}}</p>
                                                                                            <v-divider class="mx-4"></v-divider>
                                                                                            <v-card-text class="py-0 text-white" style="background-color: #898c8f;text-align: center;">
                                                                                            </v-card-text>
                                                                                        </v-card>
                                                                                    </v-col>  
                                                                                    <v-col v-if="g.allowSVoucher" md="4" sm="4" xs="6" cols="12" class="pa-1">
                                                                                        <v-card
                                                                                            class="pb-0"
                                                                                            elevation="0"
                                                                                            max-width="200"
                                                                                            style="border: 3px solid pink"
                                                                                        >
                                                                                            <template slot="progress">
                                                                                                <v-progress-linear
                                                                                                    color="deep-purple"
                                                                                                    height="10"
                                                                                                    indeterminate
                                                                                                ></v-progress-linear>
                                                                                            </template>
                                                                                            <v-img
                                                                                                class="mt-2"
                                                                                                height="140"
                                                                                                aspect-ratio="1.7"
                                                                                                contain
                                                                                                src="https://s3.ap-southeast-1.amazonaws.com/images.banhji/coupon.png"
                                                                                            ></v-img>
                                                                                            <p class="pa-2 name-items mb-0" style="height: 50px;">{{$t('voucher')}}</p>
                                                                                            <v-divider class="mx-4"></v-divider>
                                                                                            <v-card-text class="py-0 text-white" style="background-color: #898c8f;text-align: center;">
                                                                                            </v-card-text>
                                                                                        </v-card>
                                                                                    </v-col>  
                                                                                    <v-col md="4" sm="4" xs="6" cols="12" class="pa-1">
                                                                                        <v-card
                                                                                            class="pb-0"
                                                                                            elevation="0"
                                                                                            max-width="200"
                                                                                            style="border: 3px solid lightblue"
                                                                                            @click="showPointTopUp"
                                                                                        >
                                                                                            <template slot="progress">
                                                                                                <v-progress-linear
                                                                                                    color="deep-purple"
                                                                                                    height="10"
                                                                                                    indeterminate
                                                                                                ></v-progress-linear>
                                                                                            </template>
                                                                                            <v-img
                                                                                                class="mt-2"
                                                                                                height="140"
                                                                                                aspect-ratio="1.7"
                                                                                                contain
                                                                                                src="https://s3.ap-southeast-1.amazonaws.com/images.banhji/money.png"
                                                                                            ></v-img>
                                                                                            <p class="pa-2 name-items mb-0" style="height: 50px;">{{$t('point_top_up')}}</p>
                                                                                            <v-divider class="mx-4"></v-divider>
                                                                                            <v-card-text class="py-0 text-white" style="background-color: #898c8f;text-align: center;">
                                                                                                <!--h2 class="text-white mb-0" style="font-size:18px;"> {{ item.price }} {{ item.uom[0].priceLevel.currency.symbol}}</h2-->
                                                                                            </v-card-text>
                                                                                        </v-card>
                                                                                    </v-col>
                                                                                </v-row>
                                                                            </v-row>
                                                                        </v-card-text>  
                                                                    </v-card>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-tab-item>
                                        <!-- sale unit -->
                                        <v-tab-item v-if="g.saleUnitItem">
                                            <v-card flat>
                                                <v-card-text class="py-0" style="background-color: #f8f8f9;">
                                                    <v-row>
                                                        <v-col v-for="s in saleUnitData"  v-bind:key="s.id" class="pa-1 text-center" sm="4" cols="12">
                                                            <v-card
                                                                :color="s.status != 1 ? 'primary' : ''"
                                                                outlined
                                                                dense
                                                                class="pa-6  raduis_10 pop no_border pop"
                                                                min-height="150"
                                                                >
                                                                <div style="display: flex;justify-content: space-between; margin-top: 0px;">
                                                                    <h1
                                                                    v-if="s.status == 1"
                                                                    class="font_14 primary--text"
                                                                    style="text-transform: uppercase;">
                                                                        {{ $t('available') }}
                                                                    </h1>
                                                                    <h1
                                                                    v-else-if="s.status == 0"
                                                                    class="font_14 white--text"
                                                                    style="text-transform: uppercase;">
                                                                        {{ $t('serving') }}
                                                                    </h1>
                                                                    <h1
                                                                    v-else-if="s.status == 3"
                                                                    class="font_14 white--text"
                                                                    style="text-transform: uppercase;">
                                                                        {{ $t('reserve') }}
                                                                    </h1>
                                                                    <h1
                                                                    v-else
                                                                    class="font_14 white--text"
                                                                    style="text-transform: uppercase;">
                                                                        {{ $t('maintenance') }}
                                                                    </h1>
                                                                </div>
                                                                <div style="display: flex;justify-content: space-between; margin-top: 10px;">
                                                                    <h1
                                                                    :class="s.status == 1 ? 'font_18 primary--text' : 'font_18 white--text'"
                                                                    style="text-transform: uppercase;">
                                                                    {{ s.name }} 
                                                                    </h1>
                                                                    <h3
                                                                    :class="s.status == 1 ? 'font_12 primary--text' : 'font_12 white--text'"
                                                                    style="text-transform: uppercase;">
                                                                        ({{ s.code }})
                                                                    </h3>
                                                                </div>
                                                                <v-btn
                                                                    color="secendary"
                                                                    v-if="s.status == 2"
                                                                    style="width: 100%; font-size: 12px; margin-top: 10px;"
                                                                    prepend-icon="mdi-cloud-upload"
                                                                    @click="availableSU(s)"
                                                                >
                                                                    {{ $t('available') }}
                                                                </v-btn>
                                                            </v-card>
                                                        </v-col>
                                                    </v-row>
                                            </v-card-text>
                                            </v-card>
                                        </v-tab-item>
                                        <!-- order list -->
                                        <v-tab-item>
                                            <v-card flat>
                                                <v-card-text class="py-0" style="background-color: #f8f8f9;">
                                                    <v-row>
                                                        <v-col class="pa-1 text-center" sm="6" cols="12">
                                                            <v-card
                                                                color="primary"
                                                                outlined
                                                                dense
                                                                class="pa-6  raduis_10 pop no_border pop"
                                                                @click="showServingTxn"
                                                                min-height="181"
                                                                >
                                                                <i style="font-size: 75px" class="white_icon b-pos" />
                                                                <div style="display: flex;justify-content: space-between; margin-top: 40px;">
                                                                    <h1
                                                                    class="font_18 white--text"
                                                                    :class="{ line_25: this.$i18n.locale == 'kh' }"
                                                                    style="text-transform: uppercase;">
                                                                    {{ $t("serving") }}
                                                                    </h1>
                                                                    <h1
                                                                    class="font_24 white--text"
                                                                    :class="{ line_25: this.$i18n.locale == 'kh' }"
                                                                    style="text-transform: uppercase;">{{servings.length}}</h1>
                                                                </div>
                                                            </v-card>
                                                        </v-col>
                                                        <v-col v-if="g.allowOthOrder" class="pa-1 text-center" sm="6" cols="12">
                                                            <v-card
                                                                color=""
                                                                outlined
                                                                dense
                                                                class="pa-6  raduis_10 pop no_border pop"
                                                                min-height="181"
                                                                @click="showOrderTxn"
                                                                >
                                                                <i style="font-size: 75px" class=" b-pos" />
                                                                <div style="display: flex;justify-content: space-between; margin-top: 40px;">
                                                                    <h1
                                                                    class="font_18"
                                                                    :class="{ line_25: this.$i18n.locale == 'kh' }"
                                                                    style="text-transform: uppercase;">
                                                                    {{ $t("other_order") }}
                                                                    </h1>
                                                                    <h1
                                                                    class="font_24 primary--text"
                                                                    :class="{ line_25: this.$i18n.locale == 'kh' }"
                                                                    style="text-transform: uppercase;">{{txnorders.length}}</h1>
                                                                </div>
                                                                
                                                            </v-card>
                                                        </v-col>
                                                        <!-- <v-col class="pa-1 text-center" sm="6" cols="12">
                                                            <v-card
                                                                color=""
                                                                outlined
                                                                dense
                                                                class="pa-6  raduis_10 pop no_border pop"
                                                                min-height="181"
                                                                @click="showInvoice"
                                                                >
                                                                <i style="font-size: 75px" class=" b-pos" />
                                                                <div style="display: flex;justify-content: space-between; margin-top: 40px;">
                                                                    <h1
                                                                    class="font_18"
                                                                    :class="{ line_25: this.$i18n.locale == 'kh' }"
                                                                    style="text-transform: uppercase;">
                                                                    {{ $t("invoices") }}
                                                                    </h1>
                                                                    <h1
                                                                    class="font_24 primary--text"
                                                                    :class="{ line_25: this.$i18n.locale == 'kh' }"
                                                                    style="text-transform: uppercase;">{{invoices.length}}</h1>
                                                                </div>
                                                                
                                                            </v-card>
                                                        </v-col> -->
                                                        <v-col v-show="allowFPreorder" class="pa-1 text-center" sm="6" cols="12">
                                                            <v-card
                                                                color=""
                                                                outlined
                                                                dense
                                                                class="pa-6  raduis_10 pop no_border pop"
                                                                min-height="181"
                                                                @click="showPreOrderTxn"
                                                                >
                                                                <i style="font-size: 75px" class=" b-pos" />
                                                                <div style="display: flex;justify-content: space-between; margin-top: 40px;">
                                                                    <h1
                                                                    class="font_18"
                                                                    :class="{ line_25: this.$i18n.locale == 'kh' }"
                                                                    style="text-transform: uppercase;">
                                                                    {{ $t("pre_order") }}
                                                                    </h1>
                                                                    <h1
                                                                    class="font_24 primary--text"
                                                                    :class="{ line_25: this.$i18n.locale == 'kh' }"
                                                                    style="text-transform: uppercase;">{{preorders.length}}</h1>
                                                                </div>
                                                            </v-card>
                                                        </v-col>
                                                        <v-col v-show="allowFPSale" class="pa-1 text-center" sm="6" cols="12">
                                                            <v-card
                                                                color=""
                                                                outlined
                                                                dense
                                                                class="pa-6  raduis_10 pop no_border pop"
                                                                min-height="181"
                                                                @click="showPSaleTxn"
                                                                >
                                                                <i style="font-size: 75px" class=" b-pos" />
                                                                <div style="display: flex;justify-content: space-between; margin-top: 40px;">
                                                                    <h1
                                                                    class="font_18"
                                                                    :class="{ line_25: this.$i18n.locale == 'kh' }"
                                                                    style="text-transform: uppercase;">
                                                                    {{ $t("park_sale") }}
                                                                    </h1>
                                                                    <h1
                                                                    class="font_24 primary--text"
                                                                    :class="{ line_25: this.$i18n.locale == 'kh' }"
                                                                    style="text-transform: uppercase;">{{parkSaleTxns.length}}</h1>
                                                                </div>
                                                            </v-card>
                                                        </v-col>
                                                    </v-row>
                                            </v-card-text>
                                            </v-card>
                                        </v-tab-item>
                                        <!-- funtions on right -->
                                        <v-tab-item>
                                            <v-card flat>
                                                <v-card-text class="py-0" style="background-color: #f8f8f9;">
                                                <v-row>
                                                    <v-col sm="12" cols="12" class="py-0">
                                                        <v-row>
                                                            <!-- reward -->
                                                            <v-col md="6" v-show="allowFLoyalty" sm="3" col="3" class="pa-1">
                                                                <template>
                                                                    <v-dialog
                                                                        v-model="dialogReward"
                                                                        fullscreen
                                                                        hide-overlay
                                                                        persistent
                                                                        transition="dialog-bottom-transition"
                                                                        >
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-btn v-bind="attrs"  v-on="on" color=third class="white--text rounded-0 btn-funtion" style="height: 100px !important;">
                                                                                <i  class=" b-reward_s" style=""/>
                                                                                <span class="text-bold letter_spacing">{{$t('loyalty')}}</span>
                                                                            </v-btn>
                                                                        </template>
                                                                        <v-card>
                                                                            <v-toolbar
                                                                                dark
                                                                                color="secondary"
                                                                                >
                                                                                <v-btn
                                                                                    icon
                                                                                    dark
                                                                                    @click="dialogReward = false"
                                                                                >
                                                                                    <v-icon>mdi-close</v-icon>
                                                                                </v-btn>
                                                                                <v-toolbar-title>{{ $t("redeem_with") }}</v-toolbar-title>
                                                                                <v-spacer></v-spacer>
                                                                                <v-toolbar-items>
                                                                                    <v-btn
                                                                                    dark
                                                                                    text
                                                                                    @click="dialogReward = false"
                                                                                    >
                                                                                    {{ $t('skip') }}
                                                                                    </v-btn>
                                                                                </v-toolbar-items>
                                                                            </v-toolbar>
                                                                            <v-col md="12" col="12" class="function_content pa-3" style="height: 93vh;">
                                                                                <v-card class="mx-auto my-auto" max-width="1000" style="background: none;box-shadow: none;">
                                                                                    <v-row>
                                                                                        <v-col sm="6" cols="6" class="">
                                                                                            <v-card
                                                                                                class="mx-auto"
                                                                                                max-width="465"
                                                                                                outlined
                                                                                                @click="loyaltySelect('pay')"
                                                                                            >
                                                                                                <v-list-item three-line>
                                                                                                    <v-list-item-content class="pk-3">
                                                                                                        <v-row>
                                                                                                            <v-col sm="12" cols="12" class="text-center">
                                                                                                                <h2  class="font_22">{{$t('point')}}</h2>
                                                                                                                <h2  class="font_22">{{$t('earn')}}</h2>
                                                                                                            </v-col>
                                                                                                        </v-row>
                                                                                                    </v-list-item-content>
                                                                                                </v-list-item>
                                                                                            </v-card>
                                                                                        </v-col>
                                                                                        <v-col sm="6" cols="6" class="">
                                                                                            <v-card
                                                                                                class="mx-auto"
                                                                                                max-width="465"
                                                                                                outlined
                                                                                                @click="loyaltySelect('earn')"
                                                                                            >
                                                                                                <v-list-item three-line>
                                                                                                    <v-list-item-content class="pk-3">
                                                                                                        <v-row>
                                                                                                            <v-col sm="12" cols="12" class="text-center">
                                                                                                                <h2 class="primary--text font_22">{{$t('point')}}</h2>
                                                                                                                <h2 class="primary--text font_22">{{$t('purchase')}}</h2>
                                                                                                            </v-col>
                                                                                                        </v-row>
                                                                                                    </v-list-item-content>
                                                                                                </v-list-item>
                                                                                            </v-card>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                </v-card>
                                                                            </v-col>
                                                                        </v-card>
                                                                    </v-dialog>
                                                                </template> 
                                                                <!-- reward form-->
                                                                <template>
                                                                    <v-row>
                                                                        <v-dialog
                                                                            v-model="rewardForm"
                                                                            persistent
                                                                            max-width="500px"
                                                                            >
                                                                            <v-card>
                                                                                <div class="modal_header">
                                                                                    <v-card-title>{{ $t("apply_point") }}</v-card-title>
                                                                                    <v-icon
                                                                                        @click="rewardForm = false"
                                                                                        style="cursor: pointer; font-size: 30px;"
                                                                                        color="grey"
                                                                                        class="float-right mt-n1">close
                                                                                    </v-icon>
                                                                                </div>
                                                                                <v-col md="12" col="12" class="function_content pa-3">
                                                                                    <v-row>
                                                                                        <v-col cols="12" sm="12" md="12" class="">
                                                                                            <template>
                                                                                                <v-simple-table>
                                                                                                    <template>
                                                                                                        <tbody>
                                                                                                            <tr>
                                                                                                                <td class="text-left tdbig">
                                                                                                                    <span class="niradei_medium font_14 grey--text">
                                                                                                                        {{ $t("balance") }}
                                                                                                                    </span>
                                                                                                                </td>
                                                                                                                <td class="text-left tdbig">:</td>
                                                                                                                <td class="text-right">
                                                                                                                    <span class="niradei_heavy font_18 dark_grey">
                                                                                                                        00
                                                                                                                    </span>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td class="text-left tdbig">
                                                                                                                    <span class="niradei_medium font_14 grey--text">
                                                                                                                        {{ $t("available") }}
                                                                                                                    </span>
                                                                                                                </td>
                                                                                                                <td class="text-left tdbig">:</td>
                                                                                                                <td class="text-right">
                                                                                                                    <span class="niradei_heavy font_18 dark_grey">
                                                                                                                00
                                                                                                                    </span>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td class="text-left tdbig">
                                                                                                                    <span class="niradei_medium font_14 grey--text">
                                                                                                                    {{ $t("payable") }}
                                                                                                                    </span>
                                                                                                                </td>
                                                                                                                <td class="text-left tdbig">:</td>
                                                                                                                <td class="text-right">
                                                                                                                    <span class="niradei_heavy font_18 dark_grey">
                                                                                                                00
                                                                                                                    </span>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td class="text-left tdbig">
                                                                                                                    <span class="niradei_medium font_14 grey--text">
                                                                                                                    {{ $t("apply") }}
                                                                                                                    </span>
                                                                                                                </td>
                                                                                                                <td class="text-left tdbig">:</td>
                                                                                                                <td class="text-right">
                                                                                                                    <v-text-field
                                                                                                                        class="mt-2"
                                                                                                                        outlined
                                                                                                                        type="number"
                                                                                                                    ></v-text-field>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            
                                                                                                        </tbody>
                                                                                                    </template>
                                                                                                </v-simple-table>
                                                                                            </template>
                                                                                        </v-col>
                                                                                        <v-col cols="12" sm="12" md="12" class="grayBg px-6 py-0 text-center">
                                                                                            <v-row>
                                                                                                <v-col sm="5" cols="5" class="px-0">
                                                                                                    <v-btn color="primary" class="white--text rounded-0 apply-btn ml-0">
                                                                                                        5%
                                                                                                    </v-btn>
                                                                                                        
                                                                                                    <v-btn color="primary" class="white--text rounded-0 apply-btn">
                                                                                                        10%
                                                                                                    </v-btn>

                                                                                                    <v-btn color="primary" class="white--text rounded-0 apply-btn ml-0">
                                                                                                        15%
                                                                                                    </v-btn>
                                                                                                        
                                                                                                    <v-btn color="primary" class="white--text rounded-0 apply-btn">
                                                                                                        20%
                                                                                                    </v-btn>
                                                                                                    <v-btn color="primary" class="white--text rounded-0 apply-btn ml-0">
                                                                                                        30%
                                                                                                    </v-btn>
                                                                                                        
                                                                                                    <v-btn color="primary" class="white--text rounded-0 apply-btn">
                                                                                                        40%
                                                                                                    </v-btn>
                                                                                                    <v-btn color="primary" class="white--text rounded-0 apply-btn ml-0">
                                                                                                        50%
                                                                                                    </v-btn>
                                                                                                        
                                                                                                    <v-btn color="primary" class="white--text rounded-0 apply-btn">
                                                                                                        100%
                                                                                                    </v-btn>
                                                                                                    
                                                                                                </v-col>
                                                                                                <v-col sm="7" cols="7" class="px-0">
                                                                                                    <v-btn color="third" class="white--text rounded-0 receipt-btn ml-0">
                                                                                                        1
                                                                                                    </v-btn>
                                                                                                        
                                                                                                    <v-btn color="third" class="white--text rounded-0 receipt-btn">
                                                                                                        2
                                                                                                    </v-btn>

                                                                                                    <v-btn color="third" class="white--text rounded-0 receipt-btn mr-0">
                                                                                                        3
                                                                                                    </v-btn>
                                                                                                    <br>
                                                                                                    <v-btn color="third" class="white--text rounded-0 receipt-btn ml-0">
                                                                                                        4
                                                                                                    </v-btn>
                                                                                                        
                                                                                                    <v-btn color="third" class="white--text rounded-0 receipt-btn">
                                                                                                        5
                                                                                                    </v-btn>

                                                                                                    <v-btn color="third" class="white--text rounded-0 receipt-btn mr-0">
                                                                                                        6
                                                                                                    </v-btn>
                                                                                                    <br>
                                                                                                    <v-btn color="third" class="white--text rounded-0 receipt-btn ml-0">
                                                                                                        7
                                                                                                    </v-btn>
                                                                                                        
                                                                                                    <v-btn color="third" class="white--text rounded-0 receipt-btn">
                                                                                                        8
                                                                                                    </v-btn>

                                                                                                    <v-btn color="third" class="white--text rounded-0 receipt-btn mr-0">
                                                                                                        9
                                                                                                    </v-btn>
                                                                                                    <br>
                                                                                                        
                                                                                                    <v-btn color="third" class="white--text rounded-0 receipt-btn ml-0">
                                                                                                        0
                                                                                                    </v-btn>

                                                                                                    <v-btn color="third" class="white--text rounded-0 receipt-btn">
                                                                                                        .
                                                                                                    </v-btn>

                                                                                                    <v-btn color="third" class="white--text rounded-0 receipt-btn mr-0">
                                                                                                        <v-icon>fas fa-backspace</v-icon>
                                                                                                        c
                                                                                                    </v-btn>
                                                                                                    
                                                                                                </v-col>
                                                                                            </v-row>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                </v-col>
                                                                                <v-card-actions>
                                                                                    <div class="function_footer">

                                                                                        <v-btn color="primary" class="float-right white--text text-capitalize"
                                                                                            @click="rewardForm = false">
                                                                                            {{ $t('apply') }}
                                                                                        </v-btn>
                                                                                    </div>
                                                                                    <v-spacer></v-spacer>
                                                                                </v-card-actions>
                                                                            </v-card>
                                                                        </v-dialog>
                                                                    </v-row>
                                                                </template> 
                                                            </v-col>
                                                            <v-col md="6" v-show="allowFPreorder" class="pa-1">
                                                                <v-btn  @click="savePreOrder()"  color=third class="white--text rounded-0 btn-funtion" style="height: 100px !important;"> 
                                                                    <i  class="b-refresh" style=""/>
                                                                    <span class="text-bold letter_spacing">{{$t('pre_order')}}</span>
                                                                </v-btn>
                                                            </v-col>
                                                            <!-- <v-col md="6" class="pa-1">
                                                                <v-btn  @click="toggle()"  color=third class="white--text rounded-0 btn-funtion" style="height: 100px !important;"> 
                                                                    <i  :class="[fullscreen ? 'b-mini' : 'b-full']" style="color:#fff;font-size: 40px;"/>
                                                                    <span class="text-bold letter_spacing">{{ $t("full_screen") }}</span>
                                                                </v-btn>
                                                            </v-col> -->
                                                            <v-col md="6" v-show="allowFSCard" class="pa-1">
                                                                <v-btn  @click="searchLCard()"  color=third class="white--text rounded-0 btn-funtion" style="height: 100px !important;"> 
                                                                    <i  class="b-refresh" style=""/>
                                                                    <span class="text-bold letter_spacing">{{$t('search_card')}}</span>
                                                                </v-btn>
                                                            </v-col>
                                                            <v-col md="6" v-show="allowFPSale" class="pa-1">
                                                                <v-btn  @click="parkSale()"  color=third class="white--text rounded-0 btn-funtion" style="height: 100px !important;"> 
                                                                    <i  class="b-refresh" style=""/>
                                                                    <span class="text-bold letter_spacing">{{$t('park_sale')}}</span>
                                                                </v-btn>
                                                            </v-col>
                                                            <!-- start session-->
                                                            <v-col md="6" v-show="allowFSSesion" sm="3" col="3" class="pa-1">
                                                                <v-btn @click="addNewSession" color=third class="white--text rounded-0 btn-funtion" style="height: 100px !important;"> 
                                                                    <v-icon left class="mr-0 ml-2 font_40">mdi-autorenew</v-icon>
                                                                    <span class="text-bold letter_spacing">{{$t('start_session')}}</span>
                                                                </v-btn>
                                                            </v-col>
                                                            <!-- internal used-->
                                                            <v-col md="6" v-show="allowFIntUsage"  sm="3" col="3" class="pa-1">
                                                                    <v-btn @click="sendIntCode"  color=third class="white--text rounded-0 btn-funtion" style="height: 100px !important;"> 
                                                                    <i  class="b-refresh" />
                                                                    <span class="text-bold letter_spacing">{{$t('internal_usage')}}</span>
                                                                </v-btn>
                                                            </v-col>
                                                            
                                                            <!-- product look up -->
                                                            <v-col md="6" v-show="allowFPLookup"  sm="3" col="3" class="pa-1">
                                                                    <v-btn  color=third class="white--text rounded-0 btn-funtion" style="height: 100px !important;"> 
                                                                    <i  class="b-refresh" style=""/>
                                                                    <span class="text-bold letter_spacing">{{$t('product_lookup')}}</span>
                                                                </v-btn>
                                                            </v-col>
                                                            <v-col md="6" v-show="allowFESesion" sm="3" col="3" class="pa-1">
                                                                    <v-btn @click="showEndSession" color=third class="white--text rounded-0 btn-funtion" style="height: 100px !important;"> 
                                                                    <v-icon left class="mr-0 ml-2 font_40">mdi-autorenew</v-icon>
                                                                    <span class="text-bold letter_spacing">{{$t('end_session')}}</span>
                                                                </v-btn>
                                                            </v-col>
                                                            <v-col md="6" class="pa-1">
                                                
                                                                <v-btn @click="pullData()" color=third class="white--text rounded-0  btn-funtion" style="height: 100px !important;">
                                                                    <i  class="b-note_s" style=""/><span class="text-bold letter_spacing">{{$t('pull_data')}}</span>
                                                                    
                                                                </v-btn>
                                                                <span class="font_10 mb-0 white--text ml-2" style="letter-spacing: initial;position: relative;top: -28px;">As of:{{pullAsOf}}</span>   
                                                            </v-col>
                                                            <v-col md="6" class="pa-1">
                                                
                                                                <v-btn @click="logOut()" color=third class="white--text rounded-0 btn-funtion" style="height: 100px !important;">
                                                                    <i  class="b-refresh" style=""/><span class="text-bold letter_spacing">{{$t('log_out')}}</span>
                                                                </v-btn> 
                                                            </v-col>
                                                            
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                            </v-card>
                                        </v-tab-item>
                                    </v-tabs>
                                </v-card>
                            </template>
                        </v-col>
                        <!-- pop up-->
                        <!-- count guest-->
                        <v-col md="6" v-show="func.countGuest" sm="3" col="3" class="pa-1">
                            <template>
                                <v-dialog
                                    v-model="dialogCountGuest"
                                    open-delay="0"
                                    persistent
                                    fullscreen
                                    hide-overlay
                                    transition="dialog-bottom-transition"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                    </template>
                                    <v-card>
                                        <v-toolbar
                                            dark
                                            color="secondary"
                                            >
                                            <v-btn
                                                icon
                                                dark
                                                v-if="showCancelODF"
                                                @click="closeODF"
                                            >
                                                <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                            <v-btn
                                                icon
                                                dark
                                                v-if="showBackODF"
                                                @click="backODF"
                                            >
                                                <v-icon>mdi-arrow-left</v-icon>
                                            </v-btn>
                                            <v-toolbar-title>{{ $t("count_guest") }}</v-toolbar-title>
                                            <v-spacer></v-spacer>
                                            <v-toolbar-items>
                                                <v-btn
                                                dark
                                                v-if="!g.rguestcount"
                                                text
                                                @click="skipGuestCount"
                                                >
                                                {{ $t('skip') }}
                                                </v-btn>
                                                <v-btn
                                                dark
                                                text
                                                @click="enterGuestCount">
                                                {{ $t('enter') }}
                                                </v-btn>
                                            </v-toolbar-items>
                                        </v-toolbar>
                                        <v-col md="12" col="12" class="function_content pa-3 d-flex justify-center" style="height: 90vh;overflow: hidden;overflow-y: scroll;">
                                            <v-card class="mx-auto my-auto" max-width="1000" style="background: none;box-shadow: none;">
                                                <v-row>
                                                    <v-col sm="6" cols="12" class="">
                                                        <v-card
                                                            class="mx-auto"
                                                            max-width="500"
                                                            outlined
                                                        >
                                                            <v-list-item three-line>
                                                                <v-list-item-content class="pk-3">
                                                                    <v-row>
                                                                        <v-col sm="12" cols="12" class="text-center">
                                                                            <h2 class="font_22">{{$t('local')}}</h2>
                                                                        </v-col>
                                                                        <v-col sm="6" cols="6" class="text-center">
                                                                            <img
                                                                                class="img-1"
                                                                                src="@/assets/images/khmer_man.png"
                                                                                width="100%"
                                                                            />
                                                                            <h2 class="font_30 primary--text">{{guestCount.localMen}}</h2>
                                                                            <div class="function_footer pt-0 d-flex justify-center">
                                                                                <v-btn @click="guestIncLocalMen()" color="primary" class="white--text text-capitalize mx-2">
                                                                                    <v-icon dark >
                                                                                        mdi-plus
                                                                                    </v-icon>
                                                                                </v-btn>
                                                                                <v-btn @click="guestDecLocalMen()" color="secondary" class="float-right white--text text-capitalize mx-2">
                                                                                    <v-icon dark>
                                                                                        mdi-minus
                                                                                    </v-icon>
                                                                                </v-btn>
                                                                            </div>
                                                                        </v-col>
                                                                        <v-col sm="6" cols="6" class="text-center">
                                                                        <img
                                                                                class="img-1"
                                                                                src="@/assets/images/khmer_women.png"
                                                                                width="100%"
                                                                            />
                                                                            <h2 class="font_30 primary--text">{{guestCount.localWomen}}</h2>
                                                                            <div class="function_footer pt-0 d-flex justify-center">
                                                                                <v-btn @click="guestIncLocalWomen()" color="primary" class="white--text text-capitalize mx-2">
                                                                                    <v-icon dark>
                                                                                        mdi-plus
                                                                                    </v-icon>
                                                                                </v-btn>
                                                                                <v-btn @click="guestDecLocalWomen()" color="secondary" class="float-right white--text text-capitalize mx-2">
                                                                                    <v-icon dark>
                                                                                        mdi-minus
                                                                                    </v-icon>
                                                                                </v-btn>
                                                                            </div>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col sm="6" cols="12" class="">
                                                        <v-card
                                                            class="mx-auto"
                                                            max-width="500"
                                                            outlined
                                                        >
                                                            <v-list-item three-line>
                                                                <v-list-item-content class="pk-3">
                                                                    <v-row>
                                                                        <v-col sm="12" cols="12" class="text-center">
                                                                            <h2 class="primary--text font_22">{{$t('foreigner')}}</h2>
                                                                        </v-col>
                                                                        <v-col sm="6" cols="6" class="text-center">
                                                                            <img
                                                                                class="img-1"
                                                                                src="@/assets/images/foreigner_men.png"
                                                                                width="100%"
                                                                            />
                                                                            <h2 class="font_30 primary--text">{{guestCount.forMen}}</h2>
                                                                            <div class="function_footer pt-0 d-flex justify-center">
                                                                                <v-btn @click="guestIncForMen()" color="primary" class="white--text text-capitalize mx-2">
                                                                                    <v-icon dark>
                                                                                        mdi-plus
                                                                                    </v-icon>
                                                                                </v-btn>
                                                                                <v-btn @click="guestDecForMen()" color="secondary" class="float-right white--text text-capitalize mx-2">
                                                                                    <v-icon dark>
                                                                                        mdi-minus
                                                                                    </v-icon>
                                                                                </v-btn>
                                                                            </div>
                                                                        </v-col>
                                                                        <v-col sm="6" cols="6" class="text-center">
                                                                        <img
                                                                                class="img-1"
                                                                                src="@/assets/images/foreigner_women.png"
                                                                                width="100%"
                                                                            />
                                                                            <h2 class="font_30 primary--text">{{guestCount.forWomen}}</h2>
                                                                            <div class="function_footer pt-0 d-flex justify-center">
                                                                                <v-btn @click="guestIncForWomen()" color="primary" class="white--text text-capitalize mx-2">
                                                                                    <v-icon dark>
                                                                                        mdi-plus
                                                                                    </v-icon>
                                                                                </v-btn>
                                                                                <v-btn @click="guestDecForWomen()" color="secondary" class="float-right white--text text-capitalize mx-2">
                                                                                    <v-icon dark>
                                                                                        mdi-minus
                                                                                    </v-icon>
                                                                                </v-btn>
                                                                            </div>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </v-card>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                        
                                    </v-card>
                                </v-dialog>
                            </template> 
                        </v-col>
                        <!-- item uom -->
                        <v-col md="6" class="pa-1">
                            <template>
                                <v-dialog
                                    v-model="uomDialog"
                                    fullscreen
                                    persistent
                                    hide-overlay
                                    transition="dialog-bottom-transition"
                                    >
                                    <v-card>
                                        <v-toolbar
                                            dark
                                            color="secondary"
                                            >
                                            <v-toolbar-title>{{ $t("uom") }}</v-toolbar-title>
                                            <v-spacer></v-spacer>
                                            <v-toolbar-items>
                                            </v-toolbar-items>
                                        </v-toolbar>
                                        <v-col md="12" col="12" class="function_content pa-3" style="height: 97vh;">
                                            <v-card class="mx-auto my-auto" max-width="1000" style="background: none;box-shadow: none;">
                                                <v-row>
                                                    <div v-for="d in itemUOMs" v-bind:key="d.uid" class="col-6 pa-0">
                                                        <v-col sm="12" cols="12" class="">
                                                            <v-card
                                                                class="mx-auto"
                                                                max-width="465"
                                                                outlined
                                                                @click="enterUOM(d)"
                                                            >
                                                                <v-list-item three-line>
                                                                    <v-list-item-content class="pk-3">
                                                                        <v-row>
                                                                            <v-col sm="12" cols="12" class="text-center">
                                                                                <h2 class="font_22">{{d.toUOM.name}}</h2>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </v-card>
                                                        </v-col>
                                                    </div>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                    </v-card>
                                </v-dialog>
                            </template> 
                        </v-col>
                        <!-- employee -->
                        <v-col md="6" class="pa-1">
                            <template>
                                <v-dialog
                                    v-model="employeeDialog"
                                    fullscreen
                                    persistent
                                    hide-overlay
                                    transition="dialog-bottom-transition"
                                    >
                                    <v-card>
                                        <v-toolbar
                                            dark
                                            color="secondary"
                                            >
                                            <v-btn
                                                icon
                                                v-if="!g.remp"
                                                dark
                                                @click="skipEmp()"
                                            >
                                                <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                            <v-toolbar-title>{{ $t("employee") }}</v-toolbar-title>
                                            <v-spacer></v-spacer>
                                            <v-toolbar-items>
                                                <v-btn
                                                dark
                                                v-if="!g.remp"
                                                text
                                                @click="skipEmp()"
                                                >
                                                {{ $t('skip') }}
                                                </v-btn>
                                               
                                                <v-btn 
                                                dark
                                                text
                                                 @click="enterEmp()">
                                                
                                                {{ $t('enter') }}
                                                </v-btn>
                                            </v-toolbar-items>
                                        </v-toolbar>
                                        <v-col md="12" col="12" class="function_content pa-3" style="height: 85vh;">
                                            <v-card class="mx-auto my-auto" max-width="1000" style="background: none;box-shadow: none;">
                                                <v-row>
                                                        <v-col sm="12" cols="12" class="">
                                                            <v-card
                                                                class="mx-auto"
                                                                outlined
                                                            >
                                                                <v-list-item three-line>
                                                                    <v-list-item-content class="pk-3">
                                                                        <div v-for="itm in employees" v-bind:key="itm.uid" style="float: left; overflow: hidden; flex: none; border-radius: 5px;  text-align: center; background: #eee;margin-right: 10px;">
                                                                            <div :class="itm.id + '-de white--text'"
                                                                                @click="deSelectEmp(itm.id)"
                                                                                style="font-size: 12px;background: red;display:none;float: left;padding: 10px;" 
                                                                                max-width="200"
                                                                                variant="outlined">
                                                                                <div class="text-h6 mb-1">
                                                                                    {{itm.name}}
                                                                                </div>
                                                                                <div class="text-caption">{{itm.abbr}} - {{itm.number}}</div>
                                                                            </div>
                                                                            <div :class="itm.id + ' black--text'"
                                                                            @click="selectEmp(itm.id)"
                                                                                style="font-size: 12px;background: #eee;display:block;float: left;padding: 10px;" 
                                                                                max-width="200"
                                                                                variant="outlined">
                                                                                <div class="text-h6 mb-1">
                                                                                    {{itm.name}}
                                                                                </div>
                                                                                <div class="text-caption">{{itm.abbr}} - {{itm.number}}</div>
                                                                            </div>
                                                                            <!-- <v-col sm="3" cols="3" class="text-center" v-for="itm in employees" v-bind:key="itm.uid">
                                                                                <v-btn
                                                                                    :class="itm.id + '-de white--text'"
                                                                                    style="font-size: 12px;background: red;display:none;float: left;" 
                                                                                    color="primary"
                                                                                    @click="deSelectEmp(itm.id)">
                                                                                    {{itm.name}}
                                                                                </v-btn>
                                                                                <v-btn 
                                                                                    :class="itm.id + ' black--text'"
                                                                                    style="font-size: 12px;background: #eee;display:block;float: left;" 
                                                                                    @click="selectEmp(itm.id)">
                                                                                    {{itm.name}}
                                                                                </v-btn>
                                                                            </v-col> -->
                                                                        </div>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </v-card>
                                                            
                                                        </v-col>
                                                </v-row>
                                            </v-card>
                                            
                                        </v-col>
                                        <v-card-actions>
                                            <div class="function_footer">
                                                <!-- <v-btn color="secondary" class="mr-2 white--text text-capitalize"
                                                    @click="skipEmp()">
                                                    {{ $t('skip') }}
                                                </v-btn> -->
                                                <v-btn v-if="isEditEmp" color="primary" class="float-right white--text text-capitalize"
                                                    @click="clearEmp()">
                                                    {{ $t('clear') }}
                                                </v-btn>

                                                <!-- <v-btn color="primary" class="float-right white--text text-capitalize"
                                                    @click="enterEmp()">
                                                    {{ $t('enter') }}
                                                </v-btn> -->
                                            </div>
                                            <v-spacer></v-spacer>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </template> 
                        </v-col>
                        <!-- OrderType -->
                        <v-col md="6" v-show="func.orderType" sm="3" col="3" class="pa-1">
                            <template>
                                <v-dialog
                                    v-model="dialogOrderType"
                                    fullscreen
                                    hide-overlay
                                    persistent
                                    transition="dialog-bottom-transition"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                    </template>
                                    <v-card>
                                        <v-toolbar
                                            dark
                                            color="secondary"
                                            >
                                            <v-btn
                                                icon
                                                dark
                                                v-if="showCancelODF"
                                                @click="closeODF"
                                            >
                                                <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                            <v-btn
                                                icon
                                                dark
                                                v-if="showBackODF"
                                                @click="backODF"
                                            >
                                                <v-icon>mdi-arrow-left</v-icon>
                                            </v-btn>
                                            <v-toolbar-title>{{ $t("order_type") }}</v-toolbar-title>
                                            <v-spacer></v-spacer>
                                            <v-toolbar-items>
                                                <v-btn
                                                dark
                                                text
                                                @click="skipOrderType"
                                                >
                                                {{ $t('skip') }}
                                                </v-btn>
                                            </v-toolbar-items>
                                        </v-toolbar>
                                        <v-col md="12" col="12" class="function_content pa-3 " style="height: 90vh;overflow: hidden;overflow-y: scroll;">
                                            <v-card class="mx-auto my-auto" max-width="1000" style="background: none;box-shadow: none;">
                                                <v-row class="d-flex justify-center">
                                                    <div v-for="d in orderTypes" v-bind:key="d.id" class="col-6 pa-0">
                                                        <v-col sm="12" cols="12" class="">
                                                            <v-card
                                                                class="mx-auto"
                                                                max-width="465"
                                                                outlined
                                                                @click="enterOrderType(d)"
                                                            >
                                                                <v-list-item three-line>
                                                                    <v-list-item-content class="pk-3">
                                                                        <v-row>
                                                                            <v-col sm="12" cols="12" class="text-center">
                                                                                <h2 class="font_22">{{d.name}}</h2>
                                                                                <h2 color="primary" class="font_16">{{d.abbr}}</h2>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </v-card>
                                                        </v-col>

                                                    </div>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                    </v-card>
                                </v-dialog>
                            </template> 
                        </v-col>
                        <!-- modifier -->
                        <v-col md="6" class="pa-1">
                            <template>
                                <v-dialog
                                    v-model="modifierDialog"
                                    fullscreen
                                    hide-overlay
                                    persistent
                                    transition="dialog-bottom-transition"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                    </template>
                                    <v-card>
                                        <v-toolbar
                                            dark
                                            color="secondary"
                                            >
                                            <!-- <v-btn
                                                icon
                                                dark
                                                @click="skipModi"
                                            >
                                                <v-icon>mdi-close</v-icon>
                                            </v-btn> -->
                                            <v-toolbar-title>{{ $t("modifier") }}</v-toolbar-title>
                                            <v-spacer></v-spacer>
                                            <v-toolbar-items>
                                                <v-btn
                                                dark
                                                text
                                                @click="skipModi"
                                                >
                                                {{ $t('skip') }}
                                                </v-btn>
                                               
                                                <v-btn 
                                                dark
                                                text
                                                @click="enterModi()">
                                                
                                                {{ $t('enter') }}
                                                </v-btn>
                                            </v-toolbar-items>
                                        </v-toolbar>
                                        <v-col md="12" col="12" class="function_content pa-3" style="height: 85vh;">
                                            <v-card class="mx-auto my-auto" max-width="1000" style="background: none;box-shadow: none;">
                                                <v-row >
                                                    <v-col sm="6" cols="12" class="" v-for="d in activeModi" v-bind:key="d.id">
                                                        <v-card
                                                            class="mx-auto"
                                                            outlined
                                                        >
                                                            <v-list-item three-line>
                                                                <v-list-item-content class="pk-3">
                                                                    <v-row>
                                                                        <v-col sm="12" cols="12" class="text-center">
                                                                            <h4 class="font_22 text-left mb-2">{{d.name}}</h4>
                                                                            <div class="v-divider" style="color: lavenderblush;"></div>
                                                                            <v-row>
                                                                                <v-col sm="4" cols="4" class="" v-for="itm in d.items" v-bind:key="itm.pk">
                                                                                    <v-btn
                                                                                        :class="itm.pk + '-de white--text'"
                                                                                        style="padding: 20px;background: red;display:none;float: left;font-size: 12px;" 
                                                                                        color="primary"
                                                                                        @click="deSelectModi(itm.pk)">
                                                                                        {{itm.name}}
                                                                                    </v-btn>
                                                                                    <v-btn 
                                                                                        :class="itm.pk + ' black--text'"
                                                                                        style="padding: 20px;background: #eee;display:block;float: left;font-size: 12px;" 
                                                                                        @click="selectModi(itm.pk)">
                                                                                        {{itm.name}}
                                                                                    </v-btn>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </v-card>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                        <v-card-actions>
                                            <div class="function_footer">
                                                <!-- <v-btn color="secondary" class="mr-2 white--text text-capitalize"
                                                    @click="skipModi()">
                                                    {{ $t('skip') }}
                                                </v-btn> -->
                                                <v-btn v-if="isEditModi" color="primary" class="float-right white--text text-capitalize"
                                                    @click="clearModi()">
                                                    {{ $t('clear') }}
                                                </v-btn>

                                                <!-- <v-btn color="primary" class="float-right white--text text-capitalize"
                                                    @click="enterModi()">
                                                    {{ $t('enter') }}
                                                </v-btn> -->
                                            </div>
                                            <v-spacer></v-spacer>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </template> 
                        </v-col>
                        <!-- Session -->
                        <v-dialog
                            v-model="dialogCloseSession"
                            fullscreen
                            hide-overlay
                            persistent
                            transition="dialog-bottom-transition"
                        >
                            <v-card>
                                <v-toolbar
                                    dark
                                    color="secondary"
                                    >
                                    <v-btn
                                        icon
                                        dark
                                        @click="dialogCloseSession = false"
                                    >
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                    <v-toolbar-title>{{ $t("session") }}</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <!-- <v-toolbar-items>
                                        <v-btn
                                        dark
                                        text
                                        @click="dialogCloseSession = false"
                                        >
                                        {{ $t('skip') }}
                                        </v-btn>
                                    </v-toolbar-items> -->
                                </v-toolbar>
                                <v-col md="12" col="12" class="function_content pa-3" style="height: 93vh;">
                                    <v-card class="mx-auto my-auto" max-width="1000" style="background: none;box-shadow: none;">
                                        <v-row>
                                            <v-col sm="12" cols="12" class="py-0">
                                                <v-row >
                                                    <v-col sm="12" cols="12" class="">
                                                        <div class="d-flex flex-column" style="height: 100%;">
                                                            <v-simple-table fixed-header height="350px"  class="flex-1" >
                                                                <template v-slot:default>
                                                                    <thead class="th-invoice">
                                                                        <tr>
                                                                            <th class="text-left">
                                                                                {{$t('number')}}
                                                                            </th>
                                                                            <th class="text-left">
                                                                                {{$t('start_date')}}
                                                                            </th>
                                                                            <th class="text-left">
                                                                                {{$t('status')}}
                                                                            </th>
                                                                            <th class="text-left">
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody class="">
                                                                        <tr>
                                                                            <td>{{ activeSession.number }}</td>
                                                                            <td>{{ kendo.toString(new Date(activeSession.startDate), 'yyyy-MM-dd h:m tt') }}</td>
                                                                            <td>{{ activeSession.status }}</td>
                                                                            <td width="">
                                                                                <!-- <v-btn style="min-width: 0;" class="btn_edit_setting mr-2" @click="printShift(d)">
                                                                                    <v-icon
                                                                                        dark
                                                                                        right
                                                                                        style="margin-right:0;margin-left:0;"
                                                                                        color="white"
                                                                                    >
                                                                                        mdi-delete
                                                                                    </v-icon> {{$t('print')}}
                                                                                </v-btn> -->
                                                                                <v-btn v-if="isAllowShift" style="min-width: 0;height: 50px!important;color: #fff;" class="btn_edit_setting mr-2" @click="showEndShift(activeSession)">
                                                                                    {{$t('end_shift')}}
                                                                                 </v-btn>
                                                                                 <v-btn v-if="!isAllowShift" style="min-width: 0;height: 50px!important;color: #fff;" class="btn_edit_setting" @click="showEndDay()">
                                                                                     {{$t('end_day')}}
                                                                                 </v-btn>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </template>
                                                            </v-simple-table>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            v-model="dialogCashReconcile"
                            fullscreen
                            hide-overlay
                            persistent
                            transition="dialog-bottom-transition"
                        >
                            <v-card>
                                <v-toolbar
                                    dark
                                    color="secondary"
                                    >
                                    <v-btn
                                        id="closeCountCash"
                                        icon
                                        dark
                                        @click="dialogCashReconcile = false"
                                    >
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                    <v-toolbar-title>{{ $t("cash_count") }}</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <!-- <v-toolbar-items>
                                        <v-btn
                                        dark
                                        text
                                        @click="dialogCashReconcile = false"
                                        >
                                        {{ $t('skip') }}
                                        </v-btn>
                                    </v-toolbar-items> -->
                                </v-toolbar>
                                <v-col md="12" col="12" class="function_content pa-3" style="height: 93vh;">
                                    <v-card class="mx-auto my-auto" max-width="1200" style="box-shadow: none;">
                                        <CashCount :countObj="countObj" />
                                    </v-card>
                                </v-col>
                            </v-card>
                        </v-dialog>
                        <!-- loyalty pop up -->
                        <div sm="3" cols="12" class="ml-8" hidden>
                            <!-- end shift info -->
                            <template>
                                <v-row>
                                    <v-dialog
                                        v-model="dialogShiftInfo"
                                        persistent
                                        max-width="400px"
                                        >
                                        <v-card>
                                            <div class="modal_header">
                                                <v-card-title>{{ $t("end_shift") }}</v-card-title>
                                                <v-icon
                                                    @click="dialogShiftInfo = false"
                                                    style="cursor: pointer; font-size: 30px;"
                                                    color="grey"
                                                    class="float-right mt-n1">close
                                                </v-icon>
                                            </div>
                                            <v-col md="12" col="12" class="function_content pa-3">
                                                <v-row>
                                                    <v-col sm="4" cols="4" style="background-color: antiquewhite;">
                                                        <h2 class="font_14">{{$t('number')}}</h2>
                                                        <h2 class="font_18 primary--text">{{shiftObj.shiftNum}}</h2>
                                                    </v-col>
                                                    <v-col sm="4" cols="4" style="background-color: antiquewhite;">
                                                        <h2 class="font_14">{{$t('start')}}</h2>
                                                        <h2 class="font_12 primary--text">{{shiftObj.startDate}}</h2>
                                                    </v-col>
                                                    <v-col sm="4" cols="4" style="background-color: antiquewhite;">
                                                        <h2 class="font_14">{{$t('end')}}</h2>
                                                        <h2 class="font_12 primary--text">{{shiftObj.endDate}}</h2>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-card-actions>
                                                <div class="function_footer text-right">
                                                    <!-- <v-btn style="float: left;" color="secondary" class="white--text text-capitalize mr-2"
                                                        @click="endDayCountCash">
                                                        {{ $t('cash_count') }}
                                                    </v-btn> -->
                                                    <v-btn color="primary" class="float-right white--text text-capitalize"
                                                        @click="endShift">
                                                        {{ $t('enter') }}
                                                    </v-btn>
                                                </div>
                                                <v-spacer></v-spacer>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-row>
                            </template>
                            <!-- end session info -->
                            <template>
                                <v-row>
                                    <v-dialog
                                        v-model="dialogSessionInfo"
                                        persistent
                                        max-width="400px"
                                        >
                                        <v-card>
                                            <div class="modal_header">
                                                <v-card-title>{{ $t("end_session") }}</v-card-title>
                                                <v-icon
                                                    @click="dialogSessionInfo = false"
                                                    style="cursor: pointer; font-size: 30px;"
                                                    color="grey"
                                                    class="float-right mt-n1">close
                                                </v-icon>
                                            </div>
                                            <v-col md="12" col="12" class="function_content pa-3">
                                                <v-row>
                                                    <v-col sm="4" cols="4" style="background-color: antiquewhite;">
                                                        <h2 class="font_14">{{$t('number')}}</h2>
                                                        <h2 class="font_16 primary--text">{{session.number}}</h2>
                                                    </v-col>
                                                    <v-col sm="4" cols="4" style="background-color: antiquewhite;">
                                                        <h2 class="font_14">{{$t('start')}}</h2>
                                                        <h2 class="font_12 primary--text">{{dateFormat(session.startDate)}}</h2>
                                                    </v-col>
                                                    <v-col sm="4" cols="4" style="background-color: antiquewhite;">
                                                        <h2 class="font_14">{{$t('end')}}</h2>
                                                        <h2 class="font_12 primary--text">{{dateFormat(session.endDate)}}</h2>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-card-actions>
                                                <div class="function_footer text-right">
                                                    <!-- <v-btn style="float: left;" color="secondary" class="white--text text-capitalize mr-2"
                                                        @click="endDayCountCash">
                                                        {{ $t('cash_count') }}
                                                    </v-btn> -->
                                                    <v-btn color="primary" class="float-right white--text text-capitalize"
                                                        @click="endDay">
                                                        {{ $t('enter') }}
                                                    </v-btn>
                                                </div>
                                                <v-spacer></v-spacer>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-row>
                            </template>
                        </div>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    const instituteId = localStorage.getItem('instituteId') != null ? localStorage.getItem('instituteId') : ''
    import generalSettingModel from "@/scripts/commerce/model/GeneralSetting"
    import { i18n } from "@/i18n";
    const commerceHandler = require("@/scripts/commerce/handler/commerceHandler")
    const telegramBotHandler = require("@/scripts/commerce/handler/telegramBotHandler")
    const priceLevelHandler = require("@/scripts/priceLevelHandler")
    // const categoryHandler = require("@/scripts/categoryHandler")
    const groupHandler = require("@/scripts/groupHandler")
    const subGroupHandler = require("@/scripts/subGroupHandler")
    const { dataStore } = require("@/observable/store.js")
    // get localhost
    function delete_cookie(name) {
      document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
    if(localStorage.getItem('institute') == null){
        // $.cookie("banhji-counter-token" + process.env.VUE_APP_MODE, null, { path: '/' });
        delete_cookie("banhji-counter-token" + process.env.VUE_APP_MODE)
    }
    let ses = {name: 'NoName',startDate: new Date().getTime() }
    if(localStorage.getItem(instituteId + 'session') != null && localStorage.getItem(instituteId + 'session') != 'null'){
        if(Object.keys(JSON.parse(localStorage.getItem(instituteId + 'session'))).length > 0){
            ses = JSON.parse(localStorage.getItem(instituteId + 'session'))
        }
    }
    let session = ses
    if(session == null){
        session = {name: 'NoName',startDate: new Date().getTime() }
    }
    import kendo from "@progress/kendo-ui"
    const $ = kendo.jQuery
    // item line
    import ItemLineModel from "@/scripts/invoice/model/ItemLine"
    import { uuid } from "vue-uuid"
    import SaleFormContentModel from "@/scripts/model/SaleFormContent"
    const saleFormContentModel = new SaleFormContentModel({})
    const saleFormContentHandler = require("@/scripts/saleFormContentHandler")
    import InvoiceModel from "@/scripts/invoice/model/Invoice"
    import DatePickerComponent from '@/components/custom_templates/DatePickerComponent'
    const invoiceModel = new InvoiceModel({})
    const accountHandler = require("@/scripts/handler/accounting/account")
    import CustomerModel from "@/scripts/model/Customer"
    const discountItemHandler = require("@/scripts/discountItemHandler")
    const saleUCHandler = require("@/scripts/saleUCHandler");
    const saleUnitItemHandler = require("@/scripts/saleUnitItemHandler");
    // const employeeHandler = require("@/scripts/employeeHandler");
    const itemModifierHandler = require("@/scripts/itemModifierHandler")
    const loyaltyHandler = require("@/scripts/loyalty/handler/loyaltyHandler")
    const currencyHandler = require("@/scripts/currency/handler/currencyHandler")
    import CardModel from "@/scripts/loyalty/model/Card"
    // firebase
    // Import the functions you need from the SDKs you need
    import { initializeApp } from "firebase/app";
    // TODO: Add SDKs for Firebase products that you want to use
    // https://firebase.google.com/docs/web/setup#available-libraries
    import { getDatabase, ref, set } from "firebase/database";
    // Your web app's Firebase configuration
    const firebaseConfig = {
        apiKey: "AIzaSyDrI1fDDjpI5TowIT157Jf---by8xg4zOY",
        authDomain: "banhji-front-display.firebaseapp.com",
        databaseURL: "https://banhji-front-display-default-rtdb.asia-southeast1.firebasedatabase.app",
        projectId: "banhji-front-display",
        storageBucket: "banhji-front-display.appspot.com",
        messagingSenderId: "356489884091",
        appId: "1:356489884091:web:a0af427c8a87f95ac2d49e"
    };
    // window.console.log(firebaseConfig, 'firebase config')
    // Initialize Firebase
    initializeApp(firebaseConfig);
    // transaction
    import PosTransactionModel from "@/scripts/commerce/model/PosTransaction"
    // institute
    // const intHandler = require("@/scripts/instituteHandler")
    import {Trans} from "@/plugins/Translation";
    //indexeddb
    const console = window.console
    // 1
    const indexedDB =
      window.indexedDB ||
      window.mozIndexedDB ||
      window.webkitIndexedDB ||
      window.msIndexedDB ||
      window.shimIndexedDB;
    
    if (!indexedDB) {
      window.console.log("IndexedDB could not be found in this browser.");
    }
    // 2isTopUp
    let isIndDB = false
    let loadIndex = false
    let db;
    if(instituteId != '' && instituteId != undefined){
        const request = indexedDB.open(instituteId, 1);
        request.onerror = function (event) {
        window.console.error("An error occurred with IndexedDB");
        window.console.log(event, 'error');
        };
        
        request.onupgradeneeded = function () {
            db = request.result;
            let itms = db.createObjectStore(instituteId + "items", { keyPath: "id" });
            db.createObjectStore(instituteId + "itemPrice", { keyPath: "id" });
            db.createObjectStore(instituteId + "employee", { keyPath: "id" });
            db.createObjectStore(instituteId + "modifier", { keyPath: "id" });
            db.createObjectStore(instituteId + "campaign", { keyPath: "id" });
            db.createObjectStore(instituteId + "serving", { keyPath: "pk" });
            db.createObjectStore(instituteId + "invoices", { keyPath: "pk" });
            db.createObjectStore(instituteId + "preorders", { keyPath: "pk" });
            db.createObjectStore(instituteId + "offlinetxn", { keyPath: "pk" });
            let cus = db.createObjectStore(instituteId + "customer", { keyPath: "pk" });
            db.createObjectStore(instituteId + "txnlog", { keyPath: "id" });
            db.createObjectStore(instituteId + "meta1", { keyPath: "id" });
            db.createObjectStore(instituteId + "meta2", { keyPath: "id" });
            db.createObjectStore(instituteId + "meta3", { keyPath: "id" });
            itms.createIndex("cateId", "cateId", { unique: false });
            itms.createIndex("groupId", "groupId", { unique: false });
            itms.createIndex("barcode", "barcode", { unique: false });
            itms.createIndex("sku", "sku", { unique: false });
            itms.createIndex("name", "name", { unique: false });
            cus.createIndex("name", "name", { unique: false });
            cus.createIndex("abbrnumber", "abbrnumber", { unique: false });
            cus.createIndex("phonenumber", "phonenumber", { unique: false });
        };
        
        request.onsuccess = function () {
        //   console.log("Database opened successfully");
            isIndDB = true
            loadIndex = true
            db = request.result;
        };
    }
    import VOffline from "@/components/VOffline";
    const uomConversionHandler = require("@/scripts/uomConversionHandler");
    let barcodetext = ''
    $(document).keypress(function(e) {
        if(e.which == 13) {
            let barcode = $('#barcodetext').val()
            if(barcode != '' && barcode != null){
                barcodetext = barcode
                // window.console.log(barcodetext, 'barcode')
            }
        }
    });
    import StoreModel from "@/scripts/commerce/model/Store"
    import downloadexcel from "vue-json-excel"
    //main function
    export default {
        name: "BenchSunmi",
        data: () => ({
            mDateSorter: 1,
            allowDubItem: false,
            dufield: {
                'barcode': 'barcode',
                'name': 'name',
                'sku': 'sku',
                'category': 'category',
                'price': 'price',
                'uom': 'uom'
            },
            dublicateItems: [],
            dialogPointCount: false,
            showNoBack: true,
            isInvoice: false,
            showCardInv: false,
            fileSizeRules: [
            (files) =>
                !files ||
                !files.some((file) => file.size > 1024 * 1024) ||
                "Image size should be less than 1 MB!",
            ],
            allowCCardPH: false,
            allowCCardP: false,
            allowCCardR: false,
            mergeBills: [],
            dialogMBill: false,
            isSplitBill: false,
            isMergeBill: false,
            activeCustomer: {},
            customers: [],
            dailogInv: false,
            camAdminConfirmCode: false,
            camAdminPk: '',
            campaignList: [],
            internalPk: '',
            alertAdmins: [],
            alertAdmin: {},
            isInternalTxn: false,
            searchCNum: false,
            searchCPhone: false,
            searchCNFC: false,
            searchCOrd: false,
            isDiscountCard: false,
            stocks: [],
            favCate: false,
            trackTxn: {},
            requestId: '',
            dialogCreditCard: false,
            isStoreCredit: false,
            reportDatas: [],
            exfield: {
                'session': 'session',
                'shift': 'shift',
                'number': 'number',
                'issuedDate': 'issuedDate',
                'cashier': 'cashier',
                'subTotal': 'subTotal',
                'discount': 'discount',
                'tax': 'tax',
                'total': 'total'
            },
            repTotal: 0,
            repDiscount: 0,
            repSubTotal: 0,
            shiftNum: 0,
            shiftSes: {},
            //payment
            allowPStoreCredit: false,
            allowPCard: false,
            allowPBank: false,
            allowPKHQR: false,
            allowPPoint: false,
            allowPFleet: false,
            allowPMvp: false,
            allowInv: false,
            barcodetext: barcodetext,
            //
            dialogReport: false,
            reportGroup:  {field: 'shift'},
            aggregateDefinition: [
                { field: "total", aggregate: "sum" }
            ],
            isPointQRPay: false,
            isSearchCard: false,
            topUpBy: '',
            dialogSelectTopup: false,
            dialogSGCard: false,
            activeSellGCard: {},
            isSaleGCard: false,
            gcnumber: '',
            loggedUser: session.user || {},
            giftCard: {
                number: '',
                serial: ''
            },
            dialogSearchGCard: false,
            dialogPOnly: false,
            dailogPrintCard: false,
            nationalities: [],
            cardGroups: [],
            dailogRCard: false,
            registerCard: new CardModel({}),
            topUpAmount: 10,
            pointTopUpRate: localStorage.getItem(instituteId + 'topuprate') != null ? JSON.parse(localStorage.getItem(instituteId + 'topuprate')) : {},
            // search number item
            searchCodeBy: 5,
            searchCode: '',
            // buy loyalty card
            qtyAmount: 1,
            dialogQTY: false,
            createCardFunc: '',
            isCreateCard: false,
            dialogLcardBy: false,
            sCardTap: false,
            countObj: {},
            kendo: kendo,
            t: new PosTransactionModel({}),
            loadModifier: false,
            showBindItmLocal: false,
            // add item flow
            employeeDialog: false,
            modifierDialog: false,
            uomDialog: false,
            addItmFlowAt: 0,
            // end
            activeStore: localStorage.getItem(instituteId + 'commStore') != null ? new StoreModel(JSON.parse(localStorage.getItem(instituteId + 'commStore'))) : {},
            register: localStorage.getItem(instituteId + 'commRegister') != null ? JSON.parse(localStorage.getItem(instituteId + 'commRegister')) : {},
            disPriceLevel: false,
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu: false,
            modal: false,
            menu2: false,
            isEditDiscount: false,
            year: new Date().getFullYear(),
            isAccountLogo: true,
            dialogLoyalty: false,
            dialogPartner: false,
            dialogReward: false,
            rewardForm: false,
            dialogPromotion:false,
            dialogOrder: false,
            dialogCard: false,
            dialogCardPayment: false,
            dialog: false,
            dialogCash: false,
            dialogBank: false,
            dialogQrcode: false,
            dialogNote: false,
            infoBank: false,
            dialogInvoice: false,
            dialogDelivery: false,
            dialogSplit: false,
            dialogMerge: false,
            dialogCountGuest: false,
            localMen: {
                coun: 0
            },
            localWomen: {
                coun: 0
            },
            items: '',
            pinActivate: false,
            pin: '',
            valid: true,
            incorrect: false,
            showLoading: false,
            loadingAlert: false,
            loadingColorAlert: '',
            loadingTextAlert: '',
            isHide: false,
            fullscreen: false,
            s: {
                timeIn: false,
                timeOut: false,
                orderNumber: false,
                cashierName: false,
                modifier: false,
                employee: false,
                note: false,
                numberPeople: false,
                saleUnitItem: false,
                favorite: false,
                takeAway: false,
                booking: false,
                userPin: false,
                sessionPin: false,
                allowNFCCard: false,
                allowSplitItem: false,
                kitchenKitCategories: [],
                allowKitchenKit: false,
                orderListCategries: [],
                allowOrderList: false,
                cancelReasons: [],
                allowCancelReason: false,
                decimal: 0,
                appNature: 'Retail',
                screenDisplay: 'Surface',
                receiptTemplate: '80mm',
                servings: [],
            },
            g: new generalSettingModel({}),
            items1: [
                {
                    name: "coffee",
                    title: "Coffee"
                },
                {
                    name: "hotCoffee",
                    title: "Hot Coffee"
                },
                {
                    name: "iceCream",
                    title: "Ice Cream"
                }
            ],
            items2: [
            ],
            merge1: [
                {
                    name: "table1",
                    title: "Table 01"
                },
                {
                    name: "table2",
                    title: "Table 02"
                },
                {
                    name: "table3",
                    title: "Table 03"
                }
            ],
            merge2: [
            ],
            //
            imgUrl: "https://s3-ap-southeast-1.amazonaws.com/images.banhji/",
            priceLevels: [],
            priceLevel: '',
            // price product
            lastPPKey: {},
            priceProducts: [],
            // itme
            lastPKey: {},
            allItems: [],
            loadingSetting: true,
            loadPrice: true,
            loadPullData: false,
            startFlowAt: 0,
            activePin: {
                name: session.openBy,
                pinCode: ''
            },
            // other function
            func: {
                reward: false,
                promotion: false,
                parksale: false,
                invoice: false,
                note: false,
                delivery: false,
                resetOrder: false,
                splitInv: false,
                clearOrder: false,
                saleUnit: false,
                countGuest: false,
                mergeInv: false,
                orderList: false,
                orderType: false, 
            },
            // guest
            guestCount: {
                localMen: 0,
                localWomen: 0,
                forMen: 0,
                forWomen: 0
            },
            // order type
            dialogOrderType: false,
            activeOrderType: {},
            isCategoryPage: false,
            // category
            categories: [],
            cateGroup: [],
            cateGroupData: [],
            subGroup: [],
            subGroupData: [],
            showCatePage: false,
            showItem: false,
            showGroupPage: false,
            showSupGroupPage: false,
            // pull data
            pullAsOf: localStorage.getItem(instituteId + 'pulldate') != null ? kendo.toString(new Date(parseInt(localStorage.getItem(instituteId + 'pulldate'))), 'yyyy-MM-dd h:m tt') : '',
            // line 
            lineDS: [],
            selectItem: {},
            loadingItmBlock: false,
            // 
            itemLine: new ItemLineModel({}),
            saleFormContent: saleFormContentModel,
            invoice: invoiceModel,
            taxListTotal: [],
            customerOtherChargeItem: [],
            mOtherCharge: [],
            pointAmount: 0,
            jRaw: [],
            coa: [],
            receivableAcc: [],
            //customer
            customerPoint: 0,
            customer: new CustomerModel({}),
            // keypayd
            activeKeyPad: false,
            activeQty: false,
            keyPadAmount: 0,
            activePrice: false,
            activeDiscount: false,
            discountItems: [],
            //sale unit
            dialogSaleUnit: false,
            saleUnits: [],
            saleUnitCates: [],
            showSaleUnitCate: false,
            saleUnitData: [],
            saleUnit: {
                id: 0
            },
            //employee
            employees: [],
            isEditEmp: false,
            //modifier
            modifiers: [],
            isEditModi: false,
            // sidebar
            minWidth: false,
            drawer: null,
            miniVariant: false,
            handOver: false,
            isApp: true,
            isAbsolute: false,
            pins: [],
            session: session,
            operator: session.openBy,
            sessionStart: '',//new Date(session.startDate).toISOString().substr(0, 10),
            itemUOMs: [],
            activeLine: {},
            showItems: false,
            paging: {},
            params: {},
            loadSGroup: false,
            loadGroup: false,
            loadEmp: false,
            loadItem: false,
            itemDatas: [],
            decimal: 0,
            //loyalty
            lcardNumber: '',
            activeLoyaltyCard: {},
            rewardPrograms: [],
            activeRPrograms: [],
            loadReward: false,
            dialogRProgram: false,
            activeReward: {},
            //partner
            partners: [],
            isLoadPartner: false,
            activePartner: {},
            partnerRefNum: '',
            dialogPartnerRef: false,
            //order type
            orderTypes: [],
            //modifier
            activeModi: [],
            modiCategories: [],
            isLoadModiCate: false,
            activeSelectModi: [],
            // amount to pay
            amtReil: 0,
            //currency
            baseRate: {},
            secondRate: {},
            selectRate: {},
            rates: [],
            currencies: [],
            exchange: {
                base: 0,
                secondary: 0
            },
            amtReceipt: 0,
            amountToReciept: 0,
            hasKHR: false,
            hasUSD: false,
            orderNumber: '',
            //payment
            allowPayCash: false,
            allowPayCard: false,
            allowPayBank: false,
            allowPayKHQR: false,
            //txn
            showLoadingTxn: false,
            isSale: 1,
            //pay by bank
            cardPay: {
                name: '',
                type: '',
                expireDate: new Date().toISOString().substr(0, 10),
                digit: ''
            },
            banks: commerceHandler.banks(),
            bankWallets: [],
            activePayBank: {},
            payBankRef: '',
            keyFunction: '',
            oldqty: 0,
            olddis: 0,
            oldprice: 0,
            //campaign
            loadCam: false,
            campaigns: [],
            txnNumberFormat: {},
            orderNumberFormat: {},
            //session
            activeSession: {},
            dialogCloseSession: false,
            isEndShift: false,
            dialogCashReconcile: false,
            noteDS: [],
            servings: [],
            invoices: [],
            preorders: [],
            txnorders: [],
            hasInv: false,
            invs: [],
            hasServing: false,
            servs: [],
            hasPre: false,
            pres: [],
            dialogPrint: false,
            printObj: {},
            dialogServingLine: false,
            dialogPayment: false,
            servingLines: [],
            activeServing: {},
            isServing: false,
            shiftObj: {},
            dialogShiftInfo: false,
            isEndSession: false,
            dialogSessionInfo: false,
            dialogPrintSession: false,
            loyalFunc: '',
            chFStart: 0,
            dialogLoyalSearch: false,
            chFlows: [],
            dialogRewardInfo: false,
            loyaltyObj: {},
            lsearchName: 'card',
            rewardAmount: 0,
            rewardObj: {},
            promotions: [],
            dialogCheckOut: false,
            isPromoFlow: false,
            dailogPhone: false,
            phoneNumber: '',
            isLoadOrderType: false,
            isPartnerPay: false,
            // preorder
            dialogPreOrder: false,
            // institute
            logoUrl: '',
            dialogGuestType: '',
            institute: JSON.parse(localStorage.getItem('institute')),
            //
            hasLoyaltyCard: false,
            loyaltyCustomer: {},
            // invoice
            invSearchText: '',
            invoiceLines: [],
            payInvoice: false,
            activeInvoice: {},
            invLines: [],
            // order
            orderSearchText: '',
            orderLines: [],
            ordLines: [],
            dialogOrderTxn: false,
            // point card
            pointCardPay: false,
            paidPointLine: [],
            // cash count
            isCountCash: false,
            // order
            isProcessOrder: false,
            khrPaidAmt: 0,
            usdPaidAmt: 0,
            bankPaidAmt: 0,
            showAmountKeyPad: false,
            activebankpay: {
                bank: {
                    name: ''
                }
            },
            // point top up
            isTopUp: false,
            showTopUpForm: false,
            topUpCard: {},
            // verify code
            dialogCode: false,
            verifyCode: '',
            cardCode: {},
            dialogKey: false,
            sessionKey: '',
            keyincorrect: false,
            isRegisterCard: false,
            saleUnitList: [],
            dialogChangeSaleunit: false,
            cSaleUnit: {},
            cSaleUnitId: '',
            cTxn: '',
            isEditServing: false,
            delLine: false,
            delId: '',
            delTxn: false,
            isPrintServing: false,
            isEndServing: false,
            // loyalty session
            isTopUpCounter: false,
            isTopUpGCard: false,
            isSaleVoucher: false,
            isSaleCard: false,
            //check other function
            allowFLoyalty: false,
            allowFSCard: false,
            allowFPSale: false,
            allowFPreorder: false,
            allowFSSesion: false,
            allowFESesion: false,
            allowFIntUsage: false,
            allowFPLookup: false,
            dialogPPOption: false,
            allowDisCard: false,
            allowCampaign: false,
            //point qr
            qrObj: {},
            dailogPrintPQR: false,
            khqrUrl: '',
            searchCard: {},
            dialogSCard: false,
            //parksale
            parkSaleTxns: [],
            dialogPSale: false,
            parkSales: [],
            isUseParkSale: false,
            activeParksale: {},
            isCamVerifyCode: false,
            camVerify: {},
            //session
            sessionnumber: '',
            slastNumber: 1,
            addsession: {},
            showStartSession: false,
            isStartSession: false,
            isOrderFlow: false,
            isLockPin: false,
            sSumary: [],
            sesItems: [],
            dialogPrintShift: false,
            lineToAdd: {},
            waitSelectUom:false,
            offlinetxns: [],
            // fullscreen: false,
            isOnline: true,
            isLoaded: false,
            barcodeDatas: [],
            dialogCamps: false,
            mBills: [],
            searchCodeBys: [],
            pointOptQR: false,
            pointOptCode: false,
            pointOptCounter: false,
            isPointPayCounter: false,
            allowPayPoint: true,
            rawItems: [],
            repStartDate: "",
            repEndDate: "",
            activeSaleUnit: {}
        }),
        watch: {
            'barcodetext': 'searchCodeChange'
        },
        methods: {
            closeShiftPrint(){
                this.dialogPrintShift = false; 
                setTimeout(()=>{
                    this.resetOrder()
                }, 1000)
            },
            onSorterChanges(val) {
                let today = new Date()
                window.console.log(val, 'date change')
                switch (val) {
                    case 1:
                        this.repStartDate = kendo.toString(today, 'yyyy-MM-dd')
                        this.repEndDate = kendo.toString(today, 'yyyy-MM-dd')
                        break
                    case 2:
                        var first = today.getDate() - today.getDay(),
                            last = first + 6
                        this.repStartDate = kendo.toString(new Date(today.setDate(first)), 'yyyy-MM-dd')
                        this.repEndDate = kendo.toString(new Date(today.setDate(last)), 'yyyy-MM-dd')
                        break
                    case 3:
                        this.repStartDate = kendo.toString(new Date(today.getFullYear(), today.getMonth(), 1), 'yyyy-MM-dd')
                        this.repEndDate = kendo.toString(new Date(today.getFullYear(), today.getMonth(), 31), 'yyyy-MM-dd')
                        break
                    case 4:
                        this.repStartDate = kendo.toString(new Date(today.getFullYear(), 0, 1), 'yyyy-MM-dd')
                        this.repEndDate = kendo.toString(new Date(today.getFullYear(), 11, 31), 'yyyy-MM-dd')
                        break
                    default:
                        this.repStartDate = ""
                        this.repEndDate = ""
                }
            },
            dataBound: function (e) {
                const grid = kendo.jQuery("#saleSummary").data("kendoGrid")
                const items = e.sender.items()
                let self = this
                items.each(function () {
                    let dataItem = grid.dataItem(this);
                    $("tr[data-uid='" + dataItem.uid + "']").find(".isStatus").each(function () {
                        let vamt = dataItem.voidAmount || 0
                        if ( parseFloat(vamt) <= 0) {
                            $(this).removeClass('k-state-disabled')
                        }
                        if(!self.g.allowVoid){
                            $(this).addClass('k-state-disabled')
                        }
                        let today = kendo.toString(new Date(), 'yyyy-MMM-dd')
                        let repDate = kendo.toString(new Date(dataItem.issuedDate), 'yyyy-MMM-dd')
                        if(today != repDate){
                            $(this).addClass('k-state-disabled')
                        }
                    })
                })
            },
            searchCardChange(){
                // window.console.log(this.lsearchName, 'on card change') 
                if(this.lsearchName == 'nfc' && this.lcardNumber != ''){
                    this.searchLoyaltyCard()
                }
            },
            // card profile
            onCardProfileChange(e) {
                // const file = e.target.files[0];
                let input = e.target;
                // Ensure that you have a file before attempting to read it
                if (input.files && input.files[0]) {
                    if (input.files[0].size > 1024 * 1024) {
                        e.preventDefault();
                        alert("File too big (> 1MB)");
                        this.registerCard.profile = "";
                        this.$refs.fileupload.value = null;
                    } else {
                        // create a new FileReader to read this image and convert to base64 format
                        let reader = new FileReader();
                        // Define a callback function to run, when FileReader finishes its job
                        reader.onloadend = (e) => {
                            // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
                            // Read image as base64 and set to imageData
                            this.registerCard.profile = e.target.result;
                        };
                        // Start the reader job - read file as a data url (base64 format)
                        reader.readAsBinaryString(input.files[0]);
                        // this.url = input.files[0]
                        this.registerCard.profileUrl = URL.createObjectURL(e.target.files[0]);
                        this.registerCard.isEditImg = true
                    }
                }
            },
            // Split Bill
            splitBill(bill){
                // this.isSplitBill = !this.isSplitBill
                let s = this.servings.filter((o)=>{return o.pk == bill.pk})
                if(s.length <= 0){
                    this.$snotify.error(i18n.t('something_went_wrong'))
                    return;
                }
                this.activeServing = s[0]
                // if(!this.isSplitBill){
                //     this.activeServing = {}
                // }
                this.$swal({
                    title: i18n.t("msg_title_warning"),
                    text: i18n.t("confirm_split_items"),
                    icon: "warning",
                    showCancelButton: true,
                    cancelButtonText: i18n.t("cancel"),
                    confirmButtonColor: "#4d4848",
                    cancelButtonColor: "#eb0238",
                    confirmButtonText: i18n.t("confirm"),
                }).then((result) => {
                    if (result.value) {
                        // window.console.log(this.activeServing, 'confirm')
                        this.showLoading = true
                        this.splitAllItems()
                    }
                });
            },
            async splitAllItems(){
                if(this.activeServing.hasOwnProperty('pk')){
                    let serving = this.activeServing
                    let itmLine = serving.itemLines
                    if(serving.itemLines.length == 0){
                        let d = {
                            sk: serving.pk,
                            pk: 'txnline-',
                            key: {}
                        }
                        await commerceHandler.genGets(d).then(res => {
                            // window.console.log(res, 'response serving')
                            if(res.data.data.Items.length > 0){
                                let l = []
                                res.data.data.Items.forEach(itm=>{
                                    l.push(itm)
                                })
                                itmLine = l
                                this.updateLineIndxDB(serving.pk, 'serving', itmLine)
                            }
                        })
                    }
                    if(itmLine.length > 0){
                        if(itmLine.length > 1){
                            let txns = []
                            for (let index = 0; index < itmLine.length; index++) {
                                const d = itmLine[index];
                                let t = {
                                    ...serving
                                }
                                const itmpk = 'comm-txn-' + uuid.v1()
                                // window.console.log(itmpk, 'itm pk')
                                t.otherCharge = this.numberSetting(d.otherCharge)
                                t.subTotal = this.numberSetting(d.price) * this.numberSetting(d.qty)
                                t.discount = this.numberSetting(d.discount) 
                                let spTax = this.numberSetting(d.spTax) || 0
                                t.spTax = this.numberSetting(spTax)
                                t.vat = this.numberSetting(d.tax)
                                if(index > 0){
                                    t.orderNumber = t.orderNumber + '-S' + index
                                    if(t.saleUnit.hasOwnProperty('name')){
                                        t.saleUnit = {
                                            ...t.saleUnit
                                        }
                                        t.saleUnit.name = t.saleUnit.name + '-S' + index
                                    }
                                }
                                t.pk = itmpk
                                t.id = itmpk
                                t.tax = this.numberSetting(d.vat) + this.numberSetting(spTax) + this.numberSetting(d.plTax)
                                t.spTax = this.numberSetting(spTax)
                                t.vat = this.numberSetting(d.vat)
                                t.plTax = this.numberSetting(d.plTax)
                                t.modiAmt = this.numberSetting(d.modifierAmt)
                                t.total = (this.numberSetting(d.price) * this.numberSetting(d.qty)) - this.numberSetting(d.discount)
                                let line = {
                                    ...d,
                                    id: 'lin-' + uuid.v1()
                                }
                                // window.console.log(line, 'line on splite')
                                let lines = []
                                lines.push(line)
                                t.sk = this.activeStore.pk
                                t.gsi2 = this.register.pk + '#serving#1#' + new Date().getTime()
                                t.itemLines = lines
                                txns.push(t)
                            }
                            setTimeout(()=>{
                                if(txns.length > 0){
                                    this.saveData(txns, '')
                                }
                            }, 500)
                        }else{
                            this.$snotify.error(i18n.t('item_must_over_one_line'))
                        }
                    }
                }
            },
            async saveData(txns, type){
                if(txns.length > 0){
                    let oldserve = {
                        ...this.activeServing,
                        sk: this.activeStore.pk,
                        gsi2: this.register.pk + '#serving#3#' + new Date().getTime()
                    }
                    txns.push(oldserve)
                    let d = {
                        data: txns,
                        type: type
                    }
                    await commerceHandler.updateDb(d).then(()=>{
                        this.updateSplitItem(txns)
                    })
                }
                this.showLoading = false
            },
            updateSplitItem(txns){
                txns.forEach((t, idx)=>{
                    // window.console.log(t, idx, 'update split items')
                    if(idx == 0){
                        this.isEditServing = true
                        this.setLocalServing(t)
                    }else{
                        this.isEditServing = false
                        this.setLocalServing(t)
                    }
                })
                this.delIndxdb(this.activeServing.pk, 'serving', 'serving')
                setTimeout(()=>{
                    window.location.reload()
                }, 500)
            },
            numberSetting(value){
                let num = parseFloat(value)
                let dec = parseInt(this.g.decimal)
                let numb = num.toFixed(dec)
                return parseFloat(numb)
            },
            // Merge Bil
            mergeBill(bill){
                this.$swal({
                    title: i18n.t("msg_title_warning"),
                    text: i18n.t("confirm_merge_items"),
                    icon: "warning",
                    showCancelButton: true,
                    cancelButtonText: i18n.t("cancel"),
                    confirmButtonColor: "#4d4848",
                    cancelButtonColor: "#eb0238",
                    confirmButtonText: i18n.t("confirm"),
                }).then((result) => {
                    if (result.value) {
                        this.isMergeBill = true
                        // this.mergeBillSave(bill)
                        this.editServing(bill)
                    }
                });
            },
            mergeBillSave(bill){
                window.console.log(bill, 'merge bill')
                this.activeServing = bill
                let m = this.servingLines.filter((o) => {return o.pk != bill.pk})
                this.mergeBills = []
                if(m.length > 0){
                    this.mergeBills = m
                    this.mBills = []
                    this.dialogMBill = true
                }else{
                    this.$snotify.error(i18n.t('no_bill_for_merge'))
                    return;
                }
            },
            async enterMergeBill(){
                if(this.mBills.length > 0){
                    let lines = []
                    for(const b of this.mBills){
                        let s = this.servings.filter((o)=>{return o.pk == b})
                        if(s.length <= 0){
                            this.$snotify.error(i18n.t('something_went_wrong'))
                            return;
                        }
                        let serving = s[0]
                        let itmLine = serving.itemLines
                        if(serving.itemLines.length == 0){
                            let d = {
                                sk: serving.pk,
                                pk: 'txnline-',
                                key: {}
                            }
                            this.showLoading = true
                            await commerceHandler.genGets(d).then(res => {
                                this.showLoading = false
                                if(res.data.data.Items.length > 0){
                                    let l = []
                                    res.data.data.Items.forEach(itm=>{
                                        l.push(itm)
                                    })
                                    itmLine = l
                                    this.updateLineIndxDB(serving.pk, 'serving', itmLine)
                                }
                            })
                        }
                        if(itmLine.length > 0){
                            itmLine.forEach(i=>{
                                let l = {
                                    ...i
                                }
                                l.uom.toUOM = {
                                    id: l.uom.id,
                                    name: l.uom.name
                                }
                                delete l.__ob__
                                lines.push(l)
                            })
                        }
                    }
                    let s = this.servings.filter((o)=>{return o.pk == this.activeServing.pk})
                    if(s.length <= 0){
                        this.$snotify.error(i18n.t('something_went_wrong'))
                        return;
                    }
                    let serving = s[0]
                    let itmLine = serving.itemLines
                    if(serving.itemLines.length == 0){
                        let d = {
                            sk: serving.pk,
                            pk: 'txnline-',
                            key: {}
                        }
                        this.showLoading = true
                        await commerceHandler.genGets(d).then(res => {
                            this.showLoading = false
                            if(res.data.data.Items.length > 0){
                                let l = []
                                res.data.data.Items.forEach(itm=>{
                                    l.push(itm)
                                })
                                itmLine = l
                                this.updateLineIndxDB(serving.pk, 'serving', itmLine)
                            }
                        })
                    }
                    if(itmLine.length > 0){
                        itmLine.forEach(i=>{
                            let l = {
                                ...i
                            }
                            delete l.uom.toUOM
                            l.uom.toUOM = {
                                id: l.uom.id,
                                name: l.uom.name
                            }
                            delete l.__ob__
                            lines.push(l)
                        })
                    }
                    window.console.log(lines, 'lines on merge')
                    this.editServing(serving)
                    let dataItem = this.activeServing
                    dataItem.id = dataItem.pk
                    this.lineDS = lines
                    this.t = new PosTransactionModel(dataItem)
                    this.calculateTotal()
                    this.t.isEdit = true
                    this.t.paidBy = ""
                    this.dialogServingLine = false
                    this.orderNumber = this.t.orderNumber
                    let deldata = []
                    this.mBills.forEach(b=>{
                        this.delIndxdb(b, 'serving', 'serving')
                        let m = this.servings.filter((o) => {return o.pk == b})
                        if(m.length > 0){
                            let dd = {
                                ...m[0],
                                mergeId: this.activeServing.pk,
                                gsi2: this.register.pk + '#serving#3#' + new Date().getTime(),
                                sk: this.activeStore.pk,
                                id: m[0].pk
                            }
                            deldata.push(dd)
                        }
                    })
                    window.console.log(deldata, 'delete data')
                    if(deldata.length > 0){
                        let d = {
                            data: deldata,
                            type: ''
                        }
                        await commerceHandler.updateDb(d)
                    }
                    this.saveEditServing()
                }else{
                    this.$snotify.error(i18n.t('no_bill_for_merge'))
                    return;
                }
            },
            // invoice
            payByInvoice(){
                if(this.lineDS.length == 0){
                    this.$snotify.error(i18n.t('item_required'))
                    this.dialogCard = false
                    return
                }
                this.isSale = 0
                this.t.paymentMethod = 'invoice'
                this.t.amtReceipt = this.t.total
                this.t.issuedDate = new Date()
                this.t.paidBy = 'Invoice'
                this.t.itemLines = this.lineDS
                this.t.customer = {
                    id: this.g.defaultCustomer.id,
                    type: 'general',
                    name: this.g.defaultCustomer.name
                }
                this.createPrintReceipt(this.t)
                this.activeCustomer = {}
                this.dailogInv = true
            },
            onPinChange(value) {
                this.pin = value;
                window.console.log("pin", value);
            },
            // campaign
            listCampaign(){
                if(this.lineDS.length == 0){
                    this.$snotify.error(i18n.t('item_required'))
                    this.dialogCash = false
                    return
                }
                let camps = []
                this.campaigns.forEach(c=>{
                    if(c.isEndDate){
                        let enddate = c.endDate + ' 23:59:59'
                        let ed = new Date(enddate).getTime()
                        let td = new Date().getTime()
                        if(td >= ed){
                            return
                        }
                    }
                    if(c.type == 'manual'){
                        let allstore = c.allStore || false
                        if(allstore){
                            camps.push(c)
                        }else{
                            let has = false
                            c.stores.forEach(st=>{
                                if(st.pk == this.activeStore.pk){
                                    has = true
                                }
                            })
                            if(has){
                                camps.push(c)
                            }
                        }
                    }
                })
                window.console.log(camps, 'cam for store')
                if(camps.length > 0){
                    this.dialogCamps = true
                    this.campaignList = camps
                }
            },
            discountCard(){
                if(this.lineDS.length == 0){
                    this.$snotify.error(i18n.t('item_required'))
                    this.dialogCash = false
                    return
                }
                this.isDiscountCard = true
                this.lcardNumber = ''
                // this.dialogLoyalSearch = true
                if(this.g.searchCard.length <= 0){
                    this.$snotify.success(i18n.t('no_search_card_function'))
                }else if(this.g.searchCard.length == 1){
                    let s = this.g.searchCard[0]
                    if(s == 1){
                        this.lsearchBy('card')
                    }else if(s == 2){
                        this.lsearchBy('phone')
                    }else if(s == 3){
                        this.lsearchBy('nfc')
                    }else if(s == 4){
                        this.lsearchBy('ordernumber')
                    }
                }else{
                    this.dialogLoyalSearch = true
                }
            },
            payStoreCredit(){
                if(!navigator.onLine){
                    this.$snotify.error(i18n.t('please_connect_internet'))
                    return
                }
                this.isStoreCredit = true
                this.dialogCreditCard = true
            },
            sesChange(){
                setTimeout(()=>{
                    // window.console.log(this.session, 'session change')
                    let shiftNum = 0
                    if(this.session.hasOwnProperty('shifts')){
                        shiftNum = 1
                        if(this.session.shifts.length > 0){
                            let cushift = this.session.shifts.length
                            if(cushift < parseInt(this.register.shifts)){
                                shiftNum = this.session.shifts.length + 1
                            }else if(cushift == parseInt(this.register.shifts)){
                                shiftNum = 0
                            }
                        }
                    }
                    this.shiftNum = shiftNum
                }, 500)
            },
            refreshSys(){
                window.location.reload()
            },
            backItmBlock(){
                // window.console.log(this.showCatePage, this.showGroupPage, this.showSupGroupPage)
                if(!this.showGroupPage && !this.showSupGroupPage){
                    if(this.cateGroup.length > 0){
                        this.showGroupPage = true
                        this.showCatePage = false
                        this.showItem = false
                    }else{
                        this.showCatePage = true
                        this.showGroupPage = false
                        this.showItem = false
                    }
                }else if(this.showGroupPage && !this.showSupGroupPage){
                    this.showCatePage = true
                    this.showGroupPage = false
                    this.showItem = false
                }
            },
            amIOnline(e) {
                const iconOffline = require("@/assets/images/outline_wifi_off_black_48dp.png");
                const iconOnline = require("@/assets/images/outline_wifi_white_48dp.png");
                if (!e) {
                    this.isLoaded = true;
                } else {
                    this.isOnline = true;
                    this.pushData()
                }
                if (this.isLoaded) {
                    if (e) {
                        this.isOnline = true;
                        this.$snotify.success("You are connected", "", { icon: iconOnline });
                        // this.pushData()
                    } else {
                        this.isOnline = false;
                        this.$snotify.simple("You are offline!", "", { icon: iconOffline });
                    }
                }
            },
            pushData(){
                // window.console.log(navigator.onLine, 'push data')
                // items
                if(loadIndex){
                    // window.console.log('1')
                    let txn = db.transaction(instituteId + "offlinetxn", "readwrite");
                    let i = txn.objectStore(instituteId + "offlinetxn");
                    let item = []
                    this.offlinetxns = []
                    let self = this
                    i.getAll().onsuccess = function(event) {
                        item = event.target.result
                        self.offlinetxns = item
                        self.saveOffTxn(item)
                    }
                }else{
                    setTimeout(()=>{
                        // window.console.log('2')
                        this.pushData()
                    }, 300)
                }
            },
            async saveOffTxn(data){
                // window.console.log('3')
                if(data.length > 0){
                    // for (const t of data) {
                    //     t.id = ''
                    //     let offpk = t.pk
                    //     t.pk = ''
                    //     t.isOffline = true
                    //     await commerceHandler.txnCreate(new PosTransactionModel(t)).then(res=>{
                    //         window.console.log(res, 'res offline')
                    //         if(Object.keys(res.data.data).length > 0){
                    //             this.delIndxdb(offpk, 'offlinetxn', 'offline')
                    //         }
                    //     })
                    // }
                    data.forEach(t=>{
                        t.id = ''
                        let offpk = t.pk
                        t.pk = ''
                        commerceHandler.txnCreate(new PosTransactionModel(t)).then(res=>{
                            // window.console.log(res, 'res offline')
                            if(Object.keys(res.data.data).length > 0){
                                this.delIndxdb(offpk, 'offlinetxn', 'offline')
                            }
                        })
                    })
                }
            },
            payFleetCard(){
                if(this.lineDS.length == 0){
                    this.$snotify.error(i18n.t('item_required'))
                    this.dialogCard = false
                    return
                }
                this.isSale = 0
                this.t.paymentMethod = 'fleetcard'
                this.t.amtReceipt = this.t.total
                this.t.issuedDate = new Date()
                this.t.paidBy = 'Fleet card'
                this.t.itemLines = this.lineDS
                this.t.customer = {
                    id: this.g.defaultCustomer.id,
                    type: 'general',
                    name: this.g.defaultCustomer.name
                }
                this.createPrintReceipt(this.t)
                this.activeCustomer = {}
                // this.dailogInv = true
                this.$snotify.error(i18n.t('coming_soon'))
            },
            payCicMVP(){
                if(this.lineDS.length == 0){
                    this.$snotify.error(i18n.t('item_required'))
                    this.dialogCard = false
                    return
                }
                this.isSale = 0
                this.t.paymentMethod = 'cicmvp'
                this.t.amtReceipt = this.t.total
                this.t.issuedDate = new Date()
                this.t.paidBy = 'CiC MVP'
                this.t.itemLines = this.lineDS
                this.t.customer = {
                    id: this.g.defaultCustomer.id,
                    type: 'general',
                    name: this.g.defaultCustomer.name
                }
                this.createPrintReceipt(this.t)
                this.activeCustomer = {}
                // this.dailogInv = true
                this.$snotify.error(i18n.t('coming_soon'))
            },
            //search item by code
            searchCodeChange(){
                window.console.log(this.barcodetext, '->type', this.searchCodeBy)
                this.searchCode = this.barcodetext
                if(this.searchCode != '' && this.searchCode != null){
                    if(this.searchCodeBy == 1){
                        if(this.searchCode.length > 0){
                            // window.console.log(this.searchCode, 'searchode')
                            // this.showLoading  = false
                            let pro = []
                            this.itemDatas.forEach(it=>{
                                let text = this.searchCode.toString().toLowerCase()
                                let position = text.indexOf(it.sku.toString().toLowerCase());
                                if(position != -1){
                                    pro.push(it)
                                }
                            })
                            // window.console.log(this.searchCodeBy, 'search code by')
                            if(pro.length > 0){
                                this.addItem(pro[0])
                                // this.items = pro
                                this.searchCode = ''
                                this.barcodetext = ''
                                $('#searchCodeField').select()
                            }else{
                                this.$snotify.error(i18n.t('no_data_found'))
                                this.searchCode = ''
                                this.barcodetext = ''
                                $('#searchCodeField').select()
                            }
                        }
                    }else if(this.searchCodeBy == 5){
                        // this.showLoading = true
                        if(this.searchCode.length > 0){
                            window.console.log()
                            let txn = db.transaction(instituteId + "items", "readonly");
                            let i = txn.objectStore(instituteId + "items");
                            const myIndex = i.index("barcode");
                            const getRequest = myIndex.getAll(this.searchCode);
                            // this.showLoading = true
                            // window.console.log(this.searchCode, 'barcode search')
                            // search barcode dis
                            let hasBarDis = false
                            let barDis20 = false
                            let barDis30 = false
                            let barDis40 = false
                            let barDis50 = false
                            let barDis100 = false
                            let isDis20 = false
                            let isDis30 = false
                            let isDis40 = false
                            let isDis50 = false
                            let isDis100 = false
                            let barDisAmt = 0
                            if(this.g.barcodeDis20){
                                barDis20 = true
                                hasBarDis = true
                            }
                            if(this.g.barcodeDis30){
                                barDis30 = true
                                hasBarDis = true
                            }
                            if(this.g.barcodeDis40){
                                barDis40 = true
                                hasBarDis = true
                            }
                            if(this.g.barcodeDis50){
                                barDis50 = true
                                hasBarDis = true
                            }
                            if(this.g.barcodeDis100){
                                barDis100 = true
                                hasBarDis = true
                            }
                            if(hasBarDis){
                                let arr = this.searchCode.split('-')
                                // window.console.log(arr, 'array on split')
                                if(arr.length > 0){
                                    let head = arr[0].toString().toLowerCase()
                                    if(head == 'bardis'){
                                        let dismat = arr[1].toString().toLowerCase()
                                        if(dismat == '20' && barDis20 == true){
                                            isDis20 = true
                                        }else if(dismat == '30' && barDis30 == true){
                                            isDis30 = true
                                        }else if(dismat == '40' && barDis40 == true){
                                            isDis40 = true
                                        }else if(dismat == '50' && barDis50 == true){
                                            isDis50 = true
                                        }else if(dismat == '100' && barDis100 == true){
                                            isDis100 = true
                                        }
                                    }
                                }
                            }
                            let self = this
                            getRequest.onsuccess = () => {
                                self.loadingItmBlock = false
                                let bitm = self.activeLine
                                let discount = 0
                                window.console.log(Object.keys(bitm).length, bitm, 'actie length')
                                if(Object.keys(bitm).length > 0 && self.lineDS.length > 0){
                                    let amt = parseFloat(bitm.price) * 1
                                    // bitm.amount = amt
                                    // bitm.discount = 0
                                    if(isDis20){
                                        barDisAmt = 20
                                        discount = amt * 0.2
                                        bitm.discount = discount
                                    }else if(isDis30){
                                        barDisAmt = 30
                                        discount = amt * 0.3
                                        bitm.discount = discount
                                    }else if(isDis40){
                                        barDisAmt = 40
                                        discount = amt * 0.4
                                        bitm.discount = discount
                                    }else if(isDis50){
                                        barDisAmt = 50
                                        discount = amt * 0.5
                                        bitm.discount = discount
                                    }else if(isDis100){
                                        barDisAmt = 100
                                        discount = amt
                                        bitm.discount = discount
                                    }
                                    if(discount > 0 && hasBarDis == true){
                                        bitm.discount = discount
                                        bitm.amount = parseFloat(bitm.amount) - discount
                                        self.$snotify.success(i18n.t('Barcode Discount ' + barDisAmt + '%'))
                                        self.searchCode = ''
                                        self.barcodetext = ''
                                        $('#searchCodeField').select()
                                        return
                                    }
                                }
                                window.console.log(bitm, discount, 'barcode  discount')
                                console.log(getRequest.result, 'here reuslt');
                                let itm = getRequest.result
                                if(itm.length > 0){
                                    let bitm = self.converItem(itm[0])
                                    if(!bitm){
                                        self.$snotify.error(i18n.t('no_data_found'))
                                        self.searchCode = ''
                                        self.barcodetext = ''
                                        $('#searchCodeField').select()
                                        return
                                    }
                                    self.addItem(bitm)
                                    self.searchCode = ''
                                    self.barcodetext = ''
                                    $('#searchCodeField').select()
                                }else{
                                    self.$snotify.error(i18n.t('no_data_found'))
                                    self.searchCode = ''
                                    self.barcodetext = ''
                                    $('#searchCodeField').select()
                                }
                            };
                        }
                    }
                }
            },
            converItem(e){
                let tod = new Date().getTime()
                let priceItems = e.priceItems || []
                let itp = priceItems.filter((o) => {
                    return this.g.defaultPriceLevel == o.priceLevel.id && new Date(o.appliedDate).getTime() <= tod
                })
                e.categoryId = e.categoryId || e.category.id
                let cate = this.g.usedCategory.filter((a) => {
                    return a.id == e.categoryId
                })
                window.console.log(cate, e, 'on convert')
                if(itp.length > 0){
                    let uoms = []
                    let price = e.price
                    itp.sort(function (a, b) {
                        return Date.parse(b.appliedDate_) - Date.parse(a.appliedDate_)
                    })
                    let uarray = []
                    itp.forEach(u=>{
                        if($.inArray(u.uomId, uarray) == -1) {
                            uoms.push({
                                price: parseFloat(u.price),
                                ...u.uom
                            })
                            uarray.push(u.uomId)
                        }
                    })
                    if(uoms.length > 0){
                        price = parseFloat(uoms[0].price)
                    }
                    //count stock
                    let countStock = e.objExtra.countStock || false
                    //reorder qty
                    let roq = e.objExtra.roq || 0
                    if(!countStock){
                        roq = 0
                    }
                    let items = {
                        id: e.pk,
                        name: e.name,
                        sku: e.sku,
                        saleDescription: e.objName.itm.desc || e.name,
                        price: price,
                        categoryId: e.categoryId,
                        category: cate[0],
                        borderColor: 'border: 1px solid ' + cate[0].color,
                        titleColor: 'background-color: ' + cate[0].color,
                        groupId: e.group.id,
                        subGroupId: e.subGroup.id,
                        img: e.thumb != '' ? 'https://s3-ap-southeast-1.amazonaws.com/images.banhji/' + e.thumb : './images/default.png',
                        uom: uoms,
                        buom: e.uom,
                        type: e.objExtra.type || '',
                        number: '',
                        countStock: countStock,
                        productId: e.productId || '',
                        subGroup: e.subGroup || '',
                        saleComission: e.objExtra.saleComission || 0,
                        barcode: e.objExtra.barcode || e.sku,
                        stock: e.stock || 0,
                        sstock: e.sstock || 0,
                        cost: e.cost || 0,
                        isFav: e.objExtra.isFav || false,
                        roq: roq
                    }
                    return items
                }
                return false
            },
            closeODF(){
                this.dialogSaleUnit = false
                this.dialogPartner = false
                this.dialogOrderType = false
                this.dialogLoyalty = false
                this.dialogCountGuest = false
                this.pinActivate = false
                this.startFlowAt = 0
                this.isProcessOrder = false
            },
            backODF(){
                this.startFlowAt = parseInt(this.startFlowAt) - 1
                this.checkOrderShow(this.g.orderFlow[this.startFlowAt -1].name)
            },
            // sale report
            cashierTmpl(dataItem) {
                const item = dataItem.pinUser;
                if (item) {
                    let u = item.name || ''
                    return `<span>${u}</span>`;
                } else {
                    return ``;
                }
            },
            searchReport(key){
                setTimeout(()=>{
                    $('#tabProduct').click()
                }, 1000)
                if(!navigator.onLine){
                    this.$snotify.error(i18n.t('please_connect_internet'))
                    return
                }
                let ses = localStorage.getItem(instituteId + 'session')
                if(ses == null || ses == 'null'){
                    this.$snotify.error(i18n.t('you_do_not_have_session'))
                    return
                }
                if(ses.name == 'NoName'){
                    this.$snotify.error(i18n.t('you_do_not_have_session'))
                    this.showLoading = false
                    return
                }
                this.showLoading = true
                let d = {
                    epsAtt: {
                        ':sesPk': this.session.pk + '/txns'
                    },
                    index: 'sesPk-searchDate-index',
                    keyCondition: 'sesPk = :sesPk',
                    key: key
                }
                if(this.g.allowRep){
                    let st = new Date(this.repStartDate + ' 00:00:00').getTime().toString();
                    let ed = new Date(this.repEndDate + ' 23:59:59').getTime().toString();
                    d = {
                        epsAtt: {
                            ':regiPk': this.register.pk + '/txns',
                            ':startDate': st,
                            ':endDate': ed,
                        },
                        index: 'regiPk-searchDate-index',
                        keyCondition: 'regiPk = :regiPk and searchDate between :startDate and :endDate',
                        key: key
                    }
                }
                if(Object.keys(key).length == 0){
                    this.sSumary = []
                }
                this.showLoading = true
                commerceHandler.reportGets(d).then(res => {
                    let data = res.data.data.Items
                    this.showLoading = false
                    if(data.length > 0){
                        this.dialogReport = true
                        data.sort(function (a, b) {
                            return parseFloat(new Date(b.issuedDate).getTime()) - parseFloat(new Date(a.issuedDate).getTime())
                        })
                        data.forEach(d => {
                            if(d.type != 'serving' && d.type != 'internal'){
                                d.registerName = this.register.name
                                d.shift = d.shift || 1
                                let ses = d.session || {}
                                d.sessionNum = ses.number || ''
                                this.sSumary.push(d)
                            }
                        })
                        window.console.log(this.sSumary, 'samary report')
                        if(res.data.data.hasOwnProperty('LastEvaluatedKey')){
                            let dkey = res.data.data.LastEvaluatedKey
                            this.searchReport(dkey)
                        }else{
                            let subtotal = 0, dis = 0, total = 0
                            let reportDatas = []
                            this.sSumary.forEach(d=>{
                                total += parseFloat(d.total)
                                subtotal += parseFloat(d.subTotal)
                                dis += parseFloat(d.discount)
                                let cashier = ''
                                if(d.hasOwnProperty('pinUser')){
                                    cashier = d.pinUser.name || ''
                                }
                                let ses = d.session || {}
                                reportDatas.push({
                                    'shift': d.shift || 1,
                                    'number': d.txnNumber || '',
                                    'issuedDate': kendo.toString(new Date(d.issuedDate), `yyyy-MMM-dd hh:mm tt`),
                                    'cashier': cashier,
                                    'subTotal': d.subTotal,
                                    'discount': d.discount,
                                    'session': ses.number || '',
                                    'tax': d.tax,
                                    'total': d.total
                                })
                            })
                            this.repSubTotal = subtotal
                            this.repDiscount = dis
                            this.reportDatas = reportDatas
                            this.repTotal = total //subtotal - dis
                        }
                    }else{
                        this.showLoading = false
                        // this.dialogReport = false
                        this.$snotify.error(i18n.t('no_data_found'))
                    }
                })
            },
            //session
            async addNewSession(){
                if(!navigator.onLine){
                    this.$snotify.error(i18n.t('please_connect_internet'))
                    return
                }
                await this.loadTxnNumber()
                this.showLoading = true
                let d = {
                    regiPk: this.register.pk,
                    sesStatus: 'open',
                    indexName: 'regiPk-sesStatus-index',
                    condition: 'regiPk = :regiPk and sesStatus = :sesStatus',
                    type: 'searchSession'
                }
                await commerceHandler.generalGet(d).then(res=>{
                    // window.console.log(res, 'res from start session')
                    if(res.data.data.Items.length > 0){
                        localStorage.setItem(instituteId + 'session', JSON.stringify(res.data.data.Items[0]))
                    }
                })
                let ses = localStorage.getItem(instituteId + 'session')
                if(ses != null && ses != 'null'){
                    if(Object.keys(JSON.parse(localStorage.getItem(instituteId + 'session'))).length > 0){
                        this.$snotify.error(i18n.t('you_already_has_session'))
                        this.showLoading = false
                        return;
                    }
                }
                let today = new Date()
                await commerceHandler.sesNumGenerate().then(res => {
                    this.slastNumber = parseInt(res.data.data) + 1
                    this.generatNumberS(res.data.data)
                    today = new Date(res.data.total)
                    window.console.log(today, 'today')
                })
                this.showLoading = true
                // let currentdate = new Date()
                // let datetime = currentdate.toLocaleString("km-KH", {timeZone: "Asia/Phnom_Penh"})
                setTimeout(()=>{
                    this.addsession = {
                        number: this.sessionnumber,
                        startDate: new Date(today).toISOString().substr(0, 10),
                        endDate: '',
                        status: 'open',
                        openAmount: 0,
                        amtKHR: 0,
                        amtUSD: 0,
                        register: this.register.pk,
                        regiPk: this.register.pk,
                        sesStatus: 'open',
                        openBy: '',
                        closeBy: '',
                        searchNum: '',
                        searchName: 'SES#NONE',
                        searchDate: '',
                        lastNumber: this.slastNumber,
                        isEnd: 0,
                        // update 
                        totalSale: 0,
                        totalCash: 0,
                        totalDis: 0,
                        totalCredit: 0,
                        totalBankWallet: 0,
                        totalCard: 0,
                        loyalCreditCard: 0,
                        khqr: 0,
                        pointPay: 0,
                        preOrder: 0,
                        otherCharge: 0,
                        modiAmount: 0,
                        //loyalty
                        topUpCounter: 0,
                        topUpGCard: 0,
                        saleGiftCard: 0,
                        saleVoucher: 0,
                        saleLCard: 0,
                        saleLCardAmount: 0
                    }
                    window.console.log(this.addsession, 'add session')
                    this.showStartSession = true
                    this.showLoading = false
                }, 500)
            },
            async saveAddSession(){
                this.showStartSession = false
                this.showLoading = true
                let isdate =  new Date(this.s.startDate).getTime().toString()
                this.addsession.startDate = new Date().getTime()
                this.addsession.searchDate = isdate
                this.addsession.shifts = []
                this.addsession.store = this.activeStore.pk
                this.addsession.user = this.activePin
                this.addsession.userAgent = navigator.userAgent
                this.addsession.guest = 0
                this.addsession.txns = 0
                this.addsession.openBy = this.activePin.name
                this.addsession.isReset = 0
                commerceHandler.sessionCreate(this.addsession, '').then(res => {
                    this.showLoading = false
                    this.$snotify.success(i18n.t('successful'))
                    localStorage.setItem(instituteId + 'session', JSON.stringify(res.data.data))
                    this.session = res.data.data
                    window.location.reload()
                })
            },
            loadSesNumber(){
                this.showLoading = true
                commerceHandler.sesNumGenerate().then(res => {
                    this.showLoading = false
                    this.slastNumber = parseInt(res.data.data) + 1
                    this.generatNumberS(res.data.data)
                })
            },
            generatNumberS(num){
                this.sessionnumber = 'SES' + this.pad(num, 6)
            },
            pad (str, max) {
                str = str.toString();
                return str.length < max ? this.pad("0" + str, max) : str;
            },
            openSession(){
                // window.console.log(this.g.sessionPin, this.g)
                let sesping = false
                if(this.g.sessionBy == 1 || this.g.sessionBy == true){
                    sesping = true
                }
                if(sesping){
                    this.isStartSession = true
                    this.isLockPin = false
                    this.pin = ''
                    this.showNoBack = false
                    this.pinActivate = true
                }else{
                    this.saveAddSession()
                }
            },
            //parksale
            useParkSale(e){
                e.preventDefault();
                const grid = $("#gridParkSale").data("kendoGrid"),
                    dataItema = grid.dataItem($(e.currentTarget).closest("tr"));
                // window.console.log(dataItem, 'inv line');
                let inv = this.parkSaleTxns.filter((o) => {return o.pk == dataItema.pk})
                let dataItem = inv[0]
                this.activeParksale = dataItem
                this.lineDS = dataItem.itemLines
                this.addFOrderItem(dataItem.itemLines)
                dataItem.itemLines = []
                this.t = new PosTransactionModel(dataItem)
                this.dialogPSale = false
                this.isProcessOrder = true
                this.isUseParkSale = true
            },
            addQRPay(data, qr, type){
                // window.console.log(data, qr, 'add qr')
                const db = getDatabase();
                set(ref(db, 'qrpay/' + this.register.pk), {
                    data: JSON.parse(data),
                    qr: type == 'khqr' ? qr : type == 'clear' ? '' : JSON.parse(qr)
                });
            },
            addFOrderItem(itm){
                let d = []
                let lnoid = []
                itm.forEach(l=>{
                    lnoid.push({
                        description: l.description,
                        qty: l.qty,
                        discount: l.discount,
                        amount: l.amount,
                    })
                })
                d.push({
                    value: JSON.stringify(lnoid)
                })
                let r = {
                    secondRate: this.secondRate,
                    baseRate: this.baseRate
                }
                d = JSON.stringify(d)
                r = JSON.stringify(r)
                this.writeUserData(JSON.parse(d), JSON.parse(r))
            },
            writeUserData(data, info) {
                const db = getDatabase();
                set(ref(db, 'inst/' + this.register.pk), {
                    data: data,
                    info: info
                });
            },
            psRowNumber(dataItem) {
                let ds = this.$refs.gridParkSale.kendoWidget(),
                    index = ds.indexOf(dataItem);
                return index + 1;
            },
            showPSaleTxn(){
                let ps = []
                this.parkSales = []
                this.parkSaleTxns.forEach(p=>{
                    ps.push({
                        pk: p.pk,
                        total: p.total,
                        issuedDate: p.issuedDate,
                        totalItem: p.itemLines.length
                    })
                })
                this.parkSales = ps
                this.dialogPSale = true
            },
            parkSale(){
                if(this.lineDS.length == 0){
                    this.$snotify.error(i18n.t('item_required'))
                    this.pointCardPay = false
                    return
                }
                this.t.itemLines = this.lineDS
                this.t.pk = 'txn-' + uuid.v1()
                this.parkSaleTxns.push(this.t)
                localStorage.setItem(instituteId + 'parkSale', JSON.stringify(this.parkSaleTxns))
                this.$snotify.success(i18n.t('succeeful'))
                this.resetOrder()
            },
            closeSearchCard(){
                this.isSearchCard = false
                this.dialogLoyalSearch = false
                this.dialogLoyalty = false
                this.dialogSCard = false
            },
            searchLCard(){
                this.pointCardPay = false
                this.isSearchCard = true
                this.isTopUp = false
                this.isPointPayCounter = false
                // window.console.log(this.g.searchCard)
                if(this.g.searchCard.length <= 0){
                    this.$snotify.success(i18n.t('no_search_card_function'))
                }else if(this.g.searchCard.length == 1){
                    let s = this.g.searchCard[0]
                    if(s == 1){
                        this.lsearchBy('card')
                    }else if(s == 2){
                        this.lsearchBy('phone')
                    }else if(s == 3){
                        this.lsearchBy('nfc')
                    }else if(s == 4){
                        this.lsearchBy('ordernumber')
                    }
                }else{
                    this.dialogLoyalSearch = true
                }
            },
            switchLocale(locale) {
                if (this.$i18n.locale !== locale) {
                    const to = this.$router.resolve({params: {locale}});
                    return Trans.changeLocale(locale).then(() => {
                        this.$router.push(to.location);
                    });
                }
            },
            // close pay only
            closePayOnly(){
                this.dialogPOnly = false
                this.isSaleCard = false
                this.isSaleVoucher = false
                this.isSaleGCard = false
            },
            // check point qr pay
            checkPointPay(func){
                if(func == 'qr'){
                    this.showLoading = true
                    loyaltyHandler.checkPointPay(this.qrObj.pk).then(res=>{
                        this.showLoading = false
                        if(res.data.data.status){
                            let txn = res.data.data.txn
                            let d = txn.loyaltyCard
                            this.activeLoyaltyCard = d
                            this.lineDS = txn.itemLines
                            this.t = txn
                            this.topUpCard = {}
                            this.t.pointEarn = {}
                            this.isPointQRPay = true
                            this.savePointPay()
                            window.console.log(this.t, 'on qr page')
                            // this.saveTxn()
                        }else{
                            this.$snotify.success(i18n.t('no_data_found'))
                        }
                    })
                }
            },
            // top up
            closeTopUp(){
                this.dialogSelectTopup = false
                this.isTopUpCounter = false
                this.isTopUpGCard = false
            },
            // gift card
            searchTGCard(){
                // window.console.log(this.giftCard, 'search topup gift card')
                let searchData = {
                    type: 'topupg',
                    searchNum: 'giftcard#' + this.giftCard.number,
                    serial: this.giftCard.serial
                }
                this.showLoading = true
                loyaltyHandler.searchCard(searchData).then((res) => {
                    this.showLoading = false
                    let d = res.data.data 
                    if(d.length > 0){
                        let data = d[0]
                        this.activeSellGCard = data
                        this.topUpAmount = parseFloat(data.amount)
                        this.savePoint('','','top_up_g', 1)
                        this.updateGiftCard(data.pk, 'activated', 'giftcard', '', this.topUpCard.cardId)
                        // this.$snotify.success(i18n.t('successful'))
                        // this.resetOrder()
                        this.pointCardPay = false
                        this.isSearchCard = false
                        this.isPointPayCounter = false
                        this.isTopUp = true
                        // this.savePoint('','','top_up')
                        let uomId = uuid.v1()
                        let u = [
                            {
                                id: uomId,
                                name: 'Unit',
                                conversionRate: 1,
                                toUOM:{
                                    name: 'Unit',
                                    id: uomId
                                }
                            }
                        ]
                        let pk = 'loyal-topup-' + uuid.v1()
                        let line = {
                            id: uuid.v1(),
                            name: 'Top up card',
                            otherName: '',
                            number: '',
                            description: 'Top up card ' + this.topUpCard.cardNum,
                            saleDes: 'Top up card ' + this.topUpCard.cardNum,
                            amount: parseFloat(this.topUpAmount),
                            price: parseFloat(this.topUpAmount),
                            qty: 1,
                            uom: u[0],
                            buom: u[0],
                            uoms: u,
                            itemId: pk,
                            discount: 0,
                            tax: 0,
                            categoryId: '',
                            groupId: '',
                            modifierAmt: 0,
                            modifiers: [],
                            employees: [],
                            campaign: [],
                            orderDate: '',
                            stock: 1
                        }
                        this.activeLine = line
                        this.lineDS.push(line)
                        this.calculateTotal()
                        this.t.pinUser = this.activePin
                        let tcard = this.topUpCard
                        this.t.loyaltyCard = {
                            id: tcard.pk,
                            number: tcard.cardNumber,
                            name: tcard.activateName,
                            phone: tcard.activatePhone,
                            giftCardNumber: this.giftCard.number
                        }
                        this.isTopUpGCard = true
                        this.t.paidBy = 'None'
                        this.t.type = 'topup'
                        this.saveTxn()
                    }else{
                        this.giftCard = {
                            number: '',
                            serial: ''
                        }
                        this.$snotify.error(i18n.t('no_data_found'))
                    }
                })
                
            },
            searchGCard(){
                let searchData = {
                    type: 'cardnumber',
                    searchNum: 'giftcard#' + this.gcnumber
                }
                this.showLoading = true
                loyaltyHandler.searchCard(searchData).then((res) => {
                    this.showLoading = false
                    let d = res.data.data 
                    if(d.length > 0){
                        let data = d[0]
                        if(data.status == 'sold'){
                            this.gcnumber = ''
                            this.$snotify.error(i18n.t('card_has_been_sold'))
                        }else if(data.status == 'activated'){
                            this.gcnumber = ''
                            this.$snotify.error(i18n.t('card_has_been_used'))
                        }else{
                            if(parseFloat(data.price) > 0){
                            this.setSellGCard(data)
                            }
                        }
                    }else{
                        this.gcnumber = ''
                        this.$snotify.error(i18n.t('no_data_found'))
                    }
                })
            },
            async setSellGCard(data){
                this.activeSellGCard = data
                this.isSaleGCard = true
                let uomId = uuid.v1()
                let u = [
                    {
                        id: uomId,
                        name: 'Unit',
                        conversionRate: 1,
                        toUOM:{
                            name: 'Unit',
                            id: uomId
                        }
                    }
                ]
                let pk = data.pk
                let des = 'Sale Gift Card ' + data.number
                let line = {
                    id: uuid.v1(),
                    name: 'Sale Gift Card',
                    otherName: '',
                    number: '',
                    description: des,
                    saleDes: des,
                    amount: parseFloat(data.price),
                    price: parseFloat(data.price),
                    qty: 1,
                    uom: u[0],
                    buom: u[0],
                    uoms: u,
                    itemId: pk,
                    discount: 0,
                    tax: 0,
                    categoryId: '',
                    groupId: '',
                    modifierAmt: 0,
                    modifiers: [],
                    employees: [],
                    campaign: [],
                    orderDate: '',
                    stock: 1
                }
                this.activeLine = line
                this.lineDS.push(line)
                await this.loadTxnNumber()
                this.calculateTotal()
                this.t.pinUser = this.activePin
                this.calculateTotal()
                this.dialogPOnly = true
            },
            showGCardSForm(){
                this.dialogSearchGCard = false
                this.gcnumber = ''
                this.giftCard = {
                    number: '',
                    serial: ''
                }
            },
            updateGiftCard(id, status, type, txnId, topUpId){
                let d = {
                    cardId: id,
                    status: status,
                    txnId: txnId,
                    topUpId: topUpId,
                    session: this.session,
                    amount: this.topUpAmount,
                    type: type
                }
                this.showLoading = true
                loyaltyHandler.updateCard(d).then(() => {
                this.showLoading = false
                })
            },
            // close session
            closePrintSession(){
                this.dialogPrintSession = false
                localStorage.setItem(instituteId + 'session', null)
                window.location.reload()
            },
            //
            closePrintBill(){
                this.dialogPrint = false
                this.resetOrder()
            },
            // log out
            logOut(){
                delete_cookie("banhji-counter-token" + process.env.VUE_APP_MODE)
                var myItem = localStorage.getItem('banhjicounter-remember-user');
                localStorage.clear();
                localStorage.setItem('banhjicounter-remember-user',myItem);
                window.indexedDB.deleteDatabase(instituteId)
                setTimeout(()=>{ window.location.reload() }, 500)
            },
            // search key
            kpinClick(num){
                this.keyincorrect = false
                if(num == 'clear'){
                    this.sessionKey = ''
                }else{
                    this.sessionKey = this.sessionKey + num.toString()
                    if(this.sessionKey.length == 8){
                        this.searchKPin()
                    }
                }
            },
            async searchKPin(){
                let d = {
                    sesKey: this.sessionKey.toString(),
                    sesStatus: "1"
                }
                this.dialogKey = true
                commerceHandler.keyGet(d).then(res => {
                    let data = res.data.data
                    if(data.Items.length > 0){
                        let d = data.Items[0]
                        localStorage.setItem('banhjipossession', JSON.stringify(d));
                        localStorage.setItem('banhjiposkey', this.sessionKey.toString());
                        let inst = d.ins
                        localStorage.setItem(inst.id + 'commRegister', JSON.stringify(d.registerObj));
                        localStorage.setItem(inst.id + 'session', JSON.stringify(d));
                        d.store.username = ''
                        d.store.secretCode = ''
                        localStorage.setItem(inst.id + 'commStore', JSON.stringify(d.store));
                        this.loadTopUpRate()
                        dataStore.institute = {
                            instituteId: inst.id,
                            creator: d.user.name,
                            user: d.user,
                            name: d.user.name,
                            email: d.user.name,
                            plan: 14,
                            accessToken: ''
                        }
                        d.id = d.pk
                        d.sesStatus = "2"
                        d.userAgent = navigator.userAgent
                        commerceHandler.sessionCreate(d, inst.id).then(()=>{
                            setTimeout(()=>{
                                window.location.reload()
                            }, 500)
                            
                        })
                    }else{
                        this.$snotify.error(i18n.t('wrong_code'))
                    }
                    this.showLoading = false
                })
            },
            // void txn
            voidInv(e){
                e.preventDefault();
                const grid = $("#saleSummary").data("kendoGrid")
                const dataItem = grid.dataItem($(e.currentTarget).closest("tr"))
                window.console.log(dataItem, 'reprint');
                this.activeInvoice = dataItem
                this.isInvoice = true
                this.voidTxn()
            },
            async voidTxn(){
                if(this.isInvoice){
                    let inv = this.activeInvoice
                    window.console.log(this.isInvoice, this.activeInvoice, 'voic invoice')
                    let d = {
                        sk: inv.pk,
                        pk: 'txnline-',
                        key: {}
                    }
                    let itmLine = []
                    this.showLoading = true
                    await commerceHandler.genGets(d).then(res => {
                        this.showLoading = false
                        if(res.data.data.Items.length > 0){
                            let l = []
                            res.data.data.Items.forEach(itm=>{
                                l.push(itm)
                            })
                            itmLine = l
                        }
                    })
                    this.activeInvoice.itemLines = itmLine
                    if(this.g.telegramGroup != ''){
                        let data = {
                            groupId: this.g.telegramGroup,
                            pk: inv.pk,
                            sk: this.activeStore.pk,
                            type: 'deletetxn',
                            store: this.activeStore.name,
                            storePhone: this.activeStore.phone,
                            amount: parseFloat(inv.total),
                            orderNum: inv.orderNumber,
                            username: this.activePin.name,
                        }
                        this.delId = inv.pk
                        this.showLoading = true
                        telegramBotHandler.posPost(data).then(()=>{
                            this.showLoading = false
                            this.delTxn = true
                            this.verifyCode = ''
                            this.dialogCode = true
                        })
                    }
                }else{
                    if(this.isEditServing && this.g.telegramGroup != ''){
                        let data = {
                            groupId: this.g.telegramGroup,
                            pk: this.t.id,
                            sk: this.activeStore.pk,
                            type: 'deletetxn',
                            store: this.activeStore.name,
                            storePhone: this.activeStore.phone,
                            amount: parseFloat(this.t.total),
                            orderNum: this.t.orderNumber,
                            username: this.activePin.name,
                        }
                        this.delId = this.t.id
                        this.showLoading = true
                        telegramBotHandler.posPost(data).then(()=>{
                            this.showLoading = false
                            this.delTxn = true
                            this.verifyCode = ''
                            this.dialogCode = true
                        })
                    }
                }
            },
            async saveEntity(items, type){
                if(items.length > 0){
                    let d = {
                        data: items,
                        type: type
                    }
                    this.showLoading = true
                    await commerceHandler.updateDb(d)
                    this.showLoading = false
                }
            },
            // verify code
            async enterVerify(){
                if(this.delLine){
                    let d = {
                        delId: this.delId,
                        code: this.verifyCode,
                        type: 'checkcode'
                    }
                    this.showLoading = true
                    commerceHandler.checkDel(d).then(res => {
                        this.showLoading = false
                        if(res.data.data){
                            this.dialogCode = false
                            this.dialogLoyalSearch = false
                            this.dialogLoyalty =  false
                            let e = this.activeLine
                            const index = this.lineDS.indexOf(e);
                            this.lineDS.splice(index,1);
                            this.calculateTotal()
                            this.$snotify.success(i18n.t('success'))
                            this.addFOrderItem(this.lineDS)
                        }else{
                            this.$snotify.error(i18n.t('wrong_code'))
                            this.verifyCode = ''
                        }
                    })
                }else if(this.delTxn){
                    let d = {
                        delId: this.delId,
                        code: this.verifyCode,
                        type: 'checkcode'
                    }
                    this.showLoading = true
                    commerceHandler.checkDel(d).then(res => {
                        this.showLoading = false
                        if(res.data.data){
                            if(this.isInvoice){
                                this.activeInvoice.pinUser = this.activePin
                                this.activeInvoice.txnNumber = this.activeInvoice.txnNumber + '(Void)'
                                this.activeInvoice.voidAmount = this.activeInvoice.total
                                this.activeInvoice.total = 0;
                                let items = []
                                let stockItems = []
                                this.activeInvoice.itemLines.forEach(i=>{
                                    items.push({
                                        ...i,
                                        amount: 0,
                                        total: 0,
                                        txnNumber: this.activeInvoice.txnNumber
                                    })
                                    if(i.countStock){
                                        stockItems.push({
                                            itemId: i.itemId,
                                            cost: i.cost,
                                            qty: i.qty
                                        })
                                    }
                                })
                                items.push({
                                    ...this.activeInvoice,
                                    itemLines: []
                                })
                                if(stockItems.length > 0){
                                    let stock = {
                                        items: stockItems,
                                        store: this.activeStore.pk,
                                        warehouseId: this.activeInvoice.warehouseId,
                                        type: 'voidreturnstock'
                                    }
                                    window.console.log(stock, 'void stock')
                                    commerceHandler.stockPost(stock)
                                }
                                window.console.log(items, 'void items')
                                items.forEach(itm=>{
                                    delete itm.__ob__
                                })
                                this.saveEntity(items, '')
                                this.showLoading  = false
                                this.dialogCode = false
                                this.dialogLoyalSearch = false
                                this.dialogLoyalty =  false
                                this.$snotify.success(i18n.t('success'))
                                this.showLoading = true 
                                setTimeout(()=>{
                                    this.showLoading = false
                                    this.searchReport({})
                                }, 1000)
                            }else{
                                this.deleteTxn()
                            }
                        }else{
                            this.$snotify.error(i18n.t('wrong_code'))
                            this.verifyCode = ''
                        }
                    })
                }else if(this.isCamVerifyCode || this.camAdminConfirmCode){
                    let d = {
                        code: this.verifyCode,
                        cardId: this.activeLoyaltyCard.pk,
                        type: 'campaign'
                    }
                    if(this.camAdminConfirmCode){
                        d.cardId = this.camAdminPk
                    }
                    commerceHandler.checkDel(d).then(res => {
                        this.showLoading = false
                        if(res.data.data){
                            this.dialogCode = false
                            this.dialogLoyalSearch = false
                            this.dialogLoyalty =  false
                            // this.applyPromotionA(this.camVerify)
                            // this.calculateTotal()
                            const cam = this.camVerify
                            let alertAdmin = cam.isAlertAdminGroup || false
                            if(alertAdmin){
                                this.alertAdmins.push({
                                    name: cam.name,
                                    code: cam.code
                                })
                                // window.console.log(this.alertAdmins, 'alert admin')
                            }
                            this.lineDS.forEach(d=>{
                                //check overise
                                if(cam.isOverwrite){
                                    d.discount = 0
                                    // d.amount = (d.price * d.qty) + d.modifierAmt + d.tax
                                    d.amount = (d.price * d.qty)
                                }
                                // check reward
                                let dis = 0
                                window.console.log(cam, 'campaign')
                                if(cam.rewardBase == 'amountBase'){
                                    if(cam.rewardType == 'fixed'){
                                        if(cam.rewardAmountType == 'percentage'){
                                            dis = (d.amount * parseFloat(cam.rewardAmount)) / 100
                                        }else{
                                            dis = parseFloat(cam.rewardAmount)
                                        }
                                    }else if(cam.rewardType == 'varian'){
                                        let totalcom = 0
                                        this.lineDS.forEach(lcom=>{
                                            totalcom += (lcom.price * lcom.qty) + lcom.modifierAmt
                                        })
                                        for (let i = 0; i < cam.rewardVarian.length; i++) {
                                            let va = cam.rewardVarian[i]
                                            // window.console.log(va, totalcom, 'va')
                                            if(va.conditionType == '>'){
                                                if(totalcom > va.conditionAmount){
                                                    // window.console.log(totalcom, va.conditionAmount, va.rewardAmount, 'in condition')
                                                    if(va.rewardType == 'percentage'){
                                                        dis = (d.amount * parseFloat(va.rewardAmount)) / 100
                                                    }else{
                                                        dis = parseFloat(va.rewardAmount)
                                                    }
                                                    break;
                                                } 
                                            }else{
                                                if(totalcom >= va.conditionAmount){
                                                    // window.console.log(totalcom, va.conditionAmount, va.rewardAmount, 'in condition')
                                                    if(va.rewardType == 'percentage'){
                                                        dis = (d.amount * parseFloat(va.rewardAmount)) / 100
                                                    }else{
                                                        dis = parseFloat(va.rewardAmount)
                                                    }
                                                    break;
                                                } 
                                            }
                                            
                                        }
                                    }
                                }else{
                                    //production bae
                                    window.console.log(cam.rewardBase)
                                }
                                if(dis > 0){
                                    d.discount = dis
                                    d.amount -= dis
                                    if(d.amount < 0){
                                        d.discount = (d.price * d.qty) + d.modifierAmt + d.tax
                                        d.amount = 0
                                    }
                                    // this.$snotify.success('Campaign ' + cam.name + ' applyed!')
                                    d.campaign = d.campaign || []
                                    d.campaign.push({
                                        id: cam.pk,
                                        name: cam.name,
                                        code: cam.code,
                                        amount: d.discount
                                    })
                                }
                            })
                            this.calculateTotal()
                            this.isCamVerifyCode = false
                            this.camAdminConfirmCode = false
                            if(this.dialogCamps){
                                this.dialogCamps = false
                            }
                            this.$snotify.success(i18n.t('success'))
                        }else{
                            this.$snotify.error(i18n.t('wrong_code'))
                            this.verifyCode = ''
                        }
                    })
                }else if(this.isStoreCredit){
                    let d = {
                        pk: this.requestId,
                        code: this.verifyCode,
                        type: 'storecredit'
                    }
                    this.showLoading = true
                    commerceHandler.checkDel(d).then(res => {
                        this.showLoading = false
                        if(res.data.data){
                            this.dialogCode = false
                            this.dialogLoyalSearch = false
                            this.dialogLoyalty =  false
                            this.t.isCredit = true
                            this.t.isSale = 0
                            this.calculateTotal()
                            this.t.paidBy = 'Credit Card'
                            this.saveTxn()
                        }else{
                            this.$snotify.error(i18n.t('wrong_code'))
                            this.verifyCode = ''
                        }
                    })
                }else if(this.isInternalTxn){
                    let d = {
                        delId: this.internalPk,
                        code: this.verifyCode,
                        type: 'internalcode'
                    }
                    this.showLoading = true
                    commerceHandler.checkDel(d).then(res => {
                        this.showLoading = false
                        if(res.data.data){
                            this.dialogCode = false
                            this.internalPk = ''
                            this.isInternalTxn = false
                            this.saveInternal()
                        }else{
                            this.$snotify.error(i18n.t('wrong_code'))
                            this.verifyCode = ''
                        }
                    })
                }else{
                    let data = {
                        cardId: this.cardCode.cardId,
                        code: this.verifyCode,
                        type: 'checkcode'
                    }
                    this.showLoading = true
                    loyaltyHandler.verifyCode(data).then(res=>{
                        this.showLoading = false
                        // window.console.log(res, 'res verify code')
                        if(res.data.data){
                            this.dialogCode = false
                            this.dialogLoyalSearch = false
                            this.dialogLoyalty =  false
                            this.saveTxn()
                        }else{
                            this.$snotify.error(i18n.t('wrong_code'))
                            this.verifyCode = ''
                        }
                    })
                }
            },
            deleteTxn(){
                this.showLoading = true
                let d = {
                    type: 'delete',
                    txnId: this.delId
                }
                commerceHandler.checkDel(d).then(()=>{
                    this.showLoading  = false
                    this.dialogCode = false
                    this.dialogLoyalSearch = false
                    this.dialogLoyalty =  false
                    const sdstatus = this.g.saleUnitStatus || 1
                    window.console.log(this.activeSaleUnit, 'delete txn')
                    this.updateSaleUnit(this.activeSaleUnit.id, sdstatus)
                    this.delIndxdb(this.delId, 'serving', 'serving')
                    
                    this.$snotify.success(i18n.t('success'))
                    this.resetOrder()
                })
            },
            // 
            availableSU(s){
                this.updateSaleUnit(s.id, 1)
            },
            // point top up
            async saveTopUp(){
                //check shift
                let ses = localStorage.getItem(instituteId + 'session')
                if(ses != null && ses != 'null'){
                    if(this.session.shifts.length >= parseInt(this.register.shifts)){
                        this.$snotify.error(i18n.t('end_session_require'))
                        this.showLoading = false
                        return
                    }
                }else{
                    this.$snotify.error(i18n.t('require_session'))
                    this.showLoading = false
                    return
                }
                if(parseFloat(this.topUpAmount) > 0){
                    this.pointCardPay = false
                    this.isSearchCard = false
                    this.isPointPayCounter = false
                    this.isTopUp = true
                    let uomId = uuid.v1()
                    let u = [
                        {
                            id: uomId,
                            name: 'Unit',
                            conversionRate: 1,
                            toUOM:{
                                name: 'Unit',
                                id: uomId
                            }
                        }
                    ]
                    let pk = 'loyal-topup-' + uuid.v1()
                    let line = {
                        id: uuid.v1(),
                        name: 'Top up card',
                        otherName: '',
                        number: '',
                        description: 'Top up card ' + this.topUpCard.cardNum,
                        saleDes: 'Top up card ' + this.topUpCard.cardNum,
                        amount: parseFloat(this.topUpAmount),
                        price: parseFloat(this.topUpAmount),
                        qty: 1,
                        uom: u[0],
                        buom: u[0],
                        uoms: u,
                        itemId: pk,
                        discount: 0,
                        tax: 0,
                        categoryId: '',
                        groupId: '',
                        modifierAmt: 0,
                        modifiers: [],
                        employees: [],
                        campaign: [],
                        orderDate: '',
                        stock: 1
                    }
                    this.activeLine = line
                    this.lineDS.push(line)
                    await this.loadTxnNumber()
                    this.calculateTotal()
                    this.t.loyaltyCard = {
                        id: this.activeLoyaltyCard.pk,
                        number: this.activeLoyaltyCard.cardNumber,
                        name: this.activeLoyaltyCard.activateName,
                        phone: this.activeLoyaltyCard.activatePhone
                    }
                    this.t.pinUser = this.activePin
                    // this.calculateTotal()
                    this.dialogPOnly = true
                }
            },
            showPointTopUp(){
                this.pointCardPay = false
                this.isSearchCard = false
                this.isPointPayCounter = false
                this.isTopUp = true
                this.topUpAmount = 10
                // this.dialogLoyalSearch = true
                if(this.g.searchCard.length <= 0){
                    this.$snotify.success(i18n.t('no_search_card_function'))
                }else if(this.g.searchCard.length == 1){
                    let s = this.g.searchCard[0]
                    if(s == 1){
                        this.lsearchBy('card')
                    }else if(s == 2){
                        this.lsearchBy('phone')
                    }else if(s == 3){
                        this.lsearchBy('nfc')
                    }else if(s == 4){
                        this.lsearchBy('ordernumber')
                    }
                }else{
                    this.dialogLoyalSearch = true
                }
            },
            // bank paid
            bankPaid(bank){
                this.activebankpay = bank
                this.showAmountKeyPad = true
            },
            // buy loyalty card
            searchDublicateCard(){
                window.console.log(this.registerCard, 'card')
                if(this.registerCard.cardNumber != '' && this.createCardFunc == 'number'){
                    let searchData = {
                        type: 'number',
                        searchNum: this.registerCard.cardNumber
                    }
                    this.showLoading = true
                    loyaltyHandler.searchCard(searchData).then((res) => {
                        this.showLoading = false
                        let d = res.data.data
                        if(d.length > 0){
                            this.$snotify.error(i18n.t('card_number_exist'))
                        }
                    })
                }
            },
            enterQTY(){
                if(this.qtyAmount > 0 && this.qtyAmount != '' && this.isCreateCard){
                    this.saveLCard('', this.qtyAmount)
                }
            },
            showLCardSearch(){
                if(this.g.createCard.length > 1){
                    this.dialogLcardBy = true
                }else{
                    let c = this.g.createCard[0]
                    let func = ''
                    if(c == 1){
                        func = 'phone'
                    }else if(c == 2){
                        func = 'randomnumber'
                    }else if(c == 3){
                        func = 'number'
                    }
                    if(func != ''){
                        this.createCardBy(func)
                    }else{
                        this.$snotify.error(i18n.t('this_is_no_create_card_function'))
                    }
                }
            },
            async createCardBy(func){
                this.showLoading = true
                this.registerCard = new CardModel({})
                await loyaltyHandler.cardOrderNumGet().then((res)=>{
                    window.console.log(res, 'res')
                    this.registerCard.orderNumber = res.data.data || 1
                    window.console.log(this.registerCard.orderNumber, 'res')
                })
                this.showLoading = false
                this.isCreateCard = true
                this.createCardFunc = func
                this.registerCard.status = 'open'
                this.registerCard.group = this.cardGroups[0]
                this.registerCard.type = 'virtual'
                this.registerCard.register = this.register.pk
                this.registerCard.store = this.activeStore.pk
                if(func == 'randomnumber'){
                    this.registerCard.cardNumber = Math.floor((Math.random() * 999999) + 1)
                }else if(func == 'number'){
                    this.registerCard.type = 'physical'
                }
                this.dailogRCard = true
            },
            rCardPhoneChange(){
                let searchData = {
                    type: 'phone',
                    searchNum: this.registerCard.activatePhone
                }
                this.showLoading = true
                loyaltyHandler.searchCard(searchData).then((res) => {
                    this.showLoading = false
                    let d = res.data.data 
                    if(d.length > 0){
                        this.$snotify.error(i18n.t('phone_number_already_register'))
                        this.registerCard.activatePhone = ''
                        if(this.createCardFunc == 'phone'){
                            this.registerCard.cardNumber = ''
                        }
                    }else{
                        if(this.createCardFunc == 'phone'){
                            this.registerCard.cardNumber = this.registerCard.activatePhone
                        }
                    }
                })
            },
            async saveRCard() {
                //check shift
                let ses = localStorage.getItem(instituteId + 'session')
                if(ses != null && ses != 'null'){
                    if(this.session.shifts.length >= parseInt(this.register.shifts)){
                        this.$snotify.error(i18n.t('end_session_require'))
                        this.showLoading = false
                        return
                    }
                }else{
                    this.$snotify.error(i18n.t('require_session'))
                    this.showLoading = false
                    return
                }
                // check empty field
                if(this.registerCard.cardNumber == ''){
                    this.$swal({
                        title: i18n.t('card_number_required'),
                        text: i18n.t('check_card_number'),
                        icon: "warning",
                        showCancelButton: false,
                        confirmButtonColor: "#4d4848",
                        cancelButtonColor: "#eb0238",
                        confirmButtonText: i18n.t('ok'),
                    })
                    return
                }
                // check empty field
                if(this.registerCard.activateName == ''){
                    this.$swal({
                        title: i18n.t('name_required'),
                        text: i18n.t('check_card_name'),
                        icon: "warning",
                        showCancelButton: false,
                        confirmButtonColor: "#4d4848",
                        cancelButtonColor: "#eb0238",
                        confirmButtonText: i18n.t('ok'),
                    })
                    return
                }
                // check empty field
                if(this.registerCard.activatePhone == ''){
                    this.$swal({
                        title: i18n.t('phone_required'),
                        text: i18n.t('check_phone_number'),
                        icon: "warning",
                        showCancelButton: false,
                        confirmButtonColor: "#4d4848",
                        cancelButtonColor: "#eb0238",
                        confirmButtonText: i18n.t('ok'),
                    })
                    return
                }
                if(this.registerCard.cardNumber == ''){
                    this.$swal({
                        title: i18n.t('card_number_required'),
                        text: i18n.t('check_card_number'),
                        icon: "warning",
                        showCancelButton: false,
                        confirmButtonColor: "#4d4848",
                        cancelButtonColor: "#eb0238",
                        confirmButtonText: i18n.t('ok'),
                    })
                    return
                }
                let g = this.registerCard.group
                if(parseFloat(g.price) > 0){
                    let uomId = uuid.v1()
                    let u = [
                        {
                            id: uomId,
                            name: 'Unit',
                            conversionRate: 1,
                            toUOM:{
                                name: 'Unit',
                                id: uomId
                            }
                        }
                    ]
                    let pk = 'loyal-lcard-' + uuid.v1()
                    let line = {
                        id: uuid.v1(),
                        name: 'Buy loyalty card',
                        otherName: 'Buy loyalty card',
                        number: '',
                        description: 'Buy loyalty card',
                        saleDes: 'Buy loyalty card',
                        amount: parseFloat(g.price),
                        price: parseFloat(g.price),
                        qty: 1,
                        uom: u[0],
                        buom: u[0],
                        uoms: u,
                        itemId: pk,
                        discount: 0,
                        tax: 0,
                        categoryId: '',
                        groupId: '',
                        modifierAmt: 0,
                        modifiers: [],
                        employees: [],
                        campaign: [],
                        orderDate: '',
                        stock: 1
                    }
                    this.activeLine = line
                    this.lineDS.push(line)
                    await this.loadTxnNumber()
                    this.calculateTotal()
                    this.t.pinUser = this.activePin
                    this.isRegisterCard = true
                    this.saleLCard = true
                    this.isSaleCard = true
                    this.calculateTotal()
                    this.dialogPOnly = true
                }else{
                    this.savePrintCard()
                }
            },
            async savePrintCard(){
                this.registerCard.user = this.loggedUser
                this.registerCard.serial = Math.floor((Math.random() * 9999) + 1)
                if(this.registerCard.group.hasOwnProperty('startPoint')){
                    if(parseFloat(this.registerCard.totalPoint) <= 0){
                        this.registerCard.totalPoint = parseFloat(this.registerCard.group.startPoint)
                    }
                    if(this.registerCard.earnPoint <= 0){
                        this.registerCard.earnPoint = parseFloat(this.registerCard.group.pointEarn)
                    }
                }
                this.registerCard.totalPoint = parseFloat(this.registerCard.totalPoint)
                this.registerCard.earnPoint = parseFloat(this.registerCard.earnPoint)
                this.showLoading = true
                this.registerCard.isSaleCard = true
                this.registerCard.session = {
                    pk: this.session.pk != '' ? this.session.pk : this.session.id
                }
                // if(this.registerCard.isEditImg){
                //     const fileName = "regi_card_" + new Date().getTime() + ".png"
                //     const result = await S3upload.saveToS3(this.registerCard.profile, fileName)
                //     window.console.log("Data-", result);
                //     this.registerCard.profileUrl = result.Location
                //     this.registerCard.profile = ''
                // }
                window.console.log(this.registerCard, 'register card')
                loyaltyHandler.loyaltyCardCreate(new CardModel(this.registerCard)).then(response => {
                    this.showLoading = false
                    if (response.data.statusCode === 201) {
                        this.showLoading = false
                        this.dailogRCard = false
                        this.dialogLcardBy = false
                        let printObj = this.registerCard
                        printObj.pinUser = this.activePin
                        printObj.cardPrice = parseFloat(this.registerCard.group.price)
                        printObj.secondRate = this.secondRate
                        printObj.baseRate = this.baseRate
                        printObj.qrvalue = printObj.cardNumber + '_' + printObj.serial
                        this.printObj = printObj
                        this.$emit("onUpdate", printObj);
                        this.dailogPrintCard = true
                        this.$snotify.success(i18n.t('success'))
                    }
                }).catch(e => {
                    this.showLoading = false
                    this.$snotify.error('Something went wrong')
                    window.console.log(e, 'error')
                })
            },
            saveLCard(number, qty){
                let data = {
                    type: 'generate',
                    cardNum: number,
                    phoneNum: this.createCardFunc == 'phone' ? number : '',
                    user: this.activePin,
                    store: this.activeStore,
                    qty: qty
                }
                this.showLoading = true
                loyaltyHandler.loyaltyCardCreate(data).then(()=>{
                    this.showLoading = false
                })
            },
            // card
            showCard(){
                this.showCatePage = false
                this.showGroupPage = false
                this.showSupGroupPage = false
                this.sCardTap = true
                this.showItem = false
            },
            // pay by point card
            payPointCard(){
                if(this.lineDS.length == 0){
                    this.$snotify.error(i18n.t('item_required'))
                    this.pointCardPay = false
                    return
                }
                this.allowPayPoint = true
                this.isSearchCard = false
                this.isPointPayCounter = false
                this.isTopUp = false
                this.pointCardPay = true
                this.isSale = 0
                this.t.isSale = 0
                this.t.issuedDate = new Date()
                this.t.paymentMethod = 'point'
                this.t.paidBy = 'Point'
                // window.console.log(this.g.pointPayOpt, 'you are point pay')
                if(this.g.pointPayOpt.length <= 0){
                    this.$snotify.error(i18n.t('no_point_option_check_setting'))
                }else if(this.g.pointPayOpt.length == 1){
                    let popt = this.g.pointPayOpt[0]
                    if(popt == 1){
                        this.pointPayOpt('code')
                    }else if(popt == 2){
                        this.pointPayOpt('qr')
                    }else if(popt == 3){
                        this.pointPayOpt('counter')
                    }
                }else{
                    this.dialogPPOption = true
                }
                // this.dialogLoyalSearch = true
            },
            pointPayOpt(func){
                // window.console.log(func, 'you are on point pay option')
                if(func == 'code'){
                    // this.dialogLoyalSearch = true
                    if(this.g.searchCard.length <= 0){
                        this.$snotify.success(i18n.t('no_search_card_function'))
                    }else if(this.g.searchCard.length == 1){
                        let s = this.g.searchCard[0]
                        if(s == 1){
                            this.lsearchBy('card')
                        }else if(s == 2){
                            this.lsearchBy('phone')
                        }else if(s == 3){
                            this.lsearchBy('nfc')
                        }else if(s == 4){
                            this.lsearchBy('ordernumber')
                        }
                    }else{
                        this.dialogLoyalSearch = true
                    }
                }else if(func == 'qr'){
                    this.createQRPointPay()
                }else if(func == 'counter'){
                    // window.console.log('point pay by counter')
                    this.isSearchCard = false
                    this.isPointPayCounter = true
                    if(this.g.searchCard.length <= 0){
                        this.$snotify.success(i18n.t('no_search_card_function'))
                    }else if(this.g.searchCard.length == 1){
                        let s = this.g.searchCard[0]
                        if(s == 1){
                            this.lsearchBy('card')
                        }else if(s == 2){
                            this.lsearchBy('phone')
                        }else if(s == 3){
                            this.lsearchBy('nfc')
                        }else if(s == 4){
                            this.lsearchBy('ordernumber')
                        }
                    }else{
                        this.dialogLoyalSearch = true
                    }
                }
            },
            createQRPointPay(){
                //check shift
                let ses = localStorage.getItem(instituteId + 'session')
                if(ses != null && ses != 'null'){
                    if(this.session.shifts.length >= parseInt(this.register.shifts)){
                        this.$snotify.error(i18n.t('end_session_require'))
                        this.showLoading = false
                        return
                    }
                }else{
                    this.$snotify.error(i18n.t('require_session'))
                    this.showLoading = false
                    return
                }
                window.console.log(this.t, 'ss')
                const a = JSON.parse(JSON.stringify(this.t));
                let d = {
                    ...a,
                    qrtype: 'point',
                    store: this.activeStore.pk
                }
                const finalData = JSON.parse(JSON.stringify(d));
                // window.console.log(finalData, 'data')
                this.showLoading = true
                loyaltyHandler.payPointQr(finalData).then(res=>{
                    this.showLoading = false
                    // window.console.log(res.data, 'res back')
                    let dd = res.data.data
                    let qrObj = {
                        amount: dd.total,
                        qr: dd.pk + '_/' + this.activeStore.name + '_/' + this.activeStore.phone + '_/' + dd.txnNumber,
                        txnNum: dd.txnNumber,
                        pk: dd.pk
                    }
                    this.qrObj = qrObj
                    this.$emit("onUpdate", qrObj);
                    this.dailogPrintPQR = true
                    if(this.g.mirrorDisplay){
                        let d = []
                        let lnoid = []
                        lnoid.push({
                            id: '',
                            amount: this.t.total,
                            store: this.activeStore.name,
                            type: 'point'
                        })
                        d.push({
                            value: JSON.stringify(lnoid)
                        })
                        // $.post(this.g.localServer + "/kitchenoffline/api/offlines/qrpay",
                        // {
                        //     models: JSON.stringify(d),
                        //     qrcode: JSON.stringify(qrObj)
                        // })
                        this.addQRPay(JSON.stringify(d), JSON.stringify(qrObj), 'point')
                        this.addFOrderItem(this.t.itemLines)
                    }
                })
            },
            loadExpirePoint(){
                this.showLoading = true
                let data = {
                    expValue: {
                        ':pk': this.activeLoyaltyCard.pk + '#expirepoint',
                        ':currentDate': new Date().getTime().toString()
                    },
                    condition: 'pk = :pk and sk > :currentDate'
                }
                loyaltyHandler.generalGets(data).then(res=>{
                    let data = res.data.data.Items
                    let expPoint = []
                    let amtPay = this.t.total
                    if(data.length >  0){
                        data.sort(function (a, b) {
                            return parseFloat(a.sk) - parseFloat(b.sk)
                        })
                        for(let i = 0; i < data.length; i++){
                            let ex = data[i]
                            let amt = amtPay
                            amtPay -= ex.point
                            if(amtPay < 0){
                                if(i == 0){
                                    amt = this.t.total
                                }
                                expPoint.push({
                                    pk: ex.pk,
                                    sk: ex.sk,
                                    point: ex.point,
                                    paid: amt,
                                    order: i + 1
                                })
                                break;
                            }else{
                                expPoint.push({
                                    pk: ex.pk,
                                    sk: ex.sk,
                                    point: ex.point,
                                    paid: ex.point,
                                    order: i + 1
                                })
                            }
                        }
                        if(amtPay > 0){
                            expPoint.push({
                                pk: '',
                                sk: '',
                                point: this.activeLoyaltyCard.totalPoint,
                                paid: amtPay,
                                order: 0
                            })
                        }
                        expPoint.sort(function (a, b) {
                            return parseFloat(a.order) - parseFloat(b.order)
                        })
                        this.t.paidBy = 'Point'
                        this.t.pointPaid.pointLines = expPoint
                        // bill date
                        this.t.billDate = new Date()
                        this.isSale = 0
                        this.t.isSale = 0
                        this.saveTxn()
                    }
                })
            },
            async requestTelegramCode(){
                this.dialogCode =  false
                this.verifyCode = ''
                this.savePoint('', this.t.txnNumber, 'code', parseFloat(this.pointTopUpRate.topUpRate))
            },
            async savePointPay(){
                await this.loadExpirePoint()
                if(!this.isPointQRPay){
                    await this.requestTelegramCode()
                }
                let data = this.activeLoyaltyCard
                // window.console.log(data, 'here data qr pay')
                let groupName = ''
                if(data.hasOwnProperty('group')){
                    groupName = data.group.name
                }
                this.t.pointPaid = {
                    cardId: data.pk,
                    name: data.activateName,
                    cardNum: data.cardNumber,
                    phoneNum: data.phoneNum,
                    telegram: data.activateTelegram,
                    pointPaid: this.t.total,
                    totalPoint: parseFloat(data.totalPoint.toFixed(2)),
                    pointLines: [],
                    group: groupName,
                    paidBy: this.isPointQRPay ? 'qrcode' : 'verifycode'
                }
                if(!this.isPointQRPay){
                    if(data.activateTelegram != ''){
                        this.verifyCode = ''
                        this.dialogCode = true
                    }else{
                        this.saveTxn()
                    }
                }else{
                    this.saveTxn()
                }
            },
            // phone number
            enterPhone(){
                if(this.phoneNumber != ''){
                    this.dailogPhone = false
                    if(this.isCreateCard){
                        this.saveLCard(this.phoneNumber, 1)
                    }else{
                        this.showCheckOut()
                    }
                }else{
                    this.$snotify.error(i18n.t('please_input_phone_number'))
                }
            },
            //loyalty
            savePoint(txnId,txnNum,type, rate){
                this.rewardObj.txnId = txnId
                let data = {
                    ...this.t.pointEarn,
                    isPoint: true,
                    txnId: txnId,
                    instituteId: instituteId,
                    store: this.activeStore.name,
                    storePhone: this.activeStore.phone,
                    type: type,
                    txnNum: txnNum
                }
                if(type == 'paid'){
                    let lines = []
                    this.t.itemLines.forEach(i=>{
                        lines.push({
                            id: 'lin-' + uuid.v1(),
                            qty: i.qty,
                            price: i.qty,
                            name: i.name,
                            discount: i.discount,
                            amount: i.amount
                        })
                    })
                    data = {
                        ...this.t.pointPaid,
                        isPoint: true,
                        txnId: txnId,
                        items: lines,
                        cashierGroup: this.g.telCashierGroup || '',
                        instituteId: instituteId,
                        store: this.activeStore.name,
                        storePhone: this.activeStore.phone,
                        registerName: this.register.name,
                        type: type,
                        txnNum: txnNum
                    }
                }else if(type == 'pointnotenough'){
                    let lines = []
                    this.lineDS.forEach(i=>{
                        lines.push({
                            id: 'lin-' + uuid.v1(),
                            qty: i.qty,
                            price: i.qty,
                            name: i.name,
                            discount: i.discount,
                            amount: i.amount
                        })
                    })
                    data = {
                        ...this.t.pointPaid,
                        isPoint: true,
                        txnId: txnId,
                        items: lines,
                        cashierGroup: this.g.telCashierGroup || '',
                        instituteId: instituteId,
                        store: this.activeStore.name,
                        storePhone: this.activeStore.phone,
                        registerName: this.register.name,
                        type: type,
                        txnNum: txnNum
                    }
                }else if(type == 'top_up' || type == 'top_up_g'){
                    data = {
                        ...this.topUpCard,
                        isPoint: true,
                        txnId: txnId,
                        instituteId: instituteId,
                        store: this.activeStore.name,
                        storePhone: this.activeStore.phone,
                        registerName: this.register.name,
                        type: type,
                        topUpAmount: parseFloat(this.topUpAmount),
                        topUpRate: rate,
                        txnNum: txnNum
                    }
                }else if(type == 'code'){
                    data = {
                        ...this.cardCode,
                        isPoint: true,
                        txnId: txnId,
                        txnAmount: this.t.total,
                        instituteId: instituteId,
                        store: this.activeStore.name,
                        storePhone: this.activeStore.phone,
                        type: type,
                        txnNum: txnNum
                    }
                }else if(type == 'storecredit'){
                    data = {
                        ...this.t.creditCard,
                        isPoint: true,
                        txnId: txnId,
                        txnAmount: this.t.total,
                        instituteId: instituteId,
                        store: this.activeStore.name,
                        storePhone: this.activeStore.phone,
                        type: type,
                        txnNum: txnNum
                    }
                }else if(type == 'txntrack'){
                    data = {
                        ...this.trackTxn,
                        isPoint: true,
                        txnId: txnId,
                        txnAmount: this.t.total,
                        instituteId: instituteId,
                        store: this.activeStore.name,
                        storePhone: this.activeStore.phone,
                        type: type,
                        cashier: this.activePin.name,
                        paidBy: this.t.paidBy,
                        txnNum: txnNum
                    }
                    if(data.items.length > 50){
                        this.$snotify.error(i18n.t('telegram_bot_may_not_send_group'))
                    }
                }else if(type == 'alertadmin'){
                    data = {
                        ...this.alertAdmin,
                        isPoint: true,
                        txnId: txnId,
                        txnAmount: this.t.total,
                        txnDiscount: this.t.discount,
                        instituteId: instituteId,
                        store: this.activeStore.name,
                        storePhone: this.activeStore.phone,
                        type: type,
                        cashier: this.activePin.name,
                        paidBy: this.t.paidBy,
                        txnNum: txnNum
                    }
                }
                this.showLoading  = true
                if(type == 'txntrack' || type == 'alertadmin'){
                    telegramBotHandler.posPost(data).then(()=>{
                        this.showLoading = false
                    })
                }else{
                    if(instituteId == 'inst-84740919' || instituteId == 'inst-63662183'){
                        // champei telegram bot
                        telegramBotHandler.champeiPointCreate(data).then(()=>{
                            this.showLoading = false
                            if(this.isTopUp){
                                this.showTopUpForm = false
                                this.topUpCard = {}
                                this.isTopUp = false
                                this.dialogLoyalSearch = false
                                this.dialogLoyalty =  false
                                this.$snotify.success(i18n.t('successfull'))
                            }
                        })
                    }else if(instituteId == 'inst-96422704'){
                        // asda pub
                        telegramBotHandler.asdaPointCreate(data).then(()=>{
                            this.showLoading = false
                            if(this.isTopUp){
                                this.showTopUpForm = false
                                this.topUpCard = {}
                                this.isTopUp = false
                                this.dialogLoyalSearch = false
                                this.dialogLoyalty =  false
                                this.$snotify.success(i18n.t('successfull'))
                            }
                        })
                    }else if(instituteId == 'inst-03343050' || instituteId == 'inst-69999068'){
                        // amatak hotel
                        telegramBotHandler.amatakPointCreate(data).then(()=>{
                            this.showLoading = false
                            if(this.isTopUp){
                                this.showTopUpForm = false
                                this.topUpCard = {}
                                this.isTopUp = false
                                this.dialogLoyalSearch = false
                                this.dialogLoyalty =  false
                                this.$snotify.success(i18n.t('successfull'))
                            }
                        })
                    }else if(instituteId == 'inst-72006786'){
                        //klassy
                        telegramBotHandler.klassyPointCreate(data).then(()=>{
                            this.showLoading = false
                            if(this.isTopUp){
                                this.showTopUpForm = false
                                this.topUpCard = {}
                                this.isTopUp = false
                                this.dialogLoyalSearch = false
                                this.dialogLoyalty =  false
                                this.$snotify.success(i18n.t('successfull'))
                            }
                        })
                    }else if(instituteId == 'inst-17374687'){
                        // total energy
                        telegramBotHandler.totalPointCreate(data).then(()=>{
                            this.showLoading = false
                            if(this.isTopUp){
                                this.showTopUpForm = false
                                this.topUpCard = {}
                                this.isTopUp = false
                                this.dialogLoyalSearch = false
                                this.dialogLoyalty =  false
                                this.$snotify.success(i18n.t('successfull'))
                            }
                        })
                    }else if(instituteId == 'inst-30111967'){
                        // madam nom
                        telegramBotHandler.madamnomPointCreate(data).then(()=>{
                            this.showLoading = false
                            if(this.isTopUp){
                                this.showTopUpForm = false
                                this.topUpCard = {}
                                this.isTopUp = false
                                this.dialogLoyalSearch = false
                                this.dialogLoyalty =  false
                                this.$snotify.success(i18n.t('successfull'))
                            }
                        })
                    }else if(instituteId == 'inst-32217168'){
                        // noir
                        telegramBotHandler.noirPointCreate(data).then(()=>{
                            this.showLoading = false
                            if(this.isTopUp){
                                this.showTopUpForm = false
                                this.topUpCard = {}
                                this.isTopUp = false
                                this.dialogLoyalSearch = false
                                this.dialogLoyalty =  false
                                this.$snotify.success(i18n.t('successfull'))
                            }
                        })
                    }else if(instituteId == 'inst-63470686'){
                        // CIA School
                        telegramBotHandler.ciaPointCreate(data).then(()=>{
                            this.showLoading = false
                            if(this.isTopUp){
                                this.showTopUpForm = false
                                this.topUpCard = {}
                                this.isTopUp = false
                                this.dialogLoyalSearch = false
                                this.dialogLoyalty =  false
                                this.$snotify.success(i18n.t('successfull'))
                            }
                        })
                    }else{
                        // banhji commerce telegram bot
                        loyaltyHandler.pointCreate(data).then(()=>{
                            this.showLoading = false
                            if(this.isTopUp){
                                this.showTopUpForm = false
                                this.topUpCard = {}
                                this.isTopUp = false
                                this.dialogLoyalSearch = false
                                this.dialogLoyalty =  false
                                this.$snotify.success(i18n.t('successfull'))
                            }
                        })
                    }
                }
            },
            async loadRankPoint(key){
                // this.showLoading = true
                let point = 0
                let a = this.activeReward
                let startDate = kendo.toString(new Date(), 'yyyy-MMM-dd')
                let sd = new Date( startDate + ' 00:00:00').getTime().toString();
                let endDate = kendo.toString(new Date(), 'yyyy-MMM-dd')
                let ed = new Date(endDate + ' 23:59:59').getTime().toString();
                var date = new Date(), y = date.getFullYear(), m = date.getMonth();
                // window.console.log(startDate, sd, endDate, ed, a)
                if(a.rankPromotionBy == 'month'){
                    var firstDay = new Date(y, m, 1);
                    var lastDay = new Date(y, m + 1, 0);
                    sd = new Date(firstDay).getTime().toString()
                    let edFull = kendo.toString(new Date(lastDay), 'yyyy-MMM-dd')
                    ed = new Date(edFull + ' 23:59:59').getTime().toString();
                }else if(a.rankPromotionBy == 'year'){
                    sd = new Date('01-01-' + y + ' 00:00:00').getTime().toString();
                    ed = new Date('31-12-' + y + ' 00:00:00').getTime().toString();
                }
                // window.console.log(new Date(sd), sd, new Date(ed), ed);
                let d = {
                    epsAtt: {
                        ':pk': this.t.pointEarn.cardId + '#earnpoint',
                        ':startDate': sd,
                        ':endDate': ed,
                    },
                    keyCondition: 'pk = :pk and sk between :startDate and :endDate',
                    key: key
                }
                await loyaltyHandler.pointGets(d).then(res=>{
                    let data = res.data.data.Items
                    if(data.length > 0){
                        let tpoint = 0
                        data.forEach(d=>{
                            tpoint += parseFloat(d.point)
                        })
                        point = tpoint
                    }
                })
                return point
            },
            selectReward(item){
                this.activeReward = item
            },
            async enterReward(){
                // let rType = 'earn'
                let a = this.activeReward
                if (Object.keys(a).length == 0) {
                    this.$snotify.error(i18n.t('please_select_a_reward'))
                    return
                }
                let rewardamt = 0
                let txnamount = (this.t.total * parseFloat(a.percentageSale)) / 100
                if(txnamount <= 0){
                    this.$snotify.error(i18n.t('amount_not_enough_for_a_reward'))
                    this.chFStart += 1
                    this.chFlow()
                    return
                }else{
                    if(a.type == 'rateRank'){
                        if(a.rankLines.length > 0){
                            let tpoint = 0
                            if(a.rankBy == "forever"){
                                tpoint = parseFloat(this.activeLoyaltyCard.earnPoint)
                            }else{
                                tpoint = await this.loadRankPoint({})
                            }
                            
                            let dis = 0
                            let activeline = ''
                            for (let i = 0; i < a.rankLines.length; i++) {
                                let va = a.rankLines[i]
                                // window.console.log(va, totalcom, 'va')
                                if(va.conditionType == '>'){
                                    if(tpoint > va.conditionAmount){
                                        dis = (txnamount * parseFloat(va.rewardAmount)) / 100
                                        activeline = va.name
                                        break;
                                    } 
                                }else{
                                    if(tpoint >= va.conditionAmount){
                                        dis = (txnamount * parseFloat(va.rewardAmount)) / 100
                                        activeline = va.name
                                        break;
                                    } 
                                }
                            }
                            this.t.pointEarn.level = activeline
                            rewardamt = dis
                        }
                    }else if(a.type == 'rate'){
                        let pers = (txnamount * parseFloat(a.amountPerPoint)) / 100
                        rewardamt = pers
                    }else{
                        rewardamt = txnamount / parseFloat(a.amountPerPoint)
                    }
                }
                // window.console.log(rewardamt + ' = point earn', txnamount + ' = txn amount' )
                if(rewardamt <= 0){
                    this.$snotify.error(i18n.t('no_reward_for_this_transaction_amount'))
                    this.chFStart += 1
                    this.chFlow()
                    return
                }
                // check point expire
                let expire = ''
                if(a.isPointExpire){
                    if(a.expireBy == 'day'){
                        let damt = 1
                        let c = parseInt(a.expireAmount)
                        if(!isNaN(c)) damt = c
                        let ad = new Date()
                        let b = ad.setDate(ad.getDate() + damt)
                        expire = b
                    }else if(a.expireBy == 'month'){
                        let damt = 1
                        let c = parseInt(a.expireAmount)
                        if(!isNaN(c)) damt = c
                        let ad = new Date()
                        let b = ad.setMonth(ad.getMonth() + damt)
                        expire = b
                    }else{
                        let damt = 1
                        let c = parseInt(a.expireAmount)
                        if(!isNaN(c)) damt = c
                        let ad = new Date()
                        let b = ad.setFullYear(ad.getFullYear() + damt)
                        expire = b
                    }
                }
                this.t.pointEarn.pointEarn = parseFloat(rewardamt.toFixed(2))
                this.t.pointEarn.pointExpire = expire
                this.dialogRewardInfo = true
                // window.console.log(this.t.pointEarn, 'point earn')
            },
            saveReward(){
                this.dialogRewardInfo = false
                this.dialogRProgram = false
                this.dialogLoyalSearch = false
                this.dialogLoyalty = false
                this.chFStart += 1
                this.chFlow()
            },
            searchLoyaltyCard(){
                this.dialogRProgram = false
                this.showTopUpForm = false
                this.activeReward = {}
                let searchData = {
                    type: this.lsearchName,
                    searchNum: this.lcardNumber
                }
                this.showLoading = true
                loyaltyHandler.searchCard(searchData).then((res) => {
                    this.showLoading = false
                    let d = res.data.data 
                    // window.console.log(d, 'search card')
                    if(d.length > 0){
                        let data = d[0] 
                        this.hasLoyaltyCard = true
                        if(this.isStoreCredit){
                            window.console.log(data, 'store credit')
                            if(data.nature != 'credit_card'){
                                this.$snotify.error(i18n.t('this_card_is_not_a_credit_card'))
                                this.lcardNumber = ''
                                return
                            }
                            if(data.status != 'activated'){
                                this.$snotify.error(i18n.t('card_is_not_yet_activated'))
                                this.lcardNumber = ''
                                return
                            }
                            if(data.activateTelegram == ''){
                                this.$snotify.error(i18n.t('card_is_not_yet_activated'))
                                this.lcardNumber = ''
                                return
                            }
                            this.activeLoyaltyCard = data
                            let groupname = ''
                            if(this.campaigns.length > 0 && this.activeLoyaltyCard.group.hasOwnProperty('campaign')){
                                this.campaigns.forEach(c=>{
                                    if(c.pk == this.activeLoyaltyCard.group.campaign){
                                        groupname = c.name
                                        this.applyPromotion(c)
                                        this.calculateTotal()
                                    }
                                })
                            }
                            this.t.creditCard = {
                                id: data.pk,
                                name: data.activateName,
                                cardNum: data.cardNumber,
                                phoneNum: data.phoneNum,
                                telegram: data.activateTelegram,
                                storeId: this.activeStore.pk,
                                group: groupname,
                                instituteId: instituteId,
                                items: []
                            }
                            this.dialogLoyalty = false
                            let instName = 'Bench'
                            if(instituteId == 'inst-32217168'){
                                instName = 'Noir'
                            }
                            this.requestId = uuid.v1()
                            let dd = {
                                telegram: data.activateTelegram,
                                type: 'storecredit',
                                store: this.activeStore.name,
                                storePhone: this.activeStore.phone,
                                pk: this.requestId,
                                cardId: data.pk,
                                instName: instName,
                                items: this.lineDS
                            }
                            this.showLoading = true
                            telegramBotHandler.posPost(dd).then(()=>{
                                this.showLoading = false
                                this.verifyCode = ''
                                this.dialogCode = true
                            })
                        }else if(this.isDiscountCard){
                            if(data.nature != 'discount_card'){
                                this.$snotify.error(i18n.t('this_card_is_not_a_discount_card'))
                                this.lcardNumber = ''
                                return
                            }
                            this.activeLoyaltyCard = data
                            if(this.campaigns.length > 0 && this.activeLoyaltyCard.group.hasOwnProperty('campaign')){
                                this.campaigns.forEach(c=>{
                                    if(c.pk == this.activeLoyaltyCard.group.campaign){
                                        window.console.log(c, 'paartner campaign')
                                        this.applyPromotion(c)
                                        this.calculateTotal()
                                    }
                                })
                            }
                            this.t.discountCard = {
                                id: data.pk,
                                name: data.activateName,
                                cardNum: data.cardNumber,
                                phoneNum: data.phoneNum,
                                group: data.group.name || ''
                            }
                            this.t.customer = {
                                id: data.pk,
                                type: data.group.name || '',
                                name: data.activateName
                            }
                            this.dialogLoyalSearch = false
                            this.dialogLoyalty = false
                            window.console.log(this.t, 'after apply card')
                        }else{
                            if(data.nature != 'point_card'){
                                this.$snotify.error(i18n.t('this_card_is_not_a_point_card'))
                                this.lcardNumber = ''
                                return
                            }
                            this.activeLoyaltyCard = data
                            // window.console.log(this.activeLoyaltyCard, 'active')
                            if(this.campaigns.length > 0 && this.activeLoyaltyCard.group.hasOwnProperty('campaign')){
                                this.campaigns.forEach(c=>{
                                    if(c.pk == this.activeLoyaltyCard.group.campaign){
                                        // window.console.log(c, 'paartner campaign')
                                        this.applyPromotion(c)
                                        this.calculateTotal()
                                    }
                                })
                            }
                            if(this.isPointPayCounter){
                                let groupName = ''
                                if(data.hasOwnProperty('group')){
                                    groupName = data.group.name || ''
                                }
                                this.searchCard.profileUrl = 'https://s3.ap-southeast-1.amazonaws.com/images.banhji/commerce/profile.png'
                                this.searchCard = {
                                    cardId: data.pk,
                                    name: data.activateName,
                                    cardNum: data.cardNumber,
                                    serial: data.serial,
                                    status: data.status,
                                    phoneNum: data.phoneNum,
                                    loyaltyName: this.g.loyaltyProgram.name,
                                    loyaltyId: this.g.loyaltyProgram.pk,
                                    telegram: data.activateTelegram,
                                    totalPoint: parseFloat(data.totalPoint.toFixed(2)),
                                    pointEarn: 0,
                                    pointExpire: '',
                                    profileUrl: data.profileUrl || 'https://s3.ap-southeast-1.amazonaws.com/images.banhji/commerce/profile.png',
                                    group: groupName,
                                    cardLevel: data.cardLevel,
                                    gender: i18n.t(data.activateGender),
                                    orderNum: data.orderNumber
                                }
                                this.t.pointPaid = {
                                    cardId: data.pk,
                                    name: data.activateName,
                                    cardNum: data.cardNumber,
                                    phoneNum: data.phoneNum,
                                    telegram: data.activateTelegram,
                                    pointPaid: this.t.total,
                                    totalPoint: parseFloat(data.totalPoint.toFixed(2)),
                                    pointLines: [],
                                    group: groupName,
                                    paidBy: 'counter'
                                }
                                if(this.lineDS.length == 0){
                                    this.$snotify.error(i18n.t('item_required'))
                                    // this.dialogPointCount = false
                                    return
                                }
                                if(!this.isServing){
                                    this.t.issuedDate = new Date()
                                }
                                this.t.paidBy = 'Point'
                                this.t.itemLines = this.lineDS
                                this.t.customer = {
                                    id: data.pk,
                                    type: groupName,
                                    name: data.activateName
                                }
                                this.t.amtReceipt = this.t.total
                                // window.console.log(this.t, 'pay by cash')
                                this.calculateTotal()
                                this.createPrintReceipt(this.t)
                                this.showCardInv = false
                                setTimeout(()=>{
                                    this.showCardInv = true
                                }, 300)
                                this.allowPayPoint = true
                                if(parseFloat(data.totalPoint.toFixed(2)) < this.t.total){
                                    let credit = data.creditAmount || 0
                                    let amtcredit = parseFloat(data.totalPoint.toFixed(2)) + parseFloat(credit)
                                    if(amtcredit < this.t.total){
                                        this.$snotify.error(i18n.t('point_balance_not_enough'))
                                        this.allowPayPoint = false
                                        if(data.activateTelegram != ''){
                                            this.savePoint('', this.t.txnNumber, 'pointnotenough', 1)
                                        }
                                    }
                                }
                                this.dialogPointCount = true
                            }else if(this.pointCardPay){
                                // window.console.log('is point card pay')
                                this.cardCode = {
                                    cardId: data.pk,
                                    name: data.activateName,
                                    cardNum: data.cardNumber,
                                    phoneNum: data.phoneNum,
                                    loyaltyName: this.g.loyaltyProgram.name,
                                    loyaltyId: this.g.loyaltyProgram.pk,
                                    telegram: data.activateTelegram,
                                    totalPoint: parseFloat(data.totalPoint.toFixed(2)),
                                    pointEarn: 0,
                                    pointExpire: ''
                                }
                                this.topUpCard = {}
                                this.t.pointEarn = {}
                                if(this.activeLoyaltyCard.totalPoint >= this.t.total){
                                    this.savePointPay()
                                }else{
                                    this.$snotify.error(i18n.t('point_balance_not_enough'))
                                }
                            }else if(this.isTopUp){
                                window.console.log('is top up')
                                let groupName = ''
                                if(data.hasOwnProperty('group')){
                                    groupName = data.group.name || ''
                                }
                                this.topUpCard = {
                                    cardId: data.pk,
                                    name: data.activateName,
                                    cardNum: data.cardNumber,
                                    phoneNum: data.phoneNum,
                                    loyaltyName: this.g.loyaltyProgram.name,
                                    loyaltyId: this.g.loyaltyProgram.pk,
                                    telegram: data.activateTelegram,
                                    totalPoint: parseFloat(data.totalPoint.toFixed(2)),
                                    pointEarn: 0,
                                    pointExpire: '',
                                    profileUrl: data.profileUrl || 'https://s3.ap-southeast-1.amazonaws.com/images.banhji/commerce/profile.png',
                                    group: groupName,
                                    cardLevel: data.cardLevel,
                                    gender: i18n.t(data.activateGender),
                                    orderNum: data.orderNumber
                                }
                                if(this.g.pointTUOpt.length == 1){
                                    let opt = this.g.pointTUOpt[0]
                                    if(opt == 1){
                                        this.selectTopUp('counter')
                                    }else{
                                        this.selectTopUp('giftcard')
                                    }
                                }else{
                                    this.dialogSelectTopup = true
                                }
                            }else if(this.isSearchCard){
                                window.console.log('is search card')
                                let groupName = ''
                                if(data.hasOwnProperty('group')){
                                    groupName = data.group.name || ''
                                }
                                this.searchCard = {
                                    cardId: data.pk,
                                    name: data.activateName,
                                    cardNum: data.cardNumber,
                                    serial: data.serial,
                                    status: data.status,
                                    phoneNum: data.phoneNum,
                                    loyaltyName: this.g.loyaltyProgram.name,
                                    loyaltyId: this.g.loyaltyProgram.pk,
                                    telegram: data.activateTelegram,
                                    totalPoint: parseFloat(data.totalPoint.toFixed(2)),
                                    pointEarn: 0,
                                    pointExpire: '',
                                    profileUrl: data.profileUrl || 'https://s3.ap-southeast-1.amazonaws.com/images.banhji/commerce/profile.png',
                                    group: groupName,
                                    cardLevel: data.cardLevel,
                                    gender: i18n.t(data.activateGender),
                                    orderNum: data.orderNumber
                                }
                                this.dialogSCard = true
                            }else{
                                window.console.log('is point earn')
                                this.loyaltyCustomer = {
                                    id: data.customerId,
                                    type: this.activeLoyaltyCard.group.name,
                                    name: data.activateName,
                                }
                                this.customer.name = data.activateName
                                this.customer.id = data.customerId
                                this.t.pointEarn = {
                                    cardId: data.pk,
                                    name: data.activateName,
                                    cardNum: data.cardNumber,
                                    phoneNum: data.phoneNum,
                                    loyaltyName: this.g.loyaltyProgram.name,
                                    loyaltyId: this.g.loyaltyProgram.pk,
                                    telegram: data.activateTelegram,
                                    totalPoint: parseFloat(data.totalPoint.toFixed(2)),
                                    level: '',
                                    pointEarn: 0,
                                    pointExpire: ''
                                }
                                this.dialogLoyalty = false 
                                this.loadLoyaltyProgam()
                            }
                        }
                    }else{
                        this.lcardNumber = ''
                        this.$snotify.error(i18n.t('no_data_found'))
                    }
                    this.lcardNumber = ''
                    setTimeout(()=>{
                        $('#searchCardField').select()
                    },500)
                })
            },
            loadLoyaltyProgam(){
                this.showLoading = true
                loyaltyHandler.loyaltyGet(this.g.loyaltyProgram.pk).then(res=>{
                    this.showLoading = false
                    if(res.data.data.length > 0){
                        this.activeReward = res.data.data[0]
                        this.t.pointEarn.loyaltyName = res.data.data[0].name
                        this.enterReward()
                    }else{
                        this.$snotify.error(i18n.t('this_store_has_no_loyalty_program'))
                        this.chFStart += 1
                        this.chFlow()
                    }
                })
            },
            lsearchBy(name){
                this.lsearchName = name
                this.lcardNumber = ''
                this.dialogLoyalty = true
                setTimeout(()=>{
                    $('#searchCardField').select()
                },500)
            },
            // top up
            selectTopUp(func){
                if(func == 'counter'){
                    this.showTopUpForm = true
                    this.isTopUpCounter = true
                }else if(func == 'giftcard'){
                    this.dialogSGCard = true
                    this.isTopUpGCard = true
                }
                this.topUpBy = func
            },
            //loyalty
            skipLoyalty(){
                // this.dialogLoyalty = false
                // this.dialogRProgram = false
                // this.checkOrderFlow()
                this.dialogLoyalty = false
                this.dialogLoyalSearch = false
                // window.console.log('you are skip loyalty', this.isSearchCard)
                if(!this.isSearchCard){
                    this.chFStart += 1
                    this.chFlow()
                }
            },
            // skip promotion
            cancelPromotion(){
                this.dialogPromotion = false
                if(this.isPromoFlow){
                    this.chFStart += 1
                    this.chFlow()
                }
            },
            //check out block
            checkOut(){
                // window.console.log(this.g.checkOutFlow, 'your are check out')
                this.isSearchCard = false
                if(this.lineDS.length == 0){
                    this.$snotify.error(i18n.t('item_required'))
                    this.dialogCash = false
                    return
                }
                this.chFlows = []
                if(this.g.checkOutFlow == 2){
                    this.chFlows = ["loyalty", "promotion", "payment"]
                    this.chFStart = 0
                    this.chFlow()
                }else if(this.g.checkOutFlow == 1){
                    this.chFlows = ["payment"]
                    this.chFStart = 0
                    this.chFlow()
                }else if(this.g.checkOutFlow == 3){
                    this.chFlows = ["promotion", "loyalty", "payment"]
                    this.chFStart = 0
                    this.chFlow()
                }else if(this.g.checkOutFlow == 4){
                    this.chFlows = ["loyalty", "payment"]
                    this.chFStart = 0
                    this.chFlow()
                }else if(this.g.checkOutFlow == 5){
                    this.chFlows = ["promotion", "payment"]
                    this.chFStart = 0
                    this.chFlow()
                }
            },
            chFlow(){
                if(this.chFlows.length > 0){
                    if(this.chFStart < this.chFlows.length){
                        this.chFflow(this.chFlows[this.chFStart])
                        // this.chFStart += 1
                    }
                    // if((this.chFStart + 1) == this.chFlows){
                    //     window.console.log(this.g, 'you are last of check out')
                    // }
                    // window.console.log('chfstart = ' + this.chFStart, 'chflows = ', this.chFlows, this.chFlows[this.chFStart])
                }
            },
            async chFflow(func){
                window.console.log(func, 'check out flow')
                let self = this
                let pro = []
                let gusttype = this.g.selectGuestType || false
                if (func != '') {
                    switch(func) {
                        case 'promotion':
                            // window.console.log(this.campaigns, 'chflow campaign')
                            this.promotions = []
                            if(this.campaigns.length > 0){
                                this.isPromoFlow = true
                                for(let i = 0; i < this.campaigns.length; i++){
                                    let cam = self.campaigns[i]
                                    // window.console.log(cam, 'prmotion')
                                    let camValid = 1
                                    // check type
                                    if(cam.type != "manual"){
                                        // window.console.log(i, 'wrong type')
                                        camValid -= 1
                                    }
                                    // check store 
                                    if(cam.stores.length > 0){
                                        let hasStore = false
                                        cam.stores.forEach(s=>{
                                            if(s.pk == self.activeStore.pk){
                                                hasStore = true
                                            }
                                        })
                                        if(!hasStore) break;
                                    }else{
                                        // window.console.log(i, 'wrong store')
                                        camValid -= 1
                                    }
                                    // check time
                                    let validTime = true
                                    if(cam.isTiming){
                                        validTime = false
                                        let curTime = new Date().getTime()
                                        let timeF = kendo.toString(new Date(), 'yyyy-MMM-dd') + ' ' + cam.timeFrom
                                        let timeFrom = new Date(timeF).getTime()
                                        let timeT = kendo.toString(new Date(), 'yyyy-MMM-dd') + ' ' + cam.timeTo
                                        let timeTo = new Date(timeT).getTime()
                                        if(curTime >= timeFrom && curTime <= timeTo){
                                            validTime = true
                                        }
                                        // window.console.log(timeTo, timeFrom, curTime, 'compare time')
                                    }
                                    if(!validTime){
                                        camValid -= 1
                                    }
                                    // check price level
                                    let validPriceLevel = false
                                    if(cam.priceLevel.id == this.g.defaultPriceLevel){
                                        validPriceLevel = true
                                    }
                                    if(!validPriceLevel){
                                        camValid -= 1
                                    }
                                    // check rule base
                                    let validRule = false
                                    // customer base
                                    if(cam.ruleBase == 'customer'){
                                        if(cam.ruleCustomerBaseType == 'specific'){
                                            if(cam.ruleCustomers.length > 0){
                                                cam.ruleCustomers.forEach(rcus=>{
                                                    if(rcus.customer.id == self.g.defaultCustomer.id){
                                                        validRule = true
                                                    }
                                                })
                                            }
                                        }else if(cam.ruleCustomerBaseType == 'customerType'){
                                            if(cam.ruleCustomerTypes.length > 0){
                                                cam.ruleCustomerTypes.forEach(rcus=>{
                                                    if(rcus.id == self.g.defaultCustomer.customerType.id){
                                                        validRule = true
                                                    }
                                                })
                                            }
                                        }else{
                                            validRule = true
                                        }
                                    // product base
                                    }else{
                                        if(cam.ruleProductBaseOn == 'specific'){
                                            if(cam.ruleProduct.length > 0){
                                                cam.ruleProduct.forEach(rcus=>{
                                                    self.lineDS.forEach(lin=>{
                                                        if(rcus.item.id == lin.itemId){
                                                            validRule = true
                                                        }
                                                    })
                                                })
                                            }
                                        }else if(cam.ruleProductBaseOn == 'category'){
                                            if(cam.ruleProductCategory.length > 0){
                                                cam.ruleProductCategory.forEach(rcus=>{
                                                    self.lineDS.forEach(lin=>{
                                                        if(rcus.category.id == lin.categoryId){
                                                            validRule = true
                                                        }
                                                    })
                                                })
                                            }
                                        }else if(cam.ruleProductBaseOn == 'group'){
                                            if(cam.ruleProductGroup.length > 0){
                                                cam.ruleProductGroup.forEach(rcus=>{
                                                    self.lineDS.forEach(lin=>{
                                                        if(rcus.group.id == lin.groupId){
                                                            validRule = true
                                                        }
                                                    })
                                                })
                                            }
                                        }else{
                                            validRule = true
                                        }
                                    }
                                    if(!validRule){
                                        camValid -= 1
                                    }
                                    if(camValid >= 1){
                                        pro.push(cam)
                                    }
                                    // self.promtions.push(cam)
                                }
                                this.promotions = pro
                            }
                            if(this.promotions.length > 0){
                                this.dialogPromotion = true
                            }else{
                                this.chFStart += 1
                                this.chFlow()
                            }
                            // window.console.log(this.promotions, pro, this.promotions.length, this.dialogPromotion, 'promtoin blocl')
                            break;
                        case 'loyalty':
                            if(navigator.onLine){
                                // this.dialogLoyalSearch = true
                                // window.console.log(this.g.searchCard, 'chflow loyalty')
                                if(this.g.searchCard.length <= 0){
                                    this.$snotify.success(i18n.t('no_search_card_function'))
                                    this.chFStart += 1
                                    this.chFlow()
                                }else if(this.g.searchCard.length == 1){
                                    let s = this.g.searchCard[0]
                                    if(s == 1){
                                        this.lsearchBy('card')
                                    }else if(s == 2){
                                        this.lsearchBy('phone')
                                    }else if(s == 3){
                                        this.lsearchBy('nfc')
                                    }else if(s == 4){
                                        this.lsearchBy('ordernumber')
                                    }
                                }else{
                                    this.dialogLoyalSearch = true
                                }
                            }else{
                                this.chFStart += 1
                                this.chFlow()
                            }
                            break;
                        case 'payment': 
                            this.t.issuedDate = new Date()
                            // this.t.paidBy = 'Cash'
                            this.t.itemLines = this.lineDS
                            this.t.customer = {
                                id: this.g.defaultCustomer.id,
                                type: 'general',
                                name: this.g.defaultCustomer.name
                            }
                            window.console.log(this.g, 'you are last of check out')
                            if(gusttype == 1){
                                gusttype = true
                            }
                            if(gusttype){
                                this.dialogGuestType = true
                            }
                            this.createPrintReceipt(this.t)
                            await this.loadTxnNumber()
                            this.calculateTotal()
                            this.dialogPayment = true
                            break;
                        default:
                            // code block
                    }
                }
            },
            loyaltySelect(func){
                this.loyalFunc = func
                this.dialogLoyalty = true
            },
            dateFormat(v){
                return kendo.toString(new Date(v), 'dd-MMM-yyyy hh:mm tt')
            },
            closePayment(){
                if(this.isServing){
                    this.resetOrder()
                    this.isServing = false
                }
                this.dialogPayment = false
            },
            createPrintReceipt(printObj){
                // window.console.log(this.activePin, 'pin user')
                printObj.pinUser = this.activePin || {}
                // discount card
                if(Object.keys(printObj.discountCard).length > 0){
                    printObj.customer = {
                        id: printObj.discountCard.id,
                        type: printObj.discountCard.group,
                        name:  printObj.discountCard.name
                    }
                }
                if(Object.keys(this.exchange).length <= 0){
                    this.exchange = {
                        base: 0,
                        secondary: 0
                    }
                    printObj.exchange = this.exchange
                }
                // add recipt amount
                printObj.recieptUSD = this.usdPaidAmt
                printObj.recieptKHR = this.khrPaidAmt
                printObj.secondRate = this.secondRate
                printObj.baseRate = this.baseRate
                this.printObj = printObj
                window.console.log(this.printObj, 'print obj')
                // this.$emit("onUpdate", printObj);
            },
            // check out
            async showCheckOut(){
                await this.loadTxnNumber()
                this.calculateTotal()
                this.dialogPayment = true
            },
            // serving line
            async serveCheckOut(data){
                let act = this.servings.filter((o)=>{return o.pk == data.pk});
                window.console.log(act, data, 'save serving check out');
                if(act.length > 0){
                    this.activeServing = act[0]
                    this.activeServing.txnId = data.pk
                    this.activeServing.id = ''
                    let itmLine = act[0].itemLines
                    if(act[0].itemLines.length == 0){
                        let d = {
                            sk: data.pk,
                            pk: 'txnline-',
                            key: {}
                        }
                        await commerceHandler.genGets(d).then(res => {
                            // window.console.log(res, 'response serving')
                            if(res.data.data.Items.length > 0){
                                itmLine = res.data.data.Items
                                this.updateLineIndxDB(data.pk, 'serving', itmLine)
                            }
                        })
                    }
                    itmLine.forEach(l=>{
                        // l.discount = 0
                        l.uom.toUOM = {name: l.uom.name}
                    })
                    window.console.log(itmLine, 'itm line serving')
                    this.activeServing.itemLines = itmLine
                    this.createPrintReceipt(new PosTransactionModel(this.activeServing))
                    this.lineDS = itmLine
                    this.t = new PosTransactionModel(this.activeServing)
                    this.t.txnId = data.pk
                    this.t.type = 'sale'
                    this.isServing = true
                }
                // this.showCheckOut()
                this.checkOut()
            },
            // change sale unit
            changeSaleUnit(data){
                let a = this.saleUnitData.filter((o) => {return o.status == 1 && o.id != data.saleUnitId})
                if(a.length > 0){
                    this.cSaleUnit = {}
                    this.dialogChangeSaleunit = true
                    this.saleUnitList = a
                    this.cSaleUnitId = data.saleUnitId
                    this.cTxn = data.pk
                }else{
                    this.$snotify.error(i18n.t('no_sale_unit_available'))
                }
            },
            enterCSaleUnit(){
                let saleUnit = {
                    code: this.cSaleUnit.code,
                    id: this.cSaleUnit.id,
                    name: this.cSaleUnit.name
                }
                let tbname = 'serving'
                const objectStore = db.transaction(instituteId + tbname, 'readwrite').objectStore(instituteId + tbname);
                const request = objectStore.get(this.cTxn);
                this.showLoading = true
                let self = this
                request.onsuccess = ()=> {
                    let student = request.result;
                    student.saleUnit = saleUnit;
                    const res = objectStore.put(student);
                    res.onsuccess = ()=> {
                        self.bindServing()
                        const sdstatus = self.g.saleUnitStatus || 1
                        self.updateSaleUnit(self.cSaleUnitId, sdstatus)
                        self.updateSaleUnit(saleUnit.id, 0)
                        setTimeout(()=>{
                            self.showLoading = false
                            self.$snotify.success(i18n.t('success'))
                            self.dialogChangeSaleunit = false
                            self.showServingTxn()
                            self.bindSaleUnit()
                            self.updateTxnServing(self.cTxn, saleUnit)
                        }, 500)
                    }
                }
            },
            // edit serving
            editServing(data){
                let tbname = 'serving'
                const objectStore = db.transaction(instituteId + tbname, 'readwrite').objectStore(instituteId + tbname);
                const request = objectStore.get(data.pk);
                this.isInvoice = false
                this.isEditServing = true
                let self = this
                request.onsuccess = () => {
                    if(!self.isMergeBill){
                        self.setServing(request.result)
                    }else{
                        self.mergeBillSave(request.result)
                    }
                }
            },
            async setServing(dataItem){
                let itmLine = dataItem.itemLines
                if(dataItem.itemLines.length == 0){
                    let d = {
                        sk: dataItem.pk,
                        pk: 'txnline-',
                        key: {}
                    }
                    await commerceHandler.genGets(d).then(res => {
                        // window.console.log(res, 'response serving')
                        if(res.data.data.Items.length > 0){
                            let l = []
                            res.data.data.Items.forEach(itm=>{
                                l.push(itm)
                            })
                            itmLine = l
                            this.updateLineIndxDB(dataItem.pk, 'serving', itmLine)
                        }
                    })
                }
                itmLine.forEach(l=>{
                    l.isNew = 0
                    l.campaign = l.campaign || []
                    l.uom.toUOM = {
                        id: l.uom.id,
                        name: l.uom.name
                    }
                })
                dataItem.itemLines = itmLine
                dataItem.id = dataItem.pk
                this.lineDS = itmLine
                window.console.log(dataItem, 'before model')
                this.activeSaleUnit = dataItem.saleUnit
                this.t = new PosTransactionModel(dataItem)
                window.console.log(this.t, 'after model')
                this.calculateTotal()
                this.t.isEdit = true
                this.t.paidBy = ""
                this.dialogServingLine = false
                if(this.isEditServing){
                    this.orderNumber = this.t.orderNumber
                    this.sCardTap = false
                    this.setItemFlow()
                    $('#tabProduct').click()
                }
                if(this.isPrintServing){
                    this.t.paidBy = ''
                    this.createPrintReceipt(this.t)
                    this.showCardInv = false
                    setTimeout(()=>{
                        this.showCardInv = true
                    }, 300)
                    this.dialogPrint = true
                    setTimeout(()=>{
                        this.printHtml()
                    }, 500)
                }
            },
            saveEditServing(){
                if(this.activeKeyPad){
                    this.$snotify.error(i18n.t('keypad_active'))
                    return;
                }
                this.isSale = 0
                this.t.isSale = 0
                this.t.editDate = new Date().getTime().toString()
                window.console.log(this.t, 'save edit serving')
                this.saveTxn()
            },
            // print serving
            printServing(data){
                let tbname = 'serving'
                const objectStore = db.transaction(instituteId + tbname, 'readwrite').objectStore(instituteId + tbname);
                const request = objectStore.get(data.pk);
                this.isEditServing = true
                let self = this
                this.isPrintServing = true
                request.onsuccess = () => {
                    self.setServing(request.result)
                }
            },
            // end serving
            async endServing(data){
                await this.loadTxnNumber()
                setTimeout(()=>{
                    let tbname = 'serving'
                    const objectStore = db.transaction(instituteId + tbname, 'readwrite').objectStore(instituteId + tbname);
                    const request = objectStore.get(data.pk);
                    let self = this
                    this.isEndServing = true
                    
                    this.showLoading = true
                    request.onsuccess = () => {
                        let s  = request.result
                        s.status = 2
                        s.lastNumber = self.t.lastNumber 
                        s.txnNumber = self.t.txnNumber
                        if(Object.keys(s.saleUnit).length > 0){
                            const sdstatus = self.g.saleUnitStatus || 1
                            self.updateSaleUnit(s.saleUnit.id, sdstatus)
                        }
                        const res = objectStore.put(s);
                        res.onsuccess = ()=> {
                            self.bindServing()
                            setTimeout(()=>{
                                self.showLoading = false
                                self.$snotify.success(i18n.t('success'))
                                let d = {
                                    txnId: data.pk,
                                    status: 2,
                                    txnNumber: s.txnNumber,
                                    lastNumber: s.lastNumber,
                                    type: 'status'
                                }
                                commerceHandler.updateServing(d)
                                self.showServingTxn()
                                self.printServing(s)
                            }, 500)
                        }
                    }
                }, 500)
            },
            updateTxnServing(txnId, saleUnit){
                let d = {
                    txnId: txnId,
                    saleUnit: saleUnit,
                    type: 'saleunit'
                }
                commerceHandler.updateServing(d)
            },
            delIndxdb(key, tbname, func){
                // window.console.log(key, tbname, func, 'del db')
                const objectStore = db.transaction(instituteId + tbname, 'readwrite').objectStore(instituteId + tbname);
                const request = objectStore.delete(key);
                let self = this
                request.onsuccess = ()=> {
                    // window.console.log('db deleted!')
                    if(func == 'serving'){
                        self.bindServing()
                    }else if(func == 'invoice'){
                        self.bindInvoice()
                        setTimeout(()=>{
                            this.searchInv()
                        }, 500)
                    }
                }
            },
            updateLineIndxDB(key, tbname, line){
                // window.console.log(key, tbname, line, 'update db')
                const objectStore = db.transaction(instituteId + tbname, 'readwrite').objectStore(instituteId + tbname);
                const request = objectStore.get(key);
                request.onsuccess = ()=> {
                    const student = request.result;
                    // Change the name property
                    student.itemLines = line;
                    // Create a request to update
                    const updateRequest = objectStore.put(student);
                    updateRequest.onsuccess = () => {
                        // window.console.log('db updated!')
                    }
                }
            },
            showServingTxn(){
                this.servingLines = []
                if(this.servings.length > 0){
                    this.servings.forEach((s, inx)=>{
                        let d = new Date(s.orderDate)
                        let t = kendo.toString(d, 'hh:ss:tt')
                        this.servingLines.push({
                            pk: s.pk,
                            total: parseFloat(s.total),
                            saleUnit: Object.keys(s.saleUnit).length > 0 ?  Object.keys(s.saleUnit.name).length > 0 ? s.saleUnit.name : '' : '',
                            saleUnitId: Object.keys(s.saleUnit).length > 0 ?  Object.keys(s.saleUnit.id).length > 0 ? s.saleUnit.id : '' : '',
                            user: Object.keys(s.pinUser).length > 0 ? s.pinUser.name: '',
                            status: s.status,
                            hour: t,
                            serveNum: inx + 1
                        })
                    })
                    // window.console.log(this.servingLines, 'serve line')
                    this.dialogServingLine = true
                }else{
                    this.$snotify.error(i18n.t('no_serving'))
                }
            },
            showPreOrderTxn(){
                if(this.preorders.length > 0){
                    this.$emit("onUpdate", this.preorders);
                    this.dialogPreOrder = true
                }else{
                    this.$snotify.error(i18n.t('no_serving'))
                }
            },
            // order
            showOrderTxn(){
                if(this.txnorders.length > 0){
                    // this.$emit("onUpdate", this.invoices);
                    this.orderSearchText = ''
                    this.searchOrder()
                    this.dialogOrderTxn = true
                }else{
                    this.$snotify.error(i18n.t('no_order'))
                }
            },
            searchOrder(){
                if(this.orderSearchText != ''){
                    // window.console.log(this.invoices)
                    let pres = []
                    this.ordLines.forEach(pr=>{
                        let phone = pr.namePhone.toLowerCase() || ''
                        if(phone != ''){
                            // window.console.log(phone, this.invSearchText)
                            if(phone.indexOf(this.orderSearchText.toLowerCase()) !== -1){
                                pres.push(pr)
                            }
                        }
                    })
                    this.orderLines = pres
                }else{
                    let lines = []
                    this.txnorders.forEach(inv=>{
                        lines.push({
                            pk: inv.pk,
                            issuedDate: inv.issuedDate,
                            customer: {
                                name: inv.customer.name
                            },
                            total: inv.total,
                            phoneNumber: inv.phoneNumber,
                            txnNumber: inv.txnNumber,
                            namePhone: inv.customer.name + ' (' + inv.phoneNumber + ')'
                        })
                    })
                    // window.console.log(lines, 'invlines')
                    this.ordLines = lines
                    this.orderLines = lines
                }
            },
            ordRowNumber(dataItem) {
                let ds = this.$refs.gridOrderTxn.kendoWidget(),
                    index = ds.indexOf(dataItem);
                return index + 1;
            },
            //re print bill
            async rePrintBill(e){
                e.preventDefault();
                const grid = $("#saleSummary").data("kendoGrid")
                const dataItem = grid.dataItem($(e.currentTarget).closest("tr"))
                window.console.log(dataItem, 'reprint');
                let itmLine = []
                let d = {
                    sk: dataItem.pk,
                    pk: 'txnline-',
                    key: {}
                }
                await commerceHandler.genGets(d).then(res => {
                    // window.console.log(res, 'response serving')
                    if(res.data.data.Items.length > 0){
                        itmLine = res.data.data.Items
                    }
                })
                dataItem.itemLines = itmLine
                dataItem.secondRate = this.secondRate
                dataItem.baseRate = this.baseRate
                this.printObj = dataItem
                this.$emit("onUpdate", this.printObj);
                this.showCardInv = false
                setTimeout(()=>{
                    this.showCardInv = true
                }, 300)
                this.dialogPrint = true
                setTimeout(()=>{
                    this.printHtml()
                }, 500)
                // let inv = this.invoices.filter((o) => {return o.pk == dataItema.pk})
                // let dataItem = inv[0]
                // let itmLine = dataItem.itemLines
                // if(dataItem.itemLines.length == 0){
                //     let d = {
                //         sk: dataItem.pk,
                //         pk: 'txnline-',
                //         key: {}
                //     }
                //     await commerceHandler.genGets(d).then(res => {
                //         // window.console.log(res, 'response serving')
                //         if(res.data.data.Items.length > 0){
                //             itmLine = res.data.data.Items
                //             this.updateLineIndxDB(dataItem.pk, 'invoices', itmLine)
                //         }
                //     })
                // }
                // itmLine.forEach(l=>{
                //     l.uom.toUOM = l.uom
                // })
                // dataItem.itemLines = itmLine
                // this.activeInvoice = dataItem
                // this.createPrintReceipt(new PosTransactionModel(dataItem))
                // this.lineDS = itmLine
                // this.t = new PosTransactionModel(dataItem)
                // this.t.txnId = dataItem.pk
                // this.t.type = 'sale'
                // // window.console.log(this.t, this.lineDS, 'inv cash receipt')
                // this.payInvoice = true
            },
            // invoice
            async cashReceipt(e) {
                e.preventDefault();
                const grid = $("#gridInvoice").data("kendoGrid"),
                    dataItema = grid.dataItem($(e.currentTarget).closest("tr"));
                // window.console.log(dataItem, 'inv line');
                let inv = this.invoices.filter((o) => {return o.pk == dataItema.pk})
                let dataItem = inv[0]
                let itmLine = dataItem.itemLines
                if(dataItem.itemLines.length == 0){
                    let d = {
                        sk: dataItem.pk,
                        pk: 'txnline-',
                        key: {}
                    }
                    await commerceHandler.genGets(d).then(res => {
                        // window.console.log(res, 'response serving')
                        if(res.data.data.Items.length > 0){
                            itmLine = res.data.data.Items
                            this.updateLineIndxDB(dataItem.pk, 'invoices', itmLine)
                        }
                    })
                }
                itmLine.forEach(l=>{
                    l.uom.toUOM = l.uom
                })
                dataItem.itemLines = itmLine
                this.activeInvoice = dataItem
                this.createPrintReceipt(new PosTransactionModel(dataItem))
                this.lineDS = itmLine
                this.t = new PosTransactionModel(dataItem)
                this.t.txnId = dataItem.pk
                this.t.type = 'sale'
                // window.console.log(this.t, this.lineDS, 'inv cash receipt')
                this.payInvoice = true
                this.checkOut()
            },
            invRowNumber(dataItem) {
                let ds = this.$refs.gridInvoice.kendoWidget(),
                    index = ds.indexOf(dataItem);
                return index + 1;
            },
            searchInv(){
                if(this.invSearchText != ''){
                    // window.console.log(this.invoices)
                    let pres = []
                    this.invLines.forEach(pr=>{
                        let phone = pr.namePhone.toLowerCase() || ''
                        if(phone != ''){
                            // window.console.log(phone, this.invSearchText)
                            if(phone.indexOf(this.invSearchText.toLowerCase()) !== -1){
                                pres.push(pr)
                            }
                        }
                    })
                    this.invoiceLines = pres
                }else{
                    let lines = []
                    this.invoices.forEach(inv=>{
                        lines.push({
                            pk: inv.pk,
                            issuedDate: inv.issuedDate,
                            customer: {
                                name: inv.customer.name
                            },
                            total: inv.total,
                            phoneNumber: inv.phoneNumber,
                            txnNumber: inv.txnNumber,
                            namePhone: inv.customer.name + ' (' + inv.phoneNumber + ')'
                        })
                    })
                    // window.console.log(lines, 'invlines')
                    this.invLines = lines
                    this.invoiceLines = lines
                }
            },
            showInvoice(){
                if(this.invoices.length > 0){
                    // this.$emit("onUpdate", this.invoices);
                    this.invSearchText = ''
                    this.searchInv()
                    this.dialogInvoice = true
                }else{
                    this.$snotify.error(i18n.t('no_invoice'))
                }
            },
            selectLine(d){
                // window.console.log(d, 'select line')
                this.activeLine = d
            },
            //indexeddb
            //end indexddb
            // sidebar
            onMouseOver() {
                if (this.miniVariant) {
                    this.isAccountLogo = true;
                    this.backHoverColor = "";
                }
                this.minWidth = false;
            },
            onMouseLeave: function () {
                if (this.miniVariant) {
                    this.isAccountLogo = false;
                    this.backHoverColor = "#F8F8F9 !important";
                }
                this.minWidth = false;
            },
            hideSidebar() {
                this.miniVariant = !this.miniVariant;
                this.miniVariant ? (this.handOver = true) : (this.handOver = false);
                if (this.miniVariant) {
                    this.isAbsolute = true;
                    this.isAccountLogo = true;
                    this.isApp = false;

                    // window.console.log(this.miniVariant,'abc')
                } else {
                    this.isAbsolute = false;
                    this.isAccountLogo = false;
                    this.isApp = true;
                }
                this.isAccountLogo = true;
            },
            // guest count
            guestIncLocalMen(){
                this.guestCount.localMen += 1
            },
            guestDecLocalMen(){
                if(this.guestCount.localMen > 0){
                    this.guestCount.localMen -= 1
                }
            },
            guestIncLocalWomen(){
                this.guestCount.localWomen += 1
            },
            guestDecLocalWomen(){
                if(this.guestCount.localWomen > 0){
                    this.guestCount.localWomen -= 1
                }
            },
            guestIncForMen(){
                this.guestCount.forMen += 1
            },
            guestDecForMen(){
                if(this.guestCount.forMen > 0){
                    this.guestCount.forMen -= 1
                }
            },
            guestIncForWomen(){
                this.guestCount.forWomen += 1
            },
            guestDecForWomen(){
                if(this.guestCount.forWomen > 0){
                    this.guestCount.forWomen -= 1
                }
            },
            skipGuestCount(){
                this.dialogCountGuest = false
                this.resetGuest()
                this.checkOrderFlow()
            },
            enterGuestCount(){
                let g = parseInt(this.guestCount.localMen) + parseInt(this.guestCount.localWomen) + parseInt(this.guestCount.forMen) + parseInt(this.guestCount.forWomen)
                if(g <= 0){
                    this.$snotify.error(i18n.t('guest_count_required'))
                    return
                }
                this.dialogCountGuest = false
                this.t.guestCount = this.guestCount
                this.checkOrderFlow()
            },
            skipFlowPin(){
                this.pinActivate = false
                if(this.isOrderFlow){
                    this.checkOrderFlow()
                }
            },
            // partner
            skipPartner(){
                this.dialogPartner = false
                this.dialogPartnerRef = false
                this.checkOrderFlow()
            },
            skipPartnerRef(){
                this.dialogPartner = false
                this.dialogPartnerRef = false
                this.checkOrderFlow()
            },
            async enterPartnerRef(){
                let p = this.activePartner
                this.t.partner = {
                    id: p.pk,
                    name: p.name,
                    refNum: this.partnerRefNum
                }
                this.t.customer = {
                    id: p.pk,
                    name: p.name,
                    type: 'partner'
                }
                this.dialogPartner = false
                this.dialogPartnerRef = false
                this.isPartnerPay = true
                this.t.paymentMethod = 'partner'
                if(p.settlementMethod == 'credit'){
                    this.t.isCredit = true
                    this.saveTxn()
                }else{
                    this.dialogPayment = true
                }
            },
            // order type
            skipOrderType(){
                this.dialogOrderType = false
                this.checkOrderFlow()
            },
            enterOrderType(e){
                this.activeOrderType = e
                this.t.orderType = e
                this.dialogOrderType = false
                this.checkOrderFlow()
            },
            // partner
            skipSaleUnit(){
                this.dialogSaleUnit = false
                this.dialogOrder = false
                this.checkOrderFlow()
            },
            enterSaleUnit(){
                if(Object.keys(this.t.saleUnit).length == 0){
                    this.$snotify.error(i18n.t('please_select_sale_unit'))
                    return
                }
                this.dialogSaleUnit = false
                this.dialogOrder = false
                this.checkOrderFlow()
            },
            backSaleUnit(){
                this.showSaleUnitCate = true
            },
            hasHistory () { 
                return window.history.length > 2 
            },
            cancel() {
                this.$swal({
                    title: i18n.t("msg_title_warning"),
                    text: i18n.t("msg_discard"),
                    icon: "warning",
                    showCancelButton: true,
                    cancelButtonText: i18n.t("cancel"),
                    confirmButtonColor: "#4d4848",
                    cancelButtonColor: "#eb0238",
                    confirmButtonText: i18n.t("discard"),
                }).then((result) => {
                    if (result.value) {
                        // this.clear();
                        this.$router.go(-1);
                    }
                });
            },
            close() {
            this.$router.go(-1);
            },
            selectPartner(d){
                window.console.log(d, 'selected partner')
                if(d.campaigns.length > 0){
                    d.campaigns.forEach(cam=>{
                        this.campaigns.forEach(c=>{
                            if(c.pk == cam.campaign.id){
                                // window.console.log(c, 'paartner campaign')
                                this.applyPromotion(c)
                            }
                        })
                    })
                }
                this.activePartner = d
                this.dialogPartnerRef = true;
                this.dialogPartner = false;
            },
            ToPromotionForm(){
                this.dialogPromotion = false;
            },
            ToRewardForm(){
                this.rewardForm = true;
                this.dialogReward = false;
            },
            LoyaltyToSale(){
                this.dialogPartner = true;
                this.dialogLoyalty = false;
            },
            InfoBank(){
                this.infoBank = true;
            },
            cardPayment(type){
                this.dialogCardPayment = true;
                this.cardPay.type = type
            },
            clickMe(data) {
                // alert(data.link)
                this.$router.push(`${data.link}`);
                //this.$event.target.classList.toggle(active)
                //eslint-disable-next-line no-console
                console.log(data.link)
                //eslint-disable-next-line no-console
                //console.log(data)
            }, 
            hideTabs(){
                this.isHide = !this.isHide;
            },
            toggle() {
                window.console.log(this.fullscreen, 'click')
                localStorage.setItem('commercefullscreen', !this.fullscreen)
                this.$fullscreen.toggle(document.getElementsByTagName("body")[0], {
                    wrap: false,
                    callback: this.fullscreenChange,
                });
            },
            fullscreenChange(fullscreen) {
                this.fullscreen = fullscreen;
                // if(!fullscreen){
                //     let loc = localStorage.getItem('commercefullscreen')
                //     if(loc != null){
                //         if(loc){
                //             $('body').removeClass('fullscreen')
                //             setTimeout(() => {
                //                 window.onload = this.fullS()
                //             }, 300)  
                //         }
                //     }
                // }
            },
            fullS(){
                window.moveTo(0, 0);

                if (document.all) {
                    top.window.resizeTo(screen.availWidth, screen.availHeight);
                }

                else if (document.layers || document.getElementById) {
                    if (top.window.outerHeight < screen.availHeight || top.window.outerWidth < screen.availWidth) {
                        top.window.outerHeight = screen.availHeight;
                        top.window.outerWidth = screen.availWidth;
                    }
                }
            },
            // pin
            pinClick(num){
                this.incorrect = false
                if(num == 'clear'){
                    this.pin = ''
                }else{
                    this.pin = this.pin + num.toString()
                    // window.console.log(this.pin, 'click pin')
                    if(this.pin.length == 4){
                        this.showLoading = true
                        this.searchPin()
                    }
                }
            },
            searchPin(){
                // window.console.log(this.pin, 'pin')
                let activePin = this.pins.filter((o) => {return o.pinCode == this.pin})
                // window.console.log(activePin, 'actvie pin')
                if(activePin.length > 0){
                    this.activePin = activePin[0]
                    this.t.pinUser = {
                        name: activePin[0].name,
                        id: activePin[0].pk
                    }
                    this.pin = ''
                    if(this.isEndShift){
                        this.saveEndShift()
                        setTimeout(()=>{
                            this.pinActivate = false
                            let pname = this.session.openBy || ''
                            this.activePin = {
                                name: pname,
                                pinCode: ''
                            }
                        }, 300)
                    }else if(this.isEndSession){
                        this.saveSession()
                        setTimeout(()=>{
                            // this.pinActivate = false
                            let pname = this.session.openBy || ''
                            this.activePin = {
                                name: pname,
                                pinCode: ''
                            }
                        }, 300)
                    }else if(this.isCountCash){
                        this.loadCashCount()
                        this.isCountCash = false
                        setTimeout(()=>{
                            this.pinActivate = false
                            let pname = this.session.openBy || ''
                            this.activePin = {
                                name: pname,
                                pinCode: ''
                            }
                        }, 300)
                    }else if(this.isStartSession){
                        this.isStartSession = false
                        this.saveAddSession()
                        setTimeout(()=>{
                            this.pinActivate = false
                            let pname = this.session.openBy || ''
                            this.activePin = {
                                name: pname,
                                pinCode: ''
                            }
                        }, 300)
                    }else{
                        this.checkOrderFlow()
                        this.pinActivate = false
                    }
                }else{
                    this.pin = ''
                    this.activePin = {
                        name: this.session.openBy,
                        pinCode: this.session.pk
                    }
                    this.$snotify.error(i18n.t('wrong_pin'))
                }
                this.showLoading = false
            },
            // verifyclick
            vClick(num){
                if(num == 'clear'){
                    this.verifyCode = ''
                }else{
                    this.verifyCode = this.verifyCode + num.toString()
                }
            },
            // searchCardclick
            cClick(num){
                if(num == 'clear'){
                    this.lcardNumber = ''
                }else{
                    this.lcardNumber = this.lcardNumber + num.toString()
                }
            },
            // split
            insert1(event) {
                this.items1.splice(event.index, 0, event.data);
            },
            insert2(event) {
                this.items2.splice(event.index, 0, event.data);
            },
            remove(array, value) {
                let index = array.indexOf(value);
                array.splice(index, 1);
            },
            // marge
            insert3(event) {
                this.merge1.splice(event.index, 0, event.data);
            },
            insert4(event) {
                this.merge2.splice(event.index, 0, event.data);
            },
            // data
            async loadPriceLevel(num) {
                this.showLoading = true;
                const strFilter = "?nature=sale";
                priceLevelHandler.get(strFilter).then((res) => {
                    this.showLoading = false;
                    this.priceLevels = res;
                    localStorage.setItem(instituteId + 'pricelevel', JSON.stringify(res));
                });
                window.console.log(num)
                this.loadPrice = true 
                this.checkPullDataComplete()
            },
            loadSPPLevel(id){
                this.priceProducts = []
                let d = {
                    key: {},
                    priceLevelId: id
                }
                let isLast = true
                commerceHandler.getPLItem(d).then((res) => {
                    let data = res.data.data
                    if(data.Items.length > 0){
                        data.Items.forEach(e => {
                            this.priceProducts.push(e)
                        })
                    }else{
                        this.loadPrice = true 
                        this.checkPullDataComplete()
                    }
                    if(data.hasOwnProperty('LastEvaluatedKey')){
                        this.lastPPKey = data.LastEvaluatedKey
                        this.getPriceProMore(this.lastPPKey, id, isLast)
                    }else{
                        if(isLast){
                            this.bindPriceProduct()
                        }
                    }
                });
            },
            loadProductPriceLevel(){
                this.priceProducts = []
                let i = 1
                let isLast = false
                this.priceLevels.forEach(e => {
                    i++
                    if(i == this.priceLevels.length){
                        isLast = true
                    }
                    let d = {
                        key: {},
                        priceLevelId: e.id
                    }
                    commerceHandler.getPLItem(d).then((res) => {
                        let data = res.data.data
                        // window.console.log(data, 'itm pp')
                        if(data.Items.length > 0){
                            data.Items.forEach(e => {
                                this.priceProducts.push(e)
                            })
                        }else{
                            this.loadPrice = true 
                            this.checkPullDataComplete()
                        }
                        if(data.hasOwnProperty('LastEvaluatedKey')){
                            this.lastPPKey = data.LastEvaluatedKey
                            this.getPriceProMore(this.lastPPKey, e.id, isLast)
                        }else{
                            if(isLast){
                                this.bindPriceProduct()
                            }
                        }
                    });
                })
            },
            getPriceProMore(key, id, isLast){
                let d = {
                    key: key,
                    priceLevelId: id
                }
                commerceHandler.getPLItem(d).then((res) => {
                    let data = res.data.data
                    if(data.Items.length > 0){
                        // this.bindPriceProduct(data.Items)
                        data.Items.forEach(e => {
                            this.priceProducts.push(e)
                        })
                    }
                    if(data.hasOwnProperty('LastEvaluatedKey')){
                        this.lastPPKey = data.LastEvaluatedKey
                        this.getPriceProMore(this.lastPPKey, id, isLast)
                    }else{
                        if(isLast){
                            this.bindPriceProduct()
                        }
                    }
                })
            },
            bindPriceProduct(){
                if(isIndDB){
                    let transaction = db.transaction(instituteId + "itemPrice", "readwrite");
                    let inx = transaction.objectStore(instituteId + "itemPrice");
                    let j = 0
                    this.priceProducts.forEach(e => {
                        inx.put({
                            id: e.sk,
                            ...e
                        })
                        j++
                        if(j == this.priceProducts.length){
                            this.loadPrice = true 
                            this.checkPullDataComplete()
                        }
                    })
                }
            },
            // Setting
            async loadSetting(){
                commerceHandler.settingGet(this.register.pk).then((res) => {
                    let d = res.data.data
                    this.loadingSetting = true
                    this.checkPullDataComplete()
                    // for retail
                    let set = d
                    if(set.length > 0){
                        this.addSetting(d[0])
                        this.g = new generalSettingModel(d[0])
                        this.searchCNum = false
                        this.searchCPhone = false
                        this.searchCNFC = false
                        this.searchCOrd = false
                        if(this.g.searchCard.length > 0){
                            this.g.searchCard.forEach(sc=>{
                                if(sc == 1){
                                    this.searchCNum = true
                                }else if(sc == 2){
                                    this.searchCPhone = true
                                }else if(sc == 3){
                                    this.searchCNFC = true
                                }else if(sc == 4){
                                    this.searchCOrd = true
                                }
                            })
                        }
                        if(this.activeStore.hasOwnProperty('product')){
                            if(this.activeStore.product == 2){
                                this.g.usedCategory = this.g.usedCateAdmin
                            }
                        }
                        // window.console.log(this.g, 'setting')
                        //check sale unit
                        if (this.g.saleUnitItem) {
                            this.loadSaleUnitCategory()
                            this.loadSaleUnitItems()
                        }
                        //check employee
                        this.loadEmp = false
                        if(this.g.employee){
                            this.loadEmployee()
                        }else{
                            this.loadEmp = true
                            this.checkPullDataComplete()
                        }
                        //check modifier
                        this.loadModifier = false
                        if(this.g.modifier){
                            this.loadModifiers()
                        }else{
                            this.loadModifier = true
                            this.checkPullDataComplete()
                        }
                        //check pricelevel
                        if(this.g.allowSelectPriceLevel){
                            this.loadPriceLevel(1)
                        }else{
                            this.loadPriceLevel(2)
                        }
                        //pull item
                        window.console.log(this.g.usedCategory, this.activeStore.product, 'used category')
                        if(this.g.usedCategory.length > 0){
                            this.allItems = []
                            this.loadProductCate(0, {})
                        }else{
                            this.loadItem = true
                            this.checkPullDataComplete()
                        }
                    }else{
                        this.gotoSetting()
                    }
                })
            },
            checkOtherFunction(){
                //check function
                this.allowFLoyalty = false
                this.allowFPreorder = false
                this.allowFSCard = false
                this.allowFPSale = false
                this.allowFSSesion = false
                this.allowFESesion = false
                this.allowFIntUsage = false
                this.allowFPLookup = false
                this.allowDisCard = false
                this.allowCampaign = false
                // window.console.log(this.g.otherFunction, 'check function ')
                if(this.g.otherFunction.length > 0){
                    this.g.otherFunction.forEach(f=>{
                        if(f == 'loyalty'){
                            this.allowFLoyalty = true
                        }else if(f == 'preorder'){
                            this.allowFPreorder = true
                        }else if(f == 'internalusage'){
                            this.allowFIntUsage = true
                        }else if(f == 'productlookup'){
                            this.allowFPLookup = true
                        }else if(f == 'searchcard'){
                            this.allowFSCard = true
                        }else if(f == 'parksale'){
                            this.allowFPSale = true
                        }else if(f == 'startsession'){
                            this.allowFSSesion = true
                        }else if(f == 'endsession'){
                            this.allowFESesion = true
                        }else if(f == 'discard'){
                            this.allowDisCard = true
                        }else if(f == 'campaigns'){
                            this.allowCampaign = true
                        }
                    })
                }
            },
            addSetting(data){
                localStorage.setItem(instituteId + 'commRSetting', JSON.stringify(data));
            },
            gotoSetting(){
                this.$swal({
                    position: 'center',
                    icon: 'warning',
                    title: i18n.t('please_configure_setting'),
                    showConfirmButton: true,
                    confirmButtonColor: '#4d4848',
                    cancelButtonColor: '#eb0238',
                    confirmButtonText: i18n.t('back_to_setting')
                }).then((result) => {
                    if (result.value) {
                        window.history.go(-1)
                        return false
                    }
                })
            },
            isshowItem(){
                this.showCatePage = false
                this.showGroupPage = false
                this.showSupGroupPage = false
                this.sCardTap = false
                this.showItem = true
            },
            // Category
            searchItemByCate(cateId){
                // this.favCate = false
                // this.items = this.itemDatas
                // this.loadingItmBlock = true
                // // setTimeout(() => {
                // let itm = this.items.filter((o) => { return o.categoryId == cateId })
                // if(itm.length > 0){
                //     this.items = itm
                //     // window.console.log(itm, 'item by category')
                //     if(cateId != ''){
                //         this.cateGroup = this.cateGroupData.filter((o) => {return o.category.id == cateId})
                //     }
                //     this.loadingItmBlock = false
                //     if(this.g.itemFlow == 1){
                //         this.goGroup(cateId)
                //     }else{
                //         this.isshowItem()
                //     }
                // }else{
                //     this.loadingItmBlock = false
                //     this.msgNoData()
                // }
                this.favCate = false
                this.loadingItmBlock = true
                let txn = db.transaction(instituteId + "items", "readonly");
                let i = txn.objectStore(instituteId + "items");
                const myIndex = i.index("cateId");
                const getRequest = myIndex.getAll(cateId);
                // this.showLoading = true
                let self = this
                getRequest.onsuccess = () => {
                    self.loadingItmBlock = false

                    console.log(getRequest.result, 'here reuslt');
                    let itm = getRequest.result
                    if(itm.length > 0){
                        // self.items = itm
                        self.bindItemsPrice(itm)
                        if(cateId != ''){
                            self.cateGroup = this.cateGroupData.filter((o) => {return o.category.id == cateId})
                        }
                        self.loadingItmBlock = false
                        if(self.g.itemFlow == 1){
                            self.goGroup(cateId)
                        }else{
                            self.isshowItem()
                        }
                    }else{
                        self.loadingItmBlock = false
                        self.msgNoData()
                    }
                };
            },
            async loadProductCate(idx, key){
                let isLast = false
                let cateId = this.g.usedCategory[idx].id
                let catename = this.g.usedCategory[idx].name
                window.console.log(idx, catename, cateId)
                if((idx + 1) == this.g.usedCategory.length){
                    isLast = true
                }
                let d = {
                    categoryId: cateId,
                    warehouseId: this.activeStore.warehouseId,
                    key: key
                }
                commerceHandler.getItemByCate(d).then(res=>{
                    let data = res.data.data
                    if(data.Items.length > 0){
                        data.Items.forEach(e=>{
                            this.allItems.push(e)
                        })
                    }
                    if(data.hasOwnProperty('LastEvaluatedKey')){
                        let l = data.LastEvaluatedKey
                        this.loadProductCate(idx, l)
                    }else{
                        if(isLast){
                            this.showLoadingP = false
                            this.bindAllItem()
                            this.loadItem = true
                            this.checkPullDataComplete()
                        }else{
                            this.loadProductCate(idx + 1, {})
                        }
                    }
                })
            },
            bindAllItemCate(items){
                if(isIndDB){
                    let transaction = db.transaction(instituteId + "items", "readwrite");
                    let inx = transaction.objectStore(instituteId + "items");
                    window.console.log(items.length, 'bind item')
                    // items.forEach(e => {
                    //     inx.put({
                    //         id: e.pk,
                    //         ...e
                    //     })
                    // })
                    items.forEach(e => {
                        let group = e.group.id || 'NoGroup'
                        let cate = e.category.id || 'NoCategory'
                        let sku = e.sku || 'NoSKU'
                        let barcode = e.objExtra.barcode || 'NoBarcode'
                        inx.put({
                            ...e,
                            id: e.pk,
                            cateId: cate.toString(),
                            groupId: group.toString(),
                            sku: sku.toString(),
                            barcode: barcode.toString()
                        })
                    })
                    window.console.log(this.allItems, 'all items')
                }
            },
            async loadCategory() {
                const param = {
                    pattern: "grp",
                };
                groupHandler.getAllv2(param).then((res) => {
                    localStorage.setItem(instituteId + 'group', JSON.stringify(res.data.data));
                    this.loadGroup = true
                    this.checkPullDataComplete()
                });
                let p = {
                    key: {},
                    pattern: "sgr",
                }
                subGroupHandler.getv2(p).then((res) => {
                    localStorage.setItem(instituteId + 'subGroup', JSON.stringify(res.data.data));
                    this.loadSGroup = true
                    this.checkPullDataComplete()
                })
                // this.loadAllProduct()
            },
            goCategory(){
                this.categories = this.g.usedCategory
                this.categories.forEach(v=>{
                    v.borderColor = 'border: 1px solid ' + v.color
                    v.titleColor = 'background-color: ' + v.color
                    // window.console.log(typeof v.img)
                    if(typeof v.img == 'object'){
                        let img = v.img || {}
                        let def = img.default || {}
                        let image = def.thumb || ''
                        let limg = ''
                        if(image != ''){
                            limg = 'https://s3-ap-southeast-1.amazonaws.com/images.banhji/' + image
                        }
                        v.img = limg
                    }
                })
                this.showCatePage = true
                this.showGroupPage = false
                this.showSupGroupPage = false
                this.sCardTap = false
                this.showItem = false
            },
            searchItemByGroup(id){
                // this.favCate = false
                // this.items = this.itemDatas
                // this.loadingItmBlock = true
                // let title = id.substring(4, 0);
                // let itm = []
                // if(title != 'none'){
                //     itm = this.items.filter((o) => { return o.groupId == id })
                // }else{
                //     let c = id.split('_')
                //     // window.console.log(c, 'group')
                //     itm = this.items.filter((o) => { return o.groupId == "" && o.categoryId == c[1] })
                // }
                // if(itm.length > 0){
                //     this.items = itm
                //     this.loadingItmBlock = false
                //     this.isshowItem()
                // }else{
                //     this.loadingItmBlock = false
                //     this.msgNoData()
                // }
                this.favCate = false
                this.loadingItmBlock = true
                let title = id.substring(4, 0);
                if(title == 'none'){
                    let c = id.split('_')
                    // // window.console.log(c, 'group')
                    // itm = this.items.filter((o) => { return o.groupId == "" && o.categoryId == c[1] })
                    let txn = db.transaction(instituteId + "items", "readonly");
                    let i = txn.objectStore(instituteId + "items");
                    const myIndex = i.index("cateId");
                    const getRequest = myIndex.getAll(c[1]);
                    // this.showLoading = true
                    let self = this
                    getRequest.onsuccess = () => {
                        self.loadingItmBlock = false
                        // console.log(getRequest.result, 'here reuslt');
                        let itm = getRequest.result
                        let items = []
                        itm.forEach(it=>{
                            if(it.groupId == 'NoGroup'){
                                items.push(it)
                            }
                        })
                        if(items.length > 0){
                            self.bindItemsPrice(items)
                            self.isshowItem()
                        }else{
                            self.loadingItmBlock = false
                            self.msgNoData()
                        }
                    };
                }else{
                    let txn = db.transaction(instituteId + "items", "readonly");
                    let i = txn.objectStore(instituteId + "items");
                    const myIndex = i.index("groupId");
                    const getRequest = myIndex.getAll(id);
                    // this.showLoading = true
                    let self = this
                    getRequest.onsuccess = () => {
                        self.loadingItmBlock = false
                        // console.log(getRequest.result, 'here reuslt');
                        let itm = getRequest.result
                        if(itm.length > 0){
                            // self.items = itm
                            self.bindItemsPrice(itm)
                            self.isshowItem()
                        }else{
                            self.loadingItmBlock = false
                            self.msgNoData()
                        }
                    };
                }
            },
            goGroup(cateId){
                // window.console.log(this.cateGroup, 'go group')
                if(cateId != ''){
                    this.cateGroup = this.cateGroupData.filter((o) => {return o.category.id == cateId})
                }
                $.each(this.cateGroup, function(i,v){
                    v.borderColor = 'border: 1px solid ' + v.color
                    v.titleColor = 'background-color: ' + v.color
                })
                if(this.cateGroup.length == 0){
                    this.showCatePage = false
                    this.showGroupPage = false
                    this.showSupGroupPage = false
                    this.sCardTap = false
                    this.showItem = true
                }else{
                    this.showCatePage = false
                    this.showGroupPage = true
                    this.showSupGroupPage = false
                    this.sCardTap = false
                    this.showItem = false
                    let nog = {
                        id: 'none_' + cateId,
                        name: i18n.t('no_group'),
                        category: {
                            id: cateId
                        }
                    }
                    // window.console.log(nog, 'nog')
                    this.cateGroup.push(nog)
                    // window.console.log(this.cateGroup, 'cate group')
                }
            },
            searchItemBySubGroup(id){
                // window.console.log(id)
                // this.bindItems()
                this.items = this.itemDatas
                this.loadingItmBlock = true
                // setTimeout(() => {
                let itm = this.items.filter((o) => { return o.subGroupId == id })
                if(itm.length > 0){
                    this.items = itm
                    this.loadingItmBlock = false
                    this.isshowItem()
                }else{
                    this.loadingItmBlock = false
                    this.msgNoData()
                }
                // }, 500)
            },
            goSubGroup(){
                // window.console.log(this.subGroup, 'go group')
                $.each(this.subGroup, function(i,v){
                    v.borderColor = 'border: 1px solid ' + v.color
                    v.titleColor = 'background-color: ' + v.color
                })
                this.showCatePage = false
                this.showGroupPage = false
                this.showSupGroupPage = true
                this.sCardTap = false
                this.showItem = false
            },
            itemFavorite(){
                this.items = this.itemDatas
                this.loadingItmBlock = true
                // setTimeout(() => {
                let itm = this.items.filter((o) => { return o.isFav == true })
                if(itm.length > 0){
                    this.items = itm
                    this.loadingItmBlock = false
                    this.favCate = true
                    this.isshowItem()
                }else{
                    this.loadingItmBlock = false
                    this.msgNoData()
                }
                // }, 500)
            },
            msgNoData(){
                this.$snotify.error(i18n.t('no_item_found'))
            },
            // Items
            async loadAllProduct(){
                let data = {
                    key: {}
                }
                //load products
                this.showLoadingP = true
                this.allItems = []
                commerceHandler.getAllItem(data).then((res) => {
                    let data = res.data.data
                    if(data.Items.length > 0){
                        data.Items.forEach(e => {
                            this.allItems.push(e)
                        })
                    }else{
                        this.loadItem = true
                        this.checkPullDataComplete()
                    }
                    if(data.hasOwnProperty('LastEvaluatedKey')){
                        this.lastPKey = data.LastEvaluatedKey
                        this.getMoreItem(this.lastPKey)
                    }else{
                        this.showLoadingP = false
                        this.bindAllItem()
                    }
                });
            },
            bindAllItem(){
                if(isIndDB){
                    let transaction = db.transaction(instituteId + "items", "readwrite");
                    let inx = transaction.objectStore(instituteId + "items");
                    let j = 0
                    // this.allItems.forEach(e => {
                    //     inx.put({
                    //         id: e.pk,
                    //         ...e
                    //     })
                    //     j++
                    //     if(j == this.allItems.length){
                    //         // window.console.log(inx, 'bind item')
                    //         this.loadItem = true
                    //         this.checkPullDataComplete()
                    //     }
                    // })
                    this.allItems.forEach(e => {
                        let group = e.group.id || 'NoGroup'
                        let cate = e.category.id || 'NoCategory'
                        let sku = e.sku || 'NoSKU'
                        let barcode = e.objExtra.barcode || 'NoBarcode'
                        inx.put({
                            ...e,
                            id: e.pk,
                            cateId: cate.toString(),
                            groupId: group.toString(),
                            sku: sku.toString(),
                            barcode: barcode.toString()
                        })
                        j++
                        if(j == this.allItems.length){
                            // window.console.log(inx, 'bind item')
                            this.loadItem = true
                            this.checkPullDataComplete()
                        }
                    })
                    window.console.log(this.allItems, 'all items')
                }
            },
            getMoreItem(key){
                let data = {
                    key: key
                }
                commerceHandler.getAllItem(data).then((res) => {
                    let data = res.data.data
                    if(data.Items.length > 0){
                        data.Items.forEach(e => {
                            this.allItems.push(e)
                        })
                    }
                    if(data.hasOwnProperty('LastEvaluatedKey')){
                        this.lastPKey = data.LastEvaluatedKey
                        this.getMoreItem(this.lastPKey)
                    }else{
                        this.showLoadingP = false
                        this.bindAllItem()
                    }
                })
            },
            numberFormat(value) {
                // window.console.log(value, this.decimal, 'number format')
                return kendo.toString(parseFloat(value), `n${this.decimal}`);
            },
            dateTimeFormat(date){
                return kendo.toString(new Date(date), 'dd/MMM/yyyy HH:mm:ss');
            },
            loadDiscountItem(){
                this.discountItems = []
                let d = localStorage.getItem(instituteId + 'disItem');
                if(d == null){
                    this.showLoading = true;
                    discountItemHandler.list("?type=Sale").then((res) => {
                        this.showLoading = false;
                        if (res.data.statusCode === 200){ 
                            this.discountItems = res.data.data;
                            localStorage.setItem(instituteId + 'disItem', JSON.stringify(res.data.data));
                        }
                    });
                }else{
                    this.discountItems = JSON.parse(localStorage.getItem(instituteId + 'disItem'))
                }
            },
            priceChange(){
                this.lineDS = []
                this.bindItems()
            },
            loadTopUpRate(){
                let data = {
                    type: 'top_up'
                }
                loyaltyHandler.loyaltyCardSettingGet(data).then((res) => {
                    const data = res.data.data
                    // window.console.log(data, 'top up')
                    if (data.length > 0) {
                        // window.console.log(data[0], 'top up rate')
                        localStorage.setItem(instituteId + 'topuprate', JSON.stringify(data[0]))
                    }
                })
            },
            async loadSession(){
                let d = {
                    regiPk: this.register.pk,
                    sesStatus: 'open',
                    indexName: 'regiPk-sesStatus-index',
                    condition: 'regiPk = :regiPk and sesStatus = :sesStatus',
                    type: 'searchSession'
                }
                localStorage.removeItem(instituteId + 'session');
                await commerceHandler.generalGet(d).then(res=>{
                    window.console.log(res, 'res from start session')
                    if(res.data.data.Items.length > 0){
                        localStorage.setItem(instituteId + 'session', JSON.stringify(res.data.data.Items[0]))
                    }
                })
            },
            //pull data
            async pullData(){
                this.loadTopUpRate()
                this.loadSession()
                this.loadPullData = true
                this.loadingSetting = false
                this.loadPrice = false
                this.loadSGroup = false
                this.loadGroup = false
                this.loadItem = false
                this.loadReward = false
                this.isLoadPartner = false
                this.isLoadOrderType = false
                this.isLoadModiCate = false
                //clear indexdb
                await this.clearItemInd()
                await this.clearItemPriceInd()
                await this.clearModiInd()
                await this.clearEmpInd()
                await this.clearCamInd()
                await this.clearCusInd()
                //clear txn
                await this.clearInvInd()
                await this.clearServingInd()
                await this.clearPreOrderInd()
                //
                await this.loadSetting()
                await this.loadCustomers({})
                await this.loadCategory()
                await this.loadRewardProgram()
                await this.loadPartner()
                await this.loadOrderType()
                await this.loadModiCategory()
                await this.loadCampaign()
                
                this.hasInv = false
                this.invs = []
                this.loadInv({})
                this.hasServing = false
                this.servs = []
                this.loadServe({})
                this.hasPre = false
                this.pres = []
                this.loadPres({})
                localStorage.setItem(instituteId + 'pulldate', new Date().getTime())
            },
            // invoice
            async clearInvInd(){
                const t = db.transaction(instituteId + "invoices", "readwrite");
                // create an object store on the transaction
                const objectStore = t.objectStore(instituteId + "invoices");
                const objectStoreRequest = objectStore.clear();
                objectStoreRequest.onsuccess = () => {
                    // report the success of our request
                    window.console.log('clear invoices')
                };
            },
            bindInv(d){
                // window.console.log(d, 'bind inv')
                d.forEach(data => {
                    this.invs.push(data)
                })
            },
            loadInv(key){
                let data = {
                    gsi2: this.register.pk + '#invoice#1#',
                    key: key
                }
                commerceHandler.getGsi2(data).then(res=>{
                    if(res.data.data.Items.length > 0){
                        this.bindInv(res.data.data.Items)
                        if(res.data.data.hasOwnProperty('LastEvaluatedKey')){
                            this.loadInv(res.data.data.LastEvaluatedKey)
                        }else{
                            this.saveLocalInv()
                        }
                    }else{
                        this.hasInv = true
                        this.checkPullDataComplete()
                    }
                })
            },
            saveLocalInv(){
                if(isIndDB){
                    let t = db.transaction(instituteId + "invoices", "readwrite");
                    let i = t.objectStore(instituteId + "invoices");
                    // let j = 0
                    // window.console.log(this.invs, 'set inv')
                    this.invs.forEach(e => {
                        i.put({
                            id: e.pk,
                            ...e
                        })
                        // j++
                        // if(j == this.invs.length){
                        //     window.console.log(i, 'bind invoice')
                        // }
                    })
                    this.hasInv = true
                    this.checkPullDataComplete()
                }
            },
            // serving
            async clearServingInd(){
                const t = db.transaction(instituteId + "serving", "readwrite");
                // create an object store on the transaction
                const objectStore = t.objectStore(instituteId + "serving");
                const objectStoreRequest = objectStore.clear();
                objectStoreRequest.onsuccess = () => {
                    // report the success of our request
                    window.console.log('clear serving')
                };
            },
            bindServe(d){
                // window.console.log(d, 'bind inv')
                d.forEach(data => {
                    this.servs.push(data)
                })
            },
            loadServe(key){
                let data = {
                    gsi2: this.register.pk + '#serving#1#',
                    key: key
                }
                commerceHandler.getGsi2(data).then(res=>{
                    // window.console.log(res, 'seve res')
                    if(res.data.data.Items.length > 0){
                        this.bindServe(res.data.data.Items)
                        if(res.data.data.hasOwnProperty('LastEvaluatedKey')){
                            this.loadServe(res.data.data.LastEvaluatedKey)
                        }else{
                            this.saveLocalServe()
                        }
                    }else{
                        this.hasServing = true
                        this.checkPullDataComplete()
                    }
                })
            },
            saveLocalServe(){
                if(isIndDB){
                    let t = db.transaction(instituteId + "serving", "readwrite");
                    let i = t.objectStore(instituteId + "serving");
                    let j = 0
                    // window.console.log(this.servs, 'set serving')
                    this.servs.forEach(e => {
                        i.put({
                            id: e.pk,
                            ...e
                        })
                        j++
                        if(j == this.servs.length){
                            window.console.log(i, 'bind invoice')
                        }
                    })
                    this.hasServing = true
                    this.checkPullDataComplete()
                }
            },
            // pre order
            async clearPreOrderInd(){
                const t = db.transaction(instituteId + "preorders", "readwrite");
                // create an object store on the transaction
                const objectStore = t.objectStore(instituteId + "preorders");
                const objectStoreRequest = objectStore.clear();
                objectStoreRequest.onsuccess = () => {
                    // report the success of our request
                    window.console.log('clear pre order')
                };
            },
            bindPreo(d){
                // window.console.log(d, 'bind inv')
                d.forEach(data => {
                    this.pres.push(data)
                })
            },
            loadPres(key){
                let data = {
                    gsi2: this.register.pk + '#preorder#1#',
                    key: key
                }
                commerceHandler.getGsi2(data).then(res=>{
                    // window.console.log(res, 'seve res')
                    if(res.data.data.Items.length > 0){
                        this.bindPreo(res.data.data.Items)
                        if(res.data.data.hasOwnProperty('LastEvaluatedKey')){
                            this.loadPres(res.data.data.LastEvaluatedKey)
                        }else{
                            this.saveLocalPres()
                        }
                    }else{
                        this.hasPre = true
                        this.checkPullDataComplete()
                    }
                })
            },
            saveLocalPres(){
                if(isIndDB){
                    let t = db.transaction(instituteId + "preorders", "readwrite");
                    let i = t.objectStore(instituteId + "preorders");
                    let j = 0
                    // window.console.log(this.invs, 'set inv')
                    this.pres.forEach(e => {
                        i.put({
                            id: e.pk,
                            ...e
                        })
                        j++
                        if(j == this.pres.length){
                            window.console.log(i, 'bind pre order')
                        }
                    })
                    this.hasPre = true
                    this.checkPullDataComplete()
                }
            },
            // modifer
            async loadModiCategory() {
                this.showLoading = true;
                itemModifierHandler.cateGetAll().then((res) => {
                    this.showLoading = false;
                    if (res.data.statusCode === 200) {
                        this.modiCategories = res.data.data
                        localStorage.setItem(instituteId + 'modiCategory', JSON.stringify(this.modiCategories))
                        this.isLoadModiCate = true
                        this.checkPullDataComplete()
                    }
                })
            },
            async clearItemInd(){
                const t = db.transaction(instituteId + "items", "readwrite");
                // create an object store on the transaction
                const objectStore = t.objectStore(instituteId + "items");
                const objectStoreRequest = objectStore.clear();
                objectStoreRequest.onsuccess = () => {
                    // report the success of our request
                    window.console.log('clear item')
                };
            },
            async clearItemPriceInd(){
                const t = db.transaction(instituteId + "itemPrice", "readwrite");
                // create an object store on the transaction
                const objectStore = t.objectStore(instituteId + "itemPrice");
                const objectStoreRequest = objectStore.clear();
                objectStoreRequest.onsuccess = () => {
                    // report the success of our request
                    window.console.log('clear itemPrice')
                };
            },
            async clearModiInd(){
                const t = db.transaction(instituteId + "modifier", "readwrite");
                // create an object store on the transaction
                const objectStore = t.objectStore(instituteId + "modifier");
                const objectStoreRequest = objectStore.clear();
                objectStoreRequest.onsuccess = () => {
                    // report the success of our request
                    window.console.log('clear modifier')
                };
            },
            async clearEmpInd(){
                const t = db.transaction(instituteId + "employee", "readwrite");
                // create an object store on the transaction
                const objectStore = t.objectStore(instituteId + "employee");
                const objectStoreRequest = objectStore.clear();
                objectStoreRequest.onsuccess = () => {
                    // report the success of our request
                    window.console.log('clear employee')
                };
            },
            async clearCusInd(){
                const t = db.transaction(instituteId + "customer", "readwrite");
                // create an object store on the transaction
                const objectStore = t.objectStore(instituteId + "customer");
                const objectStoreRequest = objectStore.clear();
                objectStoreRequest.onsuccess = () => {
                    // report the success of our request
                    window.console.log('clear customer')
                };
            },
            async loadCustomers(key){
                let d = {
                    sk: instituteId,
                    pk: 'cus-',
                    key: key,
                    type: 'entity'
                }
                if(Object.keys(key).length == 0){
                    this.customers = []
                }
                await commerceHandler.genGets(d).then(res => {
                    window.console.log(res, d, 'response customer')
                    let data = res.data.data
                    if(data.Items.length > 0){
                        data.Items.forEach(cus=>{
                            this.customers.push(cus)
                        })
                    }
                    if(data.hasOwnProperty('LastEvaluatedKey')){
                        // this.loadCustomers(data.LastEvaluatedKey)
                        window.console.log(data.LastEvaluatedKey, 'last key')
                    }else{
                        this.setCustomerInd()
                    }
                })
            },
            setCustomerInd(){
                if(isIndDB){
                    let t = db.transaction(instituteId + "customer", "readwrite");
                    let i = t.objectStore(instituteId + "customer");
                    let j = 0
                    this.customers.forEach(e => {
                        let abbrnumber = "NoAbbrNum"
                        let phonenumber = "NoPhone"
                        if(e.hasOwnProperty("objName")){
                            phonenumber = e.objName.phone || 'NoPhone'
                        }
                        if(e.hasOwnProperty('abbr')){
                            abbrnumber = e.abbr
                        }
                        if(e.hasOwnProperty('number')){
                            abbrnumber += '-' +e.number
                        }
                        i.put({
                            ...e,
                            id: e.pk,
                            abbrnumber: abbrnumber,
                            phonenumber: phonenumber
                        })
                        j++
                        if(j == this.customers.length){
                            window.console.log(i, 'bind campaign')
                        }
                    })
                }
            },
            async clearCamInd(){
                const t = db.transaction(instituteId + "campaign", "readwrite");
                // create an object store on the transaction
                const objectStore = t.objectStore(instituteId + "campaign");
                const objectStoreRequest = objectStore.clear();
                objectStoreRequest.onsuccess = () => {
                    // report the success of our request
                    window.console.log('clear campaign')
                };
            },
            checkPullDataComplete(){
                window.console.log(this.loadingSetting,
                    this.loadSGroup, 
                    this.loadGroup,
                    this.loadPrice, 
                    this.loadModifier,
                    this.loadItem,
                    this.loadReward,
                    this.isLoadPartner,
                    this.isLoadOrderType,
                    this.isLoadModiCate,
                    this.loadCam,
                    this.loadEmp,
                    this.hasInv,
                    this.hasServing
                )
                if(
                    this.loadingSetting && 
                    this.loadSGroup && 
                    this.loadGroup && 
                    this.loadPrice && 
                    this.loadModifier && 
                    this.loadItem &&
                    this.loadReward &&
                    this.isLoadPartner &&
                    this.isLoadOrderType &&
                    this.isLoadModiCate &&
                    this.loadCam &&
                    this.hasInv &&
                    this.hasServing &&
                    this.loadEmp){
                    setTimeout(() => {
                        this.loadPullData = false
                        window.location.reload()
                    }, 3000)
                }
            },
            startOrderFlow(){
                // window.console.log(this.startFlowAt, this.g.orderFlow, 'start order')
                if(this.startFlowAt < this.g.orderFlow.length){
                    this.checkOrderShow(this.g.orderFlow[this.startFlowAt].name)
                    this.startOrderAdd()
                }
            },
            checkOrderShow(func){
                // window.console.log(func, 'chck order flow')
                this.showNoBack = true
                if (func != '') {
                    this.isOrderFlow = true
                    switch(func) {
                        case 'pin':
                            if(this.g.rpin){
                                this.isLockPin = true
                            }
                            this.pin = ''
                            this.isEndDate = false
                            this.isEndShift = false
                            this.isEndSession = false
                            this.isStartSession = false
                            this.isCountCash = false
                            this.pinActivate = true
                            break;
                        case 'guestCount':
                            // window.console.log('guestcount')
                            this.dialogCountGuest = true
                            break;
                        case 'loyalty':
                            // window.console.log('loyalty')
                            this.lcardNumber = ''
                            this.dialogLoyalty = true
                            break;
                        case 'orderType':
                            // window.console.log(this.orderTypes, 'orderType')
                            if (this.orderTypes.length > 0) {
                                this.dialogOrderType = true
                            }else{
                                this.dialogOrderType = false
                                this.startFlowAt += 1
                                this.checkOrderFlow()
                            }
                            break;
                        case 'partner':
                            // window.console.log(this.partners)
                            this.dialogPartner = true
                            break;
                        case 'saleUnit':
                            // window.console.log('sale unit flow')
                            if(this.saleUnitData.length == 0){
                                this.dialogSaleUnit = false
                                this.startFlowAt += 1
                                this.checkOrderFlow()
                            }else{
                                this.checkFlowSaleUnit()
                                this.dialogSaleUnit = true
                            }
                            break;
                        default:
                            // code block
                    }
                }
            },
            startOrderAdd(){
                this.startFlowAt += 1
            },
            checkPaymentFunc(){
                this.allowPStoreCredit = false
                this.allowPCard = false
                this.allowPBank = false
                this.allowPKHQR = false
                this.allowPPoint = false
                this.allowPFleet = false
                this.allowPMvp = false
                this.allowInv = false
                if(this.g.paymentOption.length > 0){
                    this.g.paymentOption.forEach(f=>{
                        if(f == 'card'){
                            this.allowPCard = true
                        }else if(f == 'bank'){
                            this.allowPBank = true
                        }else if(f == 'khqr'){
                            this.allowPKHQR = true
                        }else if(f == 'pointcard'){
                            this.allowPPoint = true
                        }else if(f == 'storecredit'){
                            this.allowPStoreCredit = true
                        }else if(f == 'fleet'){
                            this.allowPFleet = true
                        }else if(f == 'cicmvp'){
                            this.allowPMvp = true
                        }else if(f == 'invoice'){
                            this.allowInv = true
                        }
                    })
                }
            },
            async bindData(){
                this.g = new generalSettingModel(JSON.parse(localStorage.getItem(instituteId + 'commRSetting')))
                if(this.activeStore.hasOwnProperty('product')){
                    if(this.activeStore.product == 2){
                        this.g.usedCategory = this.g.usedCateAdmin
                    }
                }
                if(this.g.userPin == true){
                    this.loadPin()
                }
                this.setItemFlow()
                this.customer = new CustomerModel(this.g.defaultCustomer)
                // window.console.log(this.customer, this.g.defaultCustomer, 'customer')
                this.checkOtherFunction()
                this.checkPaymentFunc()
                this.bindItems()
                this.bindCategory()
                this.bindPriceLevel()
                this.bindDiscountItem()
                this.bindEmployee()
                this.bindCustomer()
                this.bindCampaign()
                this.bindModifier()
                this.bindSaleUnit()
                this.bindRewardProgram()
                this.bindPartner()
                this.bindOrderType()
                this.bindModiCate()
                this.resetOrder()
                this.bindServing()
                this.bindInvoice()
                this.bindPreOrder()
                this.bindParkSale()
            },
            bindPreOrder(){
                // items
                let txn = db.transaction(instituteId + "preorders", "readwrite");
                let i = txn.objectStore(instituteId + "preorders");
                let item = []
                let self = this
                i.getAll().onsuccess = function(event) {
                    item = event.target.result
                    self.preorders = item
                }
            },
            bindInvoice(){
                // items
                let txn = db.transaction(instituteId + "invoices", "readwrite");
                let i = txn.objectStore(instituteId + "invoices");
                let item = []
                let self = this
                i.getAll().onsuccess = function(event) {
                    item = event.target.result
                    self.invoices = item
                }
            },
            bindOffTxn(){
                // items
                let txn = db.transaction(instituteId + "offlinetxn", "readwrite");
                let i = txn.objectStore(instituteId + "offlinetxn");
                let item = []
                this.offlinetxns = []
                let self = this
                i.getAll().onsuccess = function(event) {
                    item = event.target.result
                    self.offlinetxns = item
                }
            },
            bindServing(){
                // items
                let txn = db.transaction(instituteId + "serving", "readwrite");
                let i = txn.objectStore(instituteId + "serving");
                let item = []
                let self = this
                i.getAll().onsuccess = function(event) {
                    item = event.target.result
                    self.servings = item
                    // window.console.log(self.servings, 'serving')
                    self.bindSaleUnitServing()
                }
            },
            bindModiCate(){
                let d = JSON.parse(localStorage.getItem(instituteId + 'modiCategory'))
                this.modiCategories = d
            },
            bindParkSale(){
                let d = JSON.parse(localStorage.getItem(instituteId + 'parkSale'))
                if(d != null){
                    this.parkSaleTxns = d
                }else{
                    this.parkSaleTxns = []
                }
            },
            bindModifier(){
                // items
                let txn = db.transaction(instituteId + "modifier", "readwrite");
                let i = txn.objectStore(instituteId + "modifier");
                let item = []
                let self = this
                i.getAll().onsuccess = function(event) {
                    item = event.target.result
                    self.modifiers = item
                    // window.console.log(item, 'bind local modifier')
                }
            },
            bindEmployee(){
                // items
                let txn = db.transaction(instituteId + "employee", "readwrite");
                let i = txn.objectStore(instituteId + "employee");
                let item = []
                let self = this
                i.getAll().onsuccess = function(event) {
                    item = event.target.result
                    let fdata = []
                    if(self.g.hasOwnProperty('usedEmp')){
                        if(self.g.usedEmp.length > 0){
                            let cat = []
                            self.g.usedEmp.forEach(c=>{
                                cat.push(c.id)
                            })
                            let su = []
                            item.forEach(dd=>{
                                if($.inArray(dd.department.id, cat) != -1) {
                                    if(dd.status == "active"){
                                        dd.name = dd.firstName + ' ' + dd.lastName
                                        su.push(dd)
                                    }
                                    
                                }
                            })
                            // window.console.log(cat, 'emp')
                            fdata = su
                        }
                    }
                    // window.console.log(fdata, 'emp')
                    self.employees = fdata
                }
            },
            bindCustomer(){
                // items
                let txn = db.transaction(instituteId + "customer", "readwrite");
                let i = txn.objectStore(instituteId + "customer");
                let item = []
                let self = this
                i.getAll().onsuccess = function(event) {
                    item = event.target.result
                    let cus = []
                    item.forEach(c=>{
                        let cutype = c.customerType || {}
                        let group = ''
                        if(cutype.hasOwnProperty('name')){
                            group = cutype.name
                        }
                        cus.push({
                            id: c.pk,
                            name: c.name,
                            abbr: c.abbr || '',
                            group: group,
                            number: c.number || ''
                        })
                    })
                    self.customers = cus
                }
            },
            bindCampaign(){
                // items
                let txn = db.transaction(instituteId + "campaign", "readwrite");
                let i = txn.objectStore(instituteId + "campaign");
                let item = []
                let self = this
                i.getAll().onsuccess = function(event) {
                    item = event.target.result
                    self.campaigns = item
                    // window.console.log(item, 'bind campaign')
                }
            },
            bindSaleUnit(){
                let d = JSON.parse(localStorage.getItem(instituteId + 'saleUnit'))
                if(d == null){
                    return
                }
                d.forEach(dd=>{
                    dd.status = dd.status || 0
                })
                d.sort(function (a, b) {
                    return parseFloat(a.code) - parseFloat(b.code)
                })
                let fdata = []
                // window.console.log(d, 'sale unit')
                if(this.g.hasOwnProperty('usedSaleUnit')){
                    if(this.g.usedSaleUnit.length > 0){
                        let cat = []
                        this.g.usedSaleUnit.forEach(c=>{
                            cat.push(c.id)
                        })
                        let su = []
                        d.forEach(dd=>{
                            // window.console.log(dd.category.id, cat, 'sale uni')
                            if($.inArray(dd.category.id, cat) != -1) {
                                su.push(dd)
                            }
                        })
                        // window.console.log(su, 'after')
                        fdata = su
                    }
                }else{
                    fdata = d
                }
                this.saleUnits = fdata.filter((s)=>{return s.status == 1})
                this.saleUnitData = fdata
                // sale unit category
                let slcate = JSON.parse(localStorage.getItem(instituteId + 'saleUnitCategory'))
                if(slcate != null){
                    slcate.sort(function (a, b) {
                        return parseFloat(a.code) - parseFloat(b.code)
                    })
                }
                this.saleUnitCates = slcate
                // window.console.log(this.saleUnitCates, 'sale unit cate')
                this.bindSaleUnitServing()
            },
            bindOrderType(){
                window.console.log('you are bind order type')
                //order type
                let d = JSON.parse(localStorage.getItem(instituteId + 'orderType'))
                this.orderTypes = d
                //bank wallet
                let w = JSON.parse(localStorage.getItem(instituteId + 'bankWallet'))
                w.forEach(b=>{
                    b.uid = uuid.v1()
                })
                this.bankWallets = w
                //txn number
                let t = JSON.parse(localStorage.getItem(instituteId + 'txnNumberFormat'))
                // window.console.log(t, 'of')
                this.txnNumberFormat = t
                //ordernumber
                let o = JSON.parse(localStorage.getItem(instituteId + 'orderNumberFormat'))
                this.orderNumberFormat = o
            },
            bindRewardProgram(){
                let d = JSON.parse(localStorage.getItem(instituteId + 'rewardProgram'))
                this.rewardPrograms = d
            },
            bindPartner(){
                let d = JSON.parse(localStorage.getItem(instituteId + 'partner'))
                if(d != null){
                    d.forEach(dd=>{
                        let image  = ''
                        if(Object.keys(dd.img).length > 0){
                            let def = dd.img.default || {}
                            image = def.thumb
                        }
                        dd.image = image
                    })
                }
                this.partners = d
            },
            bindPriceLevel(){
                this.priceLevels = JSON.parse(localStorage.getItem(instituteId + 'pricelevel'))
            },
            bindDiscountItem(){
                this.discountItems = JSON.parse(localStorage.getItem(instituteId + 'disItem'))
            },
            // checkOtherFunction(){
            //     this.g.otherFunction.forEach(e => {
            //         switch(e.id) {
            //             case 'reward':
            //                 this.func.reward = true
            //                 break;
            //             case 'promotion':
            //                 this.func.promotion = true
            //                 break;
            //             case 'parksale':
            //                 this.func.parksale = true
            //                 break;
            //             case 'invoice':
            //                 this.func.invoice = true
            //                 break;
            //             case 'note':
            //                 this.func.note = true
            //                 break;
            //             case 'delivery':
            //                 this.func.delivery = true
            //                 break;
            //             case 'resetOrder':
            //                 this.func.resetOrder = true
            //                 break;
            //             case 'splitInv':
            //                 this.func.splitInv = true
            //                 break;
            //             case 'clearOrder':
            //                 this.func.clearOrder = true
            //                 break;
            //             case 'saleUnit':
            //                 this.func.saleUnit = true
            //                 break;
            //             case 'countGuest':
            //                 this.func.countGuest = true
            //                 break;
            //             case 'mergeInv':
            //                 this.func.mergeInv = true
            //                 break;
            //             case 'orderList':
            //                 this.func.orderList = true
            //                 break;
            //             case 'orderType':
            //                 this.func.orderType = true
            //                 break;
            //             default:
            //                 break;
            //         }
            //     })
            // },
            bindCategory(){
                this.categories = this.g.usedCategory
                this.cateGroup = JSON.parse(localStorage.getItem(instituteId + 'group'))
                this.cateGroupData = JSON.parse(localStorage.getItem(instituteId + 'group'))
                this.subGroup = JSON.parse(localStorage.getItem(instituteId + 'subGroup'))
                this.subGroupData = JSON.parse(localStorage.getItem(instituteId + 'subGroup'))
            },
            bindItems(){
                // let txn = db.transaction(instituteId + "items", "readwrite");
                // let i = txn.objectStore(instituteId + "items");
                // let item = []
                // let self = this
                // this.showBindItmLocal = true
                // i.getAll().onsuccess = function(event) {
                //     item = event.target.result
                //     self.rawItems = item
                //     self.checkDublicateItems(item)
                //     self.showBindItmLocal = false
                // }
                // i.onerror = function(e){
                //     window.console.log(e)
                //     self.showBindItmLocal = false
                // }
            },
            checkDublicateItems(items){
                let ditems = []
                window.console.log(this.rawItems.length, 'all item')
                items.forEach(itm=>{
                    let d = this.rawItems.filter((o)=>{return o.barcode == itm.barcode})
                    if(d.length > 1){
                        ditems.push(itm)
                    }
                })
                window.console.log(ditems, 'dublicate itmes')
                if(ditems.length > 0){
                    this.allowDubItem = true
                    let datas = []
                    let noPrice = []
                    ditems.forEach(a=>{
                        datas.push({
                            'barcode': a.barcode,
                            'name': a.name,
                            'sku': a.sku,
                            'category': a.category.name,
                            'price': a.priceItems[0].price,
                            'uom': a.uom.name
                        })
                        noPrice.push({
                            pk: a.pk,
                            sk: a.sk
                        })
                    })
                    this.dublicateItems = datas
                    window.console.log(noPrice, 'no Price')
                    // this.delDub(noPrice)
                }
            },
            delDub(items){
                let stock = {
                    items: items,
                    type: 'deldublicate'
                }
                commerceHandler.stockPost(stock)
            },
            bindItemsPrice(item){
                this.items = []
                this.itemDatas = []
                if(item.length > 0){
                    let myarray = []
                    this.g.usedCategory.forEach(e => {
                        myarray.push(e.id)
                    })
                    let items = []
                    item.forEach(e => {
                        let cate = e.category || {}
                        let cateId = cate.id || ''
                        let group = e.group || {}
                        let groupId = group.id || ''
                        let subG = e.subGroup || {}
                        let subGroupId = subG.id || ''
                        e.categoryId = cateId
                        e.groupId = groupId
                        e.subGroupId = subGroupId
                        if($.inArray(e.categoryId, myarray) != -1) {
                            let tod = new Date().getTime()
                            let priceItems = e.priceItems || []
                            let itp = priceItems.filter((o) => {
                                return this.g.defaultPriceLevel == o.priceLevel.id && new Date(o.appliedDate).getTime() <= tod
                            })
                            let cate = this.g.usedCategory.filter((a) => {
                                return a.id == e.categoryId
                            })
                            if(itp.length > 0){
                                let uoms = []
                                let price = e.price
                                itp.sort(function (a, b) {
                                    return Date.parse(b.appliedDate_) - Date.parse(a.appliedDate_)
                                })
                                let uarray = []
                                itp.forEach(u=>{
                                    if($.inArray(u.uomId, uarray) == -1) {
                                        uoms.push({
                                            price: parseFloat(u.price),
                                            ...u.uom
                                        })
                                        uarray.push(u.uomId)
                                    }
                                })
                                if(uoms.length > 0){
                                    price = parseFloat(uoms[0].price)
                                }
                                //count stock
                                let countStock = e.objExtra.countStock || false
                                //reorder qty
                                let roq = e.objExtra.roq || 0
                                if(!countStock){
                                    roq = 0
                                }
                                items.push({
                                    id: e.pk,
                                    name: e.name,
                                    sku: e.sku,
                                    saleDescription: e.objName.itm.desc || e.name,
                                    price: price,
                                    categoryId: e.categoryId,
                                    category: cate[0],
                                    borderColor: 'border: 1px solid ' + cate[0].color,
                                    titleColor: 'background-color: ' + cate[0].color,
                                    groupId: e.group.id,
                                    subGroupId: e.subGroup.id,
                                    img: e.thumb != '' ? 'https://s3-ap-southeast-1.amazonaws.com/images.banhji/' + e.thumb : './images/default.png',
                                    uom: uoms,
                                    buom: e.uom,
                                    type: e.objExtra.type || '',
                                    number: '',
                                    countStock: countStock,
                                    productId: e.productId || '',
                                    subGroup: e.subGroup || '',
                                    saleComission: e.objExtra.saleComission || 0,
                                    barcode: e.objExtra.barcode || e.sku,
                                    stock: e.stock || 0,
                                    sstock: e.sstock || 0,
                                    cost: e.cost || 0,
                                    isFav: e.objExtra.isFav || false,
                                    roq: roq
                                })
                            }
                        }
                    })
                    items.sort(function (a, b) {
                        return a.sku - b.sku
                    })
                    this.items = items
                    this.itemDatas = items
                    this.showBindItmLocal = false
                    // window.console.log(items, 'bind items')
                }
            },
            bindItemsPriceBK(item){
                // item price
                let self = this
                let itemPrice = []
                let ptxn = db.transaction(instituteId + "itemPrice", "readwrite");
                let ip = ptxn.objectStore(instituteId + "itemPrice");
                ip.getAll().onsuccess = function(event) {
                    itemPrice = event.target.result
                    // window.console.log(itemPrice, 'it price')
                    self.items = []
                    self.itemDatas = []
                    if(item.length > 0){
                        let myarray = []
                        self.g.usedCategory.forEach(e => {
                            myarray.push(e.id)
                        })
                        let items = []
                        // let bar = []
                        item.forEach(e => {
                            if($.inArray(e.categoryId, myarray) != -1) {
                                let tod = new Date().getTime()
                                let itp = itemPrice.filter((o) => {
                                    return o.itemId == e.pk && self.g.defaultPriceLevel == o.priceLevel.id && new Date(o.appliedDate).getTime() <= tod
                                })
                                let cate = self.g.usedCategory.filter((a) => {
                                    return a.id == e.categoryId
                                })
                                // if(e.pk == 'itm-0831a411-0e69-11ee-b1e6-337cbaa3b196'){
                                //     window.console.log(itp, e, 'here price')
                                // }
                                // window.console.log(itp, self.g.defaultPriceLevel , 'bind price uom')
                                if(itp.length > 0){
                                    let uoms = []
                                    let price = e.price
                                    // if(itp.length > 1){
                                    //     window.console.log(itp, 'item price')
                                    // }
                                    itp.sort(function (a, b) {
                                        // return parseFloat(new Date(b.appliedDate_).getTime()) - parseFloat(new Date(a.appliedDate_).getTime())
                                        return Date.parse(b.appliedDate_) - Date.parse(a.appliedDate_)
                                    })
                                    let uarray = []
                                    itp.forEach(u=>{
                                        if($.inArray(u.uomId, uarray) == -1) {
                                            uoms.push({
                                                price: parseFloat(u.price),
                                                ...u.uom
                                            })
                                            uarray.push(u.uomId)
                                        }
                                    })
                                    if(uoms.length > 0){
                                        price = parseFloat(uoms[0].price)
                                    }
                                    items.push({
                                        id: e.pk,
                                        name: e.name,
                                        sku: e.sku,
                                        saleDescription: e.saleDescription || e.name,
                                        price: price,
                                        categoryId: e.categoryId,
                                        category: cate[0],
                                        borderColor: 'border: 1px solid ' + cate[0].color,
                                        titleColor: 'background-color: ' + cate[0].color,
                                        groupId: e.group.id,
                                        subGroupId: e.subGroup.id,
                                        img: e.thumb != '' ? 'https://s3-ap-southeast-1.amazonaws.com/images.banhji/' + e.thumb : './images/default.png',
                                        uom: uoms,
                                        buom: e.uom,
                                        type: e.type,
                                        number: '',
                                        countStock: e.countStock || false,
                                        productId: e.productId || '',
                                        subGroup: e.subGroup || '',
                                        saleComission: e.saleComission || 0,
                                        barcode: e.barcode || e.sku,
                                    })
                                    // bar.push({
                                    //     id: e.pk,
                                    //     barcode: e.barcode || e.sku
                                    // })
                                }
                            }
                        })
                        // self.barcodeDatas = bar
                        self.items = items
                        self.itemDatas = items
                        self.showBindItmLocal = false
                        // window.console.log(self.items, self.itemDatas, 'bind local item')
                    }
                    // self.isshowItem()
                    // window.console.log(item, itemPrice, 'bind item')
                };
            },
            checkOrderFlow(){
                // check order flow
                // window.console.log(this.g, 'setting')
                if(this.g.allowOrderFlow == true){
                    this.startOrderFlow()
                }
            },
            //sale uni
            checkFlowSaleUnit(){
                this.showSaleUnitCate = false
                if(this.g.saleUnitFlow == 1){
                    this.showSaleUnitCate = true
                }
                // window.console.log(this.g.saleUnitFlow, 'ch3ck sale unit flow')
                this.saleUnits = this.saleUnitData.filter((s)=>{return s.status == 1})
            },
            selectCateSaleUnit(d){
                this.saleUnits = this.saleUnitData.filter((o) => { return o.category.id == d.id && o.status == 1})
                // window.console.log(d.id, 'select sale uint ate')
                if(this.saleUnits.length > 0){
                    this.showSaleUnitCate = false
                }else{
                    this.$snotify.error(i18n.t('no_data_found'))
                }
            },
            selectSaleUnit(d){
                this.saleUnit = d
                this.t.saleUnit = {
                    id: d.id,
                    name: d.name,
                    code: d.code
                }
                this.enterSaleUnit()
            },
            //promotion
            async applyPromotion(cam){
                window.console.log(cam, 'apply discount')
                if(this.isEditDiscount){
                    return
                }
                window.console.log(1)
                // let dis = parseFloat(cam.rewardAmount)
                // check store 
                if(cam.stores.length > 0){
                    let hasStore = false
                    cam.stores.forEach(s=>{
                        if(s.pk == this.activeStore.pk){
                            hasStore = true
                        }
                    })
                    if(!hasStore) return
                }else{
                    return
                }
                window.console.log(2)
                // check veriffy
                if(cam.isVerifyCode && this.activeLoyaltyCard.activateTelegram !== ''){
                    window.console.log(cam, this.activeLoyaltyCard, 'need code')
                    this.isCamVerifyCode = true
                    let data = {
                        groupId: this.g.telegramGroup,
                        type: 'campaign',
                        store: this.activeStore.name,
                        storePhone: this.activeStore.phone,
                        chardId: this.activeLoyaltyCard.activateTelegram,
                        cardId: this.activeLoyaltyCard.pk,
                        username: this.activePin.name,
                        instId: instituteId
                    }
                    this.showLoading = true
                    await telegramBotHandler.posPost(data).then(()=>{
                        this.showLoading = false
                        this.verifyCode = ''
                        this.dialogCode = true
                    })
                    this.camVerify = cam
                    return
                }
                window.console.log(3)
                // check confirm code admin
                let confirmCode = cam.confirmCode || false
                if(confirmCode){
                    if(this.g.telegramGroup != ''){
                        this.camAdminConfirmCode = true
                        this.camAdminPk = 'camadmin-' + uuid.v1()
                        let data = {
                            pk: this.camAdminPk,
                            groupId: this.g.telegramGroup,
                            type: 'camadmincode',
                            store: this.activeStore.name,
                            storePhone: this.activeStore.phone,
                            username: this.activePin.name,
                            instId: instituteId
                        }
                        this.showLoading = true
                        await telegramBotHandler.posPost(data).then(()=>{
                            this.showLoading = false
                            this.verifyCode = ''
                            this.dialogCode = true
                        })
                        this.camVerify = cam
                        return
                    }else{
                        this.$snotify.error(i18n.t('please_fill_in_telegram_group_admin'))
                        return
                    }
                }
                // check time
                let validTime = true
                if(cam.isTiming){
                    validTime = false
                    let curTime = new Date().getTime()
                    let timeF = kendo.toString(new Date(), 'yyyy-MMM-dd') + ' ' + cam.timeFrom
                    let timeFrom = new Date(timeF).getTime()
                    let timeT = kendo.toString(new Date(), 'yyyy-MMM-dd') + ' ' + cam.timeTo
                    let timeTo = new Date(timeT).getTime()
                    if(curTime >= timeFrom && curTime <= timeTo){
                        validTime = true
                    }
                    // window.console.log(timeTo, timeFrom, curTime, 'compare time')
                }
                if(!validTime){
                    return
                }
                window.console.log(4)
                // check price level
                let validPriceLevel = false
                if(cam.priceLevel.id == this.g.defaultPriceLevel){
                    validPriceLevel = true
                }
                window.console.log(5, validPriceLevel)
                if(!validPriceLevel){
                    return
                }
                // check rule base
                let validRule = false
                // customer base
                if(cam.ruleBase == 'customer'){
                    if(cam.ruleCustomerBaseType == 'specific'){
                        if(cam.ruleCustomers.length > 0){
                            cam.ruleCustomers.forEach(rcus=>{
                                if(rcus.customer.id == this.g.defaultCustomer.id){
                                    validRule = true
                                }
                            })
                        }
                    }else if(cam.ruleCustomerBaseType == 'customerType'){
                        if(cam.ruleCustomerTypes.length > 0){
                            cam.ruleCustomerTypes.forEach(rcus=>{
                                if(rcus.id == this.g.defaultCustomer.customerType.id){
                                    validRule = true
                                }
                            })
                        }
                    }else{
                        validRule = true
                    }
                // product base
                }else{
                    if(cam.ruleProductBaseOn == 'specific'){
                        if(cam.ruleProduct.length > 0){
                            cam.ruleProduct.forEach(rcus=>{
                                this.lineDS.forEach(lin=>{
                                    if(rcus.item.id == lin.itemId){
                                        validRule = true
                                    }
                                })
                            })
                        }
                    }else if(cam.ruleProductBaseOn == 'category'){
                        if(cam.ruleProductCategory.length > 0){
                            cam.ruleProductCategory.forEach(rcus=>{
                                this.lineDS.forEach(lin=>{
                                    if(rcus.category.id == lin.categoryId){
                                        validRule = true
                                    }
                                })
                            })
                        }
                    }else if(cam.ruleProductBaseOn == 'group'){
                        if(cam.ruleProductGroup.length > 0){
                            cam.ruleProductGroup.forEach(rcus=>{
                                this.lineDS.forEach(lin=>{
                                    if(rcus.group.id == lin.groupId){
                                        validRule = true
                                    }
                                })
                            })
                        }
                    }else{
                        validRule = true
                    }
                }
                if(!validRule){
                    return
                }
                this.lineDS.forEach(d=>{
                    //check overise
                    if(cam.isOverwrite){
                        d.discount = 0
                        d.amount = (d.price * d.qty) // + d.modifierAmt + d.tax
                    }
                    // check reward
                    let dis = 0
                    window.console.log(cam, 'campaign')
                    if(cam.rewardBase == 'amountBase'){
                        if(cam.rewardType == 'fixed'){
                            if(cam.rewardAmountType == 'percentage'){
                                dis = (d.amount * parseFloat(cam.rewardAmount)) / 100
                            }else{
                                dis = parseFloat(cam.rewardAmount)
                            }
                        }else if(cam.rewardType == 'varian'){
                            let totalcom = 0
                            this.lineDS.forEach(lcom=>{
                                totalcom += (lcom.price * lcom.qty) + lcom.modifierAmt
                            })
                            for (let i = 0; i < cam.rewardVarian.length; i++) {
                                let va = cam.rewardVarian[i]
                                // window.console.log(va, totalcom, 'va')
                                if(va.conditionType == '>'){
                                    if(totalcom > va.conditionAmount){
                                        // window.console.log(totalcom, va.conditionAmount, va.rewardAmount, 'in condition')
                                        if(va.rewardType == 'percentage'){
                                            dis = (d.amount * parseFloat(va.rewardAmount)) / 100
                                        }else{
                                            dis = parseFloat(va.rewardAmount)
                                        }
                                        break;
                                    } 
                                }else{
                                    if(totalcom >= va.conditionAmount){
                                        // window.console.log(totalcom, va.conditionAmount, va.rewardAmount, 'in condition')
                                        if(va.rewardType == 'percentage'){
                                            dis = (d.amount * parseFloat(va.rewardAmount)) / 100
                                        }else{
                                            dis = parseFloat(va.rewardAmount)
                                        }
                                        break;
                                    } 
                                }
                                
                            }
                        }
                    }else{
                        //production bae
                        window.console.log(cam.rewardBase)
                    }
                    window.console.log(dis, 'discount ')
                    if(dis > 0){
                        d.discount = dis
                        d.amount -= dis
                        if(d.amount < 0){
                            d.discount = (d.price * d.qty) + d.modifierAmt + d.tax
                            d.amount = 0
                        }
                        
                        d.campaign = d.campaign || []
                        d.campaign.push({
                            id: cam.pk,
                            name: cam.name,
                            code: cam.code,
                            amount: d.discount
                        })
                    }
                })
                this.t.campaigns.push({
                    id: cam.pk,
                    name: cam.name,
                    code: cam.code
                })
                let alertAdmin = cam.isAlertAdminGroup || false
                if(alertAdmin){
                    this.alertAdmins.push({
                        name: cam.name,
                        code: cam.code
                    })
                    // window.console.log(this.alertAdmins, 'alert admin')
                }
                if(this.isPromoFlow ==  true){
                    this.dialogPromotion = false
                    this.chFStart += 1
                    this.chFlow()
                }
                // window.console.log(this.lineDS, 'after discount')
                // if(cam.type == 'manual'){
                //     this.calculateTotal()
                // }
                this.$snotify.success('Campaign ' + cam.name + ' applyed!')
                if(this.lineDS.length > 0 && this.g.mirrorDisplay){
                    this.addFOrderItem(this.lineDS)
                }
                if(this.dialogCamps){
                    this.dialogCamps = false
                }
                this.calculateTotal()
            },
            applyPromotionA(cam){
                if(this.isEditDiscount){
                    return
                }
                // let dis = parseFloat(cam.rewardAmount)
                // check store 
                if(cam.stores.length > 0){
                    let hasStore = false
                    cam.stores.forEach(s=>{
                        if(s.pk == this.activeStore.pk){
                            hasStore = true
                        }
                    })
                    if(!hasStore) return
                }else{
                    return
                }
                // check time
                let validTime = true
                if(cam.isTiming){
                    validTime = false
                    let curTime = new Date().getTime()
                    let timeF = kendo.toString(new Date(), 'yyyy-MMM-dd') + ' ' + cam.timeFrom
                    let timeFrom = new Date(timeF).getTime()
                    let timeT = kendo.toString(new Date(), 'yyyy-MMM-dd') + ' ' + cam.timeTo
                    let timeTo = new Date(timeT).getTime()
                    if(curTime >= timeFrom && curTime <= timeTo){
                        validTime = true
                    }
                    // window.console.log(timeTo, timeFrom, curTime, 'compare time')
                }
                if(!validTime){
                    return
                }
                // check price level
                let validPriceLevel = false
                if(cam.priceLevel.id == this.g.defaultPriceLevel){
                    validPriceLevel = true
                }
                if(!validPriceLevel){
                    return
                }
                // check rule base
                let validRule = false
                // customer base
                if(cam.ruleBase == 'customer'){
                    if(cam.ruleCustomerBaseType == 'specific'){
                        if(cam.ruleCustomers.length > 0){
                            cam.ruleCustomers.forEach(rcus=>{
                                if(rcus.customer.id == this.g.defaultCustomer.id){
                                    validRule = true
                                }
                            })
                        }
                    }else if(cam.ruleCustomerBaseType == 'customerType'){
                        if(cam.ruleCustomerTypes.length > 0){
                            cam.ruleCustomerTypes.forEach(rcus=>{
                                if(rcus.id == this.g.defaultCustomer.customerType.id){
                                    validRule = true
                                }
                            })
                        }
                    }else{
                        validRule = true
                    }
                // product base
                }else{
                    if(cam.ruleProductBaseOn == 'specific'){
                        if(cam.ruleProduct.length > 0){
                            cam.ruleProduct.forEach(rcus=>{
                                this.lineDS.forEach(lin=>{
                                    if(rcus.item.id == lin.itemId){
                                        validRule = true
                                    }
                                })
                            })
                        }
                    }else if(cam.ruleProductBaseOn == 'category'){
                        if(cam.ruleProductCategory.length > 0){
                            cam.ruleProductCategory.forEach(rcus=>{
                                this.lineDS.forEach(lin=>{
                                    if(rcus.category.id == lin.categoryId){
                                        validRule = true
                                    }
                                })
                            })
                        }
                    }else if(cam.ruleProductBaseOn == 'group'){
                        if(cam.ruleProductGroup.length > 0){
                            cam.ruleProductGroup.forEach(rcus=>{
                                this.lineDS.forEach(lin=>{
                                    if(rcus.group.id == lin.groupId){
                                        validRule = true
                                    }
                                })
                            })
                        }
                    }else{
                        validRule = true
                    }
                }
                if(!validRule){
                    return
                }
                this.lineDS.forEach(d=>{
                    //check overise
                    if(cam.isOverwrite){
                        d.discount = 0
                        d.amount = (d.price * d.qty) // + d.modifierAmt + d.tax
                    }
                    // check reward
                    let dis = 0
                    // window.console.log(cam, 'campaign')
                    if(cam.rewardBase == 'amountBase'){
                        if(cam.rewardType == 'fixed'){
                            if(cam.rewardAmountType == 'percentage'){
                                dis = (d.amount * parseFloat(cam.rewardAmount)) / 100
                            }else{
                                dis = parseFloat(cam.rewardAmount)
                            }
                        }else if(cam.rewardType == 'varian'){
                            let totalcom = 0
                            this.lineDS.forEach(lcom=>{
                                totalcom += (lcom.price * lcom.qty) + lcom.modifierAmt
                            })
                            for (let i = 0; i < cam.rewardVarian.length; i++) {
                                let va = cam.rewardVarian[i]
                                // window.console.log(va, totalcom, 'va')
                                if(va.conditionType == '>'){
                                    if(totalcom > va.conditionAmount){
                                        // window.console.log(totalcom, va.conditionAmount, va.rewardAmount, 'in condition')
                                        if(va.rewardType == 'percentage'){
                                            dis = (d.amount * parseFloat(va.rewardAmount)) / 100
                                        }else{
                                            dis = parseFloat(va.rewardAmount)
                                        }
                                        break;
                                    } 
                                }else{
                                    if(totalcom >= va.conditionAmount){
                                        // window.console.log(totalcom, va.conditionAmount, va.rewardAmount, 'in condition')
                                        if(va.rewardType == 'percentage'){
                                            dis = (d.amount * parseFloat(va.rewardAmount)) / 100
                                        }else{
                                            dis = parseFloat(va.rewardAmount)
                                        }
                                        break;
                                    } 
                                }
                                
                            }
                        }
                    }else{
                        //production bae
                        window.console.log(cam.rewardBase)
                    }
                    if(dis > 0){
                        d.discount = dis
                        d.amount -= dis
                        if(d.amount < 0){
                            d.discount = (d.price * d.qty) + d.modifierAmt + d.tax
                            d.amount = 0
                        }
                        // this.$snotify.success('Campaign ' + cam.name + ' applyed!')
                        d.campaign = d.campaign || []
                        d.campaign.push({
                            id: cam.pk,
                            name: cam.name,
                            code: cam.code,
                            amount: d.discount
                        })
                    }
                })
                // if(this.isPromoFlow == true){
                    this.dialogPromotion = false
                    this.chFStart += 1
                    this.chFlow()
                // }
                // window.console.log(this.lineDS, 'after discount')
                // if(cam.type == 'manual'){
                //     this.calculateTotal()
                // }
                if(this.lineDS.length > 0 && this.g.mirrorDisplay){
                    this.addFOrderItem(this.lineDS)
                }
            },
            //product
            async addItem(item){
                //check shift
                let ses = localStorage.getItem(instituteId + 'session')
                if(ses != null && ses != 'null'){
                    if(this.session.shifts.length >= parseInt(this.register.shifts)){
                        this.$snotify.error(i18n.t('end_session_require'))
                        this.showLoading = false
                        return
                    }
                }else{
                    this.$snotify.error(i18n.t('require_session'))
                    this.showLoading = false
                    return
                }
                if(this.activeStore.allowStock && !this.activeStore.allowNegativeStock){
                    if(item.countStock){
                        if(parseInt(item.stock) >= 1){
                            window.console.log(this.checkRemainStock(item.id), 'check stock')
                            if(this.checkRemainStock(item.id)){
                                this.addLine(item)
                            }else{
                                this.$snotify.error(i18n.t('item_no_stock'))
                                return
                            }
                        }else{
                            this.$snotify.error(i18n.t('item_no_stock'))
                            return
                        }
                    }else{
                        this.addLine(item)
                    }
                }else{
                    this.addLine(item)
                }
            },
            checkRemainStock(itmId){
                if(this.lineDS.length > 0){
                    let qty = 1
                    let stock = 0
                    this.lineDS.forEach(itm=>{
                        if(itm.itemId == itmId){
                            qty += parseInt(itm.qty)
                            stock = itm.stock
                        }
                    })
                    if((stock - qty) <= 0){
                        return false
                    }
                }
                return true
            },
            addLine(item){
                // window.console.log(this.g, 'setting')
                let u = item.uom[0]
                let dis = item.discount || 0
                let line = {
                    id: 'lin-' + uuid.v1(),
                    name: item.name,
                    sku: item.sku,
                    productId: item.productId || '',
                    otherName: item.otherName ? item.otherName : '',
                    number: item.number,
                    description: item.saleDescription != '' ? item.saleDescription : item.name,
                    saleDes: item.saleDescription != '' ? item.saleDescription : item.name,
                    amount: (parseFloat(u.price) * 1) - dis,
                    price: parseFloat(u.price) * 1,
                    qty: 1,
                    uom: u,
                    buom: u,
                    uoms: item.uom,
                    itemId: item.id,
                    itmUom: item.id + u.id,
                    discount: dis,
                    vat: 0,
                    plTax: 0,
                    spTax: 0,
                    categoryId: item.categoryId,
                    groupId: item.groupId,
                    modifierAmt: 0,
                    modifiers: [],
                    employees: [],
                    campaign: [],
                    isNew: 1,
                    comission: 0,
                    otherCharge: 0,
                    orderDate: new Date(),
                    countStock: item.countStock || false,
                    subGroup: item.subGroup || '',
                    saleComission: item.saleComission || 0,
                    sorting: new Date().getTime(),
                    barcode: item.barcode || '',
                    stock: item.stock,
                    sstock: item.sstock,
                    cost: item.cost,
                    roq: item.roq
                }
                this.activeUom = u
                this.activeLine = line
                if(this.g.allowSplitItem){
                    this.lineDS.push(line)
                }else{
                    //check uom
                    let hasSelectUom = false
                    this.g.addItemFlow.forEach(g=>{
                        if(g.name == 'uom'){
                            hasSelectUom = true
                        }
                    })
                    let hasItem = false
                    if(this.activeLine.uoms.length > 1 && hasSelectUom){
                        this.waitSelectUom = true
                        hasItem = true
                    }
                    // window.console.log(this.activeLine.uoms.length, hasSelectUom, hasItem, 'add itm')
                    if(!hasItem){
                        let noItem = true
                        this.lineDS.forEach(itm=>{
                            if(itm.itemId == line.itemId){
                                noItem = false
                                itm.qty += 1
                                itm.amount = itm.qty * itm.price
                            }
                        })
                        if(noItem){
                            this.lineDS.push(line)
                        }
                    }
                }
                this.lineDS.sort(function (a, b) {
                    return parseFloat(b.sorting) - parseFloat(a.sorting)
                })
                this.addItmFlowAt = 0
                this.checkAddFlow()
            },
            checkAddFlow(){
                // window.console.log(this.addItmFlowAt, 'add item flow')
                if(this.addItmFlowAt < this.g.addItemFlow.length){
                    this.checkAddItemShow(this.g.addItemFlow[this.addItmFlowAt].name)
                    // window.console.log(this.addItmFlowAt, 'add check flow')
                }
                // this.addItmFlowAt += 1
                // window.console.log(this.addItmFlowAt, this.g.addItemFlow.length, 'add item flow')
                if (this.addItmFlowAt == this.g.addItemFlow.length) {
                    this.calculateTotal()
                    if(this.lineDS.length > 0 && this.g.mirrorDisplay){
                        this.addFOrderItem(this.lineDS)
                    }
                }
            },
            checkAutoPromotion(){
                if(this.campaigns.length > 0){
                    this.campaigns.forEach(c=>{
                        if(c.type == 'automatic'){
                            if(c.isEndDate){
                                let edFull = kendo.toString(new Date(c.endDate), 'yyyy-MMM-dd')
                                let ed = new Date(edFull + ' 23:59:59').getTime()
                                let tdFull = kendo.toString(new Date(), 'yyyy-MMM-dd')
                                let td = new Date(tdFull + ' 00:00:00').getTime()
                                if(ed > td ){
                                    this.applyPromotion(c)
                                }
                            }else{
                                this.applyPromotion(c)
                            }
                        }
                    })
                }
            },
            async calculateTotal(){
                if(!this.isSaleGCard && !this.isTopUp){
                    await this.checkAutoPromotion()
                }
                let totalsubtotal = 0, totaldiscount = 0, totalmodi = 0, totalvat = 0, total = 0, totalpltax = 0, totalOCharge = 0, totalsptax = 0
                let pcat = this.g.plTaxCate
                let plcats = []
                if(pcat.length > 0){
                    pcat.forEach(c=>{
                        plcats.push(c.id)
                    })
                }
                this.lineDS.forEach(d=>{
                    let subtotal = this.numberSetting(d.price) * this.numberSetting(d.qty)
                    //other charge
                    let ocharge = 0
                    if(this.g.allowOCharge){
                        ocharge = (subtotal * this.numberSetting(this.g.oChargeAmt)) / 100
                    }
                    ocharge = this.numberSetting(ocharge)
                    d.otherCharge = this.numberSetting(ocharge)
                    totalOCharge += this.numberSetting(ocharge)
                    //discount
                    let amount = subtotal - this.numberSetting(d.discount)
                    amount = this.numberSetting(amount)
                    //public lighting tax
                    let allamt = amount + ocharge + this.numberSetting(d.modifierAmt)
                    let pltax = 0
                    let sptax = 0
                    allamt = this.numberSetting(allamt)
                    if(this.g.allowSPTax){
                        sptax = (0.90 * this.numberSetting(allamt)) * 0.10
                    }
                    sptax = this.numberSetting(sptax)
                    if(this.g.allowPLTax){
                        if(plcats.length > 0){
                            if($.inArray(d.categoryId, plcats) != -1) {
                                pltax = (allamt + sptax) * 0.03
                            }
                        }
                    }
                    pltax = this.numberSetting(pltax)
                    d.spTax = this.numberSetting(sptax)
                    totalsptax += this.numberSetting(sptax)
                    d.plTax = this.numberSetting(pltax)
                    totalpltax += this.numberSetting(pltax)
                    //vat
                    let vat = 0
                    if(this.g.allowTax){
                        if(this.g.taxAmt == 1){
                            vat = (allamt + pltax + sptax) * 0.1
                        }
                    }
                    d.vat = this.numberSetting(vat)
                    d.amount = this.numberSetting(amount)
                    d.discount = this.numberSetting(d.discount)
                    total += this.numberSetting(amount + pltax + sptax + vat + ocharge)
                    totalsubtotal += this.numberSetting(subtotal)
                    totaldiscount += this.numberSetting(d.discount)
                    totalmodi += this.numberSetting(d.modifierAmt)
                    totalvat += this.numberSetting(vat)
                })
                // window.console.log(this.lineDS, 'line with tax and other charge')
                this.t.otherCharge = this.numberSetting(totalOCharge)
                this.t.subTotal = this.numberSetting(totalsubtotal)
                this.t.discount = this.numberSetting(totaldiscount)
                this.t.spTax = this.numberSetting(totalsptax)
                this.t.vat = this.numberSetting(totalvat)
                this.t.tax = this.numberSetting(totalvat + totalsptax + totalpltax)
                this.t.plTax = this.numberSetting(totalpltax)
                this.t.modiAmt = this.numberSetting(totalmodi)
                this.t.total = this.numberSetting(total)
                if(this.lineDS.length > 0 && this.g.mirrorDisplay){
                    this.addFOrderItem(this.lineDS)
                }
            },
            async checkAddItemShow(func){
                if (func != '') {
                    switch(func) {
                        case 'uom':
                            this.uomDialog = false
                            if(this.activeLine.uoms.length > 1){
                                this.activeLine.uoms.forEach(a=>{
                                    a.uid = uuid.v1()
                                })
                                this.itemUOMs = this.activeLine.uoms
                                this.uomDialog = true
                            }else{
                                this.addItmFlowAt += 1
                                this.checkAddFlow()
                            }
                            break;
                        case 'modifier':
                            this.modifierDialog = false
                            this.activeSelectModi = []
                            if(this.modifiers.length > 0){
                                this.activeModi = []
                                let cat = []
                                this.modifiers.forEach(m=>{
                                    m.selected = false
                                    if(m.applyTo == 'item'){
                                        m.itemLines.forEach(itm=>{
                                            if (itm.itemId == this.activeLine.itemId) {
                                                cat.push(m)
                                            }
                                        })
                                    }else if (m.applyTo == 'category') {
                                        m.categories.forEach(itm=>{
                                            if (itm.id == this.activeLine.categoryId) {
                                                cat.push(m)
                                            }
                                        })
                                    }else{
                                        m.groups.forEach(itm=>{
                                            if (itm.id == this.activeLine.groupId) {
                                                cat.push(m)
                                            }
                                        })
                                    }
                                })
                                if(cat.length > 0){
                                    this.createModi(cat)
                                }else{
                                    this.addItmFlowAt += 1
                                    this.checkAddFlow()
                                }
                                
                            }else{
                                this.addItmFlowAt += 1
                                this.checkAddFlow()
                            }
                            break;
                        case 'employee':
                            if(this.employees.length > 0){
                                let emp = []
                                this.employees.forEach(e=>{
                                    if(e.employeeId != ''){
                                        e.selected = false
                                        e.uid = uuid.v1()
                                        emp.push(e)
                                    }
                                })
                                this.employees = emp.sort(function (a, b) {
                                    return parseFloat(a.number) - parseFloat(b.number)
                                })
                                this.employeeDialog = true
                            }else{
                                this.addItmFlowAt += 1
                                this.checkAddFlow() 
                            }
                            break;
                        default:
                            // code block
                    }
                }
            },
            deSelectModi(itm){
                this.activeModi.forEach(a=>{
                    a.items.forEach(b=>{
                        if (b.pk == itm) {
                            b.selected = false
                        }
                    })
                })
                let a = $('.' + itm + '-de')
                let b = $('.' + itm)
                a.css('display', 'none')
                b.css('display', 'block')
            },
            selectModi(itm){
                // window.console.log(itm, 'select modi')
                this.activeModi.forEach(a=>{
                    a.items.forEach(b=>{
                        if (b.pk == itm) {
                            b.selected = true
                        }
                    })
                })
                let a = $('.' + itm + '-de')
                let b = $('.' + itm)
                a.css('display', 'block')
                b.css('display', 'none')
            },
            enterModi(){
                let selectModi = []
                let totalAmt = 0
                let text = ''
                let modiDes = ''
                this.activeModi.forEach(a=>{
                    a.items.forEach(b=>{
                        if (b.selected == true) {
                            selectModi.push({
                                id: b.id,
                                price: b.price,
                                isCount: b.isCount,
                                name: b.name
                            })
                            if(parseFloat(b.price) > 0){
                                totalAmt += parseFloat(b.price)
                            }
                            text += ',' + b.name
                        }
                    })
                })
                text = text.substring(1)
                if(selectModi.length > 0){
                    if(!this.isEditModi){
                        modiDes = this.activeLine.description + '<span style="font-size: 10px; font-style: italic;" >[M:' + text + ']</span>'
                    }else{
                        modiDes = this.activeLine.saleDes + '<span style="font-size: 10px; font-style: italic;" >[M:' + text + ']</span>'
                        if(this.activeLine.employees.length > 0){
                            let texta = ''
                            this.activeLine.employees.forEach(b=>{
                                texta += ',' + b.employeeId
                            })
                            texta = texta.substring(1)
                            modiDes = modiDes + '[E:' + texta + ']'
                        }
                    }
                    this.lineDS.forEach(l=>{
                        if (l.id == this.activeLine.id) {
                            l.modifierAmt += totalAmt
                            l.amount += totalAmt
                            l.modifiers = selectModi
                            l.description = modiDes
                        }
                    })
                    this.addItmFlowAt += 1
                    this.checkAddFlow()
                    this.modifierDialog = false
                }else{
                    this.$snotify.error(i18n.t('please_select_a_modifier'))
                }
            },
            clearModi(){
                if(this.activeLine.hasOwnProperty('id')){
                    this.lineDS.forEach(l=>{
                        if (l.id == this.activeLine.id) {
                            l.modifierAmt = 0
                            l.amount = parseFloat(l.price) * parseFloat(l.qty)
                            l.modifiers = []
                            l.description = l.saleDes
                            if(l.employees.length > 0){
                                let texta = ''
                                l.employees.forEach(b=>{
                                    texta += ',' + b.employeeId
                                })
                                texta = texta.substring(1)
                                l.description = l.saleDes + '[E:' + texta + ']'
                            }
                        }
                    })
                    this.modifierDialog = false
                }
            },
            skipModi(){
                this.modifierDialog = false
                this.addItmFlowAt += 1
                this.checkAddFlow()
            },
            createModi(ct){
                let cate = []
                if(this.modiCategories.length > 0){
                    this.modiCategories.forEach(c=>{
                        let m = ct.filter((a) => {return a.modifierCategory == c.pk})
                        if(m.length > 0){
                            cate.push({
                                id: c.pk,
                                name: c.name,
                                items: m
                            })
                        }
                    })
                }
                if(cate.length > 0){
                    this.activeModi = cate
                    this.modifierDialog = true
                }
            },
            editModi(){
                if(this.activeLine.hasOwnProperty('id')){
                    window.console.log(this.activeLine, 'modi edit')
                    this.modifierDialog = false
                    this.isEditModi = false
                    this.activeSelectModi = []
                    if(this.modifiers.length > 0){
                        this.activeModi = []
                        let cat = []
                        this.modifiers.forEach(m=>{
                            m.selected = false
                            if(m.applyTo == 'item'){
                                m.itemLines.forEach(itm=>{
                                    if (itm.itemId == this.activeLine.itemId) {
                                        cat.push(m)
                                    }
                                })
                            }else if (m.applyTo == 'category') {
                                m.categories.forEach(itm=>{
                                    if (itm.id == this.activeLine.categoryId) {
                                        cat.push(m)
                                    }
                                })
                            }else{
                                m.groups.forEach(itm=>{
                                    if (itm.id == this.activeLine.groupId) {
                                        cat.push(m)
                                    }
                                })
                            }
                        })
                        // window.console.log(cat, 'modifier')
                        if(cat.length > 0){
                            this.isEditModi = true
                            this.createModi(cat)
                            if(this.activeLine.modifiers.length > 0){
                                setTimeout(() => {
                                    window.console.log(this.activeLine.modifiers, 'modi on line')
                                    this.activeLine.modifiers.forEach(m=>{
                                        this.selectModi(m.id)
                                    })
                                }, 500);
                            }
                        }else{
                            this.$snotify.error(i18n.t('there_is_no_modifier_for_this_item'))
                        }
                    }
                }
            },
            async loadUOM(id){
                this.showLoading = true
                uomConversionHandler.get(id).then(() => {
                    this.showLoading = false;
                });
            },
            enterUOM(itm){
                if(this.g.allowSplitItem){
                    this.lineDS.forEach(l=>{
                        if (l.id == this.activeLine.id) {
                            l.amount = parseFloat(itm.price) * 1
                            l.price = parseFloat(itm.price) * 1
                            l.sorting = new Date().getTime()
                            l.uom = itm
                        }
                    })
                }else{
                    window.console.log(this.lineDS, itm, 'enter uom')
                    this.activeLine.uom = itm
                    this.activeLine.amount = parseFloat(itm.price) * 1
                    this.activeLine.price = parseFloat(itm.price) * 1
                    let hasItem = false
                    this.lineDS.forEach(itm=>{
                        if(itm.itemId == this.activeLine.itemId && itm.uom.id == this.activeLine.uom.id){
                            hasItem = true
                            itm.sorting = new Date().getTime()
                            itm.qty += 1
                            itm.amount = itm.qty * itm.price
                        }
                    })
                    if(!hasItem){
                        this.lineDS.push(this.activeLine)
                    }
                }
                this.lineDS.sort(function (a, b) {
                    return parseFloat(b.sorting) - parseFloat(a.sorting)
                })
                this.uomDialog = false
                this.addItmFlowAt += 1
                this.checkAddFlow()
            },
            editUOM(){
                if(this.activeLine.hasOwnProperty('id')){
                    this.uomDialog = false
                    if(this.activeLine.uoms.length > 1){
                        this.activeLine.uoms.forEach(a=>{
                            a.uid = uuid.v1()
                        })
                        this.itemUOMs = this.activeLine.uoms
                        this.uomDialog = true
                    }else{
                        this.$snotify.error(i18n.t('there_is_only_one_uom_on_this_item'))
                    }
                }
            },
            //employee
            deSelectEmp(itm){
                this.employees.forEach(b=>{
                    if (b.id == itm) {
                        b.selected = false
                    }
                })
                // window.console.log(this.activeModi, itm, 'de select modi')
                let a = $('.' + itm + '-de')
                let b = $('.' + itm)
                // window.console.log(a, itm, 'select modi')
                a.css('display', 'none')
                b.css('display', 'block')
            },
            selectEmp(itm){
                this.employees.forEach(b=>{
                    if (b.id == itm) {
                        b.selected = true
                    }
                })
                let a = $('.' + itm + '-de')
                let b = $('.' + itm)
                // window.console.log(a, itm, 'select modi')
                a.css('display', 'block')
                b.css('display', 'none')
                // a.style.display = 'block'
            },
            enterEmp(){
                let selectModi = []
                let text = ''
                let desEmp = ''
                this.employees.forEach(b=>{
                    if (b.selected == true) {
                        selectModi.push({
                            id: b.id,
                            employeeId: b.employeeId,
                            name: b.name
                        })
                        text += ',' + b.employeeId
                    }
                })
                text = text.substring(1)
                if(selectModi.length > 0){
                    if(!this.isEditEmp){
                        desEmp = this.activeLine.description + '[E:' + text + ']'
                    }else{
                        desEmp = this.activeLine.saleDes + '[E:' + text + ']'
                        if(this.activeLine.modifiers.length > 0){
                            let texta = ''
                            this.activeLine.modifiers.forEach(b=>{
                                texta += ',' + b.name
                            })
                            texta = texta.substring(1)
                            desEmp = desEmp + '[M:' + texta + ']'
                        }
                    }
                    this.lineDS.forEach(l=>{
                        if (l.id == this.activeLine.id) {
                            l.description = desEmp
                            l.employees = selectModi
                        }
                    })
                    this.skipEmp()
                }else{
                    this.$snotify.error(i18n.t('please_select_a_employee'))
                }
            },
            skipEmp(){
                this.employeeDialog = false
                window.console.log('skip emp')
                this.addItmFlowAt += 1
                this.checkAddFlow()
            },
            editEmp(){
                if(this.activeLine.hasOwnProperty('id')){
                    if(this.employees.length > 0){
                        let emp = []
                        this.employees.forEach(e=>{
                            if(e.employeeId != ''){
                                e.selected = false
                                e.uid = uuid.v1()
                                emp.push(e)
                            }
                        })
                        this.employees = emp
                        this.isEditEmp = true
                        this.employeeDialog = true
                        if(this.activeLine.employees.length > 0){
                            setTimeout(() => {
                                // window.console.log(this.activeLine.employees, 'emp')
                                this.activeLine.employees.forEach(m=>{
                                    this.selectEmp(m.id)
                                })
                            }, 500);
                        }
                    }else{
                        this.$snotify.error(i18n.t('there_is_no_employee'))
                    }
                }
            },
            clearEmp(){
                if(this.activeLine.hasOwnProperty('id')){
                    this.lineDS.forEach(l=>{
                        if (l.id == this.activeLine.id) {
                            l.employees = []
                            l.description = l.saleDes
                            if(l.modifiers.length > 0){
                                let texta = ''
                                l.modifiers.forEach(b=>{
                                    texta += ',' + b.name
                                })
                                texta = texta.substring(1)
                                l.description = l.saleDes + '[M:' + texta + ']'
                            }
                        }
                    })
                    this.employeeDialog = false
                }
            },
            //customer
            deSelectCus(){
                this.activeCustomer = {}
            },
            selectCus(itm){
                this.activeCustomer = {}
                this.customers.forEach(b=>{
                    if (b.id == itm) {
                        this.activeCustomer = b
                    }
                })
            },
            editCus(){
                if(!this.activeCustomer.hasOwnProperty('id')){
                    this.$snotify.error(i18n.t('there_is_no_customer'))
                }
            },
            clearCus(){
                this.activeCustomer = {}
            },
            // line
            lineChange(){
                let grid = kendo.jQuery("#lineDS").data("kendoGrid")
                let selectedItem = grid.dataItem(grid.select())
                this.selectItem = selectedItem
            },
            // sale form content
            async loadSaleFormContent() {
                saleFormContentHandler.list().then((res) => {
                    if (res.data.statusCode === 200) {
                        const data = res.data.data;
                        if (data.length > 0) {
                            this.saleFormContent = data[0]
                        }
                    }
                })
            },
            async loadAccount() {
                accountHandler.getAll().then((res) => {
                    this.showLoading = false;
                    //Receivable Account
                    this.coa = res.data || [];
                    this.receivableAcc = this.coa
                    .filter((m) => m.account_type.number === 7)
                    .map((itm) => {
                        return {
                        id: itm.uuid,
                        uuid: itm.uuid,
                        name: itm.name,
                        local_name: itm.local_name,
                        number: itm.number,
                        is_taxable: itm.is_taxable,
                        banhjiAccCode: itm.banhjiAccCode,
                        group_code: itm.group_code,
                        parent_account: itm.parent_account,
                        type_code: itm.type_code,
                        account_type: itm.account_type,
                        };
                    });
                    if (this.receivableAcc.length > 0) {
                        this.invoice.receivableAcc = this.receivableAcc[0];
                    }
                });
            },
            viewRow(item){
                window.console.log(item, 'view row')
            },
            removeRow() {
                if(!this.g.allowDelItem){
                    this.$snotify.error(i18n.t('not_allow_function'))
                    return
                }
                // window.console.log(e, 'item')
                if(Object.keys(this.activeLine).length == 0){
                    return
                }
                let e = this.activeLine
                if(e.isNew == 0 && this.g.telegramGroup != ''){
                    let data = {
                        groupId: this.g.telegramGroup,
                        pk: 'txnline-' + e.id,
                        sk: this.t.id,
                        type: 'deleteline',
                        store: this.activeStore.name,
                        storePhone: this.activeStore.phone,
                        amount: parseFloat(e.amount),
                        itemName: e.name,
                        username: this.activePin.name,
                    }
                    this.delId = 'txnline-' + e.id
                    this.showLoading = true
                    telegramBotHandler.posPost(data).then(()=>{
                        this.showLoading = false
                        this.delLine = true
                        this.verifyCode = ''
                        this.dialogCode = true
                    })
                }else{
                    const index = this.lineDS.indexOf(e);
                    this.lineDS.splice(index,1);
                    this.calculateTotal()
                }
                this.addFOrderItem(this.lineDS)
            },
            removeLine(ritm) {
                let index = 0, i = 0
                this.lineDS.forEach(e => {
                    if(e.id == ritm){
                        index = i
                    }
                    i++
                })
                window.console.log(index, ritm)
                this.lineDS.splice(index, 1);
            },
            // keynote function
            enterKeyPad(){
                this.activeKeyPad = false
                this.activeQty = false
                // window.console.log(this.keyPadAmount)
                this.lineDS.forEach(itemLine => {
                    if(itemLine.id == this.activeLine.id){
                        if(this.keyFunction == 'qty'){
                            if(itemLine.qty == '' || itemLine.qty == 0){
                                itemLine.qty = 1
                            }
                            if(this.activeStore.allowStock && !this.activeStore.allowNegativeStock){
                                if(this.activeLine.countStock){
                                    if(!this.checkRemainStock(this.activeLine.id)){
                                        this.$snotify.error(i18n.t('item_no_stock'))
                                        return
                                    }
                                }
                            }
                            let amount = parseFloat(itemLine.price) * parseFloat(itemLine.qty)
                            itemLine.amount = amount
                        }else if(this.keyFunction == 'dis'){
                            if(itemLine.discount == '' || parseFloat(itemLine.discount) > itemLine.amount){
                                itemLine.discount = 0
                            }
                            let amount = (parseFloat(itemLine.price) * parseFloat(itemLine.qty)) - parseFloat(itemLine.discount)
                            itemLine.amount = amount
                        }else if(this.keyFunction == 'price'){
                            if(itemLine.price == ''){
                                itemLine.price = 0
                            }
                            let amount = parseFloat(itemLine.price) * parseFloat(itemLine.qty)
                            itemLine.amount = amount
                        }
                    }
                })
                this.calculateTotal()
                this.isEditDiscount = false
            },
            setKeyFunction(func){
                if(this.activeLine.hasOwnProperty('id')){
                    if(func == 'qty'){
                        // window.console.log(this.g, 'setting')
                        if(this.g.editQTY){
                            this.keyPadAmount = this.activeLine.qty.toString()
                        }else{
                            this.$snotify.error(i18n.t('not_allow_function'))
                            return
                        }
                    }else if(func == 'dis'){
                        if(this.g.editDis){
                            this.isEditDiscount = true
                            this.keyPadAmount = this.activeLine.discount.toString()
                        }else{
                            this.$snotify.error(i18n.t('not_allow_function'))
                            return
                        }
                    }else if(func == 'price'){
                        if(this.g.editPrice){
                            this.keyPadAmount = this.activeLine.price.toString()
                        }else{
                            this.$snotify.error(i18n.t('not_allow_function'))
                            return
                        }
                    }
                    this.activeKeyPad = true
                    this.activeQty = true
                    this.activePrice = false
                    this.activeDiscount = false
                    this.isEditDiscount = false
                    this.keyFunction = func
                    this.oldqty = this.activeLine.qty
                    this.olddis = this.activeLine.discount
                    this.oldprice = this.activeLine.price
                }else{
                    this.$snotify.error(i18n.t('item_required'))
                }
            },
            cancelKeyFunction(){
                this.activeKeyPad = false
                this.activeQty = false
                window.console.log(this.keyPadAmount)
                this.lineDS.forEach(itemLine => {
                    if(itemLine.id == this.activeLine.id){
                        itemLine.qty = this.oldqty
                        itemLine.price = this.oldprice
                        itemLine.discount = this.olddis
                    }
                })
                this.calculateTotal()
            },
            qtyOnItem(){
                window.console.log(this.activeLine)
                if(this.activeLine.hasOwnProperty('id')){
                    this.activeKeyPad = true
                    this.activeQty = true
                    this.activePrice = false
                    this.activeDiscount = false
                    this.keyPadAmount = this.activeLine.qty.toString()
                }
            },
            enterPrice(){
                this.activeKeyPad = false
                this.activePrice = false
                window.console.log(this.keyPadAmount)
                this.lineDS.forEach(itemLine => {
                    if(itemLine.id == this.selectItem.id){
                        if(itemLine.price == ''){
                            itemLine.price = 0
                        }
                        let amount = parseFloat(itemLine.uom.price) * parseFloat(itemLine.qty)
                        let xAmount = amount * parseFloat(this.invoice.txnRate)
                        itemLine.price = parseFloat(itemLine.uom.price)
                        itemLine.amount = amount
                        itemLine.exchangeAmount = xAmount
                    }
                })
                setTimeout(()=>{
                    this.autoCalculate()
                }, 300)
            },
            priceOnItem(){
                window.console.log(this.selectItem)
                if(this.selectItem.hasOwnProperty('id')){
                    this.activeKeyPad = true
                    this.activeQty = false
                    this.activePrice = true
                    this.activeDiscount = false
                    this.keyPadAmount = this.selectItem.price.toString()
                }
            },
            enterDis(){
                this.activeKeyPad = false
                this.activePrice = false
                window.console.log(this.keyPadAmount)
                this.lineDS.forEach(itemLine => {
                    if(itemLine.id == this.activeLine.id){
                        if(itemLine.discount == '' || parseFloat(itemLine.discount) > itemLine.amount){
                            itemLine.discount = 0
                        }
                        let amount = (parseFloat(itemLine.price) * parseFloat(itemLine.qty)) - parseFloat(itemLine.discount)
                        itemLine.amount = amount
                    }
                })
                this.calculateTotal()
            },
            disOnItem(){
                window.console.log(this.activeLine)
                if(this.activeLine.hasOwnProperty('id')){
                    this.activeKeyPad = true
                    this.activeQty = false
                    this.activePrice = false
                    this.activeDiscount = true
                    this.keyPadAmount = this.activeLine.discount.toString()
                }
            },
            keyPad(value){
                if(value == 'c'){
                    this.keyPadAmount = 0
                }else{
                    var amt = "";
                    if(this.keyPadAmount != "") amt = this.keyPadAmount;
                    this.keyPadAmount = amt + value.toString()
                }
                // window.console.log(this.keyPadAmount, 'key amount')
                this.lineDS.forEach(e => {
                    if(e.id == this.activeLine.id){
                        if(this.keyFunction == 'qty'){
                            e.qty = parseFloat(this.keyPadAmount)
                        } else if(this.keyFunction == 'dis'){
                            e.discount = parseFloat(this.keyPadAmount)
                        }else if(this.keyFunction == 'price'){
                            e.price = parseFloat(this.keyPadAmount)
                        }
                    }
                })
            },
            inactiveKeyPad(){
                this.selectItem = {}
                this.activeKeyPad = false
                this.activeQty = false
            },
            async loadPin() {
                this.pins = []
                if(navigator.onLine){
                    this.showLoading = true
                    commerceHandler.pinGets().then(res => {
                        this.showLoading = false
                        if (res.data.statusCode === 200) {
                            let pins = []
                            const data = res.data.data
                            if(data.length > 0){
                                data.forEach(d=>{
                                    d.register.forEach(r=>{
                                        if(r == this.register.pk){
                                            pins.push(d)
                                        }
                                    })
                                })
                            }
                            if (pins.length > 0) {
                                this.pins = pins
                                localStorage.setItem(instituteId + 'userpin', JSON.stringify(pins))
                            }else{
                                this.$snotify.error(i18n.t('no_pin_user'))
                                this.gotoSetting()
                            }
                        }
                    })
                }else{
                    let c = localStorage.getItem(instituteId + 'userpin')
                    if(c != null){
                        this.pins = JSON.parse(c)
                    }
                }
            },
            async loadSaleUnitItems() {
                this.showLoading = true;
                saleUnitItemHandler.list().then((res) => {
                    this.showLoading = false;
                    if (res.data.statusCode === 200) {
                        this.saleUnits = res.data.data;
                        this.saleUnitData = res.data.data
                        localStorage.setItem(instituteId + 'saleUnit', JSON.stringify(this.saleUnitData))
                    }
                });
            },
            async loadSaleUnitCategory() {
                this.showLoading = true;
                saleUCHandler.get().then((res) => {
                    this.showLoading = false;
                    this.saleUnitCates = res;
                    localStorage.setItem(instituteId + 'saleUnitCategory', JSON.stringify(res))
                    window.console.log(res, 'saleunit category')
                });
            },
            async loadRewardProgram() {
                this.showLoading = true;
                loyaltyHandler.rewardGets().then((res) => {
                    this.showLoading = false;
                    if (res.data.statusCode === 200) {
                        this.rewardPrograms = res.data.data
                        localStorage.setItem(instituteId + 'rewardProgram', JSON.stringify(this.rewardPrograms))
                        this.loadReward = true
                        this.checkPullDataComplete()
                    }
                });
            },
            async loadPartner() {
                this.showLoading = true;
                commerceHandler.partnerGets().then((res) => {
                    this.showLoading = false;
                    if (res.data.statusCode === 200) {
                        this.partners = res.data.data
                        window.console.log(this.partners, 'partner')
                        localStorage.setItem(instituteId + 'partner', JSON.stringify(this.partners))
                        this.isLoadPartner = true
                        this.checkPullDataComplete()
                    }
                });
            },
            async loadOrderType() {
                this.showLoading = true;
                commerceHandler.settingGet(this.activeStore.pk).then(res => {
                    this.showLoading = false
                    window.console.log(res, 'ordertype res')
                    if (res.data.statusCode === 200) {
                        const d = res.data.data
                        // let d = data.filter((o) => {return o.type == 'retail'})
                        if (d.length > 0) {
                            this.orderTypes = d[0].orderTypes
                            this.bankWallets = d[0].bankWallet
                            this.txnNumberFormat = d[0].txnNumber
                            this.orderNumberFormat = d[0].orderNum
                            this.decimal = d[0].decimal
                            localStorage.setItem(instituteId + 'orderType', JSON.stringify(this.orderTypes))
                            localStorage.setItem(instituteId + 'bankWallet', JSON.stringify(this.bankWallets))
                            localStorage.setItem(instituteId + 'txnNumberFormat', JSON.stringify(this.txnNumberFormat))
                            localStorage.setItem(instituteId + 'orderNumberFormat', JSON.stringify(this.orderNumberFormat))
                            this.isLoadOrderType = true
                            this.checkPullDataComplete()
                        }else{
                            this.isLoadOrderType = true
                            this.checkPullDataComplete()
                        }
                    }else{
                        this.isLoadOrderType = true
                        this.checkPullDataComplete()
                    }
                });
            },
            // item
            setItemFlow(){
                this.showCatePage = false
                this.showGroupPage = false
                this.showSupGroupPage = false
                this.sCardTap = false
                this.showItem = true
                // window.console.log(this.g.itemFlow, 'item flow')
                if(this.g.itemFlow != 3){
                    this.goCategory()
                }else{
                    let txn = db.transaction(instituteId + "items", "readwrite");
                    let i = txn.objectStore(instituteId + "items");
                    let item = []
                    let self = this
                    this.showBindItmLocal = true
                    i.getAll().onsuccess = function(event) {
                        item = event.target.result
                        self.rawItems = item
                        // self.checkDublicateItems(item)
                        self.bindItemsPrice(item)
                        self.showBindItmLocal = false
                    }
                    i.onerror = function(e){
                        window.console.log(e)
                        self.showBindItmLocal = false
                    }
                }
            },
            //employee
            async loadEmployee(){
                this.employees = [];
                this.showLoading = true;
                // employeeHandler.center(undefined).then((res) => {
                //     this.loadEmp = true
                //     this.checkPullDataComplete()
                //     if (res.data.statusCode === 200) {
                //         this.showLoading = false;
                //         this.employees = res.data.data;
                //         this.addEmp()
                //     }
                // }).catch((e) => {
                //     this.showLoading = false
                //     window.console.error(e);
                // })
                let d = {
                    sk: this.activeStore.pk,
                    pk: 'emp-',
                    key: {},
                    type: 'entity'
                }
                await commerceHandler.generalGets(d).then(res => {
                    // window.console.log(res, 'response employee')
                    if(res.data.data.Items.length > 0){
                        // itmLine = res.data.data.Items
                        this.loadEmp = true
                        this.checkPullDataComplete()
                        this.showLoading = false;
                        this.employees = res.data.data.Items;
                        this.employees.forEach(img=>{
                            img.id = img.pk
                        })
                        this.addEmp()
                    }else{
                        this.loadEmp = true
                        this.checkPullDataComplete()
                    }
                })
            },
            addEmp(){
                if(isIndDB){
                    let t = db.transaction(instituteId + "employee", "readwrite");
                    let i = t.objectStore(instituteId + "employee");
                    let j = 0
                    this.employees.forEach(e => {
                        i.put({
                            id: e.id,
                            ...e
                        })
                        j++
                        if(j == this.employees.length){
                            window.console.log(i, 'bind employee')
                        }
                    })
                }
            },
            //modifier
            async loadModifiers() {
                this.modifiers = [];
                this.showLoading = true;
                itemModifierHandler.list().then((res) => {
                    this.showLoading = false;
                    if(res.data.data.length > 0){
                        if (res.data.statusCode === 200) {
                            this.modifiers = res.data.data;
                            this.addModi()
                        }
                    }else{
                        this.loadModifier = true
                        this.checkPullDataComplete()
                    }
                });
            },
            addModi(){
                if(isIndDB){
                    let t = db.transaction(instituteId + "modifier", "readwrite");
                    let idx = t.objectStore(instituteId + "modifier");
                    let j = 0
                    this.modifiers.forEach(e => {
                        idx.put({
                            id: e.id,
                            ...e
                        })
                        j++
                        if(j == this.modifiers.length){
                            this.loadModifier = true
                            window.console.log(idx,this.loadModifier, 'bind modifier')
                            
                            this.checkPullDataComplete()
                        }
                    })
                }
            },
            datediff(first, second) {        
                window.console.log(first, second, 'dif date')
                return Math.round((second - first) / (1000 * 60 * 60 * 24));
            },
            parseDate(str) {
                var mdy = str.split('/');
                return new Date(mdy[2], mdy[0] - 1, mdy[1]);
            },
            // check indexdb
            checkIndexDB(){
                if(loadIndex){
                    let d = localStorage.getItem(instituteId + 'pulldate');
                    let dd = new Date(parseInt(d))
                    // window.console.log(dd, 'ssss')
                    if(d != null){
                        let days = this.datediff(dd, new Date())
                        let datesetting = 1
                        let rawsetting = JSON.parse(localStorage.getItem(instituteId + 'commRSetting'))
                        if(rawsetting.hasOwnProperty('pullDay')){
                            datesetting = rawsetting.pullDay
                        }
                        // window.console.log(datesetting, this.g.pullDay, 'date setting')
                        if(days > parseInt(datesetting)){
                            this.pullData()
                            // window.console.log(this.g, days, 'setting on check index')
                        }else{
                            this.bindData()
                        }
                        // window.console.log(this.g, days, 'setting on check index')
                        // if(new Date(dd).toISOString().substr(0, 10) != new Date().toISOString().substr(0, 10)){
                        //     this.pullData()
                        // }else{
                        //     this.bindData()
                        // }
                    }else{
                        this.pullData()
                    }
                }else{
                    setTimeout(() => {
                        this.checkIndexDB()
                    }, 300);
                }
            },
            //currency
            async loadCurrency(){
                this.currencies = []
                this.rates = []
                this.baseRate = {}
                this.secondRate = {}
                this.selectRate = {}
                this.isSecondRate = false
                this.showLoading = true
                this.hasKHR = false
                this.hasUSD = false
                if(navigator.onLine){
                    currencyHandler.list('fun-c').then(response => {
                        this.showLoading = false
                        if (response.data.statusCode === 200) {
                            // window.console.log(response, 'function currency')
                            this.currencies = response.data.data
                            localStorage.setItem(instituteId + 'currencies', JSON.stringify(this.currencies))
                            if (this.currencies.length > 0) {
                                setTimeout(()=>{
                                    this.loadRate()
                                }, 1000)
                            }else{
                                alert('Please set up functional currency on setting');
                                // window.top.close();
                            }
                        }
                    })
                }else{
                    let r = localStorage.getItem(instituteId + 'rates')
                    if(r != null){
                        this.rates = JSON.parse(r)
                        this.baseRate = this.rates[0]
                        this.selectRate = this.rates[0]
                        if(this.rates.length > 1){
                            this.isSecondRate = true
                            this.secondRate = this.rates[1]
                        }
                    }
                    let c = localStorage.getItem(instituteId + 'currencies')
                    if(c != null){
                        this.currencies = JSON.parse(c)
                        this.currencies.forEach(cur => {
                            if(cur.code == 'KHR'){
                                this.hasKHR = true
                            }
                            if(cur.code == 'USD'){
                                this.hasUSD = true
                            }
                        })
                    }
                }
            },
            loadRate(){
                const date = new Date().toISOString().substring(0, 10)
                let base = this.institute.baseCurrency.code;
                let j = 1;
                this.currencies.forEach(c=>{
                    currencyHandler.getLastExchangeRateByDate(date, c.code).then(r => {
                        if(r.data.data.length > 0){
                            let d = r.data.data[0]
                            d.isBase = 0
                            if(c.code == base){
                                d.isBase = 1
                                this.baseRate = d
                                this.selectRate = d
                            }
                            d.uid = uuid.v1()
                            this.rates.push(d)
                            // window.console.log(d.code, 'cur code')
                            if(d.code == 'KHR'){
                                this.hasKHR = true
                            }
                            if(d.code == 'USD'){
                                this.hasUSD = true
                            }
                        }
                        if(j == this.currencies.length){
                            if(this.rates.length == 0){
                                alert('Please set up exchange rate on setting');
                                window.top.close();
                            }
                            if(Object.keys(this.baseRate).length == 0){
                                alert('Please set up base exchange rate on setting');
                                window.top.close();
                            }
                            this.rates.sort(function (a, b) {
                                return parseFloat(b.isBase) - parseFloat(a.isBase)
                            })
                            // window.console.log(this.rates, 'second rate')
                            if(this.rates.length > 1){
                                this.isSecondRate = true
                                this.secondRate = this.rates[1]
                            }
                            // window.console.log(this.rates, 'c rates')
                            // localStorage.setItem(instituteId + 'rates', JSON.stringify(this.rates))
                        }
                        j++
                    })
                })
            },
            //exchange
            resetReceipt(){
                this.selectRate = this.baseRate
                this.amtReceipt = this.t.total
                this.t.amtReceipt = 0
                this.exchange.base = 0
                this.exchange.secondary = 0
                this.usdPaidAmt = 0
                this.khrPaidAmt = 0
                this.t.exchange = this.exchange
                this.createPrintReceipt(this.t)
                this.showCardInv = false
                setTimeout(()=>{
                    this.showCardInv = true
                }, 300)
            },
            changeClick(data){
                let amt = ""
                switch(data) {
                    case 'Clear':
                        this.amtReceipt = 0
                        break;
                    case '1$' :
                        this.usdPaid(1)
                        break;
                    case '2$' :
                        this.usdPaid(2)
                        break;
                    case '5$' :
                        this.usdPaid(5)
                        break;
                    case '10$' :
                        this.usdPaid(10)
                        break;
                    case '15$' :
                        this.usdPaid(15)
                        break;
                    case '20$' :
                        this.usdPaid(20)
                        break;
                    case '25$' :
                        this.usdPaid(25)
                        break;
                    case '30$' :
                        this.usdPaid(30)
                        break;
                    case '35$' :
                        this.usdPaid(35)
                        break;
                    case '40$' :
                        this.usdPaid(40)
                        break;
                    case '45$' :
                        this.usdPaid(45)
                        break;
                    case '50$' :
                        this.usdPaid(50)
                        break;
                    case '55$' :
                        this.usdPaid(55)
                        break;
                    case '60$' :
                        this.usdPaid(60)
                        break;
                    case '70$' :
                        this.usdPaid(70)
                        break;
                    case '80$' :
                        this.usdPaid(80)
                        break;
                    case '90$' :
                        this.usdPaid(90)
                        break;
                    case '100$' :
                        this.usdPaid(100)
                        break;
                    case '100r' :
                        this.khrPaid(100)
                        break;
                    case '500r' :
                        this.khrPaid(500)
                        break;
                    case '1000r' :
                        this.khrPaid(1000)
                        break;
                    case '5000r' :
                        this.khrPaid(5000)
                        break;
                    case '10000r' :
                        this.khrPaid(10000)
                        break;
                    case '20000r' :
                        this.khrPaid(20000)
                        break;
                    case '50000r' :
                        this.khrPaid(50000)
                        break;
                    case '100000r' :
                        this.khrPaid(100000)
                        break;
                    case '.' :
                        if(this.amtReceipt.toString().length > 0){
                            this.amtReceipt = this.amtReceipt.toString() + ".";
                        }
                        break;
                    default:
                        amt = "";
                        if(this.amtReceipt.toString() === "0"){
                            amt = "";
                        }else{
                            amt = this.amtReceipt;
                        }
                        this.amtReceipt = amt.toString() + data;
                        break;
                }
            },
            khrPaid(value){
                if(parseFloat(value) > 0){
                    this.khrPaidAmt += parseFloat(value)
                }else{
                    this.khrPaidAmt += parseFloat(this.amtReceipt)
                }
                this.setChangeAmt()
            },
            usdPaid(value){
                if(parseFloat(value) > 0){
                    this.usdPaidAmt += parseFloat(value)
                }else{
                    this.usdPaidAmt += parseFloat(this.amtReceipt)
                }
                this.setChangeAmt()
            },
            setChangeAmt(){
                let amt = 0
                if(this.baseRate.code == 'USD'){
                    amt += parseFloat(this.usdPaidAmt)
                }
                if(this.secondRate.code == 'USD'){
                    amt += this.usdPaidAmt / parseFloat(this.secondRate.rate)
                }
                if(this.baseRate.code == 'KHR'){
                    amt += parseFloat(this.khrPaidAmt)
                }
                if(this.secondRate.code == 'KHR'){
                    amt += this.khrPaidAmt / parseFloat(this.secondRate.rate)
                }
                this.amtReceipt = amt
                this.t.amtReceipt = amt
                this.t.usdPaidAmt = this.usdPaidAmt
                this.t.khrPaidAmt = this.khrPaidAmt
                // window.console.log(this.usdPaidAmt, this.khrPaidAmt, amt, this.baseRate, this.secondRate)
                if(amt > this.t.total){
                    let t = amt - parseFloat(this.t.total)
                    if(t > 0){
                        this.exchange.base = t
                        if(this.isSecondRate){
                            let st = t * parseFloat(this.secondRate.rate)
                            this.exchange.secondary = st
                        }
                        window.console.log(this.exchange, 'exchange')
                    }
                }
                this.t.exchange = this.exchange
                this.createPrintReceipt(this.t)
                this.showCardInv = false
                setTimeout(()=>{
                    this.showCardInv = true
                }, 300)
            },
            createOrderNumber(){
                let local = localStorage.getItem(instituteId + this.register.pk + 'ordernumber')
                // window.console.log(local, 'order number')
                if(local == null || local == ''){
                    this.loadOrderNumber()
                }else{
                    this.setOrderNumber(local)
                }
            },
            setOrderNumber(d){
                let num = JSON.parse(d)
                // window.console.log(this.orderNumberFormat, num, 'order number format')
                let o = this.orderNumberFormat
                if(parseInt(num.number) >= parseInt(o.resetOn)){
                    num = 1
                }
                let isReset = false
                if(o.resetType == 'Day'){
                    // window.console.log('Day')
                    let sd = new Date().toISOString().substr(0, 10)
                    if(sd != num.date){
                        num = 1
                        isReset = true
                    }
                }else if(o.resetType == 'Month'){
                    let md = new Date(num.date).getMonth()
                    let mtd = new Date().getMonth()
                    if(md != mtd){
                        num = 1
                        isReset = true
                    }
                }else if(o.resetType == 'Year'){
                    let yd = new Date(num.date).getFullYear()
                    let ytd = new Date().getFullYear()
                    if(yd != ytd){
                        num = 1
                        isReset = true
                    }
                }
                if(isReset){
                    let d = {
                        number: 1,
                        date: new Date().toISOString().substr(0, 10)
                    }
                    localStorage.setItem(instituteId + this.register.pk + 'ordernumber', JSON.stringify(d))
                    num = d
                }
                let len = parseInt(o.format)
                if(num.number == null) num.number = 1
                let n = this.zeroPad(num.number, len)
                this.t.lastOrderNum = num.number
                this.orderNumber = o.abbr + o.numberSeparator + n
                this.t.orderNumber = this.orderNumber
            },
            loadOrderNumber(){
                this.showLoading = true
                commerceHandler.orderNumberGet(this.register.pk).then(res => {
                    this.showLoading = false 
                    // window.console.log(res, 'ordernumber load from server')
                    let d = {
                        number: res.data.data,
                        date: new Date().toISOString().substr(0, 10)
                    }
                    localStorage.setItem(instituteId + this.register.pk + 'ordernumber', JSON.stringify(d))
                    this.setOrderNumber(JSON.stringify(d))
                })
            },
            //txn number
            async loadTxnNumber(){
                // this.showLoading = true
                if(navigator.onLine){
                    commerceHandler.txnNumberGet(this.activeStore.pk).then(res => {
                        // this.showLoading = false 
                        // window.console.log(res, 'res')
                        this.setTxnNumber(res.data.data)
                        localStorage.setItem(instituteId + 'txnNum', parseInt(res.data.data))
                    })
                }else{
                    let lnum = localStorage.getItem(instituteId + 'txnNum')
                    window.console.log(lnum, 'last num')
                    this.setTxnNumber( parseInt(lnum) + 1)
                }
            },
            setTxnNumber(num){
                let dataItem = this.txnNumberFormat
                window.console.log(dataItem, 'you are set txn number')
                const year = new Date().getFullYear();
                const shortMonth = ("0" + (new Date().getMonth() + 1)).slice(-2);
                if (dataItem.sequence === 0) {
                    dataItem.suffix = year;
                } else if (dataItem.sequence === 1) {
                    dataItem.suffix = year.toString().substr(2);
                } else if (dataItem.sequence === 2) {
                    dataItem.suffix = year + shortMonth;
                } else if (dataItem.sequence === 3) {
                    dataItem.suffix = year.toString().substr(2) + shortMonth;
                } else if (dataItem.sequence === 4) {
                    dataItem.suffix = "";
                }
                // const preview = dataItem.abbr + dataItem.prefixSeparator + dataItem.suffix +  dataItem.numberSeparator + "0".repeat(dataItem.format - dataItem.number.toString().length) + num;
                // window.console.log(dataItem, 'dataitem number')
                const preview = dataItem.abbr + dataItem.prefixSeparator + dataItem.suffix +  dataItem.numberSeparator + this.zeroPad(num, parseInt(dataItem.format)) //"0".repeat(dataItem.format - dataItem.number.toString().length) + num;
                this.t.txnNumber = preview 
                this.t.lastNumber = num
                window.console.log(this.t.txnNumber, 'txn number')
            },
            zeroPad(num, places) {
                return String(num).padStart(places, "0");
            },
            printHtmlCard(){
                let self = this
                let styleTemplate = `<style>
                    * {
                        padding: 0;
                        margin: 0;
                        font-size: 14px;
                        font-family: 'Hanuman', Arial;
                        color: #000!important;
                    }
                    body {
                        background-color: #fff!important;
                        background: #fff!important;
                    }
                    #invoiceContent {
                        padding: 2.5%;
                        width: 95%;
                    }
                    .col-4{
                        float: left;
                        width: 33%;
                        min-height: 12em;
                    }
                    .col-8{
                        float: left;
                        width: 66%;
                        min-height: 12em;
                    }
                    h2.mt-4 {
                        margin-top: 16px;
                        font-size: 2em;
                        margin-bottom: 16px;
                    }
                    .col-6{
                        float: left;
                        width: 48%;
                        margin-top: 20px;
                    }
                    .itemlist {
                        min-height: 550px;
                    }
                    .col-6-right {
                        float: right;
                    }
                    .col-12{
                        float: left;
                        width: 100%;
                        margin-top: 20px;
                    }
                    th, td.tb_receipt{
                        border: 1px solid #000;
                        padding: 20px;
                        background-color: #eee!important;
                        background: #eee!important;
                        font-size: 1em;
                    }
                    td.tb_receipt{
                        font-size: .98em;
                        background-color: #fff!important;
                        background: #fff!important;
                    }
                    table {
                        width: 100%;
                        border-collapse: collapse;
                    }
                    .text-right {
                        text-align: right;
                    }
                    .covertotal {
                        padding-top: 20px;
                        border-top: 1px solid;
                    }
                    .covertotal td {
                        font-size: 1.2em;
                        padding: 3px;
                    }
                    .powerbanhji {
                        margin: 10px;
                        font-size: 1.5em;
                        text-align: center;
                    }
                </style>`
                // window.console.log(this.g.receiptTemplate, 'receipt template on sale page')
                if(this.g.receiptTemplate == '80mm'){
                    styleTemplate = `<style>
                        * {
                            padding: 0;
                            margin: 0;
                            font-size: 14px;
                            font-family: 'Hanuman', Arial;
                            color: #000!important;
                        }
                        img.logo {
                            width: 150px;
                            height: auto;
                        }
                        .vat{
                            width: 50%;
                        }
                        body {
                            background-color: #fff!important;
                            background: #fff!important;
                        }
                        #invoiceContent {
                            padding: 2.5%;
                            width: 95%;
                        }
                        .col-4{
                            float: left;
                            width: 100%;
                            min-height: 160px;
                            text-align: center;
                            border: none!important;
                        }
                        .col-8{
                            float: left;
                            width: 100%;
                            padding-bottom: 15px;
                        }
                        h2.mt-4 {
                            font-size: 16px;
                            text-align: center;
                            background: #eee!important;
                            padding: 5px;
                        }
                        .col-6{
                            float: left;
                            width: 100%;
                        }
                        .col-6-right {
                            float: right;
                        }
                        .col-12{
                            float: left;
                            width: 100%;
                        }
                        th, td.tb_receipt{
                            border: 1px solid #000;
                            padding: 5px;
                            background-color: #eee!important;
                            background: #eee!important;
                            font-size: 11px;
                        }
                        td.tb_receipt{
                            font-size: 12px;
                            background-color: #fff!important;
                            background: #fff!important;
                        }
                        table {
                            width: 100%;
                            border-collapse: collapse;
                        }
                        .text-right {
                            text-align: right;
                        }
                        .covertotal {
                            padding-top: 20px;
                            border-top: 1px solid;
                        }
                        .covertotal td {
                            font-size: 12px;
                        }
                        .powerbanhji {
                            margin: 5px;
                            font-size: 16px;
                            text-align: center;
                        }
                    </style>`
                }else if(this.g.receiptTemplate == '58mm'){
                    styleTemplate = `<style>
                        * {
                            padding: 0;
                            margin: 0;
                            font-size: 14px;
                            font-family: 'Hanuman', Arial;
                            color: #000!important;
                        }
                        img.logo {
                            width: 80px;
                            height: auto;
                        }
                        .vat{
                            width: 50%;
                        }
                        body {
                            background-color: #fff!important;
                            background: #fff!important;
                        }
                        #invoiceContent {
                            padding: 1%;
                            width: 98%;
                        }
                        .col-4{
                            float: left;
                            width: 100%;
                            min-height: 160px;
                            text-align: center;
                            border: none!important;
                        }
                        .col-8{
                            float: left;
                            width: 100%;
                            padding-bottom: 15px;
                        }
                        h2.mt-4 {
                            font-size: 14px;
                            text-align: center;
                            background: #eee!important;
                            padding: 2px;
                        }
                        .col-6{
                            float: left;
                            width: 100%;
                        }
                        .col-6-right {
                            float: right;
                        }
                        .col-12{
                            float: left;
                            width: 100%;
                        }
                        th, td.tb_receipt{
                            border: 1px solid #000;
                            padding: 2px;
                            background-color: #eee!important;
                            background: #eee!important;
                            font-size: 10px;
                        }
                        td.tb_receipt{
                            font-size: 11px;
                            background-color: #fff!important;
                            background: #fff!important;
                        }
                        table {
                            width: 100%;
                            border-collapse: collapse;
                        }
                        .text-right {
                            text-align: right;
                        }
                        .covertotal {
                            padding-top: 5px;
                            border-top: 1px solid;
                        }
                        .covertotal td {
                            font-size: 10px;
                        }
                        .powerbanhji {
                            margin: 5px;
                            font-size: 14px;
                            text-align: center;
                        }
                    </style>`
                }else if(this.g.receiptTemplate == 'A5'){
                    styleTemplate = `<style>
                        * {
                            padding: 0;
                            margin: 0;
                            font-size: 14px;
                            font-family: 'Hanuman', Arial;
                            color: #000!important;
                        }
                        img.logo {
                            width: 100px;
                            height: auto;
                        }
                        body {
                            background-color: #fff!important;
                            background: #fff!important;
                        }
                        .vat {
                            width: 40%;
                        }
                        #invoiceContent {
                            padding: 1%;
                            width: 98%;
                        }
                        .col-4{
                            float: left;
                            width: 25%;
                            min-height: 130px;
                        }
                        .col-8{
                            float: left;
                            width: 75%;
                            min-height: 130px;
                        }
                        h2.mt-4 {
                            font-size: 18px;
                            margin-bottom: 14px;
                        }
                        .col-6{
                            float: left;
                            width: 48%;
                            margin-top: 15px;
                        }
                        .itemlist {
                            min-height: 280px;
                        }
                        .col-6-right {
                            float: right;
                        }
                        .col-12{
                            float: left;
                            width: 100%;
                            margin-top: 15px;
                        }
                        th, td.tb_receipt{
                            border: 1px solid #000;
                            padding: 10px;
                            background-color: #eee!important;
                            background: #eee!important;
                            font-size: 13px;
                        }
                        td.tb_receipt{
                            font-size: 12px;
                            background-color: #fff!important;
                            background: #fff!important;
                        }
                        table {
                            width: 100%;
                            border-collapse: collapse;
                        }
                        .text-right {
                            text-align: right;
                        }
                        .covertotal {
                            padding-top: 10px;
                            border-top: 1px solid;
                        }
                        .covertotal td {
                            font-size: 12px;
                        }
                        .powerbanhji {
                            margin: 10px;
                            font-size: 16px;
                            text-align: center;
                        }
                    </style>`
                }
                var  Win;
                Win = window.open('', '', 'width=1048, height=900');
                var printableContent = '',
                    win = Win,
                    doc = win.document.open();
                var htmlStart = 
                '<meta charset="utf-8" /><title></title><link href="https://raw.githubusercontent.com/choeun88/css/main/kendo.common.min.css" rel="stylesheet" type="text/css">' +
                '<link rel="preconnect" href="https://fonts.googleapis.com"><link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>' +
                '<link href="https://fonts.googleapis.com/css2?family=Hanuman:wght@100;400;900&display=swap" rel="stylesheet">' +
                '<link href="https://raw.githubusercontent.com/choeun88/css/main/printreceipt.css" rel="stylesheet">' + 
                styleTemplate +
                '</head><body><div class="row-fluid" >' +
                '<div id="printC" style="background: none!important;color: #000!important;padding: 20px" class="k-content document-body">';
                var htmlEnd = '</div></div></body></html>';
                printableContent = $('#printC').html();
                // window.console.log(htmlStart + printableContent + htmlEnd, 'pp')
                doc.write(htmlStart + printableContent + htmlEnd);
                doc.close();
                setTimeout(function(){
                    win.print();
                    if(!self.g.template.mobileDevice){
                        win.close();
                    }
                },1000);
            },
            printHtmlA(){
                let self = this
                let styleTemplate = `<style>
                    * {
                        padding: 0;
                        margin: 0;
                        font-size: .98em;
                        font-family: 'Hanuman', Arial;
                        color: #000!important;
                    }
                    body {
                        background-color: #fff!important;
                        background: #fff!important;
                    }
                    #invoiceContent {
                        padding: 2.5%;
                        width: 95%;
                    }
                    .col-4{
                        float: left;
                        width: 33%;
                        min-height: 12em;
                    }
                    .col-8{
                        float: left;
                        width: 66%;
                        min-height: 12em;
                    }
                    h2.mt-4 {
                        margin-top: 16px;
                        font-size: 2em;
                        margin-bottom: 16px;
                    }
                    .col-6{
                        float: left;
                        width: 48%;
                        margin-top: 20px;
                    }
                    .itemlist {
                        min-height: 550px;
                    }
                    .col-6-right {
                        float: right;
                    }
                    .col-12{
                        float: left;
                        width: 100%;
                        margin-top: 20px;
                    }
                    th, td.tb_receipt{
                        border: 1px solid #000;
                        padding: 20px;
                        background-color: #eee!important;
                        background: #eee!important;
                        font-size: 1em;
                    }
                    td.tb_receipt{
                        font-size: .98em;
                        background-color: #fff!important;
                        background: #fff!important;
                    }
                    table {
                        width: 100%;
                        border-collapse: collapse;
                    }
                    .text-right {
                        text-align: right;
                    }
                    .covertotal {
                        padding-top: 20px;
                        border-top: 1px solid;
                    }
                    .covertotal td {
                        font-size: 1.2em;
                        padding: 3px;
                    }
                    .powerbanhji {
                        margin: 10px;
                        font-size: 1.5em;
                        text-align: center;
                    }
                </style>`
                window.console.log(this.g.receiptTemplate, 'receipt template on sale page')
                if(this.g.receiptTemplate == '80mm'){
                    styleTemplate = `<style>
                        * {
                            padding: 0;
                            margin: 0;
                            font-size: 12px;
                            font-family: 'Hanuman', Arial;
                            color: #000!important;
                        }
                        img.logo {
                            width: 150px;
                            height: auto;
                        }
                        .vat{
                            width: 50%;
                        }
                        body {
                            background-color: #fff!important;
                            background: #fff!important;
                        }
                        #invoiceContent {
                            padding: 2.5%;
                            width: 95%;
                        }
                        .col-4{
                            float: left;
                            width: 100%;
                            min-height: 160px;
                            text-align: center;
                            border: none!important;
                        }
                        .col-8{
                            float: left;
                            width: 100%;
                            padding-bottom: 15px;
                        }
                        h2.mt-4 {
                            font-size: 16px;
                            text-align: center;
                            background: #eee!important;
                            padding: 5px;
                        }
                        .col-6{
                            float: left;
                            width: 100%;
                        }
                        .col-6-right {
                            float: right;
                        }
                        .col-12{
                            float: left;
                            width: 100%;
                        }
                        th, td.tb_receipt{
                            border: 1px solid #000;
                            padding: 5px;
                            background-color: #eee!important;
                            background: #eee!important;
                            font-size: 11px;
                        }
                        td.tb_receipt{
                            font-size: 12px;
                            background-color: #fff!important;
                            background: #fff!important;
                        }
                        table {
                            width: 100%;
                            border-collapse: collapse;
                        }
                        .text-right {
                            text-align: right;
                        }
                        .covertotal {
                            padding-top: 20px;
                            border-top: 1px solid;
                        }
                        .covertotal td {
                            font-size: 12px;
                        }
                        .powerbanhji {
                            margin: 5px;
                            font-size: 16px;
                            text-align: center;
                        }
                    </style>`
                }else if(this.g.receiptTemplate == '58mm'){
                    styleTemplate = `<style>
                        * {
                            padding: 0;
                            margin: 0;
                            font-size: 10px;
                            font-family: 'Hanuman', Arial;
                            color: #000!important;
                        }
                        img.logo {
                            width: 80px;
                            height: auto;
                        }
                        .vat{
                            width: 50%;
                        }
                        body {
                            background-color: #fff!important;
                            background: #fff!important;
                        }
                        #invoiceContent {
                            padding: 1%;
                            width: 98%;
                        }
                        .col-4{
                            float: left;
                            width: 100%;
                            min-height: 160px;
                            text-align: center;
                            border: none!important;
                        }
                        .col-8{
                            float: left;
                            width: 100%;
                            padding-bottom: 15px;
                        }
                        h2.mt-4 {
                            font-size: 14px;
                            text-align: center;
                            background: #eee!important;
                            padding: 2px;
                        }
                        .col-6{
                            float: left;
                            width: 100%;
                        }
                        .col-6-right {
                            float: right;
                        }
                        .col-12{
                            float: left;
                            width: 100%;
                        }
                        th, td.tb_receipt{
                            border: 1px solid #000;
                            padding: 2px;
                            background-color: #eee!important;
                            background: #eee!important;
                            font-size: 10px;
                        }
                        td.tb_receipt{
                            font-size: 11px;
                            background-color: #fff!important;
                            background: #fff!important;
                        }
                        table {
                            width: 100%;
                            border-collapse: collapse;
                        }
                        .text-right {
                            text-align: right;
                        }
                        .covertotal {
                            padding-top: 5px;
                            border-top: 1px solid;
                        }
                        .covertotal td {
                            font-size: 10px;
                        }
                        .powerbanhji {
                            margin: 5px;
                            font-size: 14px;
                            text-align: center;
                        }
                    </style>`
                }else if(this.g.receiptTemplate == 'A5'){
                    styleTemplate = `<style>
                        * {
                            padding: 0;
                            margin: 0;
                            font-size: 11px;
                            font-family: 'Hanuman', Arial;
                            color: #000!important;
                        }
                        img.logo {
                            width: 100px;
                            height: auto;
                        }
                        body {
                            background-color: #fff!important;
                            background: #fff!important;
                        }
                        .vat {
                            width: 40%;
                        }
                        #invoiceContent {
                            padding: 1%;
                            width: 98%;
                        }
                        .col-4{
                            float: left;
                            width: 25%;
                            min-height: 130px;
                        }
                        .col-8{
                            float: left;
                            width: 75%;
                            min-height: 130px;
                        }
                        h2.mt-4 {
                            font-size: 18px;
                            margin-bottom: 14px;
                        }
                        .col-6{
                            float: left;
                            width: 48%;
                            margin-top: 15px;
                        }
                        .itemlist {
                            min-height: 280px;
                        }
                        .col-6-right {
                            float: right;
                        }
                        .col-12{
                            float: left;
                            width: 100%;
                            margin-top: 15px;
                        }
                        th, td.tb_receipt{
                            border: 1px solid #000;
                            padding: 10px;
                            background-color: #eee!important;
                            background: #eee!important;
                            font-size: 13px;
                        }
                        td.tb_receipt{
                            font-size: 12px;
                            background-color: #fff!important;
                            background: #fff!important;
                        }
                        table {
                            width: 100%;
                            border-collapse: collapse;
                        }
                        .text-right {
                            text-align: right;
                        }
                        .covertotal {
                            padding-top: 10px;
                            border-top: 1px solid;
                        }
                        .covertotal td {
                            font-size: 12px;
                        }
                        .powerbanhji {
                            margin: 10px;
                            font-size: 16px;
                            text-align: center;
                        }
                    </style>`
                }
                var  Win;
                Win = window.open('', '', 'width=1048, height=900');
                var printableContent = '',
                    win = Win,
                    doc = win.document.open();
                var htmlStart = 
                '<meta charset="utf-8" /><title></title><link href="https://raw.githubusercontent.com/choeun88/css/main/kendo.common.min.css" rel="stylesheet" type="text/css">' +
                '<link rel="preconnect" href="https://fonts.googleapis.com"><link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>' +
                '<link href="https://fonts.googleapis.com/css2?family=Hanuman:wght@100;400;900&display=swap" rel="stylesheet">' +
                '<link href="https://raw.githubusercontent.com/choeun88/css/main/printreceipt.css" rel="stylesheet">' + 
                styleTemplate +
                '</head><body><div class="row-fluid" >' +
                '<div id="invoicecontenta" style="background: none!important;color: #000!important;" class="k-content document-body">';
                var htmlEnd = '</div></div></body></html>';
                printableContent = $('#invoiceContentA').html();
                // window.console.log(htmlStart + printableContent + htmlEnd, 'pp')
                doc.write(htmlStart + printableContent + htmlEnd);
                doc.close();
                setTimeout(function(){
                    win.print();
                    if(!self.g.template.mobileDevice){
                        win.close();
                    }
                },1000);
            },
            printHtmlB(){
                let styleTemplate = `<style>
                    * {
                        padding: 0;
                        margin: 0;
                        font-size: .98em;
                        font-family: 'Hanuman', Arial;
                        color: #000!important;
                    }
                    body {
                        background-color: #fff!important;
                        background: #fff!important;
                    }
                    #invoiceContent {
                        padding: 2.5%;
                        width: 95%;
                    }
                    .col-4{
                        float: left;
                        width: 33%;
                        min-height: 12em;
                    }
                    .col-8{
                        float: left;
                        width: 66%;
                        min-height: 12em;
                    }
                    h2.mt-4 {
                        margin-top: 16px;
                        font-size: 2em;
                        margin-bottom: 16px;
                    }
                    .col-6{
                        float: left;
                        width: 48%;
                        margin-top: 20px;
                    }
                    .itemlist {
                        min-height: 550px;
                    }
                    .col-6-right {
                        float: right;
                    }
                    .col-12{
                        float: left;
                        width: 100%;
                        margin-top: 20px;
                    }
                    th, td.tb_receipt{
                        border: 1px solid #000;
                        padding: 20px;
                        background-color: #eee!important;
                        background: #eee!important;
                        font-size: 1em;
                    }
                    td.tb_receipt{
                        font-size: .98em;
                        background-color: #fff!important;
                        background: #fff!important;
                    }
                    table {
                        width: 100%;
                        border-collapse: collapse;
                    }
                    .text-right {
                        text-align: right;
                    }
                    .covertotal {
                        padding-top: 20px;
                        border-top: 1px solid;
                    }
                    .covertotal td {
                        font-size: 1.2em;
                        padding: 3px;
                    }
                    .powerbanhji {
                        margin: 10px;
                        font-size: 1.5em;
                        text-align: center;
                    }
                </style>`
                window.console.log(this.g.receiptTemplate, 'receipt template on sale page')
                if(this.g.receiptTemplate == '80mm'){
                    styleTemplate = `<style>
                        * {
                            padding: 0;
                            margin: 0;
                            font-size: 12px;
                            font-family: 'Hanuman', Arial;
                            color: #000!important;
                        }
                        img.logo {
                            width: 150px;
                            height: auto;
                        }
                        .vat{
                            width: 50%;
                        }
                        body {
                            background-color: #fff!important;
                            background: #fff!important;
                        }
                        #invoiceContent {
                            padding: 2.5%;
                            width: 95%;
                        }
                        .col-4{
                            float: left;
                            width: 100%;
                            min-height: 160px;
                            text-align: center;
                            border: none!important;
                        }
                        .col-8{
                            float: left;
                            width: 100%;
                            padding-bottom: 15px;
                        }
                        h2.mt-4 {
                            font-size: 16px;
                            text-align: center;
                            background: #eee!important;
                            padding: 5px;
                        }
                        .col-6{
                            float: left;
                            width: 100%;
                        }
                        .col-6-right {
                            float: right;
                        }
                        .col-12{
                            float: left;
                            width: 100%;
                        }
                        th, td.tb_receipt{
                            border: 1px solid #000;
                            padding: 5px;
                            background-color: #eee!important;
                            background: #eee!important;
                            font-size: 11px;
                        }
                        td.tb_receipt{
                            font-size: 12px;
                            background-color: #fff!important;
                            background: #fff!important;
                        }
                        table {
                            width: 100%;
                            border-collapse: collapse;
                        }
                        .text-right {
                            text-align: right;
                        }
                        .covertotal {
                            padding-top: 20px;
                            border-top: 1px solid;
                        }
                        .covertotal td {
                            font-size: 12px;
                        }
                        .powerbanhji {
                            margin: 5px;
                            font-size: 16px;
                            text-align: center;
                        }
                    </style>`
                }else if(this.g.receiptTemplate == '58mm'){
                    styleTemplate = `<style>
                        * {
                            padding: 0;
                            margin: 0;
                            font-size: 10px;
                            font-family: 'Hanuman', Arial;
                            color: #000!important;
                        }
                        img.logo {
                            width: 80px;
                            height: auto;
                        }
                        .vat{
                            width: 50%;
                        }
                        body {
                            background-color: #fff!important;
                            background: #fff!important;
                        }
                        #invoiceContent {
                            padding: 1%;
                            width: 98%;
                        }
                        .col-4{
                            float: left;
                            width: 100%;
                            min-height: 160px;
                            text-align: center;
                            border: none!important;
                        }
                        .col-8{
                            float: left;
                            width: 100%;
                            padding-bottom: 15px;
                        }
                        h2.mt-4 {
                            font-size: 14px;
                            text-align: center;
                            background: #eee!important;
                            padding: 2px;
                        }
                        .col-6{
                            float: left;
                            width: 100%;
                        }
                        .col-6-right {
                            float: right;
                        }
                        .col-12{
                            float: left;
                            width: 100%;
                        }
                        th, td.tb_receipt{
                            border: 1px solid #000;
                            padding: 2px;
                            background-color: #eee!important;
                            background: #eee!important;
                            font-size: 10px;
                        }
                        td.tb_receipt{
                            font-size: 11px;
                            background-color: #fff!important;
                            background: #fff!important;
                        }
                        table {
                            width: 100%;
                            border-collapse: collapse;
                        }
                        .text-right {
                            text-align: right;
                        }
                        .covertotal {
                            padding-top: 5px;
                            border-top: 1px solid;
                        }
                        .covertotal td {
                            font-size: 10px;
                        }
                        .powerbanhji {
                            margin: 5px;
                            font-size: 14px;
                            text-align: center;
                        }
                    </style>`
                }else if(this.g.receiptTemplate == 'A5'){
                    styleTemplate = `<style>
                        * {
                            padding: 0;
                            margin: 0;
                            font-size: 11px;
                            font-family: 'Hanuman', Arial;
                            color: #000!important;
                        }
                        img.logo {
                            width: 100px;
                            height: auto;
                        }
                        body {
                            background-color: #fff!important;
                            background: #fff!important;
                        }
                        .vat {
                            width: 40%;
                        }
                        #invoiceContent {
                            padding: 1%;
                            width: 98%;
                        }
                        .col-4{
                            float: left;
                            width: 25%;
                            min-height: 130px;
                        }
                        .col-8{
                            float: left;
                            width: 75%;
                            min-height: 130px;
                        }
                        h2.mt-4 {
                            font-size: 18px;
                            margin-bottom: 14px;
                        }
                        .col-6{
                            float: left;
                            width: 48%;
                            margin-top: 15px;
                        }
                        .itemlist {
                            min-height: 280px;
                        }
                        .col-6-right {
                            float: right;
                        }
                        .col-12{
                            float: left;
                            width: 100%;
                            margin-top: 15px;
                        }
                        th, td.tb_receipt{
                            border: 1px solid #000;
                            padding: 10px;
                            background-color: #eee!important;
                            background: #eee!important;
                            font-size: 13px;
                        }
                        td.tb_receipt{
                            font-size: 12px;
                            background-color: #fff!important;
                            background: #fff!important;
                        }
                        table {
                            width: 100%;
                            border-collapse: collapse;
                        }
                        .text-right {
                            text-align: right;
                        }
                        .covertotal {
                            padding-top: 10px;
                            border-top: 1px solid;
                        }
                        .covertotal td {
                            font-size: 12px;
                        }
                        .powerbanhji {
                            margin: 10px;
                            font-size: 16px;
                            text-align: center;
                        }
                    </style>`
                }
                var  Win;
                Win = window.open('', '', 'width=1048, height=900');
                var printableContent = '',
                    win = Win,
                    doc = win.document.open();
                var htmlStart = 
                '<meta charset="utf-8" /><title></title><link href="https://raw.githubusercontent.com/choeun88/css/main/kendo.common.min.css" rel="stylesheet" type="text/css">' +
                '<link rel="preconnect" href="https://fonts.googleapis.com"><link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>' +
                '<link href="https://fonts.googleapis.com/css2?family=Hanuman:wght@100;400;900&display=swap" rel="stylesheet">' +
                '<link href="https://raw.githubusercontent.com/choeun88/css/main/printreceipt.css" rel="stylesheet">' + 
                styleTemplate +
                '</head><body><div class="row-fluid" >' +
                '<div id="invoicecontentb" style="background: none!important;color: #000!important;padding: 20px;" class="k-content document-body">';
                var htmlEnd = '</div></div></body></html>';
                let self = this;
                printableContent = $('#invoiceContentB').html();
                // window.console.log(htmlStart + printableContent + htmlEnd, 'pp')
                doc.write(htmlStart + printableContent + htmlEnd);
                doc.close();
                setTimeout(function(){
                    win.print();
                    if(!self.g.template.mobileDevice){
                        win.close();
                    }
                },1000);
            },
            printHTMLQR(){
                let styleTemplate = `<style>
                    * {
                        padding: 0;
                        margin: 0;
                        font-size: .98em;
                        font-family: 'Hanuman', Arial;
                        color: #000!important;
                    }
                    body {
                        background-color: #fff!important;
                        background: #fff!important;
                    }
                    #invoiceContent {
                        padding: 2.5%;
                        width: 95%;
                    }
                    .col-4{
                        float: left;
                        width: 33%;
                        min-height: 12em;
                    }
                    .col-8{
                        float: left;
                        width: 66%;
                        min-height: 12em;
                    }
                    h2.mt-4 {
                        margin-top: 16px;
                        font-size: 2em;
                        margin-bottom: 16px;
                    }
                    .col-6{
                        float: left;
                        width: 48%;
                        margin-top: 20px;
                    }
                    .itemlist {
                        min-height: 550px;
                    }
                    .col-6-right {
                        float: right;
                    }
                    .col-12{
                        float: left;
                        width: 100%;
                        margin-top: 20px;
                    }
                    th, td.tb_receipt{
                        border: 1px solid #000;
                        padding: 20px;
                        background-color: #eee!important;
                        background: #eee!important;
                        font-size: 1em;
                    }
                    td.tb_receipt{
                        font-size: .98em;
                        background-color: #fff!important;
                        background: #fff!important;
                    }
                    table {
                        width: 100%;
                        border-collapse: collapse;
                    }
                    .text-right {
                        text-align: right;
                    }
                    .covertotal {
                        padding-top: 20px;
                        border-top: 1px solid;
                    }
                    .covertotal td {
                        font-size: 1.2em;
                        padding: 3px;
                    }
                    .powerbanhji {
                        margin: 10px;
                        font-size: 1.5em;
                        text-align: center;
                    }
                </style>`
                window.console.log(this.g.receiptTemplate, 'receipt template on sale page')
                if(this.g.receiptTemplate == '80mm'){
                    styleTemplate = `<style>
                        * {
                            padding: 0;
                            margin: 0;
                            font-size: 12px;
                            font-family: 'Hanuman', Arial;
                            color: #000!important;
                        }
                        img.logo {
                            width: 150px;
                            height: auto;
                        }
                        .vat{
                            width: 50%;
                        }
                        body {
                            background-color: #fff!important;
                            background: #fff!important;
                        }
                        #invoiceContent {
                            padding: 2.5%;
                            width: 95%;
                        }
                        .col-4{
                            float: left;
                            width: 100%;
                            min-height: 160px;
                            text-align: center;
                            border: none!important;
                        }
                        .col-8{
                            float: left;
                            width: 100%;
                            padding-bottom: 15px;
                        }
                        h2.mt-4 {
                            font-size: 16px;
                            text-align: center;
                            background: #eee!important;
                            padding: 5px;
                        }
                        .col-6{
                            float: left;
                            width: 100%;
                        }
                        .col-6-right {
                            float: right;
                        }
                        .col-12{
                            float: left;
                            width: 100%;
                        }
                        th, td.tb_receipt{
                            border: 1px solid #000;
                            padding: 5px;
                            background-color: #eee!important;
                            background: #eee!important;
                            font-size: 11px;
                        }
                        td.tb_receipt{
                            font-size: 12px;
                            background-color: #fff!important;
                            background: #fff!important;
                        }
                        table {
                            width: 100%;
                            border-collapse: collapse;
                        }
                        .text-right {
                            text-align: right;
                        }
                        .covertotal {
                            padding-top: 20px;
                            border-top: 1px solid;
                        }
                        .covertotal td {
                            font-size: 12px;
                        }
                        .powerbanhji {
                            margin: 5px;
                            font-size: 16px;
                            text-align: center;
                        }
                    </style>`
                }else if(this.g.receiptTemplate == '58mm'){
                    styleTemplate = `<style>
                        * {
                            padding: 0;
                            margin: 0;
                            font-size: 10px;
                            font-family: 'Hanuman', Arial;
                            color: #000!important;
                        }
                        img.logo {
                            width: 80px;
                            height: auto;
                        }
                        .vat{
                            width: 50%;
                        }
                        body {
                            background-color: #fff!important;
                            background: #fff!important;
                        }
                        #invoiceContent {
                            padding: 1%;
                            width: 98%;
                        }
                        .col-4{
                            float: left;
                            width: 100%;
                            min-height: 160px;
                            text-align: center;
                            border: none!important;
                        }
                        .col-8{
                            float: left;
                            width: 100%;
                            padding-bottom: 15px;
                        }
                        h2.mt-4 {
                            font-size: 14px;
                            text-align: center;
                            background: #eee!important;
                            padding: 2px;
                        }
                        .col-6{
                            float: left;
                            width: 100%;
                        }
                        .col-6-right {
                            float: right;
                        }
                        .col-12{
                            float: left;
                            width: 100%;
                        }
                        th, td.tb_receipt{
                            border: 1px solid #000;
                            padding: 2px;
                            background-color: #eee!important;
                            background: #eee!important;
                            font-size: 10px;
                        }
                        td.tb_receipt{
                            font-size: 11px;
                            background-color: #fff!important;
                            background: #fff!important;
                        }
                        table {
                            width: 100%;
                            border-collapse: collapse;
                        }
                        .text-right {
                            text-align: right;
                        }
                        .covertotal {
                            padding-top: 5px;
                            border-top: 1px solid;
                        }
                        .covertotal td {
                            font-size: 10px;
                        }
                        .powerbanhji {
                            margin: 5px;
                            font-size: 14px;
                            text-align: center;
                        }
                    </style>`
                }else if(this.g.receiptTemplate == 'A5'){
                    styleTemplate = `<style>
                        * {
                            padding: 0;
                            margin: 0;
                            font-size: 11px;
                            font-family: 'Hanuman', Arial;
                            color: #000!important;
                        }
                        img.logo {
                            width: 100px;
                            height: auto;
                        }
                        body {
                            background-color: #fff!important;
                            background: #fff!important;
                        }
                        .vat {
                            width: 40%;
                        }
                        #invoiceContent {
                            padding: 1%;
                            width: 98%;
                        }
                        .col-4{
                            float: left;
                            width: 25%;
                            min-height: 130px;
                        }
                        .col-8{
                            float: left;
                            width: 75%;
                            min-height: 130px;
                        }
                        h2.mt-4 {
                            font-size: 18px;
                            margin-bottom: 14px;
                        }
                        .col-6{
                            float: left;
                            width: 48%;
                            margin-top: 15px;
                        }
                        .itemlist {
                            min-height: 280px;
                        }
                        .col-6-right {
                            float: right;
                        }
                        .col-12{
                            float: left;
                            width: 100%;
                            margin-top: 15px;
                        }
                        th, td.tb_receipt{
                            border: 1px solid #000;
                            padding: 10px;
                            background-color: #eee!important;
                            background: #eee!important;
                            font-size: 13px;
                        }
                        td.tb_receipt{
                            font-size: 12px;
                            background-color: #fff!important;
                            background: #fff!important;
                        }
                        table {
                            width: 100%;
                            border-collapse: collapse;
                        }
                        .text-right {
                            text-align: right;
                        }
                        .covertotal {
                            padding-top: 10px;
                            border-top: 1px solid;
                        }
                        .covertotal td {
                            font-size: 12px;
                        }
                        .powerbanhji {
                            margin: 10px;
                            font-size: 16px;
                            text-align: center;
                        }
                    </style>`
                }
                var  Win;
                Win = window.open('', '', 'width=1048, height=900');
                var printableContent = '',
                    win = Win,
                    doc = win.document.open();
                var htmlStart = 
                '<meta charset="utf-8" /><title></title><link href="https://raw.githubusercontent.com/choeun88/css/main/kendo.common.min.css" rel="stylesheet" type="text/css">' +
                '<link rel="preconnect" href="https://fonts.googleapis.com"><link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>' +
                '<link href="https://fonts.googleapis.com/css2?family=Hanuman:wght@100;400;900&display=swap" rel="stylesheet">' +
                '<link href="https://raw.githubusercontent.com/choeun88/css/main/printreceipt.css" rel="stylesheet">' + 
                styleTemplate +
                '</head><body><div class="row-fluid" >' +
                '<div id="invoicecontentkhqr" style="background: none!important;color: #000!important;" class="k-content document-body">';
                var htmlEnd = '</div></div></body></html>';
                printableContent = $('#printKHQR').html();
                // window.console.log(htmlStart + printableContent + htmlEnd, 'pp')
                doc.write(htmlStart + printableContent + htmlEnd);
                doc.close();
                let self = this
                setTimeout(function(){
                    win.print();
                    if(!self.g.template.mobileDevice){
                        win.close();
                    }
                },1000);
            },
            printHtml(){
                let styleTemplate = `<style>
                    * {
                        padding: 0;
                        margin: 0;
                        font-size: 10px;
                        font-family: 'Battambang', Arial;
                        color: #000!important;
                        overflow: hidden;
                    }
                    img.logo {
                        width: 150px;
                        height: auto;
                    }
                    .vat{
                        width: 50%;
                    }
                    body {
                        background-color: #fff!important;
                        background: #fff!important;
                    }
                    #invoiceContent {
                        padding: 2.5%;
                        width: 95%;
                    }
                    .col-4{
                        float: left;
                        width: 100%;
                        min-height: 160px;
                        text-align: center;
                        border: none!important;
                    }
                    .col-8{
                        float: left;
                        width: 100%;
                        padding-bottom: 15px;
                    }
                    h2.mt-4 {
                        font-size: 16px;
                        text-align: center;
                        background: #eee!important;
                        padding: 5px;
                    }
                    .col-6{
                        float: left;
                        width: 100%;
                    }
                    .col-6-right {
                        float: right;
                    }
                    .col-12{
                        float: left;
                        width: 100%;
                    }
                    th, td.tb_receipt{
                        border: 1px solid #000;
                        padding: 5px;
                        background-color: #eee!important;
                        background: #eee!important;
                        font-size: 11px;
                    }
                    td.tb_receipt{
                        font-size: 12px;
                        background-color: #fff!important;
                        background: #fff!important;
                    }
                    table {
                        width: 100%;
                        border-collapse: collapse;
                    }
                    .text-right {
                        text-align: right;
                    }
                    .covertotal {
                        padding-top: 20px;
                        border-top: 1px solid;
                    }
                    .covertotal td {
                        font-size: 12px;
                    }
                    .powerbanhji {
                        margin: 5px;
                        font-size: 16px;
                        text-align: center;
                    }
                </style>`
                var  Win;
                Win = window.open('', '', 'width=1048, height=900');
                var printableContent = '',
                    win = Win,
                    doc = win.document.open();
                var htmlStart = 
                '<meta charset="utf-8" /><title></title><link href="https://raw.githubusercontent.com/choeun88/css/main/kendo.common.min.css" rel="stylesheet" type="text/css">' +
                '<link rel="preconnect" href="https://fonts.googleapis.com"><link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>' +
                '<link rel="preconnect" href="https://fonts.googleapis.com"><link rel="preconnect" href="https://fonts.gstatic.com" crossorigin><link href="https://fonts.googleapis.com/css2?family=Battambang:wght@100;300;400;700;900&display=swap" rel="stylesheet">' +
                '<link href="https://raw.githubusercontent.com/choeun88/css/main/printreceipt.css" rel="stylesheet">' + 
                styleTemplate +
                '</head><body><div class="row-fluid" >' +
                '<div id="invoicecontent" style="background: none!important;color: #000!important;" class="k-content document-body">';
                var htmlEnd = '</div></div></body></html>';
                printableContent = $('#invoiceContent').html();
                // window.console.log(htmlStart + printableContent + htmlEnd, 'pp')
                doc.write(htmlStart + printableContent + htmlEnd);
                doc.close();
                let self = this
                // setTimeout(function(){
                    win.print();
                    if(!self.g.template.mobileDevice){
                        // setTimeout(()=>{
                            win.close();
                        // }, 500)
                    }
                // },1000);
            },
            payByCash(){
                if(this.lineDS.length == 0){
                    this.$snotify.error(i18n.t('item_required'))
                    this.dialogCash = false
                    return
                }
                this.isSale = 1
                if(!this.isServing){
                    this.t.issuedDate = new Date()
                }
                this.t.paidBy = 'Cash'
                this.t.itemLines = this.lineDS
                this.t.customer = {
                    id: this.g.defaultCustomer.id,
                    type: 'general',
                    name: this.g.defaultCustomer.name
                }
                this.t.amtReceipt = this.t.total
                // window.console.log(this.t, 'pay by cash')
                this.calculateTotal()
                this.createPrintReceipt(this.t)
                this.dialogCash = true
                this.showCardInv = false
                setTimeout(()=>{
                    this.showCardInv = true
                }, 300)
            },
            enterCardPay(){
                // if(this.cardPay.digit == ''){
                //     this.$snotify.error(i18n.t('please_input_4_digits'))
                //     return
                // }
                if(this.cardPay.type == ''){
                    this.$snotify.error(i18n.t('please_select_type_of_card'))
                    return
                }
                // if(this.cardPay.name == ''){
                //     this.$snotify.error(i18n.t('please_select_a_bank'))
                //     return
                // }
                this.t.cardPay = this.cardPay
                // bill date
                // this.t.billDate = new Date()
                this.dialogCardPayment = false
                this.saveTxn()
            },
            payByCard(){
                if(this.lineDS.length == 0){
                    this.$snotify.error(i18n.t('item_required'))
                    this.dialogCard = false
                    return
                }
                this.isSale = 1
                this.t.paymentMethod = 'card'
                this.t.amtReceipt = this.t.total
                this.t.issuedDate = new Date()
                this.t.paidBy = 'Card'
                this.t.itemLines = this.lineDS
                this.t.customer = {
                    id: this.g.defaultCustomer.id,
                    type: 'general',
                    name: this.g.defaultCustomer.name
                }
                this.calculateTotal()
                
                this.createPrintReceipt(this.t)
                this.dialogCard = true
                this.showCardInv = false
                setTimeout(()=>{
                    this.showCardInv = true
                }, 300)
                
            },
            payByBank(){
                if(this.lineDS.length == 0){
                    this.$snotify.error(i18n.t('item_required'))
                    this.dialogBank = false
                    return
                }
                this.isSale = 1
                this.t.amtReceipt = this.t.total
                this.t.issuedDate = new Date()
                this.t.paidBy = 'Bank'
                this.t.paymentMethod = 'bank'
                this.activePayBank = {}
                this.t.itemLines = this.lineDS
                this.t.customer = {
                    id: this.g.defaultCustomer.id,
                    type: 'general',
                    name: this.g.defaultCustomer.name
                }
                this.calculateTotal()
                this.createPrintReceipt(this.t)
                this.dialogBank = true
                this.showCardInv = false
                setTimeout(()=>{
                    this.showCardInv = true
                }, 300)
            },
            selectBank(itm){
                this.activePayBank = {
                    bankName: itm.bank.name,
                    accountName: itm.accountName,
                    accountNumber: itm.accountNumber,
                    refNum: ''
                }
                window.console.log(this.activePayBank)
                this.infoBank = true
            },
            enterBank(){
                // if(this.activePayBank.refNum == ''){
                //     this.$snotify.error(i18n.t('please_fill_reference_number'))
                //     return;
                // }
                this.t.bankPay = this.activePayBank
                this.dialogBank = false
                this.infoBank = false
                // bill date
                // this.t.billDate = new Date()
                
                this.saveTxn()
                this.showCardInv = false
                setTimeout(()=>{
                    this.showCardInv = true
                }, 300)
            },
            payByKHQR(){
                if(this.lineDS.length == 0){
                    this.$snotify.error(i18n.t('item_required'))
                    this.dialogQrcode = false
                    return
                }
                this.isSale = 1
                this.t.amtReceipt = this.t.total
                this.t.issuedDate = new Date()
                this.t.paidBy = 'KHQR'
                this.t.paymentMethod = 'khqr'
                this.t.itemLines = this.lineDS
                this.t.customer = {
                    id: this.g.defaultCustomer.id,
                    type: 'general',
                    name: this.g.defaultCustomer.name
                }
                // this.createPrintReceipt(this.t)
                this.loadKHQR()
            },
            base64encode(str) {
                return btoa(unescape(encodeURIComponent(str)));
            },
            async loadKHQR(){
                this.khqrUrl = ''
                if(this.g.khqrMerchantId == ''){
                    this.$snotify.error(i18n.t('store_not_yet_register'))
                    return;
                }
                this.showLoading = true
                // const imgTag = document.getElementById('qr')
                const requestToken = await fetch('http://13.228.216.52:8989/auth/sign-in', {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                    'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                    "username": "AMKBanhJiQR",
                    "password": "AmkP@ssword"
                    })
                })
                let token = await requestToken.text();
                token = JSON.parse(token)
                const access_token = token.access_token
                const type = token.token_type
                token = `${type} ${access_token}`
                const a = JSON.parse(JSON.stringify(this.t));
                let d = {
                    ...a,
                    store: this.activeStore.pk,
                    qrtype: 'khqr',
                    localServer: this.g.localServer
                }
                const finalData = JSON.parse(JSON.stringify(d));
                // window.console.log(finalData, 'data')
                this.showLoading = true
                let billId = ""
                await loyaltyHandler.payPointQr(finalData).then(res=>{
                    billId = res.data.data.pk
                })
                const requestQRCode = await fetch('http://13.228.216.52:8989/qr/generator', {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token
                    },
                    body: JSON.stringify({
                        "mobileNumber": this.activeStore.phone.replaceAll(' ', ''),
                        "merchantId": this.g.khqrMerchantId,
                        "currency": this.baseRate.code,
                        "amount": this.t.total.toFixed(2),
                        "billNumber": billId,
                        "customerNumber": this.t.customer.id,
                        "storeLabel": this.activeStore.name
                    })
                })
                let qrcode = await requestQRCode.blob()
                const reader = new FileReader()
                let self = this
                reader.onload = (e) => {
                    const data = e.target.result
                    // imgTag.src = data
                    self.khqrUrl = data
                    self.t.qrCode = data
                    self.calculateTotal()
                    self.createPrintReceipt(this.t)
                    self.dialogQrcode = true
                    self.showLoading = false
                    if(self.g.mirrorDisplay){
                        let d = []
                        let lnoid = []
                        lnoid.push({
                            id: '',
                            amount: self.t.total,
                            store: self.activeStore.name,
                            type: 'khqr'
                        })
                        d.push({
                            value: JSON.stringify(lnoid)
                        })
                        // $.post(this.g.localServer + "/kitchenoffline/api/offlines/qrpay",
                        // {
                        //     models: JSON.stringify(d),
                        //     qrcode: data.toString()
                        // })
                        this.addQRPay(JSON.stringify(d), data.toString(), 'khqr')
                        this.addFOrderItem(this.lineDS)
                    }
                }
                reader.readAsDataURL(qrcode)
            },
            payByPartner(){
                if(this.lineDS.length == 0){
                    this.$snotify.error(i18n.t('item_required'))
                    this.dialogQrcode = false
                    return
                }
                if(this.partners.length > 0){
                    this.dialogPartner = true
                }else{
                    this.$snotify.error(i18n.t('no_partner'))
                }
            },
            resetPaymentModule(){
                this.allowPayCash = false
                this.allowPayCard = false
                this.allowPayBank = false 
                this.allowPayKHQR = false
                // check setting
                this.g.paymentOption.forEach(p=> {
                    if(p.name == 'Cash'){
                        this.allowPayCash = true
                    }
                    if(p.name == 'Card'){
                        this.allowPayCard = true
                    }
                    if(p.name == 'Bank'){
                        this.allowPayBank = true
                    }
                    if(p.name == 'KHQR'){
                        this.allowPayKHQR = true
                    }
                })
            },
            resetOrder(){
                this.mDateSorter = 1
                this.onSorterChanges(1)
                this.activeSaleUnit = {}
                this.allowPayPoint = true
                this.dialogPointCount = false
                this.isPointPayCounter = false
                this.showNoBack = true
                this.isInvoice = false
                this.dialogMBill = false
                this.isSplitBill = false
                this.mergeBills = []
                this.isMergeBill = false
                this.dailogInv = false
                this.camAdminConfirmCode = false
                this.camAdminPk = ''
                this.campaignList = []
                this.dialogCamps = false
                this.alertAdmins = []
                this.alertAdmin = {}
                this.internalPk = ''
                this.isInternalTxn = false
                this.isDiscountCard = false
                this.activeKeyPad = false
                this.favCate = false
                this.trackTxn = {}
                this.isStoreCredit = false
                this.dialogCreditCard = false
                this.dialogPrintShift = false
                this.searchCode = ''
                this.isOrderFlow = false
                this.isLockPin = false
                this.camVerify = {}
                // if(this.g.localServer != ''){
                this.addFOrderItem([])
                let d = []
                let lnoid = []
                lnoid.push({
                    id: '',
                    amount: this.t.total,
                    store: this.activeStore.name,
                    type: ''
                })
                d.push({
                    value: JSON.stringify(lnoid)
                })
                // $.post(this.g.localServer + "/kitchenoffline/api/offlines/qrpay",
                // {
                //     models: JSON.stringify(d),
                //     qrcode: JSON.stringify({})
                // })
                this.addQRPay(JSON.stringify(d), JSON.stringify({}), 'clear')
                // }
                this.isPointQRPay = false
                this.activeParksale = {}
                this.isUseParkSale = false
                this.dialogPSale = false
                this.dialogSCard = false
                this.isSearchCard = false
                this.khqrUrl = false
                this.dailogPrintPQR = false
                this.dialogPPOption = false
                this.isTopUpCounter = false
                this.isTopUpGCard = false
                this.isSaleVoucher = false
                this.isSaleCard = false
                this.dialogSelectTopup = false
                this.showTopUpForm = false
                this.dialogSGCard = false
                this.isTopUp = false
                this.activeSellGCard = {}
                this.isSaleGCard = false
                this.dialogSearchGCard = false
                this.isEndServing = false
                this.isPrintServing = false
                this.delTxn = false
                this.delLine = false
                this.dialogChangeSaleunit = false
                this.isRegisterCard = false
                this.dialogPOnly = false
                this.dailogPrintCard = false
                this.dailogRCard = false
                this.dialogCashReconcile = false
                this.sCardTap = false
                this.isProcessOrder = false
                this.dialogLoyalSearch = false
                this.dialogRewardInfo = false
                this.dialogLoyalty = false
                this.pointCardPay = false
                this.payInvoice = false
                // partner
                this.isPartnerPay = false
                // loyalty
                this.hasLoyaltyCard = false
                this.loyaltyCustomer = {}
                //session
                this.isEndShift = false
                this.resetPaymentModule()
                this.dialogCash = false
                this.dialogCard = false
                this.dialogBank = false 
                this.dialogQrcode = false 
                this.lineDS = []
                this.calculateTotal()
                this.saleUnit = {}
                let pinName = this.session.openBy
                let pinId = this.session.pk
                this.g = new generalSettingModel(JSON.parse(localStorage.getItem(instituteId + 'commRSetting')))
                if(this.session.hasOwnProperty('shifts')){
                    window.console.log(this.session, 'session ')
                    if(this.session.shifts.length > 0){
                        let set = this.g.sessionForm || {}
                        let rshift = set.openShift || false
                        if(rshift ==  1){
                            rshift = true
                        }
                        if(rshift){
                            pinName = this.session.shifts[this.session.shifts.length - 1].endBy
                        }else{
                            pinName = this.session.shifts[this.session.shifts.length - 1].startBy
                        }
                    }
                }
                this.activePin = {
                    name: pinName,
                    id: pinId
                }
                this.activePartner = {}
                this.partnerRefNum = ''
                this.activeReward = {}
                
                this.searchCodeBys = []
                if(this.g.searchProd.length > 0){
                    this.g.searchProd.forEach(p=>{
                        if(p == 1){
                            this.searchCodeBys.push({id: 1,name: i18n.t('product_sku')})
                        // }else if(p ==2){
                        //     this.searchCodeBys.push({id: 2,name: i18n.t('batch_number')})
                        // }else if(p == 3){
                        //     this.searchCodeBys.push({id: 3,name: i18n.t('serial_number')})
                        }else if(p == 4){
                            this.searchCodeBys.push({id: 4,name: i18n.t('variant_sku')})
                        }else if(p == 5){
                            this.searchCodeBys.push({id: 5,name: i18n.t('barcode_upc')})
                        }else if(p == 6){
                            this.searchCodeBys.push({id: 6,name: i18n.t('name')})
                        }else if(p == 7){
                            this.searchCodeBys.push({id: 7,name: i18n.t('brand')})
                        }
                    })
                }
                if(this.searchCodeBys.length > 0){
                    this.searchCodeBy = this.searchCodeBys[0].id
                }
                this.allowCCardPH = false
                this.allowCCardP = false 
                this.allowCCardR = false
                if(this.g.hasOwnProperty('createCard')){
                    this.g.createCard.forEach(c=>{
                        if(c == 1){
                            this.allowCCardP = true
                        }else if(c == 2){
                            this.allowCCardR = true
                        }else if(c == 3){
                            this.allowCCardPH = true
                        }
                    })
                }
                this.searchCNum = false
                this.searchCPhone = false
                this.searchCNFC = false
                this.searchCOrd = false
                if(this.g.searchCard.length > 0){
                    this.g.searchCard.forEach(sc=>{
                        if(sc == 1){
                            this.searchCNum = true
                        }else if(sc == 2){
                            this.searchCPhone = true
                        }else if(sc == 3){
                            this.searchCNFC = true
                        }else if(sc == 4){
                            this.searchCOrd = true
                        }
                    })
                }
                // point pay option
                this.pointOptQR = false
                this.pointOptCode = false
                this.pointOptCounter = false
                if(this.g.pointPayOpt.length > 0){
                    this.g.pointPayOpt.forEach(sc=>{
                        if(sc == 1){
                            this.pointOptQR = true
                        }else if(sc == 2){
                            this.pointOptCode = true
                        }else if(sc == 3){
                            this.pointOptCounter = true
                        }
                    })
                }
                if(this.activeStore.hasOwnProperty('product')){
                    if(this.activeStore.product == 2){
                        this.g.usedCategory = this.g.usedCateAdmin
                    }
                }
                if(this.g.screenDisplay == 'Desktop'){
                    this.$router.push({name: 'pos'})
                }else if(this.g.screenDisplay == 'Tablet'){
                    this.$router.push({name: 'tablet'})
                }
                if(!this.g.allowOrderFlow){
                    this.isProcessOrder = true
                }
                this.decimal = parseInt(this.g.decimal)
                this.setItemFlow()
                this.customer = new CustomerModel(this.g.defaultCustomer)
                // this.startFlowAt = 0
                // this.checkOrderFlow()
                this.t = new PosTransactionModel({})
                this.t.customer = {
                    id: this.g.defaultCustomer.id,
                    type: 'general',
                    name: this.g.defaultCustomer.name
                }
                this.resetReceipt()
                this.resetGuest()
                if(this.isEditServing == false){
                    this.createOrderNumber()
                }
                this.isEditServing = false
                this.resetCardPay()
                this.loadTxnNumber()
                this.isServing = false
                this.activeOrderType = {}
                // this.dialogPrint = false
                this.dialogPayment = false
                this.dialogServingLine = false
                let ses = localStorage.getItem(instituteId + 'session')
                if(ses != null && ses != 'null'){
                    let currentdate = new Date(this.session.startDate)
                    let datetime = currentdate.toLocaleString("km-KH", {timeZone: "Asia/Phnom_Penh"})
                    this.sessionStart = kendo.toString(new Date(datetime), 'yyyy-MMM-dd') //new Date(this.session.startDate).toISOString().substr(0, 10)
                }
            },
            startOrder(){
                //check shift
                let ses = localStorage.getItem(instituteId + 'session')
                if(ses != null && ses != 'null'){
                    if(this.session.shifts.length >= parseInt(this.register.shifts)){
                        this.$snotify.error(i18n.t('end_session_require'))
                        this.showLoading = false
                        return
                    }
                }else{
                    this.$snotify.error(i18n.t('require_session'))
                    this.showLoading = false
                    return
                }
                this.startFlowAt = 0
                this.checkOrderFlow()
                this.isProcessOrder = true
                this.sCardTap = false
                this.setItemFlow()
                $('#tabProduct').click()
                this.t.timeIn = kendo.toString(new Date(), 'hh:mm tt')
            },
            resetCardPay(){
                this.cardPay = {
                    name: '',
                    type: '',
                    expireDate: new Date().toISOString().substr(0, 10),
                    digit: ''
                }
            },
            resetGuest(){
                this.guestCount.localMen = 0
                this.guestCount.localWomen = 0
                this.guestCount.forMen = 0
                this.guestCount.forWomen = 0
            },
            setLocalTxn(data){
                //  window.console.log(data, 'local serving')
                // window.console.log(data, 'add idxdb')
                if(loadIndex){
                    const t = db.transaction(instituteId + "offlinetxn", "readwrite");
                    const objectStore = t.objectStore(instituteId + "offlinetxn");
                    data.pk = uuid.v1()
                    objectStore.add(data);
                    setTimeout(() => {
                        this.bindOffTxn()
                    }, 300);
                }else{
                    setTimeout(() => {
                        this.setLocalInvoice(data)
                    }, 300);
                }
            },
            setLocalInvoice(data){
                //  window.console.log(data, 'local serving')
                // window.console.log(data, 'add idxdb')
                if(loadIndex){
                    const t = db.transaction(instituteId + "invoices", "readwrite");
                    const objectStore = t.objectStore(instituteId + "invoices");
                    objectStore.add(data);
                    setTimeout(() => {
                        this.bindInvoice()
                    }, 300);
                }else{
                    setTimeout(() => {
                        this.setLocalInvoice(data)
                    }, 300);
                }
            },
            setLocalServing(data){
                // window.console.log(data, 'local serving')
                // window.console.log(data, 'add idxdb')
                if(loadIndex){
                    const t = db.transaction(instituteId + "serving", "readwrite");
                    const objectStore = t.objectStore(instituteId + "serving");
                    if(this.isEditServing){
                        objectStore.put(data);
                    }else{
                        objectStore.add(data);
                    }
                    setTimeout(() => {
                        this.bindServing()
                    }, 300);
                }else{
                    setTimeout(() => {
                        this.setLocalServing(data)
                    }, 300);
                }
            },
            setLocalPreOrder(data){
                if(loadIndex){
                    const t = db.transaction(instituteId + "preorders", "readwrite");
                    const objectStore = t.objectStore(instituteId + "preorders");
                    objectStore.add(data);
                    setTimeout(() => {
                        this.bindPreOrder()
                    }, 300);
                }else{
                    setTimeout(() => {
                        this.setLocalPreOrder(data)
                    }, 300);
                }
            },
            sendIntCode(){
                if(this.lineDS.length == 0){
                    this.$snotify.error(i18n.t('item_required'))
                    this.dialogCash = false
                    return
                }
                if(this.g.telegramGroup != ''){
                    this.internalPk = 'int-' + uuid.v1()
                    let data = {
                        groupId: this.g.telegramGroup,
                        pk: this.internalPk,
                        sk: this.activeStore.pk,
                        type: 'internaltxn',
                        store: this.activeStore.name,
                        storePhone: this.activeStore.phone,
                        amount: parseFloat(this.t.total),
                        orderNum: this.t.orderNumber,
                        username: this.activePin.name,
                    }
                    this.isInternalTxn = true
                    this.showLoading = true
                    telegramBotHandler.posPost(data).then(()=>{
                        this.showLoading = false
                        this.verifyCode = ''
                        this.dialogCode = true
                    })
                }else{
                    this.saveInternal()
                }
            },
            saveInternal(){
                this.isSale = 0
                this.t.isSale = 0
                this.t.issuedDate = new Date()
                // bill date
                this.t.billDate = new Date()
                this.t.type = 'internal'
                this.t.paymentMethod = 'None'
                this.t.paidBy = 'internal'
                if(this.isEditServing){
                    this.isServing = true
                    this.activeServing.pk = this.t.id
                    window.console.log(this.t.id, 'txn id')
                    this.t.txnId = this.t.id 
                    this.t.id = ''
                }
                this.saveTxn()
            },
            saveInvoice(){
                if(!this.activeCustomer.hasOwnProperty('id')){
                    this.$snotify.error(i18n.t('please_customer'))
                    return;
                }
                this.isSale = 0
                this.t.isSale = 0
                this.t.orderDate = new Date()
                this.t.issuedDate = new Date()
                this.t.type = 'invoice'
                this.saveTxn()
                this.dailogInv = false
            },
            saveServing(){
                this.isSale = 0
                this.t.isSale = 0
                this.t.orderDate = new Date()
                this.t.issuedDate = new Date()
                this.t.type = 'serving'
                window.console.log(this.t, 'in serving')
                this.saveTxn()
            },
            async savePreOrder(){
                this.isSale = 1 
                this.t.amtReceipt = this.t.total
                this.t.billDate = new Date()
                this.t.orderDate = new Date()
                this.t.issuedDate = new Date()
                this.t.type = 'preorder'
                this.t.itemLines = this.lineDS
                this.t.customer = {
                    id: this.g.defaultCustomer.id,
                    type: 'general',
                    name: this.g.defaultCustomer.name
                }
                this.createPrintReceipt(this.t)
                this.phoneNumber = ''
                this.dailogPhone = true
            },
            //campaign
            loadCampaign(){
                this.campaigns = [];
                this.showLoading = true;
                commerceHandler.campaignGets().then((res) => {
                    if (res.data.statusCode === 200) {
                        this.showLoading = false;
                        this.campaigns = res.data.data;
                        window.console.log(res.data.data,'campaigns')
                        this.addCampaign()
                    }
                    this.loadCam = true
                    this.checkPullDataComplete()
                }).catch((e) => {
                    this.showLoading = false
                    window.console.error(e);
                })
            },
            addCampaign(){
                if(isIndDB){
                    let t = db.transaction(instituteId + "campaign", "readwrite");
                    let i = t.objectStore(instituteId + "campaign");
                    let j = 0
                    this.campaigns.forEach(e => {
                        i.put({
                            ...e,
                            id: e.pk
                        })
                        j++
                        if(j == this.campaigns.length){
                            window.console.log(i, 'bind campaign')
                        }
                    })
                }
            },
            // session
            showEndSession(){
                if(!navigator.onLine){
                    this.$snotify.error(i18n.t('please_connect_internet'))
                    return
                }
                this.isCountCash = false
                this.showLoading = true 
                this.dialogCloseSession = false
                let ses = localStorage.getItem(instituteId + 'session')
                if(ses == null || ses == 'null'){
                    this.$snotify.error(i18n.t('you_do_not_have_session'))
                    this.showLoading = false
                    return
                }
                if(ses.name == 'NoName'){
                    this.$snotify.error(i18n.t('you_do_not_have_session'))
                    this.showLoading = false
                    return
                }
                // window.console.log(this.session, 'show end session')
                commerceHandler.sessionGet(this.session.pk).then(res=>{
                    this.showLoading = false 
                    if(res.data.data.length > 0){
                        if(res.data.data[0].status != 'open'){
                            this.$snotify.error(i18n.t('you_do_not_have_session'))
                            return
                        }
                        this.activeSession = res.data.data[0]
                        this.session = this.activeSession
                        this.dialogCloseSession = true
                    }
                })
            },
            printShift(d){
                window.console.log(d, 'print shift')
            },
            closeEndShift(){
                this.dialogShiftInfo = false
                this.pinActivate = false
                this.isEndShift = false
                this.dialogCashReconcile = false
                // this.showEndSession()
            },
            showEndShift(d){
                // window.console.log(this.g, d, 'end shfit')
                if(d.shifts.length >= this.register.shifts){
                    this.$snotify.error(i18n.t('no_shift'))
                    return
                }
                // if(this.servings.length > 0){
                //     if(this.g.sessionForm.clearServe == 1 || this.g.sessionForm.clearServe == true){
                //         this.$snotify.error(i18n.t('please_clear_serving'))
                //         return
                //     }
                // }
                let st = d.startDate
                if(d.shifts.length > 0){
                    st = d.shifts[d.shifts.length - 1].date
                }
                let currentdate = new Date()
                let datetime = currentdate.toLocaleString("km-KH", {timeZone: "Asia/Phnom_Penh"})
                this.shiftObj = {
                    id: 'comm-shift-' + uuid.v1(),
                    shiftNum: d.shifts.length + 1,
                    startDate: kendo.toString(new Date(st), 'dd/MMM/yyyy hh:mm: tt'),
                    endDate: kendo.toString(new Date(datetime), 'dd/MMM/yyyy hh:mm: tt'),
                }
                this.dialogShiftInfo = true
            },
            endShift(){
                // window.console.log(this.session.shifts.length, this.register.shifts, 'here from end shift')
                this.showNoBack = false
                let isLastShift = false
                let cushift = this.session.shifts.length + 1
                if(cushift == parseInt(this.register.shifts)){
                    isLastShift = true
                    // this.$snotify.error(i18n.t('you_are_in_last_shift'))
                    // return;
                }
                window.console.log(isLastShift, cushift, this.register.shifts, 'is last shift')
                if(this.servings.length > 0 && isLastShift){
                    if(this.g.sessionForm.clearServe == 1 || this.g.sessionForm.clearServe == true){
                        this.$snotify.error(i18n.t('please_clear_serving'))
                        return
                    }
                }
                this.isEndShift = true
                this.isLockPin = false
                this.pin = ''
                this.pinActivate = true
            },
            async saveEndShift(){
                this.showLoading = true
                let startBy = this.session.openBy || ''
                if(this.session.hasOwnProperty('shifts')){
                    if(this.session.shifts.length > 1){
                        startBy = this.session.shifts[this.session.shifts.length -1].endBy || ''
                    }
                    this.session.shifts.push({
                        shiftNum: this.shiftObj.shiftNum,
                        endBy: this.activePin.name,
                        startBy: startBy,
                        date: new Date().getTime()
                    })
                }else{
                    this.session.shifts = []
                    this.session.shifts.push({
                        shiftNum: this.shiftObj.shiftNum,
                        endBy: this.activePin.name,
                        startBy: startBy,
                        date: new Date().getTime()
                    })
                }
                this.session.id = this.session.pk
                this.session.closeBy = this.activePin.name
                this.shiftSes = this.session
                this.shiftSes.shiftInfo = this.shiftObj
                await commerceHandler.sessionCreate(this.session, '').then(() => {
                    this.$emit("onUpdate", this.shiftSes);
                    this.dialogPrintShift = true
                    this.showLoading = false
                    // window.console.log(response, 'shift res')
                    this.$snotify.success(i18n.t('successfull'))
                    this.closeEndShift()
                    this.sesChange()
                    this.dialogSessionInfo = false
                    this.dialogCloseSession = false
                    localStorage.setItem(instituteId + 'session', JSON.stringify(this.session))
                })
            },
            showEndDay(){
                if(parseInt(this.register.shifts) > 0){
                    let cushift = this.session.shifts.length + 1
                    if(cushift < parseInt(this.register.shifts)){
                        this.$snotify.error(i18n.t('please_end_shift'))
                        return;
                    }
                }
                this.dialogPrintSession = false
                if(this.servings.length > 0){
                    if(this.g.sessionForm.clearServe == 1 || this.g.sessionForm.clearServe == true){
                        this.$snotify.error(i18n.t('please_clear_serving'))
                        return
                    }
                }
                let currentdate = new Date()
                let datetime = currentdate.toLocaleString("km-KH", {timeZone: "Asia/Phnom_Penh"})
                this.session.endDate = new Date(datetime).getTime()
                this.dialogSessionInfo = true
            },
            endDay(){
                this.showNoBack = false
                this.isEndSession = true
                this.isLockPin = false
                this.isOrderFlow = false
                this.pin = ''
                this.pinActivate = true
            },
            endDayCountCash(){
                this.isCountCash = true
                this.isLockPin = false
                this.pin = ''
                this.showNoBack = false
                this.pinActivate = true
                // this.dialogCashReconcile = true
            },
            loadCashCount(){
                this.showLoading = true
                let data = {
                    sk: this.session.pk,
                    pk: 'ses-cashcount-'
                }
                commerceHandler.genGets(data).then(res=>{
                    this.showLoading = false
                    window.console.log(res, 'cash count res')
                    let notes = []
                    let info = {}
                    let d = res.data.data.Items
                    if(d.length > 0){
                        notes = d[0].notes
                        info = d[0].info
                    }
                    let countObj = this.session
                        countObj.secondRate = this.secondRate
                        countObj.baseRate = this.baseRate
                        countObj.currencies = this.currencies
                        countObj.hasKHR = this.hasKHR
                        countObj.hasUSD = this.hasUSD
                        countObj.isShift = true
                        countObj.countBy = this.activePin.name
                        countObj.notes = notes
                        countObj.info = info
                    this.countObj = countObj
                    this.$emit("onUpdate", this.countObj);
                    this.dialogCashReconcile = true
                })
            },
            searchItem(key){
                let d = {
                    epsAtt: {
                        ':sesPk': this.activeSession.pk + '/items',
                    },
                    index: 'sesPk-searchDate-index',
                    keyCondition: 'sesPk = :sesPk',
                    key: key
                }
                if(Object.keys(key).length == 0){
                    this.sesItems = []
                }
                this.showLoading = true
                commerceHandler.reportGets(d).then(res => {
                    let data = res.data.data.Items
                    if(data.length > 0){
                        data.sort(function (a, b) {
                            return parseFloat(new Date(b.issuedDate).getTime()) - parseFloat(new Date(a.issuedDate).getTime())
                        })
                        // window.console.log(data, 'data  items')
                        if(data.length > 0){
                            data.forEach(d=>{
                                let isstock = d.countStock || false
                                if(isstock){
                                    this.sesItems.push(d)
                                }
                            })
                        }
                        if(res.data.data.hasOwnProperty('LastEvaluatedKey')){
                            let dkey = res.data.data.LastEvaluatedKey
                            this.searchItem(dkey)
                        }else{
                            this.showLoading = false
                            this.genStock()
                        }
                    }else{
                        this.showLoading = false
                        this.genStock()
                    }
                })
            }, 
            async genStock(){
                // window.console.log(this.sesItems, 'sess item')
                //merge item
                let items = []
                this.sesItems.forEach(s=>{
                    if($.inArray(s.itemId, items) == -1) {
                        items.push(s.itemId)
                    }
                })
                window.console.log(items, 'item')
                // if(items.length > 0){
                //     let line = []
                //     items.forEach(itm=>{
                //         let sto = this.sesItems.filter((o)=>{return itm == o.itemId})
                //         if(sto.length > 0){
                //             let issDate = kendo.toString(new Date(), 'yyyy-MM-dd')
                //             let item = sto[0]
                //             let qty = 0
                //             let qoh = parseInt(localStorage.getItem(kendo.toString(new Date(), 'yyyy-MM-dd') + this.activeStore.pk + itm))
                //             sto.forEach(st => {
                //                 qty += parseInt(st.qty)
                //             })
                //             line.push({
                //                 "id": "lin-" + uuid.v1(),
                //                 "serviceDate": issDate,
                //                 "serviceDateTo": issDate,
                //                 "item": {
                //                     "id": itm,
                //                     "name": item.itmName,
                //                     "type": "Variant",
                //                     "sku": item.sku || '',
                //                     "uom": item.uom,
                //                     "isPlan": false,
                //                     "description": item.description,
                //                     "category": {
                //                         "name": "",
                //                         "id": item.categoryId,
                //                         "abbr": "",
                //                         "type": {}
                //                     },
                //                     "group": {
                //                         "name": "",
                //                         "id": item.groupId,
                //                         "abbr": "",
                //                         "type": {}
                //                     },
                //                     "subGroup": {
                //                         "name": "",
                //                         "id": item.subGroup || '',
                //                         "abbr": "",
                //                         "type": {}
                //                     },
                //                     "brand": {},
                //                     "country": {},
                //                     "product": {
                //                         "name": item.itmName,
                //                         "sku":  item.sku || '',
                //                         "id": item.productId || ''
                //                     },
                //                     "objVarType": {},
                //                     "costOfGoodsSoldAcc": {},
                //                     "inventoryAcc": {},
                //                     "incomeAcc": {},
                //                     "deferredIncomeAcc": {},
                //                     "batchOrSerial": 1,
                //                     "account": {},
                //                     "revenueSharingRates": [],
                //                     "isLicense": false,
                //                     "reUsed": false,
                //                     "productId": item.productId || ''
                //                 },
                //                 "description": item.description,
                //                 "qoh": 0,
                //                 "bValue": 0,
                //                 "conversionRate": 1,
                //                 "count": 0,
                //                 "different": 0,
                //                 "cost": 0,
                //                 "buom":  item.uom,
                //                 "amount": 0,
                //                 "adjAmount": 0,
                //                 "class1": {},
                //                 "class2": {},
                //                 "class3": {},
                //                 "class4": {},
                //                 "class5": {},
                //                 "deleted": 0,
                //                 "dateFormat": "dd-MM-yyyy",
                //                 "decimalFormat": "n2"
                //             })
                            
                //         }
                //     })
                // }
            },
            async saveSession(){
                // this.searchItem({})
                this.showLoading = true
                this.activeSession.endDate = new Date().getTime()
                this.activeSession.isEnd = 1
                this.activeSession.id = this.activeSession.pk
                this.activeSession.closeBy = this.activePin.name
                this.activeSession.status = 'end'
                this.activeSession.sesStatus = 'end'
                this.activeSession.isReset = 0
                commerceHandler.sessionCreate(this.activeSession, '').then(() => {
                    this.showLoading = false
                    // this.sendSesTelegram(this.activeSession)
                    this.$snotify.success(i18n.t('successfull'))
                    this.closeEndSes()
                    localStorage.removeItem(instituteId + 'session');
                    setTimeout(()=>{
                        this.activeSession.from = 'pos'
                        this.$emit("onUpdate", this.activeSession);
                        this.dialogSessionInfo = false
                        this.dialogCloseSession = false
                        this.$emit("onUpdate", this.session);
                        if(this.activeStore.allowStock){
                            let nagativeStock = [],
                                reorderStock = []
                            this.itemDatas.forEach(itm=>{
                                if(itm.countStock){
                                    if(itm.stock <= parseInt(itm.roq)){
                                        reorderStock.push({
                                            name: itm.name,
                                            barcode: itm.barcode,
                                            sku: itm.sku,
                                            stock: itm.stock
                                        })
                                    }
                                    if(itm.stock < 0){
                                        nagativeStock.push({
                                            name: itm.name,
                                            barcode: itm.barcode,
                                            sku: itm.sku,
                                            stock: itm.stock
                                        })
                                    }
                                }
                            })
                            let nega = this.g.sessionForm.sendROStock || false
                            if(nega){
                                if(nagativeStock.length > 0){
                                    let dd = {
                                        telegram: this.g.telegramGroup,
                                        type: 'nagativeStock',
                                        store: this.activeStore.name,
                                        storePhone: this.activeStore.phone,
                                        items: nagativeStock
                                    }
                                    telegramBotHandler.posPost(dd)
                                }
                            }
                            let reord = this.g.sessionForm.sendNGStock || false
                            if(reord){
                                if(reorderStock.length > 0){
                                    let dd = {
                                        telegram: this.g.telegramGroup,
                                        type: 'reorderStock',
                                        store: this.activeStore.name,
                                        storePhone: this.activeStore.phone,
                                        items: reorderStock
                                    }
                                    telegramBotHandler.posPost(dd)
                                }
                            }
                        }
                        this.dialogPrintSession = true
                        // this.logOut()
                    }, 300)
                })
            },
            closeEndSes(){
                this.pinActivate = false
                this.dialogSessionInfo = false
            },
            // update sale unit
            updateSaleUnit(id, status){
                window.console.log(id, status, 'update sale uint')
                let salunits = []
                this.saleUnitData.forEach(d=>{
                    if(d.id == id){
                        d.status = status
                    }
                    salunits.push(d)
                })
                localStorage.setItem(instituteId + 'saleUnit', JSON.stringify(salunits))
            },
            bindSaleUnitServing(){
                if(this.servings.length > 0 && this.saleUnitData.length > 0){
                    this.servings.forEach(s=>{
                        if(Object.keys(s.saleUnit).length > 0 && s.status == 1){
                            this.updateSaleUnit(s.saleUnit.id, 0)
                        }
                    })
                }
            },
            // get order
            loadOrder(key){
                this.txnorders = []
                let data = {
                    gsi2: this.register.pk + '#order#1#',
                    key: key
                }
                commerceHandler.getGsi2(data).then(res=>{
                    if(res.data.data.Items.length > 0){
                        this.bindOrder(res.data.data.Items)
                        if(res.data.data.hasOwnProperty('LastEvaluatedKey')){
                            this.loadOrder(res.data.data.LastEvaluatedKey)
                        }
                    }
                })
            },
            bindOrder(data){
                data.forEach(d=>{
                    this.txnorders.push(d)
                })
            },
            // get data server
            loadServerData(){
                // load order
                this.loadOrder()
            },
            loadCardGroup(){
                this.cardGroups = []
                if(navigator.onLine){
                    this.showLoading = true 
                    loyaltyHandler.groupGet().then(res => {
                        this.showLoading = false
                        this.cardGroups = res.data.data
                        localStorage.setItem(instituteId + 'cardgroup', JSON.stringify(res.data.data))
                    })
                }else{
                    let c = localStorage.getItem(instituteId + 'cardgroup')
                    if(c != null){
                        this.cardGroups = JSON.parse(c)
                    }
                }
            },
            loadNational(){
                this.nationalities = []
                if(navigator.onLine){
                    loyaltyHandler.nationalGet().then(res => {
                        if(res.data.data.length > 0){
                            let n = []
                            res.data.data.forEach(e => {
                                n.push({
                                    name: e.name,
                                    code: e.Iso2
                                })
                            })
                            n.sort(function(a, b){
                                if(a.name < b.name) { return -1; }
                                if(a.name > b.name) { return 1; }
                                return 0;
                            })
                            this.nationalities = n
                            localStorage.setItem(instituteId + 'national', JSON.stringify(n))
                        }
                    })
                }else{
                    let c = localStorage.getItem(instituteId + 'national')
                    if(c != null){
                        this.nationalities = JSON.parse(c)
                    }
                }
            },
            // delete localstorage
            delLocalDB(id, el,dbname){
                let a = el.filter((o) => {return o.pk == id})
                if(a.length > 0){
                    const index = el.indexOf(a[0]);
                    el.splice(index,1);
                    this.parkSaleTxns = el
                    localStorage.setItem(instituteId + dbname, JSON.stringify(el))
                }
            },
            // print order
            printOrder(data){
                if(data.length > 0){
                    let sname = ''
                    if(Object.keys(this.t.saleUnit).length > 0){
                        sname = this.t.saleUnit.name
                    }
                    var htmlStart =
                        '<!DOCTYPE html>' +
                        '<html>' +
                        '<head>' +
                        '<meta charset="utf-8" />' +
                        '<title></title>' +
                        '<style type="text/css" media="print">' +
                        '* {font-size: 16px;}'+
                        '</style>' +
                        '</head>' +
                        '<body style="background: #fff; padding: 10px;width: 80mm"><div class="row-fluid" ><div id="example" class="k-content">' +
                        '<p style="padding: 0;margin: 0;font-size: 12px;text-align: center;">Drink Order</p>' +
                        '<p style="padding: 0;margin: 0;font-size: 12px;">Order Number : ' + this.t.orderNumber + '</p>' +
                        '<p style="padding: 0;margin: 0;font-size: 11px;">Issued Date : ' + kendo.toString(new Date(), "MMMM dd, hh:ss: tt") + '</p><br>' +
                        '<p style="padding: 0;margin: 0;font-size: 16px;font-weight: bold;">Sale Unit : ' + sname + '</p>' +
                        '<table style="width: 100%; ">' +
                            '<thead>'+
                                '<tr>' +
                                    '<th style="width: 80%; border: 1px solid black;padding: 5px;">Item</th>' +
                                    '<th style="width: 20%; border: 1px solid black;padding: 5px;">Qty</th>' +
                                '</tr>' +
                            '</thead>'
                        ;
                    var htmlEnd =
                        '</table>' + '</div></div></body>' +
                        '</html>';
                    var item = "";
                    data.forEach(v=>{
                        item += '<tr><td style="border: 1px solid black;padding: 5px;">' + v.name + '</td><td style="border: 1px solid black;padding: 5px;">' + v.qty + '<td></tr>';
                    })
                    var w = window.open();
                    // window.console.log(htmlStart, 'start html')
                    var html = htmlStart + item + htmlEnd;
                    let self = this
                    $(w.document.body).html(html);
                    setTimeout(function() {
                        w.print();
                        if(!self.g.template.mobileDevice){
                            w.close();
                        }
                    }, 100);
                }
            },
            //update stock
            async updateStock(key, stock, sstock){
                if(loadIndex){
                    const t = db.transaction(instituteId + "items", "readwrite");
                    const objectStore = t.objectStore(instituteId + "items");
                    const request = objectStore.get(key);
                    let self = this;
                    request.onsuccess = ()=> {
                        const item = request.result;
                        // window.console.log(item, key, stock, 'update stock')
                        item.stock = stock;
                        item.sstock = sstock;
                        objectStore.put(item);
                        self.itemDatas.forEach(upi=>{
                            if(upi.id == key){
                                upi.stock = stock
                                upi.sstock = sstock
                            }
                        })
                    }
                }else{
                    setTimeout(() => {
                        this.updateStock(key, stock, sstock)
                    }, 300);
                }
            },
            // save 
            async saveTxn(){
                // check session
                let session = localStorage.getItem(instituteId + 'session')
                if(session == null || session == 'null'){
                    this.$snotify.error(i18n.t('require_session'))
                    return
                }
                if(session.name == 'NoName'){
                    this.$snotify.error(i18n.t('you_do_not_have_session'))
                    this.showLoading = false
                    return
                }
                // loyalty
                this.t.isTopUpCounter = this.isTopUpCounter
                this.t.isTopUpGCard = this.isTopUpGCard
                this.t.isSaleVoucher = this.isSaleVoucher
                this.t.isSaleCard = this.isSaleCard
                this.t.isSaleGCard = this.isSaleGCard
                if(this.isTopUpCounter || this.isSaleCard || this.isSaleVoucher){
                    this.guestCount.localMen = 1
                    this.t.guestCount = this.guestCount
                }
                // condition is sale
                if(this.isSale == 1){
                    if(parseFloat(this.t.amtReceipt) < parseFloat(this.t.total)){
                        this.t.amtReceipt = this.t.total
                        // this.$snotify.error(i18n.t('amount_receipt_must_over_amount_pay'))
                        // return
                    }
                }
                // set customer
                this.t.customer = {
                    id: this.g.defaultCustomer.id,
                    type: 'general',
                    name: this.g.defaultCustomer.name
                }
                // order number
                this.t.orderNumber = this.orderNumber
                // item line
                // check kitchen
                let kitCate = []
                if(this.g.allowKitchenKit){
                    let kcat = this.g.kitchenKitCategories
                    if(kcat.length > 0){
                        kcat.forEach(c=>{
                            kitCate.push(c.id)
                        })
                    }
                }
                // check label print
                let labelCate = []
                if(this.g.labelPName){
                    let lcat = this.g.labelCate
                    if(lcat.length > 0){
                        lcat.forEach(c=>{
                            labelCate.push(c.id)
                        })
                    }
                }
                // print order
                let poCate = []
                if(this.g.printOrder){
                    let pcat = this.g.printOrderCate
                    if(pcat.length > 0){
                        pcat.forEach(c=>{
                            poCate.push(c.id)
                        })
                    }
                }
                let line = []
                let printCats = []
                let oCates = []
                let emptext = ''
                let stocklines = []
                let stockItems = []
                let sItems = []
                let labele = 1
                let labelItems = []
                this.lineDS.forEach(itm=>{
                    let bdate = ''
                    if(this.t.type != 'serving'){
                        bdate = new Date()
                    }
                    let countStock = itm.countStock || false
                    itm.plTax = this.numberSetting(itm.plTax)
                    itm.vat = this.numberSetting(itm.vat)
                    itm.spTax = this.numberSetting(itm.spTax)
                    line.push({
                        id: 'lin-' + uuid.v1(),
                        name: itm.name,
                        otherName: itm.otherName ? itm.otherName : '',
                        number: itm.number,
                        description: itm.description,
                        amount: itm.amount,
                        price: itm.price,
                        qty: itm.qty,
                        uom: {
                            id: itm.uom.id,
                            price: itm.uom.price,
                            conversionRate: itm.uom.conversionRate,
                            name: itm.uom.toUOM.name
                        },
                        itemId: itm.itemId,
                        discount: itm.discount,
                        tax: this.numberSetting(itm.plTax + itm.vat + itm.spTax),
                        plTax: itm.plTax,
                        spTax: itm.spTax,
                        vat: itm.vat,
                        otherCharge: itm.otherCharge,
                        categoryId: itm.categoryId,
                        groupId: itm.groupId,
                        modifierAmt: itm.modifierAmt,
                        modifiers: itm.modifiers,
                        employees: itm.employees,
                        comission: itm.comission,
                        orderDate: itm.orderDate,
                        billDate: bdate,
                        countStock: countStock,
                        saleComission: itm.saleComission || 0,
                        sku: itm.sku,
                        subGroup: itm.subGroup || '',
                        productId: itm.productId || '',
                        barcode: itm.barcode || '',
                        stock: itm.stock || 0,
                        sstock: itm.sstock || 0,
                        cost: itm.cost || 0
                    })
                    if(itm.employees.length > 0){
                        itm.employees.forEach(emp=>{
                            emptext += ',' + emp.name
                        })
                    }
                    if(itm.isNew == 1){
                        if(kitCate.length > 0){
                            if($.inArray(itm.categoryId, kitCate) != -1) {
                                printCats.push({
                                    id: itm.id,
                                    itemId: itm.itemId,
                                    description: itm.description,
                                    otherName: itm.otherName,
                                    name: itm.name,
                                    categoryId: itm.categoryId,
                                    number: itm.number,
                                    qty: itm.qty
                                })
                            }
                        }
                        if(poCate.length > 0){
                            if($.inArray(itm.categoryId, poCate) != -1) {
                                oCates.push({
                                    name: itm.description,
                                    qty: itm.qty
                                })
                            }
                        }
                        //check label print
                        if(this.g.labelPName){
                            if($.inArray(itm.categoryId, labelCate) != -1) {
                                let salunit = this.t.saleUnit || {}
                                labelItems.push({
                                    orderNum: this.t.orderNumber,
                                    saleunitname: salunit.name || 'No',
                                    itmName: itm.description
                                })
                                // this.printLabel(this.t.orderNumber, salunit.name || 'No', itm.description, labele)
                                // labele++
                            }
                        }
                        //stock
                        if(countStock){
                            if(stockItems.length == 0){
                                stockItems.push(itm.itemId)
                                sItems.push({
                                    itemId: itm.itemId,
                                    stock: parseInt(itm.stock),
                                    sstock: parseInt(itm.sstock),
                                    cost: itm.cost || 0,
                                    price: itm.price
                                })
                            }else{
                                if($.inArray(itm.itemId, stockItems) == -1) {
                                    stockItems.push(itm.itemId)
                                    sItems.push({
                                        itemId: itm.itemId,
                                        stock: parseInt(itm.stock),
                                        sstock: parseInt(itm.sstock),
                                        cost: itm.cost || 0,
                                        price: itm.price
                                    })
                                }
                            }
                        }
                    }
                })
                if(emptext != ''){
                    emptext = emptext.substring(1)
                    this.t.employee = emptext
                }
                //label printer
                if(this.g.allowLabelP && labelItems.length > 0){
                    labelItems.forEach(l=>{
                        this.printLabel(l.orderNum, l.saleunitname, l.itmName, labele, labelItems.length)
                        labele++
                    })
                }
                // check stock item
                let txnStocks = []
                if(sItems.length > 0){
                    sItems.forEach(s=>{
                        let items = line.filter((o) => {return o.itemId == s.itemId})
                        let qty = 0
                        if(items.length > 0){
                            items.forEach(q=>{
                                qty += parseInt(q.qty)
                            })
                        }
                        stocklines.push({
                            id: s.itemId,
                            stock: parseInt(s.stock) - qty,
                            sstock: parseInt(s.sstock) - qty,
                        })
                        txnStocks.push({
                            itemId: s.itemId,
                            stock: parseInt(s.stock),
                            sstock: parseInt(s.sstock),
                            cost: s.cost || 0,
                            price: s.price,
                            qty: qty
                        })
                    })
                }
                this.t.stockItems = txnStocks
                let salunit = this.t.saleUnit || {}
                if(printCats.length > 0){
                    let kdata = {
                        id: uuid.v1(),
                        item: printCats,
                        orderNumber: this.t.orderNumber,
                        saleUnit: salunit.name || '',
                        orderDate: new Date().getTime()
                    }
                    let k = JSON.stringify(kdata)
                    const db = getDatabase();
                    set(ref(db, 'kitchen/' + this.activeStore.pk), {
                        data: JSON.parse(k)
                    });
                }
                if(oCates.length > 0){
                    this.printOrder(oCates)
                }
                if(line.length <= 0){
                    this.$snotify.error(i18n.t('item_required'))
                    return
                }
                this.t.itemLines = line //JSON.stringify(line)
                // price level
                this.t.priceLevel = this.g.defaultPriceLevel
                // register
                this.t.register = this.register.pk
                // session
                this.t.session = {
                    id: this.session.pk,
                    name: this.session.openBy,
                    number: this.session.number
                }
                // store
                this.t.store =  this.activeStore.pk
                // exchange
                this.exchange.base = 0
                this.exchange.secondary = 0
                this.t.exchange = this.exchange
                // exchange rate
                this.t.exchangeRate = this.secondRate.rate + ' ' + this.secondRate.code
                this.t.isSale = this.isSale
                // warehoue
                this.t.warehouseId = this.activeStore.warehouseId
                // phone number
                if(this.phoneNumber != ''){
                    this.t.phoneNumber = this.phoneNumber
                }
                // partner
                if(this.isPartnerPay){
                    let pBy = this.t.paidBy
                    if(this.activePartner.settlementMethod == 'credit'){
                        pBy = this.activePartner.settlementMethod
                    }
                    this.t.paidBy = this.activePartner.name + ' (' + pBy + ')'
                    let p = this.activePartner
                    this.t.customer = {
                        id: p.pk,
                        type: 'partner',
                        name: p.name,
                    }
                }
                // loyalty
                if(this.hasLoyaltyCard){
                    this.t.customer = this.loyaltyCustomer
                }
                // credit card
                if(this.isStoreCredit){
                    let creditcard = this.t.creditCard
                    this.t.customer = {
                        id: creditcard.id,
                        name: creditcard.name,
                        type: creditcard.group
                    }
                }
                // invoice 
                if(this.dailogInv){
                    this.t.customer = {
                        id: this.activeCustomer.id,
                        name: this.activeCustomer.name,
                        type: this.activeCustomer.group
                    }
                    this.t.isCredit = true
                }
                // discount card
                if(Object.keys(this.t.discountCard).length > 0){
                    this.t.customer = {
                        id: this.t.discountCard.id,
                        type: this.t.discountCard.group,
                        name:  this.t.discountCard.name
                    }
                }
                // window.console.log(this.t.type, 'txn type')
                // pint pay
                if(Object.keys(this.t.pointPaid).length > 0){
                    this.t.customer = {
                        id: this.t.pointPaid.cardId,
                        type: this.t.pointPaid.group,
                        name:  this.t.pointPaid.name
                    }
                }
                if(this.t.type == 'sale'){
                    this.t.billDate = new Date()
                    this.t.timeOut = kendo.toString(new Date(), 'hh:mm tt')
                }
                if(this.t.type != 'serving'){
                    if(this.t.type != 'internal'){
                        await this.loadTxnNumber()
                        this.t.timeOut = kendo.toString(new Date(), 'hh:mm tt')
                        this.t.billDate = new Date()
                    }else{
                        this.t.txnNumber = ''
                        this.t.timeOut = kendo.toString(new Date(), 'hh:mm tt')
                        this.t.billDate = new Date()
                    }
                }
                if(this.t.type == 'serving'){
                    this.t.orderDate = new Date()
                    this.t.billDate = ''
                }
                this.t.pinUser = this.activePin
                this.t.instId = instituteId
                this.showLoadingTxn = true
                // window.console.log(this.t, 'before save')
                if(this.isSaleGCard || this.isTopUpGCard){
                    this.t.giftCard = {
                        id: this.activeSellGCard.pk,
                        number: this.activeSellGCard.number
                    }
                }
                // set shift
                if(this.session.shifts.length > 0){
                    this.t.shift = this.session.shifts.length + 1
                }
                setTimeout(()=>{
                    if(navigator.onLine){
                        try {
                            commerceHandler.txnCreate(new PosTransactionModel(this.t)).then(res=>{
                                window.console.log(res, 'respone')
                                if(res == undefined){
                                    this.showLoadingTxn = false
                                    this.$snotify.error(i18n.t('sth_went_wrong_check_your'))
                                    return
                                }
                                if(res.data.statusCode == 201){
                                    let printInv = true
                                    window.console.log(this.t, 'res back')
                                    this.printObj = new PosTransactionModel(this.t) //res.data.data\
                                    this.createPrintReceipt(this.printObj)
                                    // update serving
                                    if(this.isEditServing){
                                        this.bindServing()
                                    }
                                    // stock
                                    if(stocklines.length > 0){
                                        stocklines.forEach(s=>{
                                            this.updateStock(s.id, s.stock, s.sstock)
                                        })
                                    }
                                    // send track
                                    if(this.t.type != 'serving'){
                                        if(this.g.telTxnrGroup != ''){
                                            let citems = []
                                            line.forEach(l=>{
                                                citems.push({
                                                    id: l.id,
                                                    description: l.name,
                                                    qty: l.qty,
                                                    price: l.price,
                                                    uom: l.uom.name || '',
                                                    tax: l.tax,
                                                    discount: l.discount,
                                                    itemId: l.itemId
                                                })
                                            })
                                            this.trackTxn = {
                                                telegram: this.g.telTxnrGroup,
                                                items: citems,
                                                total: this.t.total,
                                                otherC: this.t.otherCharge,
                                                tax: this.t.tax,
                                                issuedDate: this.t.issuedDate
                                            }
                                            this.savePoint(res.data.data.pk, res.data.data.txnNumber, 'txntrack', parseFloat(this.pointTopUpRate.topUpRate))
                                        }
                                    }
                                    // set point
                                    if(Object.keys(this.t.pointEarn).length > 0){
                                        this.savePoint(res.data.data.pk, res.data.data.txnNumber, 'earn', parseFloat(this.pointTopUpRate.topUpRate))
                                    }
                                    if(Object.keys(this.t.pointPaid).length > 0){
                                        this.savePoint(res.data.data.pk, res.data.data.txnNumber, 'paid', parseFloat(this.pointTopUpRate.topUpRate))
                                        // printInv = true
                                        // this.dialogPrint = true
                                        // // this.showCardInv = false 
                                        // // setTimeout(()=>{
                                        //     this.showCardInv = true
                                        // // }, 300)
                                        // // setTimeout(()=>{
                                        //     this.printHtml()
                                        // // }, 1000)
                                    }
                                    if(this.t.isCredit){
                                        printInv = false
                                        this.showCardInv = false
                                        setTimeout(()=>{
                                            this.showCardInv = true
                                        }, 300)
                                        this.dialogPrint = true
                                        setTimeout(()=>{
                                            this.printHtml()
                                        }, 500)
                                    }
                                    // campaign alert admin
                                    if(this.alertAdmins.length > 0){
                                        if(this.t.type != 'serving'){
                                            if(this.g.telegramGroup != ''){
                                                this.alertAdmin = {
                                                    telegram: this.g.telegramGroup,
                                                    items: this.alertAdmins,
                                                    total: this.t.total,
                                                    issuedDate: this.t.issuedDate
                                                }
                                                this.savePoint(res.data.data.pk, res.data.data.txnNumber, 'alertadmin', parseFloat(this.pointTopUpRate.topUpRate))
                                            }
                                        }
                                    }
                                    // store credit
                                    if(Object.keys(this.t.creditCard).length > 0){
                                        // this.saveStoreCTxn(this.t.creditCard)
                                        let citems = []
                                        line.forEach(l=>{
                                            citems.push({
                                                id: l.id,
                                                description: l.name,
                                                qty: l.qty,
                                                price: l.price,
                                                uom: l.uom.name || '',
                                                tax: l.tax,
                                                discount: l.discount,
                                                itemId: l.itemId
                                            })
                                        })
                                        this.t.creditCard.items = citems
                                        this.t.creditCard.amount = this.t.total
                                        this.savePoint(res.data.data.pk, res.data.data.txnNumber, 'storecredit', '')
                                        setTimeout(()=>{
                                            this.printHtml()
                                            this.dialogPrint = false
                                        }, 500)
                                    }
                                    // set local invoice
                                    if(this.t.type == 'invoice'){
                                        if(this.isPartnerPay){
                                            this.setLocalInvoice(res.data.data)
                                        }
                                        printInv = false
                                        this.showCardInv = false
                                        setTimeout(()=>{
                                            this.showCardInv = true
                                        }, 300)
                                        this.dialogPrint = true
                                        setTimeout(()=>{
                                            this.printHtml()
                                        }, 500)
                                    }else if(this.t.type == 'serving'){
                                        this.setLocalServing(res.data.data)
                                        printInv = false
                                    }else if(this.t.type == 'preorder'){
                                        this.setLocalPreOrder(res.data.data)
                                        printInv = false
                                        this.showCardInv = false
                                        setTimeout(()=>{
                                            this.showCardInv = true
                                        }, 300)
                                        this.dialogPrint = true
                                        setTimeout(()=>{
                                            this.printHtml()
                                        }, 500)
                                    }else if(this.t.type == 'internal'){
                                        // this.setLocalPreOrder(res.data.data)
                                        printInv = false
                                        this.showCardInv = false
                                        setTimeout(()=>{
                                            this.showCardInv = true
                                        }, 300)
                                        this.dialogPrint = true
                                        setTimeout(()=>{
                                            this.printHtml()
                                        }, 500)
                                    }
                                    // set sale unit
                                    if(Object.keys(this.t.saleUnit).length > 0 && this.t.type == 'serving'){
                                        // window.console.log('in serving process')
                                        this.updateSaleUnit(this.t.saleUnit.id, 0)
                                    }
                                    if(this.isServing){
                                        this.delIndxdb(this.activeServing.pk, 'serving', 'serving')
                                        const sdstatus = this.g.saleUnitStatus || 1
                                        this.updateSaleUnit(this.t.saleUnit.id, sdstatus)
                                    }
                                    if(this.payInvoice){
                                        this.delIndxdb(this.activeInvoice.pk, 'invoices', 'invoice')
                                    }
                                    if(this.isUseParkSale){
                                        this.delLocalDB(this.activeParksale.pk, this.parkSaleTxns, 'parkSale')
                                    }
                                    let d = {
                                        number: parseInt(this.t.lastOrderNum) + 1,
                                        date: new Date().toISOString().substr(0, 10)
                                    }
                                    if(this.isRegisterCard){
                                        printInv = false
                                        this.savePrintCard()
                                    }
                                    if(this.isTopUp){
                                        this.savePoint(res.data.data.pk, res.data.data.txnNumber,'top_up', parseFloat(this.pointTopUpRate.topUpRate))
                                    }
                                    if(this.isSaleGCard){
                                        this.updateGiftCard(this.activeSellGCard.pk, 'sold', 'giftcard', res.data.data.pk, '')
                                    }
                                    localStorage.setItem(instituteId + this.register.pk + 'ordernumber', JSON.stringify(d))
                                    if(this.t.type == 'topup'){
                                        printInv = false
                                        this.dialogPrint = false
                                    }
                                    if(printInv){
                                        this.printHtml()
                                    }
                                    this.$snotify.success(i18n.t('successful'))
                                    this.showLoadingTxn = false
                                    if(!this.dialogPrint){
                                        this.resetOrder()
                                    }
                                }else{
                                    this.showLoadingTxn = false
                                    this.$snotify.error(i18n.t('sth_went_wrong_check_your'))
                                }
                            })
                        } catch (e) {
                            console.log('error ', e)
                            this.showLoadingTxn = false
                            this.$snotify.error(i18n.t('sth_went_wrong_check_your'))
                        }
                    }else{
                        // offline
                        let txn = new PosTransactionModel(this.t)
                        let tnum =  localStorage.getItem(instituteId + 'txnNum')
                        localStorage.setItem(instituteId + 'txnNum', parseInt(tnum) + 1)
                        this.setLocalTxn(txn)
                        // set dd
                        this.showLoadingTxn = false
                        let printInv = true
                        this.printObj = txn
                        this.createPrintReceipt(this.printObj)
                        // stock
                        if(stocklines.length > 0){
                            stocklines.forEach(s=>{
                                this.updateStock(s.id, s.stock, s.sstock)
                            })
                        }
                        if(this.t.isCredit){
                            printInv = false
                            this.showCardInv = false
                            setTimeout(()=>{
                                this.showCardInv = true
                            }, 300)
                            this.dialogPrint = true
                            setTimeout(()=>{
                                this.printHtml()
                            }, 500)
                        }
                        // set local invoice
                        if(this.t.type == 'invoice'){
                            if(this.isPartnerPay){
                                this.setLocalInvoice(txn)
                            }
                            printInv = false
                            this.showCardInv = false
                            setTimeout(()=>{
                                this.showCardInv = true
                            }, 300)
                            this.dialogPrint = true
                            setTimeout(()=>{
                                this.printHtml()
                            }, 500)
                        }else if(this.t.type == 'serving'){
                            this.setLocalServing(txn)
                            printInv = false
                        }else if(this.t.type == 'preorder'){
                            this.setLocalPreOrder(txn)
                            printInv = false
                            this.showCardInv = false
                            setTimeout(()=>{
                                this.showCardInv = true
                            }, 300)
                            this.dialogPrint = true
                            setTimeout(()=>{
                                this.printHtml()
                            }, 500)
                        }else if(this.t.type == 'internal'){
                            // this.setLocalPreOrder(res.data.data)
                            printInv = false
                            this.showCardInv = false
                            setTimeout(()=>{
                                this.showCardInv = true
                            }, 300)
                            this.dialogPrint = true
                            setTimeout(()=>{
                                this.printHtml()
                            }, 500)
                        }
                        // set sale unit
                        if(Object.keys(this.t.saleUnit).length > 0 && this.t.type == 'serving'){
                            // window.console.log('in serving process')
                            this.updateSaleUnit(this.t.saleUnit.id, 0)
                        }
                        if(this.isServing){
                            this.delIndxdb(this.activeServing.pk, 'serving', 'serving')
                            const sdstatus = this.g.saleUnitStatus || 1
                            this.updateSaleUnit(this.t.saleUnit.id, sdstatus)
                        }
                        if(this.payInvoice){
                            this.delIndxdb(this.activeInvoice.pk, 'invoices', 'invoice')
                        }
                        if(this.isUseParkSale){
                            this.delLocalDB(this.activeParksale.pk, this.parkSaleTxns, 'parkSale')
                        }
                        let d = {
                            number: parseInt(this.t.lastOrderNum) + 1,
                            date: new Date().toISOString().substr(0, 10)
                        }
                        if(this.isRegisterCard){
                            printInv = false
                            this.savePrintCard()
                        }
                        localStorage.setItem(instituteId + this.register.pk + 'ordernumber', JSON.stringify(d))
                        if(this.t.type == 'topup'){
                            printInv = false
                            this.dialogPrint = false
                        }
                        if(printInv) this.printHtml()
                        this.$snotify.success(i18n.t('successful'))
                        this.resetOrder()
                        // this.loadServerData()
                    }
                }, 500)
            },
        },
        components: {
            LoadingMe: () => import(`@/components/Loading`),
            gridFullScreen: () => import("@/components/gridFullScreen"),
            'app-datepicker': DatePickerComponent,
            PrintReceipt: () => import("./PrintReceipt"),
            CashCount: () => import("./CashCount"),
            PrintSession: () => import("./PrintSession"),
            PreOrder: () => import("./PreOrder"),
            PrintCard: () => import("./PrintCard"),
            PrintPointQR: () => import("./PrintPointQR"),
            PrintShift: () => import("./PrintShift"),
            // Calculator: Calculator,
            VOffline,
            downloadexcel: downloadexcel,
        },
        computed:{
            lang() {
                return "/" + i18n.locale;
            },
            cashReceiptText(){
                return i18n.t('cash_receipt')
            },
            rePrint(){
                return i18n.t('re_print')
            },
            voidText(){
                return i18n.t('void')
            },
            useText(){
                return i18n.t('use')
            },
            // searchCodeBys(){
            //     return [
            //         {id: 1,name: i18n.t('sku')},
            //         {id: 2,name: i18n.t('batch_number')},
            //         {id: 3,name: i18n.t('serial_number')},
            //         {id: 4,name: i18n.t('variant_sku')},
            //         {id: 5,name: i18n.t('barcode_upc')},
            //     ]
            // },
            genders(){
                return [
                    {id: 'male',name: i18n.t('male')},
                    {id: 'female',name: i18n.t('female')},
                ]
            },
            isAllowShift(){
                if(parseInt(this.register.shfits) <= 0){
                    return false
                } 
                let cushift = this.session.shifts.length
                if(cushift == parseInt(this.register.shifts)){
                    return false
                }
                return true
            },
            showCancelODF(){
                if(parseInt(this.startFlowAt) == 1){
                    return true
                }
                return false
            },
            showBackODF(){
                if(parseInt(this.startFlowAt) > 1 && parseInt(this.startFlowAt) <= this.g.orderFlow.length){
                    return true
                }
                return false
            },
            dateSorters() {
                return [
                    {id: 1, name: i18n.t('today')},
                    {id: 2, name: i18n.t('this_week')},
                    {id: 3, name: i18n.t('this_month')},
                    {id: 4, name: i18n.t('this_year')}
                ]
            }
        },
        mounted: async function () {
            if(navigator.onLine){
                let institute = localStorage.getItem('institute')
                if(institute != null){
                    dataStore.institute = {
                        instituteId: institute.id
                    }
                    this.checkIndexDB()
                    await this.loadSaleFormContent()
                    await this.loadPin()
                    await this.loadCurrency()
                    await this.loadCardGroup()
                    await this.loadNational()
                    this.sesChange()
                }else{
                    delete_cookie("banhji-counter-token" + process.env.VUE_APP_MODE)
                    window.location.reload()
                }
            }else{
                this.$snotify.error(i18n.t('please_connect_internet'))
                return
            }
            this.reportGroup = [
                {
                    field: "shift",
                    aggregates: this.aggregateDefinition,
                },
            ]
        }
    };
</script>
<style scoped>
    /* .v-window, .v-window__container, .v-window-item{
        height: 100%;
    } */
    #pin > .theme--light.v-input input {
        max-height: 32px;
        font-size: 2rem !important;
        text-align: center;
    }
    .v-image__image--cover {
        background-size: contain;
        margin-top: 5px;
    }
    .v-image__image {
        background-position: top center !important;
    }
    .b-cash:before {
        color: #ffffff;
    }   
    .b-loyalty:before {
        content: "\e91b";
        color: #ffffff;
    }
    .b-full:before, .b-mini:before {
        color: #ffffff;
    }
    .btn-right .v-btn__content i{
       font-size: 40px;
    }
    .btn-right .v-btn__content{
       display: block !important;
    }
    h6{
       color: #2a2b2b;
       font-size: 0.6rem !important; 
       font-family: "Niradei-bold", serif !important;
       padding-top: 5px;
    }
    
    .btn-right{
        background-color: #ffffff !important;
        border-bottom: 1px solid;
        border-color: #e5e5e6 !important;
        width: 100%;
        height: 12% !important;
        text-align: center;
        padding: 0 3px !important;
        border-radius: 0px !important;  
    }
    .v-tab {
        justify-content: right;
        font-size: 20px;
    }

    .v-tab--active {
        background-color: rgb(255, 255, 255);
    }

    .tab_setting .v-tab--active {
        font-weight: 700;
        color: #000;
    }

    .v-tab--active {
        background-color: #ffffff !important;
        border-bottom: 4px solid #92d050;
        border-left: none;
    }

    p {
        color: rgba(0, 0, 0, 0.87);
    }
    .theme--light.v-tabs-items {
        background-color: #FFFFFF;
        height: 550px !important;
        overflow: scroll !important;
    }
    
    .sale-container{
        max-width: 1870px !important;
    }
    .theme--light.v-tabs-items {
        background-color: #ffffff00 !important;
    }
    .card-item{
        background-color: #ffffff00;
        border: 0.2px solid #dcdcdc33;
        border-radius: 0;
    }
    .catagory-btn{
        width: 100%;
        background-color: #ffffff !important;
        border: 0.2px solid #e4e4e6;
    }
    .v-btn__content{
        font-family: "Niradei-Bold", serif !important;
        display: block !important;
    }
    .btn_h2{
        font-size: 22px;
    }
    .sidebar-left{
        -webkit-box-flex: 0;
        -ms-flex: 0 0 12.666667%;
        flex: 0 0 12.666667%;
        max-width: 12.666667%;
    }
    .sidebar-left2{
        -webkit-box-flex: 0;
        -ms-flex: 0 0 38.666667%;
        flex: 0 0 38.666667%;
        max-width: 38.666667%;
    }
    .sidebar-left3{
    -webkit-box-flex: 0;
    -ms-flex: 0 0 7.000000%;
    flex: 0 0 7.000000%;
    max-width: 7.000000%;
    }
    .container h2 {
        font-size: 17px;
        margin-bottom: 0;
    }
    .btn-sidebar{
        justify-content: space-between !important;
        background-color: #fff !important;
        font-family: "Niradei-bold", serif !important;
    }

    .b-payment:before {
        content: "\e91f";
        color: #ffffff;
    }
    .b-banking:before {
        content: "\e90e";
        color: #ffffff;
    }
    .btn-money {
        background-color: #969696 !important;
        color: white;
    }
    
    .calculator{
        border: 1px solid gainsboro;
    }
    .receipt-btn{
        min-width: 22% !important;
        height: 50px !important;
        padding: 10px !important;
        margin: 5px;
    }
    .apply-btn{
        min-width: 33% !important;
        height: 50px !important;
        padding: 5px !important;
        margin: 5px;
    }
    .calculator-btn{
       min-width: 18% !important;
        margin: 4px;
        padding: 0 10px !important;
    }
    .calculator-btn-1{
         min-width: 24% !important;
        height: 50px !important;
        margin: 1px;
        padding: 0 2px !important;
        font-size: 17px !important;
        letter-spacing: inherit;
        font-weight: bold;
    }
    .pay-btn{
        width: 100%;
        border: 1px solid #e0e0e0;
        height: 100% !important;
        border-radius: 0px !important;
        border-color: white !important;
    }
    .v-text-field__detail .field-pos{
        display:none !important;
    }
    .btn-funtion{
        font-size: 14px;
        width: 100% !important;
        display: flex;
        justify-content: space-between;
    }
    .btn-funtion1{
        font-size: 14px;
        width: 100% !important;
        display: flex;
        justify-content: center;
    }
    .function_content {
        padding: 0px;
        border-bottom: none !important;
        background-color: #f8f8f9;
    }
    .notification{
        background-color: #ed263a;
        width: auto;
        margin-left: 2px;
        color: #fff;

    }
    .v-btn.v-size--default {
        font-size: 1.2rem;
    }
    .b-search.b:before {
        color: #d7d3d3 !important;
    }
    /* pin */
    body {
      height: 95vh;
      background-color: #181c26 !important;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      font-family: Open Sans;
    }

    body.wrong {
      -webkit-animation: bg-red 1s ease-in;
      animation: bg-red 1s ease-in;
    }

    body.correct {
      -webkit-animation: bg-green 1s ease-in;
      animation: bg-green 1s ease-in;
    }

    #inspiration {
      position: fixed;
      right: 1em;
      bottom: 1em;
    }

    #inspiration a {
      display: inline-block;
      text-decoration: none;
      font-weight: bold;
      color: white;
      -webkit-transition: all 1s ease;
      transition: all 1s ease;
    }

    #inspiration a:hover { color: #212121; }

    #inspiration p {
      margin: 0;
      padding-left: .4em;
      display: inline-block;
      color: rgba(255, 255, 255, 0.6);
    }
    #pin {
        background-color: #ffffff !important;
        width: 90%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        /* padding: 1em; */
        border-radius: .3em;
        /* box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3); */
        margin: auto;
        color: rgb(155 27 46);;
        }

    .dots {
      width: 50%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
      justify-content: space-around;
      padding: 1em;
      padding-top: 3em;
    }

    .dot {
      position: relative;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 0.8em;
      width: 0.8em;
      height: 0.8em;
      -webkit-transform: scale3d(0.7, 0.7, 0.7);
      transform: scale3d(0.7, 0.7, 0.7);
    }

    .dot.active {
      -webkit-animation: growDot .5s ease;
      animation: growDot .5s ease;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
    }

    .dot.wrong {
      -webkit-animation: wrong .9s ease;
      animation: wrong .9s ease;
    }

    .dot.correct {
      -webkit-animation: correct .9s ease;
      animation: correct .9s ease;
    }

    .cancelPin {
      width: 25%;
      margin-left: 10%;
      margin-top: 10%;
    }
    .letter_spacing{
        letter-spacing:initial;
        font-size: 14px !important;
    }

    #pin p { font-size: 1.2em; }

    .numbers {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
      justify-content: space-around;
      -webkit-align-content: flex-end;
      -ms-flex-line-pack: end;
      align-content: flex-end;
      margin: 1em 0;
    }

    .number {
      position: relative;
      width: 2.5em;
      height: 2.5em;
      margin: 0.5em;
      border-radius: 2.5em;
      border: 2px solid rgb(154 27 46);
      text-align: center;
      line-height: 2.5em;
      font-weight: 400;
      font-size: 1.8em;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-transition: all .5s ease;
      transition: all .5s ease;
      cursor: pointer;
    }

    .number:hover { color: rgba(243, 134, 134, 0.5); }

    .number:hover:before { border: 2px solid rgba(255, 255, 255, 0.5); }

    .number:before {
      content: "";
      position: absolute;
      left: -2px;
      width: 2.5em;
      height: 2.5em;
      border: 2px solid rgba(255, 255, 255, 0.1);
      border-radius: 2.5em;
      -webkit-transition: all .5s ease;
      transition: all .5s ease;
    }
    .number.grow:before {
        -webkit-animation: grow .6s ease;
        animation: grow .6s ease;
        }
    @-webkit-keyframes 
    growDot {  100% {
    background: white;
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
    }
    }
    @keyframes 
    growDot {  100% {
    background: white;
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
    }
    }
    @-webkit-keyframes 
    grow {  50% {
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
    transform: scale3d(1.5, 1.5, 1.5);
    }
    100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    }
    }
    @keyframes 
    grow {  50% {
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
    transform: scale3d(1.5, 1.5, 1.5);
    }
    100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    }
    }
    @-webkit-keyframes 
    wrong {  20% {
    background: crimson;
    }
    40% {
    -webkit-transform: translate(-15px, 0);
    transform: translate(-15px, 0);
    }
    60% {
    -webkit-transform: translate(10px, 0);
    transform: translate(10px, 0);
    }
    80% {
    -webkit-transform: translate(-5px, 0);
    transform: translate(-5px, 0);
    }
    }
    @keyframes 
    wrong {  20% {
    background: crimson;
    }
    40% {
    -webkit-transform: translate(-15px, 0);
    transform: translate(-15px, 0);
    }
    60% {
    -webkit-transform: translate(10px, 0);
    transform: translate(10px, 0);
    }
    80% {
    -webkit-transform: translate(-5px, 0);
    transform: translate(-5px, 0);
    }
    }
    @-webkit-keyframes 
    correct {  20% {
    background: limegreen;
    }
    40% {
    -webkit-transform: translate(0, -15px);
    transform: translate(0, -15px);
    }
    60% {
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
    }
    80% {
    -webkit-transform: translate(0, -5px);
    transform: translate(0, -5px);
    }
    }
    @keyframes 
    correct {  20% {
    background: limegreen;
    }
    40% {
    -webkit-transform: translate(0, -15px);
    transform: translate(0, -15px);
    }
    60% {
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
    }
    80% {
    -webkit-transform: translate(0, -5px);
    transform: translate(0, -5px);
    }
    }
    @-webkit-keyframes 
    bg-red {  50% {
    background: crimson;
    }
    }
    @keyframes 
    bg-red {  50% {
    background: crimson;
    }
    }
    @-webkit-keyframes 
    bg-green {  50% {
    background: limegreen;
    }
    }
    @keyframes 
    bg-green {  50% {
    background: limegreen;
    }
    }
    #pin >.v-input input {
        text-align: center !important;
        font-size: 35px !important;
    }
    .td-invioce td{
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .tb-pong td{
        border-bottom: none !important;
        height: 25px !important;
    }
    .th-invoice th{
      border-top: thin solid rgba(0, 0, 0, 0.12) !important;  
    }
    .v-list-item-left {
        padding: 0 10px !important;
    }
    .theme--light.v-divider{
        border-color: rgb(0 0 0 / 0%);
    }


    /* Surface pro */
    @media only screen and (min-width : 1400px){
        .btn-funtion{
            font-size: 14px !important;
        }
        .v-application--is-ltr .v-btn__content .v-icon--left {
            margin-right: 8px;
        }
        .v-btn__content .v-icon.v-icon--left, .v-btn__content .v-icon.v-icon--right {
            font-size: 18px;
            height: 18px;
            width: 18px;
        }
        .calculator-btn{
            font-size: 14px;
        }
        
    }
   @media only screen and (min-device-height : 720px) and (max-device-width : 1280px) {
    
        /** Surface Pro styles here **/
        .btn-funtion{
            font-size: 12px !important;
        }
        .v-application--is-ltr .v-btn__content .v-icon--left {
            margin-right: 5px;
        }
        .v-btn__content .v-icon.v-icon--left, .v-btn__content .v-icon.v-icon--right {
            font-size: 16px;
            height: 10px;
            width: 10px;
        }
        .calculator-btn{
            font-size: 12px;
        }
    }
    .angle_action {
        right: 0;
        z-index: 10;
        position: absolute;
        top: 60px;
    }
    .angle_action_small {
        right: 12px;
        z-index: 10;
        position: absolute;
        top: 60px;
    }
    .b-order_type:before {
        content: "\e933";
        color: #ffffff;
    }
    .b-reward_s:before {
        content: "\e937";
        color: #ffffff;
        font-size: 40px;
    }
    .b-promotion:before {
        content: "\e936";
        color: #ffffff;
        font-size: 40px;
    }
    .b-count_guest:before {
        content: "\e935";
        color: #ffffff;
        font-size: 40px;
    }
    .b-note_s:before {
        content: "\e932";
        color: #ffffff;
        font-size: 40px;
    }
    .b-delivery_s:before {
        content: "\e931";
        color: #ffffff;
        font-size: 40px;
    }
    .b-parksale:before {
        content: "\e934";
        color: #ffffff;
        font-size: 35px;
    }
    .b-invoice_s:before {
        content: "\e930";
        color: #ffffff;
        font-size: 35px;
    }
    .b-refresh:before {
        content: "\e92e";
        color: #ffffff;
        font-size: 40px;
    }
    .v-tabs--vertical > .v-tabs-bar .v-tab {
        height: auto !important;
        -webkit-box-pack: left;
        -ms-flex-pack: left;
        justify-content: space-evenly;
        text-align: center !important;
        margin-top: 0px;
        padding-top: 20px;
        padding-bottom: 20px;
        border: 1px solid gainsboro;
        font-size: 14px;
    }
    .v-tabs-items {
        background-color:  #f8f8f9 !important;
    }
    /* .row {
        margin-top: 0!important;
    } */
</style>